import axios from "axios";
import { get, post, put } from "../../api/client";
import {
  BOOKING_STATUS,
  BookingUpdateStatus,
  JobStatus,
  UpdateRequestStatus,
  UpdateRequestedBy,
} from "../../constants/booking";
import { Booking, BookingUpdateRequest, CorporateBooking } from "../../models";

const getBookingById = (bookingId: string) => get(`bookings/${bookingId}`);

const getLastBookings = (accessToken: string) => {
  return axios.get("/bookings/last?channel=web", {
    params: {
      accessToken,
    },
  });
};

const getServices = () => {
  return get("api/v3/service?global=yes&channel=web");
};

const sendUpdateRequest = (bookingId: string | null, data: any) =>
  post(`api/v2/bookings/update-request/${bookingId}`, data);

const confirmBookingCancel = (
  bookingId: number,
  accessToken: string | null,
  cancelRecurringBooking: boolean,
  reason: string
) =>
  post(`api/v2/bookings/confirm-cancel/${bookingId}`, {
    accessToken,
    cancelRecurringBooking,
    reason,
  });

const confirmUpdateBooking = (bookingId: number, accessToken: string | null, jobId: number) =>
  put(`api/v2/bookings/update-request/${bookingId}/accept`, { accessToken }, { jobId });

const declineUpdateBooking = (bookingId: number, accessToken: string | null, jobId: number) =>
  put(`api/v2/bookings/update-request/${bookingId}/decline`, { accessToken }, { jobId });

const retryPayment = (bookingId: number) => put(`payments/retry/${bookingId}`, {});

const settleDeclinedPayment = (
  bookingId: number,
  body: { paymentmethodId: string | number | null | undefined; paymentType: string }
) => put(`api/v2/bookings/settle-declined-payment/${bookingId}`, body);

const requestBookingCancel = (
  bookingId: number,
  body: {
    accessToken: string | null;
    reason: string | null;
    withFutureBookings?: boolean;
    onlyFutureBookings?: boolean;
  }
) => axios.post(`api/v2/bookings/request-cancel/${bookingId}`, body);

const getBookingCancellationReason = (bookingId: number) =>
  axios.get(`api/v2/bookings/cancel-options?bookingId=${bookingId}`);

const verifyBookingCancellationReason = (
  bookingId: number,
  body: { reason: string; accessToken: string | null }
) => axios.post(`/api/v2/bookings/verify-cancel-reason/${bookingId}`, body);

const publishBookingToAllPros = (bookingId: Number, accessToken: string | null) =>
  axios.post(`/api/v2/bookings/${bookingId}/publish?accessToken=${accessToken}`);

// keep booking after update request is declined
const keepDeclinedBooking = (bookingId: number) =>
  post(`api/v2/bookings/update-request/${bookingId}/keep`, {});

// checks if any job is confirmed for booking
const checkAnyJobConfirmed = (bookingdetails: any) => {
  let jobConfirmed = false;
  if (bookingdetails && bookingdetails.length > 0) {
    for (let i = 0; i < bookingdetails.length; i += 1) {
      const { job } = bookingdetails[i];
      if (job.status === JobStatus.accepted) {
        jobConfirmed = true;
        break;
      }
    }
  }
  return jobConfirmed;
};

const getBookingUpdateDetails = (
  currentUserId: number,
  requestedUpdates: BookingUpdateRequest[] = [],
  jobId?: number | null
) => {
  if (!currentUserId || !requestedUpdates) return null;
  if (requestedUpdates.length > 0) {
    let updateRequest = requestedUpdates.find(
      (request: BookingUpdateRequest) => request.clientId === currentUserId
    );

    if (jobId) {
      updateRequest = requestedUpdates.find(
        (request: BookingUpdateRequest) =>
          request.clientId === currentUserId && request.jobId === jobId
      );
    }

    if (updateRequest) {
      const requestDetail: any = {
        pro: updateRequest.pro,
        isRequestedByPro: updateRequest.requestedBy === UpdateRequestedBy.pro,
        isRequestByClient: updateRequest.requestedBy === UpdateRequestedBy.client,
        requestDetails: updateRequest.requestDetails,
        status: updateRequest.status,
        isPending: updateRequest.status === UpdateRequestStatus.pending,
        isDeclined: updateRequest.status === UpdateRequestStatus.declined,
        jobId: updateRequest.jobId,
      };

      const isAllBookingRequestAccepted = requestedUpdates.every(
        (request) => request.status === UpdateRequestStatus.accepted
      );
      const isAllBookingRequestDeclined = requestedUpdates.every(
        (request) => request.status === UpdateRequestStatus.declined
      );

      if (
        (!isAllBookingRequestAccepted &&
          !isAllBookingRequestDeclined &&
          !jobId &&
          requestDetail.isRequestByClient) ||
        (requestDetail.isPending && requestDetail.isRequestByClient)
      ) {
        requestDetail.jobStatus = BookingUpdateStatus.requestByClient;
      }
      return requestDetail;
    }
  }
  return null;
};

const fetchBookingPrice = (params: any) => get("bookings/price", params);

const isManualBookingUnpaid = (booking: Booking) => {
  const isUnpaid = !booking.isActuallyPaid;
  return booking.isManual && isUnpaid;
};

const isUnpaidHomeCareBooking = (booking: Booking) => {
  return booking.isHomeCare && !booking.isActuallyPaid;
};

const isCorprateBooking = (booking: Booking) => {
  const { sessionType } = booking;
  return ["corporate", "groupbooking"].includes(sessionType);
};

const isAfterPayUnpaidBooking = (booking: Booking) => {
  const isUnpaid = !booking.isActuallyPaid;
  const isArranged = booking.status === BOOKING_STATUS.ARRANGED;
  const isCompleted = booking.status === BOOKING_STATUS.COMPLETED;
  return booking.afterpayInitiatedAt && isUnpaid && (isArranged || isCompleted);
};

const isUnPaidCancelledBookingWithDueGreaterZero = (booking: Booking) => {
  const isUnpaid = !booking.isActuallyPaid;
  const isCancelled = booking.status === BOOKING_STATUS.CANCELLED;
  const dueGreaterZero = (booking.totalAmountDue || 0) > 0;

  return isUnpaid && isCancelled && dueGreaterZero;
};

const getPriceForTimeOfArrival = (bookingId: number, timeOfArrival: string) => {
  const URL = `api/v2/bookings/${bookingId}/updated-price?timeOfArrival=${timeOfArrival}`;
  return get(URL);
};

const makeCorporateBooking = (payload: CorporateBooking) => {
  //Make booking for Corporate
  const URL = "api/v2/bookings/corporate";
  return post(URL, payload);
};

const updateCorporateBooking = (bookingId: number, payload: any) => {
  const URL = `api/v2/bookings/corporate/${bookingId}`;
  return put(URL, payload);
};

const updateCorporateBookingSlots = (payload: {
  uuid: string;
  breakStartTime: string;
  breakEndTime: string;
}) => {
  const URL = `api/v2/bookings/corporate/${payload.uuid}/update-slots`;
  return post(URL, payload);
};

export {
  checkAnyJobConfirmed,
  confirmBookingCancel,
  confirmUpdateBooking,
  declineUpdateBooking,
  fetchBookingPrice,
  getBookingById,
  getBookingCancellationReason,
  getBookingUpdateDetails,
  getLastBookings,
  getPriceForTimeOfArrival,
  getServices,
  isAfterPayUnpaidBooking,
  isCorprateBooking,
  isManualBookingUnpaid,
  isUnPaidCancelledBookingWithDueGreaterZero,
  isUnpaidHomeCareBooking,
  keepDeclinedBooking,
  makeCorporateBooking,
  publishBookingToAllPros,
  requestBookingCancel,
  retryPayment,
  sendUpdateRequest,
  settleDeclinedPayment,
  updateCorporateBooking,
  updateCorporateBookingSlots,
  verifyBookingCancellationReason,
};
