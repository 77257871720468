import { isNil } from "lodash";
import moment, { Moment, unitOfTime } from "moment";
import momentTz from "moment-timezone";
import { RecurringType, TIME_PICKER_DIFFERENCE_IN_MINUTES } from "../constants/booking";
import { GRACE_TIME_FOR_CLIENT } from "../constants/prodashboard";
import { BOOKING_SESSION_TYPE } from "../helpers/booking";
import { MonthData } from "../stores/booking";
import { getValue } from "./object";
import { checkIsString } from "./string";

const getTimeFromDate = (date: string) => {
  return moment(date).isValid() ? moment(date).format("HH--mm") : null;
};

const getCurrentWeekDay = (date: string) => {
  // set monday as first of the week
  return moment(date).isValid() ? moment(date).isoWeekday() - 1 : null;
};

const getTimeTitle = (date: string, timezone?: string, removeWhiteSpace: boolean = true) => {
  if (!moment(date).isValid()) return null;
  let formattedDate;
  if (timezone) {
    formattedDate = moment(date).tz(timezone).format("h:mm a");
  } else {
    formattedDate = moment(date).format("h:mm a");
  }
  return removeWhiteSpace ? formattedDate.replace(/\s/g, "") : formattedDate;
};

const getDiffInHours = (startDate: string, endDate: string) => {
  const momentStartDate = moment(startDate).isValid() ? moment(startDate) : null;
  const momentEndDate = moment(startDate).isValid() ? moment(endDate) : null;

  if (!momentStartDate || !momentEndDate) {
    return null;
  }

  return (momentStartDate.diff(momentEndDate, "minutes") / 60).toFixed(3);
};

const getBookingDurationInHour = (startDate: string, endDate: string) => {
  const momentStartDate = moment(startDate).isValid() ? moment(startDate) : null;
  const momentEndDate = moment(endDate).isValid() ? moment(endDate) : null;
  if (!momentStartDate || !momentEndDate) {
    return null;
  }

  return momentEndDate.diff(momentStartDate, "minutes") / 60;
};

const getWeekDays = () => {
  const weekStart = moment().startOf("isoWeek");

  const days = [];

  // generate for a week
  for (let i = 0; i <= 6; i++) {
    let weekDay = moment(weekStart).add(i, "days");
    days.push({ title: weekDay.format("dddd"), value: i });
  }

  return days;
};

const getDaysBetween = (start: Moment | Date, end: Moment | Date) => {
  const dates = [];

  const currDate = moment(start).startOf("day");
  const lastDate = moment(end).endOf("day");

  do {
    const nextDay = currDate.clone();
    dates.push({
      monthName: nextDay.format("MMMM"),
      month: nextDay.format("M"),
      date: nextDay.format("D"),
      year: nextDay.format("YYYY"),
      day: nextDay.format("ddd"),
      fullDate: nextDay.format("yyyy-MM-DD 00:00:00"),
      weekDay: nextDay.isoWeekday() - 1,
    });
  } while (currDate.add(1, "days").diff(lastDate) < 0);

  return dates;
};

const getStartAndEndDates = (type: string, date?: string, change = 0) => {
  const formattedDate = moment(date || new Date());
  if (type === "week") {
    if (change) {
      formattedDate.add(change, "w");
    }

    // set monday as first day of the week
    return getDaysBetween(
      formattedDate.clone().startOf("isoWeek"),
      formattedDate.clone().endOf("isoWeek")
    );
  }

  if (change) {
    formattedDate.add(change, "d");
  }

  return getDaysBetween(formattedDate.startOf("day"), formattedDate.startOf("day"));
};

const getEndOfDay = (date: string) => {
  return moment(date).endOf("day").format("yyyy-MM-DD HH:mm:ss");
};

const formatDateWithTimezone = (
  date: string | Moment,
  timezone: string = "Australia/Sydney",
  format: string = "ddd, D MMM"
) => {
  let dateMoment = typeof date === "string" ? moment(date) : date;
  return dateMoment.tz(timezone).format(format);
};

const isCurrentDay = (time: any, timezone?: string) => {
  const currentMoment = timezone ? getCurrentTimeStamp(timezone) : moment();
  const timeMoment = timezone ? moment(time).tz(timezone) : moment(time);

  return timeMoment.isSame(currentMoment, "days");
};

// checks date object disregarding the given date object's time and timezone
const isDateObjectToday = (
  date: Date,
  timezone?: string,
  granularity: unitOfTime.StartOf = "days"
) => {
  const currentTimezoneMoment = getCurrentTimeStamp(timezone);
  const selectedDateMoment = convertDatetoTimezoneMoment(date, timezone);
  return currentTimezoneMoment.isSame(selectedDateMoment, granularity);
};

// REMINDER: DATE OBJECTS ARE IN LOCAL TIMEZONE, use isSameDay if not comparing Date objects
const isSameDate = (date1: Date, date2: Date, timezone?: string) => {
  const dateMoment1 = convertDatetoTimezoneMoment(date1, timezone);
  const dateMoment2 = convertDatetoTimezoneMoment(date2, timezone);
  return dateMoment1.isSame(dateMoment2);
};

// checks date object disregarding the given date object's time and timezone
const isFutureDateObject = (date: Date, timezone?: string) => {
  const currentTimezoneMoment = getCurrentTimeStamp(timezone);
  const selectedDateMoment = convertDatetoTimezoneMoment(date, timezone);
  return selectedDateMoment.isAfter(currentTimezoneMoment, "days");
};

const isCurrentDayMoment = (selectedMoment: Moment, timezone?: string) => {
  const currentMoment = timezone ? getCurrentTimeStamp(timezone) : moment();
  return selectedMoment.isSame(currentMoment, "days");
};

const convertTimeToMatchTimePicker = (time: moment.Moment): moment.Moment => {
  const timeInMinute = time.minutes();
  if (timeInMinute > 0 && timeInMinute % TIME_PICKER_DIFFERENCE_IN_MINUTES !== 0) {
    time = time.set({
      hour: time.hour(),
      minute:
        timeInMinute +
        TIME_PICKER_DIFFERENCE_IN_MINUTES -
        (timeInMinute % TIME_PICKER_DIFFERENCE_IN_MINUTES),
    });
    return time;
  }
  return time;
};

const getFutureTimeStamp = (time: {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}) => {
  const duration = moment.duration({
    days: time.days,
    hours: time.hours,
    minutes: time.minutes,
    seconds: time.seconds,
  });
  return moment().add(duration);
};

const getCurrentTimezone = () => {
  return momentTz.tz.guess();
};

const getCurrentTimeStamp = (timezone?: string) => (timezone ? moment().tz(timezone) : moment());

const convertToMoment = (dateString: string, timezone?: string | null) =>
  timezone ? momentTz.tz(dateString, timezone) : moment(dateString);

const getDifferenceInMinutesFromCurrentTime = (time: string) => {
  const timeMoment = moment(time);
  const now = moment();

  return now.diff(timeMoment, "minutes");
};

const getFormattedDiffForDeadline = (deadlineTime: string, allowNegative = true) => {
  const diffInMinutes = getDifferenceInMinutesFromCurrentTime(deadlineTime) * -1;

  if (!allowNegative && diffInMinutes < 0) {
    return "0m";
  }

  const MINUTES_IN_DAY = 1440; // 24*60
  const diffDays = Math.floor(diffInMinutes / MINUTES_IN_DAY);
  const remainingMinutes = diffInMinutes % MINUTES_IN_DAY;
  const diffHours = Math.floor(remainingMinutes / 60);
  const diffMinutes = remainingMinutes % 60;

  const text = `${diffDays ? `${diffDays}d ` : ""}${diffHours ? `${diffHours}h ` : ""}${
    diffMinutes ? `${diffMinutes}m` : ""
  }`;
  return text;
};

const getMomentTimeInMinutes = (selectedMoment: Moment) =>
  selectedMoment.hours() * 60 + selectedMoment.minutes();

const getCurrentTimeInMinutes = (timezone?: string, bufferMinutes = 0) => {
  const currentMoment = timezone ? getCurrentTimeStamp(timezone) : moment();
  return getMomentTimeInMinutes(currentMoment) + bufferMinutes;
};

const getDateStringInMinutes = (date: string, timezone: string) => {
  const selectedMoment = convertToMoment(date, timezone);
  return getMomentTimeInMinutes(selectedMoment);
};

const getCurrentDateMoment = (timezone?: string) => {
  const selectedMoment = timezone ? momentTz.tz(timezone) : moment();
  return selectedMoment.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
};

// changes date object to timezone moment (without converting date using timezone)
const convertDatetoTimezoneMoment = (selectedDate: Date, timezone?: string) => {
  if (checkIsString(selectedDate) || !selectedDate) return getCurrentDateMoment(timezone);
  const selectedMoment = timezone ? momentTz.tz(timezone) : moment();
  return selectedMoment.set({
    year: selectedDate.getFullYear(),
    month: selectedDate.getMonth(),
    date: selectedDate.getDate(),
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
};

// returns current day in the given timezone (date object - YYYY-MM-DD 00:00 localTimezone)
const getCurrentDayInTimezone = (timezone?: string) => {
  const currentMoment = getCurrentTimeStamp(timezone);
  return convertDayMomentToDate(currentMoment);
};

// reset time from date object
const resetTimeFromDate = (selectedDate: Date) => {
  const momentDate = moment(selectedDate);
  return new Date(momentDate.year(), momentDate.month(), momentDate.date());
};

// converts moment to date object - (YYYY-MM-DD 00:00 local timezone)
const convertDayMomentToDate = (selectedMoment: Moment) => {
  return new Date(selectedMoment.year(), selectedMoment.month(), selectedMoment.date());
};

interface CorporateEndTimeProps {
  startTimeInMinutes: number | null;
  durationInMinutes: number;
  timezone?: string;
  format?: string;
}
const getCorporateEndTime = ({
  startTimeInMinutes,
  durationInMinutes,
  timezone,
  format = "hh:mm a",
}: CorporateEndTimeProps): string => {
  if (!startTimeInMinutes || !durationInMinutes) {
    return "";
  }
  const endTimeInminutes = startTimeInMinutes + durationInMinutes;
  const momentTime = timezone
    ? moment().tz(timezone).startOf("day").add(endTimeInminutes, "minutes")
    : moment().startOf("day").add(endTimeInminutes, "minutes");

  return momentTime.format(format);
};

const getTimeOfArrivalWithGraceTime = (timeOfArrival: string | undefined) => {
  const timezone = moment.tz.guess();
  return moment(timeOfArrival).tz(timezone).add(GRACE_TIME_FOR_CLIENT.VALUE, "minutes");
};

const convertDateToUserTimezone = (start: string | null, end: string | null) => {
  const timezone = moment.tz.guess();
  const startTime = moment.tz(start, timezone).format("YYYY-MM-DD[T]HH:mm:ss");
  const endTime = moment.tz(end, timezone).format("YYYY-MM-DD[T]HH:mm:ss");
  return { startTime, endTime };
};

const formatTime = (date: string, format = "YYYY-MM-DD[T]HH:mm:ss") => {
  const timezone = moment.tz.guess();
  return moment.tz(date, timezone).format(format);
};

const formatDate = ({
  date,
  timezone,
  format = "YYYY-MM-DD",
}: {
  date: Date | string;
  timezone?: string;
  format?: string;
}) => {
  return timezone ? moment(date).tz(timezone).format(format) : moment(date).format(format);
};

const subtractDateTime = (date: string, minute: number) => {
  const timezone = moment.tz.guess();
  const updatedDate = moment(date).subtract(minute, "minutes").toISOString();
  const time = moment(updatedDate).tz(timezone).format("YYYY-MM-DD[T]HH:mm:ss");
  return time;
};

const addDateTime = (date: string, minute: number) => {
  const timezone = moment.tz.guess();
  const updatedDate = moment(date).add(minute, "minutes").toISOString();
  const time = moment(updatedDate).tz(timezone).format("YYYY-MM-DD[T]HH:mm:ss");
  return time;
};

const getMinutesFormAddedTime = (date: string, addTime: number) => {
  const time = addDateTime(date, addTime);
  return moment(time).minutes();
};

const getMinutesFormSubtractedTime = (date: string, subtractTime: number) => {
  const time = subtractDateTime(date, subtractTime);
  return moment(time).minutes();
};

const calcDiffBySubtracting = (date: string, time: number) => {
  const time1 = subtractDateTime(date, time);
  const time2 = formatTime(date);
  const hour1 = moment(time1).hours();
  const hour2 = moment(time2).hours();
  return { hour1, hour2 };
};

const calcTimeDiffBetweenBookingStart = (date: string, time: number) => {
  const time1 = subtractDateTime(date, time);
  const time2 = formatTime(date);

  const calcHour = moment(time1).hours();
  const hour1 = calcHour < 6 ? 6 : calcHour;
  const hour2 = moment(time2).hours();
  return hour2 - hour1;
};

const calcTimeDiffBetweenBookingEnd = (bookingStart: string, bookingEnd: string, time: number) => {
  const time1 = formatTime(bookingEnd);
  const time2 = addDateTime(bookingEnd, time);
  const time3 = formatTime(bookingStart);
  const hour1 = moment(time1).hours();
  const hour2 = moment(time2).hours();
  const diff = hour2 - hour1;
  if (hour2 === 0 || hour2 < hour1) {
    return 24 - moment(time3).hours();
  } else if (moment(time2).minutes() === 0 && diff === 1) {
    return 0;
  } else {
    return hour2 - hour1;
  }
};

const calcDiffByAddding = (start: string, end: string, time: number) => {
  const time1 = formatTime(start);
  const time2 = addDateTime(end, time);
  const time3 = formatTime(end);
  const day1 = moment(time1).days();
  const day2 = moment(time2).days();
  const hour = moment(time3).hours();
  let hourDiff = 0;
  if (hour === 0) {
    hourDiff = 1;
  } else if (hour === 23) {
    hourDiff = 2;
  } else {
    hourDiff = 24 - hour + 1;
  }
  return { day1, day2, hourDiff };
};

const addBufferTimeBeforeBooking = (date: string, time: number): number => {
  const bookingStartWithBuffer = subtractDateTime(date, time);
  const bookingStartHourWithBuffer = moment(bookingStartWithBuffer).hours();
  return bookingStartHourWithBuffer < 6 ? 6 : bookingStartHourWithBuffer;
};

const addBufferTimeAfterBooking = (date: string, addTime: number) => {
  const bookingEnd = formatTime(date);
  const bookingEndWithBuffer = addDateTime(date, addTime);
  const bookingEndHour = moment(bookingEnd).hours();
  const bookingEndHourWithBuffer = moment(bookingEndWithBuffer).hours();
  const bookingEndMinutesWithBuffer = moment(bookingEndWithBuffer).minutes();
  if (bookingEndHourWithBuffer < bookingEndHour && bookingEndHour === 0) {
    return 24;
  } else if (bookingEndMinutesWithBuffer === 0) {
    return bookingEndHourWithBuffer - 1;
  } else {
    return bookingEndHourWithBuffer;
  }
};

const getFormatRespectToCountry = (countryCode = "AU") => {
  switch (countryCode) {
  case "US":
    return "MM/DD/yyyy";
  case "AU":
    return "DD/MM/yyyy";
  default:
    return "DD/MM/yyyy";
  }
};

const formatDatePerCountry = (date: string, countryCode = "AU") => {
  return moment(date).format(getFormatRespectToCountry(countryCode));
};

interface FormattedJobTimeProps {
  earliestTime: string;
  latestTime: string;
  timezone: string;
  sessionType: string;
  timeOfArrival?: string;
  symbol?: string;
}

const getFormattedJobTime = ({
  earliestTime,
  latestTime,
  timezone,
  sessionType,
  timeOfArrival,
  symbol = "~",
}: FormattedJobTimeProps) => {
  const defaultTimeFormat = "h:mma";
  if (timeOfArrival) {
    return moment(timeOfArrival).tz(timezone).format(defaultTimeFormat);
  }

  if (
    sessionType === BOOKING_SESSION_TYPE.COUPLES ||
    moment(earliestTime).isSame(moment(latestTime))
  ) {
    return moment(earliestTime).tz(timezone).format(defaultTimeFormat);
  }

  return `${moment(earliestTime).tz(timezone).format(defaultTimeFormat)} ${symbol} ${moment(
    latestTime
  )
    .tz(timezone)
    .format(defaultTimeFormat)}`;
};

const isSameMoment = (date1: string, date2: string) => {
  return moment(date1).isSame(date2);
};

const isFutureMoment = (date: string, timezone: string) => {
  const selectedMoment = moment(date).tz(timezone);
  const current = moment().tz(timezone);
  return selectedMoment.isAfter(current);
};

const isValidDate = (date: any) => moment(date).isValid();

const getCurrentDate = (date?: any) => {
  if (isValidDate(date)) {
    return moment(date).format("YYYY-MM-DD");
  }
  return moment().format("YYYY-MM-DD");
};

const getDateObject = (date: Date | string | undefined | null, timezone?: string) => {
  if (isValidDate(date)) {
    const selectedMoment = timezone ? moment(date).tz(timezone) : moment(date);
    return selectedMoment.toDate();
  }
  return getCurrentTimeStamp(timezone).toDate();
};

const isSelectedDateBeforeEndDate = (date1: Date | string, date2: Date | string) => {
  return moment(date1).isBefore(date2);
};

const isBeforeCurrentDate = (date: Date, timezone?: string) => {
  const currentDate = getCurrentTimeStamp(timezone).startOf("day");
  const selectedDate = convertDatetoTimezoneMoment(date, timezone);
  return selectedDate.isBefore(currentDate);
};

// checks time only
const isSameTime = (date1: string, date2: string) => {
  const first = moment(date1);
  const second = moment(date2);

  const isSameHour = first.hours() === second.hours();
  const isSameMinutes = first.minutes() === second.minutes();

  return isSameHour && isSameMinutes;
};

const isSameDay = (
  date1: string,
  date2: string,
  timezone: string,
  granularity: unitOfTime.StartOf = "date"
) => {
  const momentOne = convertToMoment(date1, timezone);
  const momentTwo = convertToMoment(date2, timezone);
  return momentOne.isSame(momentTwo, granularity);
};

const getFormattedDateObject = (date: Date, timezone: string, format = "D MMM") => {
  const convertedMoment = convertDatetoTimezoneMoment(date, timezone);
  return convertedMoment.format(format);
};

const checkIsBetweenMoment = (date1: Moment, date2: Moment, selected: any) =>
  moment(selected).isBetween(date1, date2, null, "[]");

const checkIfBefore = (time1: Moment, time2: Moment, orEqual = false) =>
  orEqual ? time1.isSameOrBefore(time2) : time1.isBefore(time2);

const getDayStartMoment = (date: Moment | string, unit: unitOfTime.StartOf = "day") =>
  moment(date).startOf(unit);

const getDayEndMoment = (date: Moment | string, unit: unitOfTime.StartOf = "day") =>
  moment(date).endOf(unit);

const getTimeRangeForDate = ({ date, startTime, endTime, timezone }: any) => {
  const startMoment = convertToMoment(startTime, timezone);
  const endMoment = convertToMoment(endTime, timezone);

  const start = convertToMoment(date, timezone).set({
    hours: startMoment.hours(),
    minutes: startMoment.minutes(),
    seconds: 0,
  });
  const end = convertToMoment(date, timezone).set({
    hours: endMoment.hours(),
    minutes: endMoment.minutes(),
    seconds: 0,
  });
  return {
    start,
    end,
  };
};

const formatStringToMoment = ({
  date,
  format,
  timezone,
}: {
  date: string;
  format: string;
  timezone?: string;
}) => {
  const stringToMoment = moment(date, format);
  return timezone ? stringToMoment.tz(timezone) : stringToMoment;
};

const addMomentTime = (time: Moment, amount: number, unit: unitOfTime.DurationConstructor) =>
  time.add(amount, unit);

// REMAINDER: DO NOT implement utils function like this, NOT REUSABLE
const addTime = (time: string, timeToAdd: number = 30) => {
  const timeFormat = "h:mm a";

  const initialTime = moment(time, timeFormat);

  // Add 30 minutes to the initial time
  const newTime = initialTime.add(timeToAdd, "minutes");

  return newTime.format(timeFormat);
};

const convertToUtc = ({
  date,
  time,
  timezone,
}: {
  date: string;
  time: string;
  timezone: string;
}) => {
  const localTime = moment.tz(time, "hh:mm a", timezone);
  // convert utc booking time to local time
  const localEarliestTime = moment(date).tz(timezone);
  // set the current selected time to the local booking time
  const localMomentTime = localEarliestTime.set({
    hour: localTime.hours(),
    minute: localTime.minutes(),
  });
  // return the UTC time
  return localMomentTime.clone().utc().format();
};

const checkIfSameTime = ({
  time1,
  time2,
  format = "h:mm a",
}: {
  time1: string;
  time2: string;
  format?: string;
}) => {
  const t1 = moment(time1, format);
  const t2 = moment(time2, format);
  return t1.format("HH:mm") === t2.format("HH:mm");
};

const convertTimeToUtc = (date: string) => moment(date).utc();

const isCurrentDateBeforeProvidedDate = (timeOfArrival: string) => {
  const timeOfArrivalWithGraceTime = getTimeOfArrivalWithGraceTime(timeOfArrival).utc();
  const now = moment.utc();

  return now.isBefore(timeOfArrivalWithGraceTime);
};

const getTimeInRange = ({
  startTime,
  endTime,
  breakTime,
  timezone,
}: {
  startTime: string | null;
  endTime: string | null;
  breakTime: string | null;
  timezone: string | null;
}) => {
  const format = "h:mm a";
  const times = [];

  const startMoment = moment.utc(startTime).tz(timezone || "");

  const breakMinutes = moment
    .utc(breakTime)
    .tz(timezone || "")
    .minutes();

  const endMoment = moment.utc(endTime).tz(timezone || "");
  let currentMoment = startMoment.minute(breakMinutes);

  while (currentMoment.isSameOrBefore(endMoment)) {
    times.push(currentMoment.format(format));
    currentMoment.add(1, "hour");
  }

  return times;
};

const getTimeDifferenceInHour = ({ timeOfArrival }: { timeOfArrival: string }) => {
  const timeNow = moment.utc();
  const diffInHours = timeOfArrival ? moment(timeOfArrival).diff(timeNow, "hour") : 0;
  return diffInHours;
};

const getTimeDiffBetween = (
  start: string,
  end: string,
  diffIn?: "minutes" | "hour" | "days" | "months",
  precise?: boolean
) => {
  if (!start || !end) return 0;

  const startMoment = convertToMoment(start);
  const endMoment = convertToMoment(end);
  return Math.abs(startMoment.diff(endMoment, diffIn || "minutes", precise));
};

const getDayAndWeekOfMonth = (date: Date | null) => {
  if (!date) return;
  const weekOfMonth = Math.ceil(moment(date).date() / 7);
  const dayOfMonth = moment(date).date();

  return {
    dayOfMonth,
    weekOfMonth,
  };
};

const getDayOfWeek = (date: Date | Moment) => moment(date).weekday();

const getWeekOfMonth = (date: Date | Moment) => Math.ceil(moment(date).date() / 7);

const getDayOfMonth = (date: Date | Moment) => moment(date).date();

const getDateForSpecificWeekdayOfMonth = ({
  bookingDate,
  month,
}: {
  bookingDate: Moment;
  month: number;
}) => {
  const frequency = month;

  const currentDate = getCurrentDateMoment();

  let monthsToAdd = frequency;
  const weekOfMonth = getWeekOfMonth(bookingDate);
  const weekDay = getDayOfWeek(bookingDate);

  let nextMonth = bookingDate.clone().add(monthsToAdd, "months");

  // if nextRecurring date is in the past
  if (nextMonth.isBefore(currentDate)) {
    const bookingMonth = bookingDate.clone().startOf("month");
    const currentMonth = currentDate.clone().startOf("month");

    let diffInMonths = getTimeDiffBetween(
      bookingMonth.toString(),
      currentMonth.toString(),
      "months",
      true
    );
    monthsToAdd = Math.ceil(diffInMonths / frequency) * frequency;
    nextMonth = bookingDate.clone().add(monthsToAdd, "months");

    const currentMonthWeek = getWeekOfMonth(currentDate);
    const currentMonthDay = getDayOfWeek(currentDate);

    const thisMonthPassed =
      currentMonthWeek > weekOfMonth ||
      (currentMonthWeek === weekOfMonth && currentMonthDay > weekDay);

    // if next recurring is current month and day has already passed, use next date
    if (nextMonth.month() === currentDate.month() && thisMonthPassed) {
      nextMonth = nextMonth.clone().add(frequency, "months");
    }
  }
  nextMonth = nextMonth.startOf("month");

  let count = 0;
  let lastWeekday: Moment | null = null;

  for (let day = 1; day <= nextMonth.daysInMonth(); day += 1) {
    const date = nextMonth.clone().date(day);
    if (date.weekday() === weekDay) {
      count += 1;
      lastWeekday = date;
      if (count === weekOfMonth) {
        return date;
      }
    }
  }

  return lastWeekday;
};

const getNextBookingDateForRecurrence = ({
  date,
  monthData,
  frequency,
  type = RecurringType.WEEK,
}: {
  date: Date | string | null | undefined;
  monthData: MonthData | null;
  frequency: number | null | undefined;
  type?: RecurringType | null;
}): Moment | null => {
  if (!frequency) return null;
  const momentDate = moment(date);
  const currentDate = getCurrentDateMoment();

  const isMonthlyDay = !isNil(getValue(monthData, "dayOfMonth", null));
  const isMonthlyWeekDay = !isNil(getValue(monthData, "weekOfMonth", null));

  let nextDate: Moment | null = momentDate;

  // Note: completed/cancelled booking have next recurring date in the past
  if (isMonthlyDay) {
    nextDate = momentDate.clone().add(frequency, "month");
    if (nextDate.isSameOrBefore(currentDate)) {
      const diffInMonths = Math.ceil(
        getTimeDiffBetween(momentDate.toString(), currentDate.toString(), "months", true)
      );
      const monthsToAdd = Math.ceil(diffInMonths / frequency) * frequency;
      nextDate = momentDate.clone().add(monthsToAdd, "month");
    }
  } else if (isMonthlyWeekDay) {
    nextDate = getDateForSpecificWeekdayOfMonth({
      bookingDate: momentDate,
      month: frequency,
    });
  } else if (type === RecurringType.DAY) {
    nextDate = momentDate.clone().add(frequency, "days");
  } else {
    nextDate = momentDate.clone().add(frequency, "weeks");

    // handler if nextDate is in past
    if (nextDate.isSameOrBefore(currentDate)) {
      const diffInDays = getTimeDiffBetween(momentDate.toString(), currentDate.toString(), "days");
      const weeksToAdd = Math.ceil(diffInDays / (7 * frequency)) * frequency;
      nextDate = momentDate.clone().add(weeksToAdd, "weeks");
    }
  }

  return nextDate;
};

const getDayNameFromIndex = (index: number) => {
  return moment().day(index).format("dddd");
};


const getDay = (index: number) => {
  return moment().day(index).format("dddd");
};


export {
  addBufferTimeAfterBooking,
  addBufferTimeBeforeBooking,
  addMomentTime,
  addTime,
  calcDiffByAddding,
  calcDiffBySubtracting,
  calcTimeDiffBetweenBookingEnd,
  calcTimeDiffBetweenBookingStart,
  checkIfBefore,
  checkIfSameTime,
  checkIsBetweenMoment,
  convertDateToUserTimezone,
  convertDatetoTimezoneMoment,
  convertDayMomentToDate,
  convertTimeToMatchTimePicker,
  convertTimeToUtc,
  convertToMoment,
  convertToUtc,
  formatDatePerCountry,
  formatDateWithTimezone,
  formatStringToMoment,
  formatTime,
  getBookingDurationInHour,
  getCorporateEndTime,
  getCurrentDate,
  getCurrentDayInTimezone,
  getCurrentTimeInMinutes,
  getCurrentTimeStamp,
  getCurrentTimezone,
  getCurrentWeekDay,
  getDateForSpecificWeekdayOfMonth,
  getDateObject,
  getDateStringInMinutes,
  getDayAndWeekOfMonth,
  getDayEndMoment,
  getDayNameFromIndex,
  getDayOfMonth,
  getDayOfWeek,
  getDayStartMoment,
  getDiffInHours,
  getDifferenceInMinutesFromCurrentTime,
  getEndOfDay,
  getFormatRespectToCountry,
  getFormattedDateObject,
  getFormattedDiffForDeadline,
  getFormattedJobTime,
  getFutureTimeStamp,
  getMinutesFormAddedTime,
  getMinutesFormSubtractedTime,
  getMomentTimeInMinutes,
  getNextBookingDateForRecurrence,
  getStartAndEndDates,
  getTimeDiffBetween,
  getTimeDifferenceInHour,
  getTimeFromDate,
  getTimeInRange,
  getTimeOfArrivalWithGraceTime,
  getTimeRangeForDate,
  getTimeTitle,
  getWeekDays,
  getWeekOfMonth,
  isBeforeCurrentDate,
  isCurrentDateBeforeProvidedDate,
  isCurrentDay,
  isCurrentDayMoment,
  isDateObjectToday,
  isFutureDateObject,
  isFutureMoment,
  isSameDate,
  isSameDay,
  isSameMoment,
  isSameTime,
  isSelectedDateBeforeEndDate,
  resetTimeFromDate,
  subtractDateTime,
  formatDate,
};
