import React from "react";
import { CircularProgress } from "@material-ui/core";
import { User } from "../../../models";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { StripeConnectAccountType } from "../../../constants/stripe";
import { useAccountStatus } from "../../../hooks/stripe/connect/stripeConnect.hooks";
import { StripeConnectCustom, StripeConnectExpress } from "../../../components/Stripe/Connect";

interface StripeConnectProps {
  therapistUser: User | null;
}

export default function StripeConnect({ therapistUser }: StripeConnectProps) {
  const { data: accountStatusData, isLoading: isAccountStatusLoading } = useAccountStatus();

  const accountType = getValue(accountStatusData, "accountType");

  if (isAccountStatusLoading) {
    return <CircularProgress style={{ color: Colors.NightBlue }} thickness={4} size={24} />;
  }

  const isExpressAccount = accountType === StripeConnectAccountType.EXPRESS;

  return isExpressAccount ? (
    <StripeConnectExpress />
  ) : (
    <StripeConnectCustom accountStatus={accountStatusData} />
  );
}
