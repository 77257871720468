import { useState } from "react";
import BrowseResults from "./BrowseResults";
import BrowseTherapistForm from "./BrowseForm";
import { useMobile } from "../../../hooks/mobile";
import { Box, Grid, GridProps } from "@material-ui/core";
import { getPractitioners } from "../../../services/therapist/therapist.service";
import { useHistory } from "react-router-dom";

function BrowseTherapists(): JSX.Element {
  const history = useHistory();

  const [providers, setProviders] = useState<any>(null);
  const [pageDetails, setPageDetails] = useState({ currentPage: 1, pageCount: 0 });
  const [selectedOption, setSelectedOption] = useState<any>({
    serviceId: null,
    serviceName: null,
    latitude: null,
    longitude: null,
    treatmentId: null,
    languageSpoken: null,
  });

  const [isLoading, setLoading] = useState(false);

  const isMobile = useMobile();
  const pageSize = 8;

  const gridProps = {
    spacing: 3,
    style: {
      marginTop: "40px",
      marginLeft: isMobile ? undefined : "80px",
      marginBottom: "16px",
      padding: isMobile ? "0px 24px" : "0px",
      width: "auto",
      marginRight: "0px",
    },
    direction: isMobile ? "column" : undefined,
    alignItems: isMobile ? "center" : undefined,
  } as GridProps;

  const handleBrowse = async (
    selectedCords: { lat: number; long: number },
    serviceId: string,
    serviceName: string,
    treatmentId: string,
    languageSpoken: string,
    page: number = 1,
  ) => {
    setSelectedOption({
      serviceId,
      latitude: selectedCords.lat,
      longitude: selectedCords.long,
      serviceName,
      treatmentId,
      languageSpoken,
    });
    fetchProviders(
      selectedCords.lat,
      selectedCords.long,
      serviceId,
      serviceName,
      treatmentId,
      languageSpoken,
      page,
    );
  };

  const handlePageChange = async (page: number) => {
    fetchProviders(
      selectedOption.latitude,
      selectedOption.longitude,
      selectedOption.serviceId,
      selectedOption.serviceName,
      selectedOption.treatmentId,
      selectedOption.languageSpoken,
      page,
    );
  };

  const fetchProviders = async (
    latitude: number,
    longitude: number,
    serviceId: string,
    serviceName: string,
    treatmentId: string,
    languageSpoken: string,
    currentPage?: number,
  ) => {
    setLoading(true);
    try {
      let response = await getPractitioners(
        latitude,
        longitude,
        serviceId,
        serviceName,
        currentPage,
        treatmentId,
        languageSpoken,
        pageSize,
      );
      if (response.success && response.data) {
        let { totalCount, currentPage, therapistList } = response.data;
        const totalPages = Math.ceil(totalCount / pageSize);
        let pageDetails = { currentPage: currentPage, pageCount: totalPages };
        setPageDetails(pageDetails);

        const providers = therapistList;
        setProviders(providers);
      }
    } catch (err) {
      console.debug(err);
    }
    setLoading(false);
  };

  const removeQueryParams = () => {
    // Replace the current URL without query parameters
    history.replace(window.location.pathname);
  };

  const handleResetSearch = () => {
    removeQueryParams();
    setProviders(null);
  };

  const renderBrowse = () => {
    let selected;
    if (providers === null || (providers != null && providers.length == 0)) {
      let notFound = false;
      if (providers != null && providers.length == 0) {
        notFound = true;
      }
      selected = (
        <Grid container={isMobile ? false : true} {...gridProps}>
          <BrowseTherapistForm isLoading={isLoading} notFound={notFound} onBrowse={handleBrowse} />
        </Grid>
      );
    } else {
      selected = (
        <Grid {...gridProps}>
          <BrowseResults
            pageDetails={pageDetails}
            providers={providers}
            resetSearch={handleResetSearch}
            onPageChange={handlePageChange}
          />
        </Grid>
      );
    }
    return selected;
  };

  return <Box>{renderBrowse()}</Box>;
}

export default BrowseTherapists;
