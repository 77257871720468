export const COUNTRIES = [
  { value: "AU", title: "Australia" },
  { value: "NZ", title: "New Zealand" },
  { value: "US", title: "United States" },
];

const COUNTRY = {
  AU: "AU",
  GB: "GB",
  US: "US",
  NZ: "NZ",
};

type COUNTRY_OBJ = Record<string, string>;

const COUNTRIES_NAME: COUNTRY_OBJ = {
  AU: "Australia",
  GB: "United Kingdom",
  NZ: "New Zealand",
  US: "United States",
};

type CURRENCY_SYMBOL_OBJ = Record<string, string>;

const COUNTRIES_CURRENCY_SYMBOL: CURRENCY_SYMBOL_OBJ = {
  GB: "£",
  AU: "A$",
  US: "US$",
};

type CURRENCY_OBJ = Record<string, string>;

const COUNTRIES_CURRENCY: CURRENCY_OBJ = {
  GB: "GBP",
  AU: "AUD",
  US: "USD",
};

const SERVICE_COUNTRIES = ["AU", "US", "GB"];

const ALLOWED_COUNTRIES = ["AU", "NZ", "US", "GB"];

const ABN_VALIDATION_MESSAGE = {
  [COUNTRY.AU]: "Business Number",
  [COUNTRY.NZ]: "Business Number",
  [COUNTRY.US]: "License Number",
};

const ABN_REQUIRED_COUNTRIES = [COUNTRY.AU, COUNTRY.NZ, COUNTRY.US];

export {
  SERVICE_COUNTRIES,
  ALLOWED_COUNTRIES,
  COUNTRY,
  COUNTRIES_NAME,
  COUNTRIES_CURRENCY_SYMBOL,
  COUNTRIES_CURRENCY,
  ABN_REQUIRED_COUNTRIES,
  ABN_VALIDATION_MESSAGE,
};
