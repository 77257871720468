import { getUrlsFromText, whiteListedUrlsForChat } from "../../utils/url";
import { TRICKY_NUMBER_PATTERN_AU, TRICKY_NUMBER_PATTERN_US } from "./personaIdentifiableConfig";

const hasPhoneNumber = (text: string) => {
  const textWithoutUrl = removeUrlsFromText(text)
    .replaceAll(" ", "")
    .replaceAll(".", "")
    .replaceAll("-", "");
  return /(\(?\+?[0-9]{1,3}\)?[- ]?)?((\(?[0-9]{3,4}\)?|[0-9]{3,4})[- ]?)?([0-9]{3,4}[- ]?[0-9]{3,4}[- ]?[0-9]{3,4}|\b[A-Z0-9]{9,}\b)/.test(
    textWithoutUrl
  );
};

const hasEmailAddress = (text: string) => {
  return /([a-z0-9_\-.+]+)@\w+(\.\w+)*/gi.test(text);
};

const hasBlockedKeywords = (text: string) => {
  return /cash/gi.test(text);
};

const hasCashKeyword = (text: string) => {
  return /cash/gi.test(text);
};

const isWhitelisted = (url: string) => {
  return whiteListedUrlsForChat.some((pattern) => pattern.test(url));
};

const removeWords = (text: string, words: string[]) => {
  const pattern = new RegExp(words.join("|"), "gi");
  return text.replace(pattern, "");
};

const removeUrlsFromText = (text: string) => {
  const urls = getUrlsFromText(text);
  const escapedUrls = urls.map((url) => url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  return removeWords(text, escapedUrls);
};

const hasBannedUrls = (text: string) => {
  const urls = getUrlsFromText(text);
  return urls.some((url) => !isWhitelisted(url));
};

const hasTrickyPhoneNumberAU = (text: string) => {
  const str = text.toLowerCase().replaceAll(" ", "");

  return TRICKY_NUMBER_PATTERN_AU.some((txt) => str.startsWith(txt));
};

const hasTrickyPhoneNumberUS = (text: string) => {
  const str = text.toLowerCase().replaceAll(" ", "");

  return TRICKY_NUMBER_PATTERN_US.some((txt) => str.startsWith(txt));
};

const hasTrickyPhoneNumber = (text: string) => {
  return hasTrickyPhoneNumberAU(text) || hasTrickyPhoneNumberUS(text);
};

const hasPersonalInformation = (text: string) => {
  if (hasBannedUrls(text)) {
    return true;
  }

  const textWithoutUrl = removeUrlsFromText(text);

  return (
    hasPhoneNumber(textWithoutUrl) ||
    hasEmailAddress(textWithoutUrl) ||
    hasBlockedKeywords(textWithoutUrl) ||
    hasTrickyPhoneNumber(textWithoutUrl)
  );
};

export {
  hasPersonalInformation,
  hasPhoneNumber,
  hasTrickyPhoneNumberAU,
  hasBannedUrls,
  hasCashKeyword,
};
