import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { RootState } from "../../stores/V2";
import { getValue } from "../../utils/object";
import { Paths } from "../../constants/paths";
import { useMobile } from "../../hooks/mobile";
import Wrapper from "../../components/Wrapper";
import { Colors } from "../../constants/colors";
import OrderSummary from "./Components/OrderSummary/OrderSummary";
import SectionPageHeader from "../../components/SectionPageHeader";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import {
  getAllBookingQuestions,
  transformBookingData,
} from "../../stores/V2/booking/booking.transformer";
import { getServices, getBookingById } from "../../services/bookings/bookings.service";
import BookingDescription from "./Components/BookingDescription/BookingDescription";
import { BOOKING_STATUS } from "../../constants/booking";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import { setServiceRates, usePaymentStore } from "../../stores/booking";
import { DEFAULT_COUNTRY } from "../../utils/country";

interface Params {
  id?: string;
}

const ConfirmedBookingDetails = () => {
  const isMobile = useMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams<Params>();
  const booking = useSelector(({ booking }: RootState) => booking);
  const { setPaymentMethod, setPaymentType } = usePaymentStore();

  const bookingCountryCode = getValue(booking, "address.countryCode") || DEFAULT_COUNTRY;
  const { services } = useServiceRates(bookingCountryCode);

  const back = getValue(location, "state.back");
  const isRedirected = getValue(location, "state.isRedirected", false);

  useEffect(() => {
    setServiceRates(services);
  }, [bookingCountryCode, services]);

  useEffect(() => {
    initLoadBooking();
  }, []);

  const initLoadBooking = () => {
    const loadBooking = getValue(location, "state.initialize", true);
    if (loadBooking || !booking.id) {
      initialize();
    }
  };

  const initialize = async () => {
    try {
      if (!id) return;
      dispatch(bookingActions.toggleFetching({ isFetching: true }));

      Promise.all([getBookingById(id), getServices(), getAllBookingQuestions()])
        .then(([booking, allServices, questions]) => {
          if (booking) {
            const { status } = booking;
            const BookingStatus = [BOOKING_STATUS.ARRANGED, BOOKING_STATUS.DECLINED];
            if (!BookingStatus.includes(status)) history.replace(`/bookings/${id}`);
            const payload = transformBookingData(booking, allServices, questions);
            dispatch(bookingActions.initialize({ ...payload, allServices }));
            const paymentType =
              getValue(payload, "paymentmethod.type") || getValue(payload, "paymentType");
            setPaymentMethod(getValue(payload, "paymentmethod"));
            setPaymentType(paymentType);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    /* if (isRedirected) {
      history.goBack();
    } else if (back) {
      history.push(back);
    } else {
      history.push(Paths.Bookings);
    } */
    if (location.key) {
      history.goBack();
    } else {
      history.push(Paths.Bookings);
    }
  };

  const { title, subtitle } = booking;

  if (!booking) return <></>;

  return (
    <Wrapper>
      <SectionPageHeader
        title={title || ""}
        titleStyle={
          isMobile
            ? {
              fontFamily: "Museo",
              fontWeight: 700,
              fontSize: "20px",
              color: Colors.NightBlue,
              textAlign: "left",
            }
            : undefined
        }
        subtitleElement={
          <Box mt={1} fontSize={16} fontFamily={"Museo"} color={Colors.Dusk}>
            Booking #{id}
          </Box>
        }
        onBack={handleBack}
      />
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        ml={isMobile ? "24px" : "80px"}
        mr={isMobile ? "24px" : undefined}
        mt={isMobile ? "40px" : "42px"}
        mb={"16px"}
        alignItems={isMobile ? "center" : undefined}
      >
        <BookingDescription booking={booking} bookingMutate={initLoadBooking} />
        <OrderSummary booking={booking} id={id} />
      </Box>
    </Wrapper>
  );
};

export default ConfirmedBookingDetails;
