import { Box } from "@material-ui/core";
import React from "react";
import ContentSectionControl from "../../components/ContentSectionControl";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import Wrapper from "../../components/Wrapper";
import config from "../../config/proDashboard/dashboardConfig.json";
import { useMobile } from "../../hooks/mobile";
import { getLegalConfig } from "../../services/proDashboard/dashboardConfig.service";
import { useUserStore } from "../../stores/user";
import Identity from "./Business/Identity";
import Information from "./Business/Information";
import Legal from "./Business/Legal";
import Qualifications from "./Business/Qualifications";

const sections = ["Information", "Identity", "Qualifications", "Legal"];

export default function Business() {
  const isMobile = useMobile();

  const { user } = useUserStore();

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  const getProConfig = (config: any, country = "AU") => {
    // defaults to AU if country code is not found
    const countryConfig = config[country] || config["AU"];
    return countryConfig.proDashboard;
  };

  const { business } = getProConfig(config, user?.country);

  const legalConfig = getLegalConfig(user?.country);

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Business" />

        <Box mt="40px" ml={isMobile ? "16px" : "80px"} mr={isMobile ? "16px" : "80px"}>
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={(index) => setSelectedSectionIndex(index)}
          />

          {selectedSectionIndex === 0 && <Information informationConfig={business.information} />}

          {selectedSectionIndex === 1 && <Identity identityConfig={business.identity} />}

          {selectedSectionIndex === 2 && <Qualifications />}

          {selectedSectionIndex === 3 && <Legal legalConfig={legalConfig} />}
        </Box>
      </Wrapper>
    </>
  );
}
