import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { isLoggedIn } from "../../helpers/user";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../constants/paths";
import axios from "axios";
import { Booking } from "../../models";
import { checkIfEmpty, getValue } from "../../utils/object";
import { Colors } from "../../constants/colors";
import { useBookingStore } from "../../stores/booking";
import { fetchAddresses } from "../../stores/address";
import { useAccessToken } from "../../hooks/common";
import { parseQueryString } from "../../helpers/string";
import { getLastBookings } from "../../services/bookings/bookings.service";
import { transformSourceDetails } from "../../stores/V2/booking/booking.transformer";

export default function Landing() {
  const history = useHistory();
  const { search } = useLocation();
  const { setLastBooking, setSourceDetails, resetSourceDetails } = useBookingStore();
  const accessToken = useAccessToken();

  useEffect(() => {
    setNewSourceDetails();
    fetchLastBookingAndRedirect();
  }, []);

  const setNewSourceDetails = () => {
    try {
      const params = parseQueryString(search);
      if(!checkIfEmpty(params)){
        const source = transformSourceDetails(params);
        setSourceDetails(source);
      } else {
        resetSourceDetails();
      }
    } catch(e) {
      history.push(Paths.LocationDetails);
    }
  };

  const fetchLastBookingAndRedirect = async () => {
    if (accessToken) {
      const addresses = await fetchAddresses();
      getLastBookings(accessToken)
        .then((response) => {
          const lastBooking = response.data as Booking;
          if (lastBooking) {
            setLastBooking(lastBooking);
            history.push(Paths.ReviewAndBook);
          } else {
            const newPath = addresses.length > 0 ? Paths.SavedLocations : Paths.LocationDetails;
            history.push(newPath);
          }
        })
        .catch((error) => {
          // Cannot fetch last booking, just go to new booking flow
          history.push(Paths.LocationDetails);
        });
    } else {
      history.push(Paths.LocationDetails);
    }
  };


  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress style={{ color: Colors.NightBlue }} />
    </Box>
  );
}
