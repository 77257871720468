import { useMutation, useQuery } from "@tanstack/react-query";

import * as httpClient from "../../api/client";
import queryClient from "../../config/queryClient";

const KEYS = {
  COMMUNICATION_PREFERNCES_UNSUBBED: "COMMUNICATION_PREFERNCES_UNSUBBED",
  USER_COMMUNICATION_PREFERENCES: "USER_COMMUNICATION_PREFERENCES",
  PRO_COMMUNICATION_PREFERENCES: "PRO_COMMUNICATION_PREFERENCES"
};

export const useGetUserCommunicationPreferences = (proDash?: boolean) =>
  useQuery(
    [
      proDash
        ? KEYS.PRO_COMMUNICATION_PREFERENCES
        : KEYS.USER_COMMUNICATION_PREFERENCES
    ],
    () =>
      httpClient
        .get(
          `api/v2/settings/communication/preferences${proDash ? "/pro" : ""}`
        )
        .then(res => res)
  );

export const useUpdateUserCommunicationPreferences = ({
  onSuccess,
  onError
}: any) =>
  useMutation(
    (data?: any) =>
      httpClient.put("api/v2/settings/communication/preference", data),
    {
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
        queryClient.invalidateQueries({
          queryKey: [KEYS.USER_COMMUNICATION_PREFERENCES]
        });
        queryClient.invalidateQueries({
          queryKey: [KEYS.PRO_COMMUNICATION_PREFERENCES]
        });
        queryClient.invalidateQueries({
          queryKey: [KEYS.COMMUNICATION_PREFERNCES_UNSUBBED]
        });
      },
      onError
    }
  );

export const useIsCommunicationPreferenceUnsubbed = ( ) => 
  useQuery([KEYS.COMMUNICATION_PREFERNCES_UNSUBBED], () =>
    httpClient.get("api/v2/settings/communication/preferences/isUnsubscribed").then((res) => res.data)
  );

export const useUnsubscribeFromAll = ({ onSuccess, onError }: any) =>
  useMutation(
    (data?: any) =>
      httpClient.post(
        "api/v2/settings/communication/preferences/unsubscribe",
        data
      ),
    {
      onSuccess: res => {
        onSuccess(res);
        queryClient.invalidateQueries({
          queryKey: [KEYS.USER_COMMUNICATION_PREFERENCES]
        });
        queryClient.invalidateQueries({
          queryKey: [KEYS.PRO_COMMUNICATION_PREFERENCES]
        });
        queryClient.invalidateQueries({
          queryKey: [KEYS.COMMUNICATION_PREFERNCES_UNSUBBED]
        });
      },
      onError
    }
  );
