import { getValue } from "../../utils/object";
import { get, post, put } from "../../api/client";

const getAccountStatus = () => get("api/v2/stripe/connect/custom/account-status");
const createAccountLink = () => get("api/v2/stripe/connect/custom/create-account-link");
const createAccountSession = () => post("api/v2/stripe/connect/custom/account-session", {});
const initiateOnboarding = () => put("api/v2/stripe/connect/custom/initiate-onboarding", {});

const getExpressAccountStatus = () => get("api/v2/stripe/express/account-status");
const createExpressAccountLink = () => get("api/v2/stripe/express/create-account-link");

const getStripeAccount = async () => {
  const accountLinkResponse = await createAccountLink();
  return getValue(accountLinkResponse, "data", {});
};

export {
  getStripeAccount,
  getAccountStatus,
  createAccountLink,
  createAccountSession,
  initiateOnboarding,
  getExpressAccountStatus,
  createExpressAccountLink,
};
