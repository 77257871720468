import { Box } from "@material-ui/core";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { BOOKING_STATUS } from "../../../constants/booking";
import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import { Colors } from "../../../constants/colors";
import markerIcon from "../../../images/booking-marker.svg";
import { capitalizeFirstLetter } from "../../../helpers/string";
import Map from "../../../components/Map";
import { ADDRESS_DISCLAIMER } from "../../../constants/address";
import { useMobile } from "../../../hooks/mobile";
import LocationTypeIcon from "../../../images/locationTypeIcon.svg";
import NotesIcon from "../../../images/notesIcon.svg";
import StairsIcon from "../../../images/stairsIcon.svg";
import PetsIcon from "../../../images/petsIcon.svg";
import TableIcon from "../../../images/table.svg";
import ParkingIcon from "../../../images/parkingIcon.svg";
import ExactBookingMarker from "../../../images/exact-booking-marker.svg";
import { openMap } from "../../../services/map/map.service";

interface Props {
  status: string;
  address: any;
  hideSensativeData: boolean;
  showLocationDetails: boolean;
  isHairAndMakeup: boolean;
  openMapModal: () => void;
}

const AddressSection = ({
  openMapModal,
  address,
  status,
  hideSensativeData,
  isHairAndMakeup,
  showLocationDetails = true,
}: Props) => {
  const isMobile = useMobile();

  if (!address || checkIfEmpty(address)) return <Box />;

  const getFormattedLocationDetails = ({ address }: any) => {
    if (!address || checkIfEmpty(address)) return [];

    let locationType = getValue(address, "locationtype.label") || null;
    if (!locationType) {
      locationType = address?.type ? capitalizeFirstLetter(address.type) : null;
    }

    const fullLocationDetails = [
      {
        type: "locationType",
        value: locationType,
        icon: LocationTypeIcon,
      },
      {
        type: "parking",
        label: "Parking",
        value: address.parkingOption?.title,
        icon: ParkingIcon,
      },
      {
        type: "stairs",
        label: "Stairs",
        value: address.stairOption?.title,
        icon: StairsIcon,
      },
      {
        type: "pets",
        label: "Pets",
        value: address.petOption?.title,
        icon: PetsIcon,
      },
      // {
      //   type: "table",
      //   label: "",
      //   value: address.tableOption?.shortTitle,
      //   icon: TableIcon,
      // },
      {
        type: "notes",
        label: "Location notes",
        value: address.instructions,
        icon: NotesIcon,
      },
    ];
    if (hideSensativeData) return [fullLocationDetails[0]];

    // remove table option form the list if the booking is hair and makeup
    if (isHairAndMakeup) {
      return fullLocationDetails.filter((data) => data.type !== "table");
    }

    return fullLocationDetails;
  };

  const getAddressText = () => {
    const { suburb, state, address: street, fullAddress, postcode } = address;

    if (hideSensativeData) return `${suburb} ${state}`;
    return fullAddress || `${street}, ${suburb}, ${state} ${postcode}`;
  };

  const addressRestrictStatus: string[] = [BOOKING_STATUS.NEW, BOOKING_STATUS.DECLINED];
  const hideFullAddress = addressRestrictStatus.includes(status);

  const locationDetails = getFormattedLocationDetails({ address });

  const addressText = getAddressText();

  return (
    <Box>
      <Box>
        <Box width="100%" height="150px" borderRadius="8px" position="relative">
          <Box
            style={styles.address}
            position="absolute"
            maxWidth={isMobile ? "85%" : "90%"}
            onClick={() => openMap({ location: addressText, isDirection: true })}
          >
            <Box>
              <img src={ExactBookingMarker} style={{ width: "14px", height: "14px" }} />
            </Box>
            <Box style={styles.addressText} whiteSpace="nowrap">
              {addressText}
            </Box>
          </Box>
          <Box style={styles.mapSnippetWrapper}>
            <Map
              id={"map-bookingdetais"}
              latitude={address.latitude}
              longitude={address.longitude}
              markerIcon={markerIcon}
              onMapClicked={() => {
                openMapModal();
              }}
              isMarkerAccurate={false}
              interractable={false}
            />
          </Box>
        </Box>
        {hideFullAddress ? <Box style={styles.helperText}>{ADDRESS_DISCLAIMER}</Box> : <></>}
      </Box>

      {showLocationDetails ? (
        <Box>
          <Box marginTop={"24px"}>
            <SectionHeader title="Location details" />
          </Box>
          <Box mt="10px">
            {locationDetails.map(({ value, label, icon }) => {
              if (!value) return <></>;

              let detail = "";
              if (label) detail += `${label}: `;
              return (
                <Box mb={"12px"} display={"flex"} alignItems={"center"} style={styles.detail}>
                  <img src={icon} alt={`${label}-icon`} style={{ marginRight: "8px" }} />
                  {`${detail}${value}`}
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

const styles = {
  helperText: {
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 400,
    marginTop: "16px",
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  address: {
    gap: 4,
    zIndex: "1",
    left: "12px",
    bottom: "8px",
    display: "flex",
    cursor: "pointer",
    padding: "4px 8px",
    borderRadius: "16px",
    alignItems: "center",
    background: Colors.White,
    boxShadow: "0 4px 10px 0 rgba(10,3,41,0.2)",
  },
  addressText: {
    fontSize: "12px",
    lineHeight: "130%",
    overflow: "hidden",
    fontFamily: "SF UI Text",
    color: Colors.ProAppGrey,
    textOverflow: "ellipsis",
    textDecorationLine: "underline",
  },
  mapSnippetWrapper: {
    flex: 1,
    height: "100%",
    borderRadius: "8px",
    overflow: "hidden",
  },
};

export default AddressSection;
