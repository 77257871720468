import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { formatDateWithTimezone, formatTime } from "../utils/date.util";
import { Colors } from "../constants/colors";

type Variant = "active" | "offline"

interface Props {
  time: string
  timezone: string;
  variant?: Variant
}

const getBackgroundColor = (variant: Variant | undefined) => {
  switch (variant) {
  case "offline":
    return Colors.LightPeriwinkle;

  case "active":
  default:
    return Colors.LightTorquiseBlue;
  }
};

const DayDateTimeSection = ({ time, timezone, variant }: Props) => {

  const [day, setDay] = useState("");
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [displayTime, setDisplayTime] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    initiailize();
  }, [, timezone]);


  const initiailize = () => {
    if (!time) return;
    const formattedDay = formatDateWithTimezone(time, timezone, "dddd");
    const formattedMonth = formatDateWithTimezone(time, timezone, "MMM");
    const date = formatDateWithTimezone(time, timezone, "D");
    const formattedTime = formatTime(time, "hh:mm A");
    const formattedYear = formatTime(time, "YYYY");

    setDate(date);
    setMonth(formattedMonth);
    setDay(formattedDay);
    setDisplayTime(formattedTime);
    setYear(formattedYear);
  };

  return (
    <Box display={"flex"} alignItems={"center"} gridGap={16}>
      <Box
        color={Colors.White}
        paddingX={2}
        paddingY={1}
        borderRadius={10}
        bgcolor={getBackgroundColor(variant)}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box fontFamily={"Museo"} fontWeight={700} fontSize={22}>{date}</Box>
        <Box fontFamily={"SF Pro Text"} fontWeight={500} fontSize={12}>{month}</Box>
        <Box fontWeight={"SF Pro Text"} fontSize={12}>{year}</Box>
      </Box>
      <Box color={Colors.NightBlue} fontSize={20} fontWeight={700} fontFamily={"Museo"}>
        <Box>{day}</Box>
        <Box>at  {displayTime} </Box>
      </Box>
    </Box>
  );
};

export default DayDateTimeSection;
