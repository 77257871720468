export function formatToPrice(number: Number = 0, currency: String = "A$") {
  return `${currency}${number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export const defaultFloatingValue: string = "0.00";

declare global {
  interface Number {
    toPriceString: (currency?: String) => string;
  }
}

Number.prototype.toPriceString = function (currency?: String): string {
  return formatToPrice(this, currency);
};
