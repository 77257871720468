import { Box } from "@material-ui/core";
import { isNil } from "lodash";
import { useMemo, useState } from "react";
import {
  BookingRecurringMonthOptionType,
  DEFAULT_MONTHLY_CUSTOM_RECURRING_FREQUENCY,
  DEFAULT_WEEKLY_CUSTOM_RECURRING_FREQUENCY,
  RecurringType,
} from "../../../../constants/booking";
import { Colors } from "../../../../constants/colors";
import { DEFAULT_TIMEZONE } from "../../../../constants/time";
import { bookingFrequencyCaption } from "../../../../helpers/booking";
import {
  useFrequencyOptions,
  useMonthlyOptions,
  useRecurringTypeOptions,
} from "../../../../hooks/booking/recurring/recurringOption.hooks";
import { useMobile } from "../../../../hooks/mobile";
import { MonthData, Recurring } from "../../../../stores/booking";
import { getDayOfMonth, getDayOfWeek, getWeekOfMonth } from "../../../../utils/date.util";
import { getValue } from "../../../../utils/object";
import Dropdown, { DropdownOption } from "../../../Dropdown";
import RadioButton from "../../../RadioButton";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../v2/Styled/enum";

interface Props {
  selectedDate: Date | null;
  onFrequencyChange: (value: number | null) => void;
  frequency: number | null;
  recurring: Recurring;
  onRecurringTypeChange: (recurringType: RecurringType) => unknown;
  onRecurringMonthDataChange: (monthData: MonthData | null) => unknown;
  onDayOfMonthChange: ({ dayOfMonth }: { dayOfMonth: number }) => unknown;
  onWeekOfMonthChange: ({
    weekOfMonth,
    dayOfWeek,
  }: {
    weekOfMonth: number;
    dayOfWeek: number;
  }) => unknown;
  timezone: string | undefined;
  updateRepeating?: (value: boolean) => unknown;
}

export const CustomRecurrence = ({
  selectedDate,
  onFrequencyChange,
  frequency,
  recurring,
  onRecurringTypeChange,
  onRecurringMonthDataChange,
  onDayOfMonthChange,
  onWeekOfMonthChange,
  timezone,
  updateRepeating,
}: Props) => {
  const isMobile = useMobile();
  const selectedRepeatOption = getValue(recurring, "type", RecurringType.WEEK);

  let defaultSelectedMonthOption = BookingRecurringMonthOptionType.DAY_OF_MONTH;
  if (!isNil(getValue(recurring, "monthly.weekOfMonth", null))) {
    defaultSelectedMonthOption = BookingRecurringMonthOptionType.WEEK_DAY_OF_MONTH;
  }
  const [selectedMonthOption, setSelectedMonthOption] = useState<BookingRecurringMonthOptionType>(
    defaultSelectedMonthOption
  );

  const isRepeating = recurring?.isRepeating;
  const customRecurringTypeOptions = useRecurringTypeOptions({ isRepeating, frequency });
  const frequencyOptions = useFrequencyOptions({ type: recurring?.type });
  const monthOptions = useMonthlyOptions({ selectedDate });

  const onMonthOptionChanged = (option: any) => {
    if (!selectedDate) return;
    setSelectedMonthOption(option.value);
    if (option.value === BookingRecurringMonthOptionType.DAY_OF_MONTH) {
      onDayOfMonthChange({
        dayOfMonth: getDayOfMonth(selectedDate),
      });
    } else if (option.value === BookingRecurringMonthOptionType.WEEK_DAY_OF_MONTH) {
      onWeekOfMonthChange({
        weekOfMonth: getWeekOfMonth(selectedDate),
        dayOfWeek: getDayOfWeek(selectedDate),
      });
    }
  };

  const onCustomFrequencyChange = (value: number) => {
    onFrequencyChange(value);
  };

  const onCustomOptionChange = (option: DropdownOption) => {
    let defaultFrequency = DEFAULT_WEEKLY_CUSTOM_RECURRING_FREQUENCY;
    if (option.value === RecurringType.WEEK || option.value === RecurringType.DAY) {
      onRecurringMonthDataChange(null);
    } else if (option.value === RecurringType.MONTH) {
      defaultFrequency = DEFAULT_MONTHLY_CUSTOM_RECURRING_FREQUENCY;
      if (selectedDate) {
        onDayOfMonthChange({
          dayOfMonth: getDayOfMonth(selectedDate),
        });
      }
    }
    onRecurringTypeChange(option.value);
    onFrequencyChange(defaultFrequency);
  };

  const nextBookingText = useMemo(() => {
    return bookingFrequencyCaption({
      bookingDate: selectedDate,
      frequency,
      monthData: getValue(recurring, "monthly", null),
      timezone: timezone ? timezone : DEFAULT_TIMEZONE,
      type: recurring.type,
    });
  }, [frequency, recurring, selectedDate, timezone]);

  const markAsNonRepeating = () => {
    updateRepeating?.(false);
    onFrequencyChange(0);
  };

  const markAsRepeating = () => {
    updateRepeating?.(true);
    onFrequencyChange(1);
  };

  return (
    <Box
      fontFamily={FontFamily.Museo}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      style={{ rowGap: Spacing.S4 }}
    >
      <Box
        fontSize={FontSize.F14}
        fontWeight={FontWeight.Regular}
        display={Display.Flex}
        alignItems={AlignItems.center}
        gridColumnGap={Spacing.S4}
      >
        <RadioButton
          selected={!isRepeating}
          onSelected={markAsNonRepeating}
          style={{ height: "auto", width: "auto" }}
        />
        <Box color={Colors.Dusk} className="cursor-pointer" onClick={markAsNonRepeating}>
          Do not repeat
        </Box>
      </Box>

      <Box
        fontSize={FontSize.F14}
        fontWeight={FontWeight.Regular}
        display={Display.Flex}
        flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
        alignItems={AlignItems.start}
        gridColumnGap={Spacing.S16}
      >
        <Box
          display={Display.Flex}
          alignItems={AlignItems.center}
          gridColumnGap={Spacing.S4}
          width="172px"
          zIndex={999}
        >
          <RadioButton
            selected={isRepeating}
            onSelected={markAsRepeating}
            style={{ height: "auto", width: "auto" }}
          />
          <Box color={Colors.Dusk} className="cursor-pointer" onClick={markAsRepeating}>
            Repeat every
          </Box>
        </Box>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{ width: isMobile ? "90%" : "276px" }}
          marginLeft={isMobile ? "34px" : "0"}
        >
          <Box
            display={Display.Flex}
            gridGap={Spacing.S4}
            alignItems={AlignItems.center}
            marginTop={isMobile ? "0" : "-10px"}
          >
            <Box width={Spacing.S15}>
              <Box style={{ flex: 1 }}>
                <Dropdown
                  options={frequencyOptions}
                  onSelectedOption={(option: DropdownOption) =>
                    onCustomFrequencyChange(option.value)
                  }
                  selectedOption={frequencyOptions.find(
                    (option) => option.value === (frequency || 1)
                  )}
                  paddingTop={"0px"}
                  paddingBottom={"8px"}
                  dropDownMarginButton={0}
                  isDisabled={!isRepeating}
                />
              </Box>
            </Box>

            <Box style={{ flex: 1 }}>
              <Dropdown
                options={customRecurringTypeOptions}
                onSelectedOption={(option: DropdownOption) => {
                  onCustomOptionChange(option);
                }}
                selectedOption={customRecurringTypeOptions.find(
                  (option) => option.value === selectedRepeatOption
                )}
                paddingTop={"0px"}
                paddingBottom={"8px"}
                dropDownMarginButton={0}
                isDisabled={!isRepeating}
              />
            </Box>
          </Box>

          <Box>
            {recurring?.type === RecurringType.MONTH ? (
              <Box>
                <Dropdown
                  options={monthOptions || []}
                  onSelectedOption={onMonthOptionChanged}
                  selectedOption={(monthOptions || []).find(
                    (option) => option.value === selectedMonthOption
                  )}
                  dropDownMarginButton={0}
                  paddingTop={0}
                  paddingBottom={"8px"}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box
            fontFamily="SF UI Text"
            fontSize="14px"
            color={Colors.BlueyGrey}
            textAlign={"left"}
            style={{ letterSpacing: "-0.5px" }}
          >
            {nextBookingText}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
