import moment, { Moment } from "moment";
import momentTZ from "moment-timezone";

export interface Time {
  hour: number;
  mins: number;
}

export function minutesToTime(minutes: number) {
  return {
    hour: Math.trunc(minutes / 60),
    mins: minutes % 60,
  };
}

export const setMomentTime = (selectedMoment: moment.Moment, time: Time, timezone: string) => {
  return selectedMoment
    .clone()
    .set({
      hours: time.hour,
      minutes: time.mins,
      seconds: 0,
      milliseconds: 0,
    })
    .tz(timezone)
    .format();
};

export function formatMinutesToTimeString(minutes: number) {
  const hour24 = Math.trunc(minutes / 60);
  const hour = hour24 > 12 ? hour24 - 12 : hour24;
  const min = String(minutes % 60).padStart(2, "0");
  const suffix = hour24 >= 12 ? "pm" : "am";

  return `${hour}:${min} ${suffix}`;
}

export function formatDateToDayMonthString(date: Date) {
  return moment(date).format("D MMM");
}

export const formatDateToMonthDayString = (date: Date) => {
  return moment(date).format("D MMM");
};

export function formatTimezonedDateWithDateAndTime(
  date: Date,
  minutes: number,
  timezone: string = "Australia/Sydney"
) {
  const hour = String(Math.trunc(minutes / 60)).padStart(2, "0");
  const min = String(minutes % 60).padStart(2, "0");
  const dateString = moment(date).format("YYYY-MM-DD");

  return momentTZ.tz(`${dateString} ${hour}:${min}`, timezone).format();
}

export const convertMomentToMinutes = (selectedMoment: Moment) => {
  return selectedMoment.hours() * 60 + selectedMoment.minutes();
};

export const formatTimeStringToMinutes = (timeString: string) => {
  const momentTimeString = moment(timeString, "h:mma");
  return momentTimeString.hours() * 60 + momentTimeString.minutes();
};

export const getCurrentTimeInMinutes = (bufferMins = 0) => {
  const currentMoment = moment();
  return currentMoment.hours() * 60 + currentMoment.minutes() + bufferMins;
};

export const getTimeOptions = (timeRange: Array<any>, timeFrom?: number | undefined | null) => {
  const timeOptions = timeRange.map((range) => {
    return {
      value: formatTimeStringToMinutes(range.value),
      title: range.label,
    };
  });
  if (timeFrom) return timeOptions.filter((options) => options.value >= timeFrom);
  return timeOptions;
};

const formatTime = ({
  timeString,
  timezone = "Australia/Sydney",
  format = "h:mma",
}: {
  timeString: string;
  timezone: string;
  format?: string;
}) => {
  const timeMoment = moment(timeString);
  return timeMoment.tz(timezone).format(format);
};

const getCurrentMoment = (timezone?: string) => (timezone ? moment.tz(timezone) : moment());

const getDate = (date: Date, days: number = 0) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const convertMinutesToHour = (minutes: number | null, decimalPlace = 1): string => {
  if (!minutes) {
    return "";
  }
  const hour = parseFloat((minutes / 60).toFixed(decimalPlace));

  return `${hour} ${hour > 1 ? "hours" : "hour"}`;
};

const formatMinutesToDisplayTime = (minutes: number | null) => {
  if (!minutes) return "";

  const minute = minutes % 60;
  if (minute === 30) return convertMinutesToHour(minutes);

  const hour = Math.floor(minutes / 60);
  let time = "";
  if (hour) time += `${hour} ${hour > 1 ? "hours" : "hour"}`;
  if (minute) time += ` ${minute} ${minute > 1 ? "minutes" : "minute"}`;
  return time;
};

export { getDate, formatTime, getCurrentMoment, convertMinutesToHour, formatMinutesToDisplayTime };
