import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import Button, { ButtonType } from "../../../../components/Button";
import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import DayPicker from "react-day-picker";
import Dropdown, { DropdownOption } from "../../../../components/Dropdown";
import {
  convertDatetoTimezoneMoment,
  convertDayMomentToDate,
  convertToMoment,
  getCurrentTimeInMinutes,
  isDateObjectToday,
  resetTimeFromDate,
} from "../../../../utils/date.util";
import { useServicesStore } from "../../../../stores/booking";
import { getTimeOptions, minutesToTime } from "../../../../helpers/time";
import moment, { Moment } from "moment";
import { useAlertStore } from "../../../../stores/alert";
import "../../../../styles/DayPicker.css";
import { Colors } from "../../../../constants/colors";

interface Props {
  visible: boolean;
  isMobile: boolean;
  isTab: boolean;
  onClose: () => unknown;
  timezone: string;
  offeredTime: string;
  jobId: string;
  updateOfferId: number | null;
  title?: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  onConfirm: ({ jobId, selectedDateTime }: { jobId: string; selectedDateTime: Moment }) => unknown;
  actionName?: string;
}
const DateAndTimeModal = ({
  visible,
  onClose,
  isMobile,
  isTab,
  jobId,
  offeredTime,
  timezone,
  updateOfferId,
  title,
  label = "Booking start time",
  minDate,
  maxDate,
  onConfirm,
  actionName = "Confirm",
}: Props) => {
  const { timeRange } = useServicesStore();
  const {setErrorMessage} = useAlertStore();
  const timeOptions = getTimeOptions(timeRange) as DropdownOption[];
  
  const earliestTime = offeredTime;
  
  const bookingTimezone = timezone;
  
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [modifiers, setModifers] = useState({from:selectedDay, to:selectedDay});

  const [earliestTimeInMinutes, setEarliestTime] = useState<number>();


  useEffect(() => {
    initialize();
  }, [earliestTime]);

  const initialize = () => {
    if (earliestTime) {
      const momentEarliestTime = convertToMoment(earliestTime, bookingTimezone);
      const earliestTimeInMinutes = moment.duration(momentEarliestTime.format("HH:mm")).asMinutes();
      setEarliestTime(earliestTimeInMinutes);
    }
  };

  const filteredTimeRange = (selectedDay: any) => {
    if (!selectedDay) return [];

    const isToday = isDateObjectToday(selectedDay, bookingTimezone);
    if (!isToday) return timeOptions;

    const minFeasibleEarliest = getCurrentTimeInMinutes(bookingTimezone);
    return timeOptions.filter(({ value }) => value >= minFeasibleEarliest);
  };

  const convertTimeInMinutesToProperTime = (day: any, time: any) => {
    const earliestTime = minutesToTime(time || 0);

    const earlyTime = convertDatetoTimezoneMoment(day, bookingTimezone)
      .set("hour", earliestTime.hour)
      .set("minute", earliestTime.mins)
      .set("second", 0)
      .set("millisecond", 0);

    return earlyTime;
  };

  const handleDayChange = (selectedDay: Date) => {
    const day = resetTimeFromDate(selectedDay);
    setModifers({from:day, to:day});
    setSelectedDay(day);
  };

  const handleTimeChange = (selectedTime: any) => {
    setEarliestTime(selectedTime);
  };

  const handleConfirm = () => {
    if (selectedDay){
      const actualOffered = convertTimeInMinutesToProperTime(selectedDay, earliestTimeInMinutes);
      const body = {
        jobId,
        selectedDateTime: actualOffered,
      };
      onConfirm(body);
    } else {
      setErrorMessage("Please select date.");
    }
  };

  if (!maxDate) {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    maxDate = oneYearFromNow;
  }

  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      maxWidth={isMobile || isTab ? "xs" : "md"}
      fullWidth={isMobile ? false : true}
      title={title}
      titleStyle={{
        fontFamily: "Museo",
        fontWeight: 700,
        fontSize: "30px",
        color: Colors.NightBlue,
        marginTop: "24px"
      }}
      actionStyle={{ padding: "32px 54px 16px 54px", gap: 16 }}
      actions={[
        <Button title="Close" type={ButtonType.outlined} onClick={onClose} />,
        <Button title={actionName} type={ButtonType.secondary} onClick={handleConfirm} />
      ]}
    >
      <Box
        display="flex"
        alignItems={isMobile || isTab ? "center" : undefined}
        flexDirection={isMobile || isTab ? "column" : "row"}
        marginTop="30px"
      >
        <Box
          width={isMobile || isTab ? "100%" : "50%"}
          display={isMobile || isTab ? "flex" : undefined}
          alignItems={isMobile || isTab ? "center" : undefined}
          flexDirection={isMobile || isTab ? "column" : undefined}
        >
          <DayPicker
            firstDayOfWeek={1}
            onDayClick={(day, modifiers) => {
              if (modifiers.disable) return;
              handleDayChange(day);
            }}
            disabledDays={{
              before: minDate || new Date(),
              after: maxDate,
            }}
            selectedDays={[modifiers?.to, { from: modifiers?.from, to: modifiers?.to }]}
            modifiers={modifiers}
          />
        </Box>
        <Box
          width={isMobile || isTab ? "80%" : "50%"}
          pr={!isMobile && !isTab ? "32px" : undefined}
        >
          <Dropdown
            title={label}
            options={filteredTimeRange(selectedDay)}
            onSelectedOption={(option) => {
              handleTimeChange(option.value);
            }}
            selectedOption={timeOptions.find((option) => option.value === earliestTimeInMinutes)}
          />
        </Box>
      </Box>
    </ContentModal>
  );
};
export default DateAndTimeModal;
