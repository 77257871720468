import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { post } from "../../api/client";
import { Colors } from "../../constants/colors";
import { PaymentType } from "../../constants/payment";
import { bookingIsCancelled, bookingIsComplete, bookingIsConfirmed } from "../../helpers/booking";
import { useMobile } from "../../hooks/mobile";
import CrossIcon from "../../images/cross.svg";
import InvoiceLinks from "../../pages/Bookings/Components/InvoiceLinks/InvoiceLinks";
import { settleDeclinedPayment } from "../../services/bookings/bookings.service";
import { useAlertStore } from "../../stores/alert";
import { usePaymentStore } from "../../stores/booking";
import { useBraintreeStore } from "../../stores/braintree";
import Button, { ButtonSize, ButtonType } from "../Button";
import Dialog from "../Dialog";
import Divider from "../Divider";
import PaymentFrame, { PaymentFrameType } from "./PaymentFrame";
import { getValue } from "../../utils/object";

export default function PaymentModal({
  onClose,
  open,
  booking,
  onSuccess,
  isVoucher,
  settleDeclinedPaymentForVoucher,
  title,
  handleBulkPayment,
  isBulkPayment = false,
  hidePayWithInvoice,
  hidePayWithNDIS,
  changePaymentMethod = false,
  showInvoiceLink = true,
}: {
  open: boolean;
  onClose: () => unknown;
  onSuccess?: (msg?: string) => unknown;
  booking: any;
  isVoucher?: boolean;
  hidePayWithInvoice?: boolean;
  hidePayWithNDIS?: boolean;
  changePaymentMethod?: boolean;
  settleDeclinedPaymentForVoucher?: () => unknown;
  showInvoiceLink?: boolean;
  title?: string;
  isBulkPayment?: boolean;
  handleBulkPayment?: () => unknown;
}) {
  const { hostedFieldsInstance } = useBraintreeStore();
  const { paymentMethod, paymentType, setPaymentType, firstTimeUserCardRecaptchaSuccess } =
    usePaymentStore();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { setErrorMessage } = useAlertStore();
  const history = useHistory();
  const isMobile = useMobile();

  const buttonWidth = isMobile ? "48%" : "40%";

  const formattedBookingForInvoiceLinks = (booking: any) => {
    return {
      id: booking.id,
      cancellationFee: booking.cancellationFee,
      clientPlatformFee: booking.clientPlatformFee,
      lateNightSurcharge: booking.lateNightSurcharge,
      price: booking.price,
      priceWithoutDiscount: booking.priceWithoutDiscount,
      publicHolidaySurcharge: booking.publicHolidaySurcharge,
      originalPrice: booking.originalPrice,
      cancellationApplicableFee: booking.cancellationApplicableFee,
      bookingdetails: booking.bookingDetails || booking.bookingdetails,
      userId: booking.userId,
      bookingUpdates: booking.bookingUpdates,
      ...booking.bookingPrice,
    };
  };

  const bookingComplete = booking && bookingIsComplete(formattedBookingForInvoiceLinks(booking));
  const bookingConfirmed = booking && bookingIsConfirmed(formattedBookingForInvoiceLinks(booking));
  const bookingCancelled = booking && bookingIsCancelled(formattedBookingForInvoiceLinks(booking));

  const settlePayment = async () => {
    try {
      let selectedPaymentMethod = paymentMethod;

      if (hostedFieldsInstance && !selectedPaymentMethod && paymentType === PaymentType.card) {
        if (!firstTimeUserCardRecaptchaSuccess) {
          setErrorMessage("Please complete reCAPTCHA");
          return;
        }

        setPaymentLoading(true);

        const {
          nonce,
          details: { lastFour, expirationMonth, expirationYear, cardType },
        } = await hostedFieldsInstance.tokenize();

        selectedPaymentMethod = await post("paymentmethods", {
          nonce,
          last4: lastFour,
          expiryMonth: expirationMonth,
          expiryYear: expirationYear,
          cardType,
        });
      }

      // if (!selectedPaymentMethod?.id) {
      //   setErrorMessage("Please select a payment method");
      //   return;
      // }

      setPaymentLoading(true);

      if (isVoucher && settleDeclinedPaymentForVoucher) {
        await settleDeclinedPaymentForVoucher();
        return;
      }

      if (isBulkPayment && handleBulkPayment) {
        await handleBulkPayment();
        return;
      }

      const response = await settleDeclinedPayment(booking.id, {
        paymentmethodId: selectedPaymentMethod?.id,
        paymentType: paymentType,
      });
      if (typeof onSuccess === "function") {
        const msg = getValue(response, "data.hasChargedForBooking", true)
          ? ""
          : "Booking payment method updated successfully.";
        onSuccess(msg);
      } else {
        history.go(0);
      }
    } catch (error) {
      setErrorMessage("Sorry, please try again.");
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <Dialog open={open} fullScreen={isMobile} onClose={onClose}>
      <Box width={isMobile ? "100%" : "480px"} bgcolor="#ffffff" borderRadius="11px">
        <Box>
          <Box display="flex" alignItems="center" justifyContent={"center"} height="64px">
            <IconButton onClick={onClose}>
              <img src={CrossIcon} alt="Close" />
            </IconButton>
            <DialogTitle style={{ flex: 1 }}>
              <Typography className="treatmentTitle" style={{ fontWeight: "700", fontSize: "16px" }}>
                {title ? title : "Change payment method"}
              </Typography>
            </DialogTitle>
          </Box>
          <DialogContent dividers>
            <PaymentFrame
              title=""
              hidePayWithInvoice={hidePayWithInvoice}
              hidePayWithNDIS={hidePayWithNDIS}
              type={PaymentFrameType.booking}
              selectedType={paymentType}
              onSelectedType={(paymentType) => setPaymentType(paymentType)}
            />
            {showInvoiceLink && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent="center"
                marginTop="16px"
              >
                <Divider marginBottom={"16px"} />
                <InvoiceLinks booking={formattedBookingForInvoiceLinks(booking)} />
              </Box>
            )}
          </DialogContent>
        </Box>

        <DialogActions
          style={{ padding: "15px", alignItems: "center", justifyContent: "space-between" }}
        >
          <Button
            type={ButtonType.outlined}
            size={ButtonSize.small}
            width={buttonWidth}
            colorTitle={Colors.Dusk}
            title="Cancel"
            fontStyle={{
              fontFamily: "Museo",
              fontWeight: 600,
              fontSize: 14,
            }}
            style={{ borderRadius: 4, width: "100%" }}
            onClick={() => onClose()}
          />
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            width={buttonWidth}
            colorTitle={Colors.White}
            title="Save And Apply"
            fontStyle={{
              fontFamily: "Museo",
              fontWeight: 600,
              fontSize: 14,
            }}
            loading={paymentLoading}
            style={{ borderRadius: 4, width: "100%" }}
            onClick={() => settlePayment()}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
}
