import { Box } from "@material-ui/core";
import { isNil } from "lodash";
import React from "react";
import { GenderPreference, MassageFor, SessionType } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { bookingFrequencyCaption, frequencyTitleForBooking } from "../../helpers/booking";
import { formatDateToDayMonthString, formatMinutesToTimeString } from "../../helpers/time";
import BookingFrequencyIcon from "../../images/booking-recurrency.png";
import RecipientIcon from "../../images/one-person-outline.svg";
import TimeIcon from "../../images/review-calendar-time.svg";
import LocationIcon from "../../images/review-item-location.svg";
import MassageIcon from "../../images/review-item-massage.svg";

import { CORPORATE_BOOKING_KEYS } from "../../data/corporate.options";
import { transformCorporateForDescription } from "../../stores/V2/booking/booking.transformer";
import {
  getCorporateDetails,
  getDurationForMassageService,
  getDurationForNonMasasgeService,
  getServiceName,
  getTreatmentDetailByTreatmentId,
  getTreatmentName,
  isBackToBackMassageType,
  isCorporateTreatmentType,
  isCoupleMassageType,
  isHairAndMakeupBooking,
  isMassageType,
  useBookingStore,
  useDateTimeStore,
  useRecipientDetailsStore,
} from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import TextLink from "../TextLink";

export enum ReviewAndBookDetailsItemType {
  massage,
  recipient,
  location,
  time,
  frequency,
}

interface Props {
  type: ReviewAndBookDetailsItemType;
  onChangeClicked: () => unknown;
  isNewAction: Boolean;
}

export default function ReviewAndBookDetailsItem({
  type,
  onChangeClicked,
  isNewAction = true,
}: Props): JSX.Element {
  const {
    sessionType,
    massageLength,
    massageLength2,
    address,
    genderPreference1,
    genderPreference2,
    recipient,
    treatmentDetails,
    frequency,
    recurring,
  } = useBookingStore();

  const [actionText, setActionText] = React.useState("");
  const isCorporateType = isCorporateTreatmentType();
  const isMassage = isMassageType();
  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);

  const { firstName, lastName, massageFor } = useRecipientDetailsStore();

  const { selectedDay, earliestStartInMinutes, latestStartInMinutes } = useDateTimeStore();
  const { user, fetchMe } = useUserStore();

  const getCaptionForCorporate = () => {
    const corporateDetails = getCorporateDetails();
    const corporateDescription = transformCorporateForDescription(corporateDetails);

    return corporateDescription.map((item: any) => {
      if (!item.value && item.key === CORPORATE_BOOKING_KEYS.eventName) return <></>;
      if (!item.value && item.key === CORPORATE_BOOKING_KEYS.noteForRecipients) return <></>;
      return (
        <Box key={item.key} lineHeight={"21px"}>{`${item.label} ${
          item.value ? item.value : "-"
        }`}</Box>
      );
    });
  };

  const imageSrc = () => {
    switch (type) {
    case ReviewAndBookDetailsItemType.massage:
      return MassageIcon;
    case ReviewAndBookDetailsItemType.recipient:
      return RecipientIcon;
    case ReviewAndBookDetailsItemType.location:
      return LocationIcon;
    case ReviewAndBookDetailsItemType.time:
      return TimeIcon;
    case ReviewAndBookDetailsItemType.frequency:
      return BookingFrequencyIcon;
    }
  };

  const getTreatmentItemLabel = (index: number) => {
    if (isCorporateType) {
      return `${getServiceName()} | ${getTreatmentName()}`; // build title for corporate booking
    }

    if (isHairAndMakeupBooking()) {
      let td = treatmentDetails[index];
      td = getTreatmentDetailByTreatmentId(td ? td.treatmentId : null);
      return td ? td.label : "-";
    }

    if (isMassage && (isNil(massageLength) || isNil(sessionType))) {
      return "Select service";
    }

    if (isMassage) {
      const massageDuration = getDurationForMassageService();
      if ((isBackToBackMassage || isCouplesMassage) && massageLength2) {
        return `${massageDuration}  ${sessionType && SessionType.toString(sessionType)}`;
      }
      return `${massageDuration}  ${sessionType && SessionType.toString(sessionType)}`;
    } else {
      const treatmentName = getTreatmentName();
      return treatmentName || "Select service";
    }
  };

  const title = (index = -1) => {
    switch (type) {
    case ReviewAndBookDetailsItemType.massage:
      return getTreatmentItemLabel(index);

    case ReviewAndBookDetailsItemType.recipient:
      if (isNil(massageFor)) {
        return "Select recipient";
      }

      const userFullName = `${user?.firstName} ${user?.lastName}`;
      if (massageFor === MassageFor.myself) {
        return `Booked by ${userFullName} for ${userFullName}`;
      }

      if (recipient) {
        return `Booked by ${userFullName} for ${recipient.firstName} ${recipient.lastName}`;
      }

      if (!isNil(massageFor) && isNil(recipient)) {
        return "Select recipient";
      }

      const currentUserFullName = `${firstName} ${lastName}`;
      return `Booked by ${currentUserFullName} for ${currentUserFullName}`;
    case ReviewAndBookDetailsItemType.location:
      if (isNil(address)) {
        return "Select address";
      }

      return (
        address?.fullAddress || `${address?.address} ${address?.suburb} ${address?.postcode}`
      );
    case ReviewAndBookDetailsItemType.time:
      if (isNil(selectedDay) && isNil(earliestStartInMinutes)) {
        return "Select date & time";
      }

      if (isCouplesMassage || earliestStartInMinutes === latestStartInMinutes) {
        return selectedDay && earliestStartInMinutes
          ? `${formatDateToDayMonthString(selectedDay)} at ${formatMinutesToTimeString(
            earliestStartInMinutes
          )}`
          : "";
      }

      return selectedDay && earliestStartInMinutes && latestStartInMinutes
        ? `${formatDateToDayMonthString(selectedDay)} between ${formatMinutesToTimeString(
          earliestStartInMinutes
        )} - ${formatMinutesToTimeString(latestStartInMinutes)}`
        : "";

    case ReviewAndBookDetailsItemType.frequency:
      const title = frequencyTitleForBooking({
        frequency,
        recurring,
      });
      return title;
    }
  };

  const caption = (index = -1) => {
    if (isCorporateType && type === ReviewAndBookDetailsItemType.massage) {
      const caption = getCaptionForCorporate();
      return caption;
    }
    if (type === ReviewAndBookDetailsItemType.massage && isHairAndMakeupBooking()) {
      const duration = getDurationForNonMasasgeService([
        parseInt(treatmentDetails[index].treatmentId),
      ]);
      return duration ? `Up to ${duration} mins` : "-";
    }

    if (isMassage && type === ReviewAndBookDetailsItemType.massage && genderPreference1) {
      let result = GenderPreference.toDescriptiveString(genderPreference1);

      if (genderPreference2 && isCouplesMassage) {
        result += ` & ${GenderPreference.toDescriptiveString(genderPreference2)}`;
      }

      return result;
    }

    if (type === ReviewAndBookDetailsItemType.massage && !isHairAndMakeupBooking() && !isMassage) {
      const duration = getDurationForNonMasasgeService();
      return duration ? `Up to ${duration} mins` : "-";
    }

    if (type === ReviewAndBookDetailsItemType.location && address?.instructions) {
      return address.instructions;
    }

    if (type === ReviewAndBookDetailsItemType.frequency) {
      return bookingFrequencyCaption({
        bookingDate: selectedDay,
        frequency,
        monthData: getValue(recurring, "monthly", null),
        timezone: getValue(address, "timezone"),
        shouldCreateFutureBookings: recurring.shouldCreateFutureBookings,
        type: recurring.type,
      });
    }

    return undefined;
  };

  React.useEffect(() => {
    fetchMe();
  }, []);

  React.useEffect(() => {
    setActionText(isNewAction ? "Add" : "Change");
  }, [isNewAction]);

  return (
    <Box display="flex" flexDirection="row" alignItems={"flex-start"}>
      <Box width="24px" height="24px" justifyContent="center" alignItems="center" display="flex">
        <img
          src={imageSrc()}
          alt="icon"
          style={{
            width: `${ReviewAndBookDetailsItemType.frequency === type && "24px"}`,
            height: `${ReviewAndBookDetailsItemType.frequency === type && "24px"}`,
          }}
        />
      </Box>

      {isHairAndMakeupBooking() && type === ReviewAndBookDetailsItemType.massage && (
        <Box display="flex" flexDirection="column" flex={1}>
          {treatmentDetails.map((td, i) => {
            if (checkIfEmpty(td)) return <Box />;

            const { treatmentId } = td;
            return (
              <Box
                key={`treatment-${treatmentId}`}
                display="flex"
                flexDirection="column"
                flex={1}
                style={{ marginBottom: "5px" }}
              >
                <Box
                  ml={2}
                  mr={2}
                  fontFamily="Museo"
                  fontSize="16px"
                  color={Colors.Dusk}
                  style={{ overflowWrap: "break-word" }}
                  // width="211px"
                >
                  {title(i)}
                </Box>
                {caption(i) && (
                  <Box
                    fontFamily="SF UI Text"
                    fontSize="12px"
                    color={Colors.BlueyGrey}
                    mt={0.2}
                    ml={2}
                    mr={2}
                  >
                    {caption(i)}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      {(type !== ReviewAndBookDetailsItemType.massage || !isHairAndMakeupBooking()) && (
        <Box display="flex" flexDirection="column" flex={1}>
          <Box mt={"2px"}>
            <Box
              ml={2}
              mr={2}
              fontFamily="Museo"
              fontSize="16px"
              color={Colors.Dusk}
              style={{ overflowWrap: "break-word" }}
              // width="211px"
            >
              {title()}
            </Box>
          </Box>
          {caption() && (
            <Box
              fontFamily="SF UI Text"
              fontSize="14px"
              color={Colors.BlueyGrey}
              mt={0.2}
              ml={2}
              mr={2}
            >
              {caption()}
            </Box>
          )}
        </Box>
      )}

      <TextLink
        title={actionText}
        onClick={onChangeClicked}
        style={{ marginTop: "2px", cursor: "pointer" }}
      />
    </Box>
  );
}
