export enum Roles {
  ACCOUNT_OWNER = "accountOwner",
  ACCOUNT_MANAGER = "accountManager",
  RECIPIENT = "recipient",
  HOME_CARE_PROVIDER = "homeCareProvider",
  AGED_CARE_SUPPORT_RECIPIENT = "agedCareSupportRecipient",
  DISABILITY_SUPPORT_RECIPIENT = "disabilitySupportRecipient",
}

export const RolesOptionsForOwner = [
  {
    title: "Account Manager",
    value: "accountManager",
  },
  {
    title: "Recipient",
    value: "recipient",
  },
];

export const RolesOptionsForManager = [
  {
    title: "Recipient",
    value: "recipient",
  },
];
