export enum QUERY_KEYS {
  CORPORATE_CONFIG = "corporate-teams-config",
  CORPORATE_BOOKING = "corporate-booking",
  REBOOKINGS = "rebookings",
  COMPLAINT_OPTIONS = "COMPLAINT_OPTIONS",
  COMPLAINT_OPTIONS_PRO = "COMPLAINT_OPTIONS_PRO",
  BLOCK_CLIENT = "BLOCK_CLIENT",
  UPCOMING_BOOKINGS = "UPCOMING_BOOKINGS",
  PAST_BOOKINGS = "PAST_BOOKINGS",
  BOOKING_DETAIL = "BOOKING_DETAIL",
}
