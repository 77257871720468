import { Box } from "@material-ui/core";
import TextLink from "../../../components/TextLink";

import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";

interface Props {
  icon: any;
  prefix?: string;
  iconStyle?: object;
  iconWrapperStyle?: object;
  allowChange?: boolean;
  prefixColor?: string;
  handleChange?: any;
  text: string;
  description?: any;
  marginBottom?: any;
  verticalAlignment?: string;
  textStyle?: object;
}

const BookingDetailRow = ({
  icon,
  prefix,
  prefixColor,
  iconWrapperStyle = {},
  textStyle = {},
  iconStyle = {},
  allowChange = false,
  handleChange = () => {},
  text,
  description,
  marginBottom,
  verticalAlignment,
}: Props) => {
  const isMobile = useMobile();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems={!!description || verticalAlignment ? "start" : "center"}
      lineHeight={"150%"}
      gridColumnGap={16}
      minWidth={isMobile ? "100%" : "400px"}
    >
      <Box
        width="24px"
        height="24px"
        justifyContent="center"
        alignItems={verticalAlignment ? verticalAlignment : "center"}
        display="flex"
        style={{ ...iconWrapperStyle }}
      >
        <img src={icon} alt="icon" style={{ maxHeight: "17px", maxWidth: "17px", ...iconStyle }} />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        justifyContent="center"
        alignSelf="center"
      >
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" flexDirection="row" alignSelf={"start"}>
            {prefix && (
              <Box mr={"4px"} fontFamily="Museo" fontSize="16px" color={prefixColor || Colors.Dusk}>
                {`${prefix}`}
              </Box>
            )}
            <Box
              mr={1.6}
              fontFamily="Museo"
              fontSize="16px"
              color={Colors.Dusk}
              style={{ ...textStyle }}
            >
              {text}
            </Box>
          </Box>
          {!!description ? (
            <Box
              fontFamily={"Open sans"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"150%"}
              color={Colors.Grey}
            >
              <Box>{description}</Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      {allowChange && (
        <Box justifyContent="center" alignSelf={verticalAlignment ? verticalAlignment : "center"}>
          <TextLink title="Change" onClick={handleChange} />
        </Box>
      )}
    </Box>
  );
};

export default BookingDetailRow;
