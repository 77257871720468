import { AxiosError } from "axios";
import { ErrorResponse } from "../models";

export function parseApiError(error: AxiosError) {
  return (
    (error.response?.data as ErrorResponse)?.errorMessage ||
    (error.response?.data as ErrorResponse)?.error?.message ||
    error.message ||
    "An unknown error has occured"
  );
}

export function parseAfterpayAccessError(error: AxiosError) {
  return {
    message:
      error.response?.data?.errorMessage ||
      error.response?.data?.error.message ||
      "An unknown error has occured",
    userDetails: error.response?.data.userDetails,
  };
}

const parseApiV2Error = (error: AxiosError) => {
  return (error.response?.data as ErrorResponse)?.error?.message || "An unknown error has occured";
};

export { parseApiV2Error };
