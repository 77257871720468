import { Box } from "@material-ui/core";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import Ratings from "../../../components/Ratings";
import FavouriteButton from "../../../components/TherapistProfile/FavouriteButton";
import { Colors } from "../../../constants/colors";
import PLACEHOLDER_IMAGE from "../../../images/profile-pic-placeholder.png";
import VACCINATED_BADGE from "../../../images/vaccinated_logo.png";

import { getProfileLink } from "../../../services/therapist/therapist.service";
import "./BrowseTherapist.css";
import { getAccessToken } from "../../../helpers/accessToken";
import { isAUDomain } from "../../../utils/url";

interface Props {
  userId: number;
  isMobile?: Boolean;
  firstName: string;
  totalReviews: number;
  rating: number;
  isVaccinated: Boolean;
  enableActions?: Boolean;
  image?: string | null;
}

const TherapistBlock = ({
  userId,
  firstName,
  totalReviews = 0,
  rating = 0,
  image,
  isMobile = false,
  isVaccinated = false,
  enableActions = false,
}: Props) => {
  const providerRating = rating ? rating : 0;

  const renderImage = () => {
    return (
      <Box className="image-wrapper">
        <img className="profile-image" src={image || PLACEHOLDER_IMAGE} />
        {isVaccinated ? (
          <Box className="vaccinated-wrapper">
            <img className="vaccinated-icon" src={VACCINATED_BADGE} />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const renderRating = () => {
    return (
      <Box display="flex" flexDirection="row" alignItems={"center"}>
        <span className="therapist-rating">{providerRating.toFixed(1)}</span>
        <Ratings rating={providerRating} />
      </Box>
    );
  };

  const renderActionButtons = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.small}
          title="Recommend"
          style={{ margin: "0px 5px" }}
          onClick={() => {}}
        />
        <FavouriteButton
          onClick={() => {}}
          favourited={false}
          buttonStyle={{ padding: "0px 10px", margin: "0px 5px" }}
        />
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.small}
          title="Book"
          style={{ margin: "0px 5px" }}
          onClick={() => {}}
        />
      </Box>
    );
  };


  const handleClick = (firstname: string, id: number) => {
    let profileURL = getProfileLink({ id, firstName });
    const accessToken = getAccessToken();
    profileURL += `?token=${accessToken}`;

    if (isAUDomain()) profileURL += "&country=AU";
    window.open(`${profileURL}`, "_blank");
  };

  return (
    <Box
      onClick={() => handleClick(firstName, userId)}
      className="therapistBlock"
      style={{
        marginRight: isMobile ? "0px" : "24px",
        maxWidth: isMobile ? "100%" : "400px",
        flex: isMobile ? "100%" : "50%",
      }}
    >
      <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {renderImage()}
        <Box style={{ alignSelf: "flex-start" }}>
          <Box mb={"8px"}>
            <span className="therapist-name" color={Colors.Dusk}>
              {firstName}
            </span>
          </Box>
          {renderRating()}
          <span
            style={{
              fontFamily: "Open Sans",
              fontWeight: 400,
              fontSize: "14px",
              color: Colors.Grey,
              lineHeight: "21px",
            }}
          >
            ({totalReviews} Reviews)
          </span>
        </Box>
      </Box>

      {enableActions && (
        <Box>
          <div
            style={{
              height: "1px",
              backgroundColor: "lightgrey",
              margin: "10px",
            }}
          />
          {renderActionButtons()}
        </Box>
      )}
    </Box>
  );
};

export default TherapistBlock;
