import { Box } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import RecipientItem from "../components/Recipients/RecipientItem";
import RecipientModal from "../components/Recipients/RecipientModal";
import SectionPageHeader from "../components/SectionPageHeader";
import TextLink from "../components/TextLink";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import { Recipient } from "../models";
import { useAlertStore } from "../stores/alert";
import { AllSortBy } from "../constants/sortOptions";
import PaginationControl from "../components/PaginationControl";
import { FilterButton } from "../components/FilterButton/FilterButton";
import { RecipientFilterAndSortModal } from "../components/Modals/FilterModal/RecipientFilterAndSortModal";
import { useBookingFilterCount, useCachedRecipientSort } from "../hooks/utility/filter.hooks";
import useRecipientFilterQuery from "../hooks/recipient/recipientFilter.hook";

export const RecipientPage = () => {
  const isMobile = useMobile();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const perPage = 20;
  const { cachedSortBy } = useCachedRecipientSort();
  const { getFilterCount } = useBookingFilterCount();

  const recipientSortBy = cachedSortBy || AllSortBy[0];

  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("token"));
  // const [searchParams, setSearchParams] = React.useState("");
  const {recipientQueries} = useRecipientFilterQuery({sortBy:recipientSortBy});

  const { data: recipientsData, mutate: recipientsMutate, revalidate } = useSWR(
    `/users/me/recipients?accessToken=${accessToken}&page=${page}&paginate=true&limit=${perPage}&${new URLSearchParams({search:JSON.stringify(recipientQueries)} ).toString()}`
  );

  const recipients = recipientsData?.data ? (recipientsData?.data as [Recipient]) : [];

  const [selectedRecipient, setSelectedRecipient] = React.useState<Recipient | null>(null);
  const [showRecipientModal, setShowRecipientModal] = React.useState(false);
  const [showRecipientFilterModal, setShowRecipientFilterModal] = React.useState(false);

  const [filterCount, setFilterCount] = React.useState(cachedSortBy ? 1 : 0);
 

  useEffect(()=>{
    setFilterCount(getFilterCount(recipientQueries,AllSortBy[0])); 
  },[recipientQueries,getFilterCount]);

  const handleSave = () => {
    setShowRecipientModal(false);
    recipientsMutate();
  };



  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader title="Saved recipients" subtitle="Manage your recipients here" />
        }
        onLoggedIn={() => {
          setAccessToken(localStorage.getItem("token"));
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          mb="24px"
          ml="80px"
          mr="80px"
          mt="43px"
          maxWidth={isMobile ? undefined : "609px"}
        >
          <FilterButton
            title="Filter and sort"
            onClick={() => {
              setShowRecipientFilterModal(true);
            }}
            filterCount={filterCount}
          />
        </Box>
        <Box
          style={{
            marginLeft: isMobile ? undefined : "80px",
            marginRight: isMobile ? undefined : "80px",
            backgroundColor: Colors.White,
            borderRadius: "6px",
            border: `solid 1px ${Colors.VeryLightPink}`,
          }}
          maxWidth={isMobile ? "100%" : "609px"}
          mt={isMobile ? 2 : undefined}
        >
          <Box ml="24px" mr="24px">
            {page === 1 && (
              <RecipientItem
                onEditClicked={() => {
                  history.push("/profile");
                }}
                myself
                showMyself
              />
            )}

            {recipients.map((recipient: Recipient) => (
              <RecipientItem
                recipient={recipient}
                onEditClicked={() => {
                  setSelectedRecipient(recipient);
                  setShowRecipientModal(true);
                }}
                onDeleteClicked={() => {
                  axios
                    .delete(`/users/me/recipients/${recipient.id}?accessToken=${accessToken}`)
                    .then((response) => {
                      recipientsMutate();
                      setSuccessMessage("Recipient deleted");
                    })
                    .catch((error) => {
                      setErrorMessage(parseApiError(error));
                    });
                }}
              />
            ))}
          </Box>
          <Box m={3}>
            <TextLink
              title={`Add ${recipients.length > 0 ? "another" : ""} recipient`}
              onClick={() => {
                setSelectedRecipient(null);
                setShowRecipientModal(true);
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          // flexWrap={true}
          mt="15px"
          justifyContent={`${isMobile ? "center" : "flex-end"}`}
          alignItems="center"
          marginBottom="25px"
          marginLeft={`${isMobile ? undefined : "80px"}`}
          marginRight={`${isMobile ? undefined : "80px"}`}
          maxWidth={isMobile ? "100%" : "609px"}
        >
          <PaginationControl
            currentPage={recipientsData?.page}
            pages={recipientsData?.totalPage || 1}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            startFromOne={true}
          />
        </Box>
      </Wrapper>

      <RecipientModal
        existingRecipient={selectedRecipient}
        open={showRecipientModal}
        onClose={() => setShowRecipientModal(false)}
        onSaved={() => handleSave()}
      />
      <RecipientFilterAndSortModal
        open={showRecipientFilterModal}
        onClose={() => {
          setShowRecipientFilterModal(false);
        }}
      />
    </>
  );
};

export default RecipientPage;
