import { Box } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import useSWR from "swr";
import Each from "../../components/Each";
import Button, { ButtonType } from "../../components/Button";
import CovidVaccinationInfo, { MessageType } from "../../components/CovidVaccinationInfo";
import { getValue } from "../../utils/object";
import DateField from "../../components/DateField";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import MobileTextField from "../../components/MobileTextField";
import TextWithLink from "../../components/Text/TextWithLink/TextWithLink";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import ProDashboardOnlineStatusPill from "../../components/ProDashboard/ProDashboardOnlineStatusPill";
import Ratings from "../../components/Ratings";
import SelectCountry from "../../components/SelectCountry";
import TextField from "../../components/TextField";
import TextLink from "../../components/TextLink";
import Wrapper from "../../components/Wrapper";
import { Colors } from "../../constants/colors";
import { BASE_UPLOADS_URL } from "../../constants/common";
import { parseApiError } from "../../helpers/error";
import { getCountryCode } from "../../helpers/mobile";
import { genericBioForTherapistPublicProfile } from "../../helpers/therapist";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import useAuth from "../../hooks/auth.hooks";
import { checkLockAttribute } from "../../utils/profileLock";
import { useCountryOptions } from "../../hooks/utility/utilty.hooks";
import LockButton from "../../components/LockButton";
import ProfileEdit from "../../components/ProfileEdit";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";
import ProfilePictureEdit from "../../images/profile-picture-edit.svg";
import { TherapistPublicProfile } from "../../models";
import { getProfileLink } from "../../services/therapist/therapist.service";
import { useAlertStore } from "../../stores/alert";
import { useUserStore } from "../../stores/user";
import Portfolio from "./Portfolio";
import MobileInput from "../../components/MobileModalOtp/MobileInput";
import { LOCKED_ATTRIBUTES } from "../../constants/profile";
import { useProfileLock } from "../../hooks/userProfileLock.hooks";
import EmailVerificationModal from "../../components/Account/EmailVerificationModal";
import {
  buildProPerformanceMatrix,
  ProPerformanceMatrix,
} from "../../services/proDashboard/transformer/proProfile.transformer";
import ProScoreRow from "../../components/ProDashboard/ProScoreRow";
import LanguageSpoken from "../../components/ProDashboard/LanguageSpoken/LanguageSpoken";
import { useGetAllLanguageSpokenOptions } from "../../hooks/languageSpoken.hooks";
import ProDashboardServiceArea from "./ServiceArea/ServiceAreas";
import { ServiceAreaProvider } from "./ServiceArea/context/ServiceAreaState";
import PersonalInfoDisclaimerModal from "../../components/DisclaimerModal/PersonalInfoDisclaimerModal";
import { hasPersonalInformation } from "../../services/utility/personalIdentifiableInfo.service";

export interface LanguageSpokenInterface {
  value: string;
  title: string;
}

const genderOptions = [
  { value: "female", title: "Female" },
  { value: "male", title: "Male" },
  { value: "non binary / non confirming", title: "Non binary / non confirming" },
] as DropdownOption[];

const profileImageStyle = {
  width: "96px",
  height: "96px",
  borderRadius: "48px",
  "object-fit": "cover",
};

const BIO_HELP_LINK =
  "https://help.getblys.com.au/support/solutions/articles/33000274546-how-to-write-a-great-bio-for-your-blys-provider-profile";

function StatsRowSeparator() {
  return <Box height="1px" width="100%" bgcolor={Colors.LightPeriwinkle} my={"12px"} />;
}

export default function ProProfile() {
  const { user, fetchMe } = useUserStore();
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const { isAdminLoggedInAsClient } = useAuth();

  const { isLoading: isLockLoading, toggleLock } = useProfileLock();

  const { countries: countryOption } = useCountryOptions();
  const { data: languageSpokenOption } = useGetAllLanguageSpokenOptions();

  const { data, mutate } = useSWR(
    user?.id ? `/therapists/${user?.id}/publicProfile?accessToken=${accessToken}` : null
  );
  const therapistPublicProfile = data as TherapistPublicProfile;
  const numberOfReviews = therapistPublicProfile?.totalReviews || 0;

  const [selectedImageFile, setSelectedImageFile] = React.useState<File | null>(null);

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    // Do something with the files

    console.debug("acceptedFiles: ", acceptedFiles);

    if (!isEmpty(acceptedFiles)) {
      setSelectedImageFile(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const isOnline = !isEmpty(user?.therapistprofile?.pushToken);

  // const [therapistProfileDrawerOpen, setTherapistProfileDrawerOpen] =
  //   React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [preferredFirstName, setPreferredFirstName] = React.useState<string | null>();
  const [middleName, setMiddleName] = React.useState<string | null>();
  const [lastName, setLastName] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState("female");
  const [dateOfBirth, setDateOfBirth] = React.useState<Date | null>(null);
  const [city, setCity] = React.useState("");
  const [proCountry, setProCountry] = React.useState("");
  const [selectedLanguage, setSelectedLanguage] = React.useState<any[]>([]);
  const [bio, setBio] = React.useState("");
  const [kit, setKit] = React.useState("");
  const [isCovidVaccinated, setIsCovidVaccinated] = React.useState(false);
  const [proPerformanceMatrix, setProPerformanceMatrix] = React.useState<
    Array<ProPerformanceMatrix>
  >([]);
  const [personalInfoDisclaimerOpen, setPersonalDisclaimerOpen] = useState<boolean>(false);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const bioRef = useRef<HTMLInputElement>(null);
  const preferedNameRef = useRef<HTMLInputElement>(null);

  const [saving, setSaving] = React.useState(false);
  const [lastUpdated, setLastUpdated] = React.useState<Date | null>(null);

  const [cityOptions, setCityOption] = useState<DropdownOption[]>([]);
  useEffect(() => {
    const country = countryOption.find(({ value }: any) => value === proCountry);
    if (country) {
      setCityOption(country.city);
    }
  }, [proCountry, countryOption]);

  React.useEffect(() => {
    if (window) window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (user) {
      const mobileCountryCodeData = getCountryCode(user.mobile);

      const firstName = getValue(user, "legalFirstName") || getValue(user, "firstName");
      setFirstName(firstName);
      setLastName(user.lastName);
      setMiddleName(user.middleName);
      setPreferredFirstName(user.preferredFirstName);
      setCountryCode(mobileCountryCodeData?.countryCode || "+61");
      setMobile(mobileCountryCodeData?.withoutCountryCode || "");
      setEmail(user.email);
      setGender(user.gender?.toLowerCase());
      setDateOfBirth(user.birthday ? moment(user.birthday, "YYYY-MM-DD").toDate() : null);
      setCity(user.therapistprofile?.city || "");
      setProCountry(user.country || "");
      setIsCovidVaccinated(user.isCovidVaccinated || false);
      setKit(user.therapistprofile?.kit || "");
      setSelectedLanguage(user.languageSpoken || []);

      if (therapistPublicProfile && isEmpty(user.therapistprofile?.bio)) {
        setBio(genericBioForTherapistPublicProfile(therapistPublicProfile));
      } else {
        setBio(user.therapistprofile?.bio || "");
      }

      const proPerformance = buildProPerformanceMatrix(user);
      setProPerformanceMatrix(proPerformance);
    }
  }, [user, therapistPublicProfile]);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const onProfileUpdated = () => {
    setSaving(false);

    setSuccessMessage("Your profile has been updated");

    setLastUpdated(new Date());

    fetchMe();
  };

  const handleProfileLock = (fieldName: string) => toggleLock({ fieldName });

  const closePersonalInfoDisclaimerModal = () => {
    setTimeout(() => {
      if (hasPersonalInformation(firstName)) {
        firstNameRef.current?.focus();
      } else if (hasPersonalInformation(lastName)) {
        lastNameRef.current?.focus();
      } else if (hasPersonalInformation(bio)) {
        bioRef.current?.focus();
      } else if (hasPersonalInformation(preferredFirstName || "")) {
        preferedNameRef.current?.focus();
      }
    }, 100);

    setPersonalDisclaimerOpen(false);
  };

  const bioSubtitle = () => (
    <TextWithLink
      preText={
        "Put your best foot forward by telling new clients a bit about yourself, your skills and your experience. Check out this  "
      }
      postText={" or get in touch with us if you need help!"}
      linkText={"article"}
      link={BIO_HELP_LINK}
      linkStyle={{ color: Colors.Dusk }}
      style={{
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "14px",
        color: Colors.Dusk,
        lineHeight: "21px",
      }}
    />
  );
  const handleMobileVerified = (
    formattedMobileNumber: string,
    numberOnly?: string,
    countryCode?: string
  ) => {
    setMobile(formattedMobileNumber || "");
    setCountryCode(countryCode || "");
    fetchMe();
  };
  const handleEmailVerified = (email: string) => {
    setEmail(email);
    fetchMe();
  };
  const isProfileLocked = user?.lockedAttributes.some(
    (data) => data.fieldName === LOCKED_ATTRIBUTES.NAME
  );

  const isProProfilePicLocked = checkLockAttribute({
    lockedAttributes: user?.lockedAttributes,
    fieldName: LOCKED_ATTRIBUTES.PRO_PROFILE_IMAGE,
  });

  const handleSpokenLanguageChange = (language: LanguageSpokenInterface) => {
    const { value } = language;
    const isAlreadyExist = selectedLanguage.find(
      (data: string) => data.toLowerCase() === value.toLowerCase()
    );

    if (isAlreadyExist) {
      const filteredData = selectedLanguage.filter(
        (data: string) => data.toLowerCase() !== value.toLowerCase()
      );
      setSelectedLanguage(filteredData);
      return;
    }

    setSelectedLanguage((prev) => [...prev, value]);
  };

  const handleLanguageRemove = (language: string) => {
    const filteredData = selectedLanguage.filter(
      (data: string) => data.toLowerCase() !== language.toLowerCase()
    );
    setSelectedLanguage(filteredData);
  };

  const removeAllSelectedLanguage = () => {
    setSelectedLanguage([]);
  };

  const verifyFields = () => {
    if (isNil(dateOfBirth)) {
      setErrorMessage("Please enter date of birth");
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!verifyFields()) {
      return;
    }
    if (
      hasPersonalInformation(firstName) ||
      hasPersonalInformation(lastName) ||
      hasPersonalInformation(bio) ||
      hasPersonalInformation(preferredFirstName || "")
    ) {
      setPersonalDisclaimerOpen(true);
      return;
    }
    setSaving(true);

    const data = {
      bio,
      kit,
      birthday: dateOfBirth ? moment(dateOfBirth).format("YYYY-MM-DD") : null,
      city,
      country: proCountry,
      email,
      firstName,
      middleName,
      preferredFirstName,
      lastName,
      gender,
      mobile: `${countryCode}${mobile}`,
      isCovidVaccinated,
      languageSpoken: selectedLanguage,
    };

    axios
      .put(`/therapists/me?accessToken=${accessToken}`, data)
      .then((response) => {
        if (!isNil(selectedImageFile)) {
          console.debug("selectedImageFile: ", selectedImageFile);

          const form = new FormData();
          form.append("image", selectedImageFile);

          axios
            .put(`/therapists/me?accessToken=${accessToken}`, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              onProfileUpdated();
            })
            .catch((error) => {
              setSaving(false);
              setErrorMessage(parseApiError(error));
            });
        }
      })
      .catch((error) => {
        setSaving(false);

        setErrorMessage(parseApiError(error));
      })
      .finally(() => {
        onProfileUpdated();
      });
  };

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Profile" />

        <Box
          mt="40px"
          paddingLeft={isMobile ? "16px" : "88px"}
          paddingRight={isMobile ? "16px" : undefined}
          maxWidth="632px"
        >
          <Box display={"flex"}>
            <div {...getRootProps()} style={{ cursor: "pointer", position: "relative" }}>
              {!isProProfilePicLocked ? <input {...getInputProps()} /> : <React.Fragment />}

              {selectedImageFile && (
                <img
                  src={URL.createObjectURL(selectedImageFile)}
                  style={profileImageStyle}
                  alt="user"
                />
              )}

              {!selectedImageFile && user?.therapistprofile?.profileImage && (
                <img
                  src={`${BASE_UPLOADS_URL}/${user.therapistprofile.profileImage}`}
                  style={profileImageStyle}
                  alt="profile"
                />
              )}

              {!selectedImageFile && isNil(user?.therapistprofile?.profileImage) && (
                <img src={ProfilePicturePlaceholder} style={profileImageStyle} alt="profile" />
              )}

              <ProfileEdit isLocked={isProProfilePicLocked} />
            </div>
            {isAdminLoggedInAsClient ? (
              <Box display={"flex"} alignItems={"flex-end"}>
                <LockButton
                  isLocked={isProProfilePicLocked}
                  handlePress={() => handleProfileLock(LOCKED_ATTRIBUTES.PRO_PROFILE_IMAGE)}
                  size={24}
                />
              </Box>
            ) : (
              <React.Fragment />
            )}
          </Box>

          <Box mt="32px" display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Box flex={1}>
              <ProDashboardOnlineStatusPill online={isOnline} />

              <Box fontFamily="Museo" fontSize="24px" fontWeight={600} mt="16px" mb="16px">
                {user?.firstName} {user?.middleName ? `${user.middleName} ` : ""}
                {user?.lastName}
              </Box>
              <TextLink
                title="Public profile"
                fontSize="16px"
                fontWeight={600}
                onClick={() => {
                  if (user) {
                    window.open(getProfileLink(user), "_blank");
                  }
                }}
              />

              <Box mt="27.5px">
                <Ratings rating={user?.therapistprofile?.rating || 0} width="18px" height="18px" />

                <Box color={Colors.TurquoiseBlue} fontSize="14px" fontFamily="Open Sans" mt="2.5px">
                  ({numberOfReviews} Reviews)
                </Box>
              </Box>
            </Box>

            <Box width={isMobile ? "100%" : "300px"} mt={isMobile && 3} mb={isMobile && 3}>
              <Each
                of={proPerformanceMatrix}
                render={({ title, value, modalInfo, link }) => (
                  <React.Fragment>
                    <ProScoreRow title={title} value={value} modalInfo={modalInfo} link={link} />
                    <StatsRowSeparator />
                  </React.Fragment>
                )}
              />
            </Box>
          </Box>

          <Box
            fontFamily="Museo"
            fontSize="24px"
            fontWeight={600}
            color={Colors.NightBlue}
            mt={"20px"}
            mb={"10px"}
          >
            Edit profile
          </Box>
          <Box>
            <Box>
              <TextField
                title="First name"
                subtitle={
                  "The legal name on your government document, such as a license or a passport."
                }
                inputRef={firstNameRef}
                placeholder="(required)"
                value={firstName}
                onChange={(text) => setFirstName(text)}
                isLocked={isProfileLocked}
                isAdminLoggedInAsClient={isAdminLoggedInAsClient}
                handleToggleLock={!isLockLoading ? toggleLock : () => {}}
              />
            </Box>

            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <TextField
                title="Middle name"
                placeholder="Middle name"
                value={middleName}
                onChange={(text) => setMiddleName(text)}
              />
              <Box width="32px" />
              <TextField
                title="Last name"
                placeholder="(required)"
                value={lastName}
                inputRef={lastNameRef}
                onChange={(text) => setLastName(text)}
                isLocked={isProfileLocked}
                isAdminLoggedInAsClient={isAdminLoggedInAsClient}
                lockFieldName={LOCKED_ATTRIBUTES.NAME}
                handleToggleLock={!isLockLoading ? toggleLock : () => {}}
                showLock={true}
              />
            </Box>

            <Box>
              <TextField
                title="Preferred first name"
                subtitle={
                  "The name that you prefer to be called or to have on your public Blys profile."
                }
                placeholder="Preferred first name"
                value={preferredFirstName}
                onChange={(text) => setPreferredFirstName(text)}
                inputRef={preferedNameRef}
              />
            </Box>

            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <MobileInput
                phoneNumber={countryCode + mobile}
                onVerified={handleMobileVerified}
                isVerified={!!user?.mobileVerifiedAt}
              />
              <Box width="32px" />
              <EmailVerificationModal
                email={email}
                onVerified={handleEmailVerified}
                isVerified={!!user?.emailVerifiedAt}
              />
            </Box>

            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <Dropdown
                title="Gender"
                options={genderOptions}
                selectedOption={genderOptions.find((option) => option.value === gender)}
                onSelectedOption={(option) => setGender(option.value)}
              />
              <Box width="32px" />
              <DateField
                title="Date of birth"
                selectedDate={dateOfBirth}
                onSelectedDate={(date) => setDateOfBirth(date)}
                width="100%"
                disableFuture
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <SelectCountry onChange={(country) => setProCountry(`${country}`)} value={proCountry} />
          </Box>

          <Box display="flex" flexDirection={isMobile ? "column" : "row"} mt={2} mb={2}>
            <LanguageSpoken
              languageSpoken={languageSpokenOption}
              handleSpokenLanguageChange={handleSpokenLanguageChange}
              slectedLanguage={selectedLanguage}
              handleLanguageRemove={handleLanguageRemove}
              removeAllSelectedLanguage={removeAllSelectedLanguage}
            />
          </Box>

          <TextField
            title="Bio"
            subtitleElement={bioSubtitle()}
            placeholder="Tell your clients about yourself (required)"
            onChange={(text) => setBio(text)}
            value={bio}
            multiline
            maxLength={1000}
            inputRef={bioRef}
          />

          <TextField
            title="My Kit"
            subtitle="This is where you can list what products and equipment you use, making note of any reputable brands that might entice clients."
            placeholder="Tell your clients about your kit (optional)"
            onChange={(text) => setKit(text)}
            value={kit}
            multiline
            maxLength={500}
          />

          <Portfolio />

          <CovidVaccinationInfo
            type={MessageType.pro}
            isCovidVaccinated={isCovidVaccinated}
            onCheckBoxChange={(status) => {
              setIsCovidVaccinated(status);
            }}
          />

          <Box mb={20}>
            <Button
              loading={saving}
              title="Save changes"
              type={ButtonType.primary}
              width="152px"
              onClick={handleSave}
            />
          </Box>
        </Box>
      </Wrapper>
      <PersonalInfoDisclaimerModal
        open={personalInfoDisclaimerOpen}
        onClose={closePersonalInfoDisclaimerModal}
      />

      {/* {user && (
        <TherapistProfileDrawer
          therapistId={user.id}
          open={therapistProfileDrawerOpen}
          onClose={() => setTherapistProfileDrawerOpen(false)}
          mutateKey={lastUpdated ? String(lastUpdated?.getTime()) : undefined}
        />
      )} */}
    </>
  );
}
