import React from "react";
import { StripeConnectInstance } from "@stripe/connect-js";
import { ConnectAccountManagement, ConnectComponentsProvider } from "@stripe/react-connect-js";

interface Props {
  stripeConnectInstance: StripeConnectInstance | undefined;
  isLoading: boolean;
}

const StripeAccountManagement = ({ stripeConnectInstance, isLoading }: Props) => {
  if (isLoading || !stripeConnectInstance) {
    return <React.Fragment />;
  }

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountManagement />
    </ConnectComponentsProvider>
  );
};

export default StripeAccountManagement;
