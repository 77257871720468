interface iPagination {
  perPage: number;
  totalCount: number;
  page: number;
  [data: string]: any;
}

export const transformPagination = (data: iPagination) => {
  const { perPage = 1, totalCount = 0 } = data;
  return {
    ...data,
    totalPages: Math.ceil(totalCount / perPage) || 1,
  };
};
