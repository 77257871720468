import { BOOKING_STATUS, JobStatus } from "./booking";

export enum SORT_BY {
  ATOZ = "atoz",
  ZtoA = "ztoa",
  latestFirst = "latestFirst",
  oldFirst = "oldFirst",
}

export namespace SORT_BY {
  export function toString(sortBy: SORT_BY): string {
    switch (sortBy) {
    case SORT_BY.ATOZ:
      return "Alphabetically, A-Z";
    case SORT_BY.ZtoA:
      return "Alphabetically, Z-A";
    case SORT_BY.latestFirst:
      return "Date added, new to old";
    case SORT_BY.oldFirst:
      return "Date added, old to new";
    }
  }
}

export const AllSortBy = [
  SORT_BY.ATOZ,
  SORT_BY.ZtoA,
  SORT_BY.latestFirst,
  SORT_BY.oldFirst,
];

export const bookingSortOptions = [
  {
    title: "Booking date, new to old",
    value: "desc",
  },
  {
    title: "Booking date, old to new",
    value: "asc",
  }
];

export const BookingFilterStatus = {
  [BOOKING_STATUS.NEW]: "pending",
  [BOOKING_STATUS.ARRANGED]: "confirmed",
  [BOOKING_STATUS.CANCELLED]: "cancelled",
  [BOOKING_STATUS.COMPLETED]: "completed",
  [JobStatus.onway]: "ontheway",
};

export const upcomingBookingStatusOptions = [
  {
    title: BookingFilterStatus[BOOKING_STATUS.NEW],
    description: "Show bookings that are pending provider acceptance and confirmation."
  },
  {
    title: BookingFilterStatus[BOOKING_STATUS.ARRANGED],
    description: "Show bookings that are accepted and confirmed by the providers."
  },
  {
    title: BookingFilterStatus[JobStatus.onway],
    description: "Show confirmed bookings with the provider on the way."
  },
];


export const pastBookingStatusOptions = [
  {
    title: BookingFilterStatus[BOOKING_STATUS.COMPLETED],
    description: "Show bookings that are completed."
  },
  {
    title: BookingFilterStatus[BOOKING_STATUS.CANCELLED],
    description: "Show bookings that are cancelled."
  },
];