import { Box, BoxProps, LinearProgress, SwipeableDrawer } from "@material-ui/core";
import { isNil } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import SideMenu from "../components/SideMenu";
import Snackbar, { SnackbarType } from "../components/Snackbar";
import TextLink from "../components/TextLink";
import { Colors } from "../constants/colors";
import { setAccessToken } from "../helpers/accessToken";
import { parseQueryString } from "../helpers/string";
import { useMobile } from "../hooks/mobile";
import ForgotPasswordModal from "../pages/ForgotPassword/ForgotPasswordModal";
import LoginModal from "../pages/Login/LoginModal";
import SignUpModal from "../pages/SignUp/SignUpModal";
import { useAlertStore } from "../stores/alert";
import { useUserStore } from "../stores/user";
import UserAvatarButton from "./UserAvatarButton";

interface Props extends BoxProps {
  header?: React.ReactNode;
  onLoggedIn?: () => unknown;
  withFixedSidebar?: boolean; // Used to restrict the max height to 100vh of the sidebar and min height of wrapper
}

interface SignInToAccessProps {
  onSignIn: () => unknown;
}

function SignInToAccess({ onSignIn }: SignInToAccessProps) {
  return (
    <Box display="flex" flex={1} alignItems="center" justifyContent="center" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <TextLink title="Log in" onClick={onSignIn} mr="5px" fontWeight={600} />
        <Box fontFamily="Museo" fontWeight={400} fontSize="16px">
          to access this page
        </Box>
      </Box>
    </Box>
  );
}

export default function Wrapper({
  header,
  onLoggedIn,
  children,
  withFixedSidebar,
}: Props): JSX.Element {
  const isMobile = useMobile();

  const { search } = useLocation();
  const query = parseQueryString(search);

  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } = useAlertStore();

  const { user, fetchMe, isFetchingUser } = useUserStore();

  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = React.useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = React.useState(false);
  const [forgotPasswordPrefillEmail, setForgotPasswordPrefillEmail] = React.useState("");

  React.useEffect(() => {
    fetchMe();
  }, []);

  React.useEffect(() => {
    if (!query.accessToken || !!user) return;

    setAccessToken(query.accessToken as string);
    fetchMe(query.accessToken as string);
  }, [query]);

  return (
    <>
      <Box>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          minHeight={isMobile || withFixedSidebar ? "100dvh" : undefined}
        >
          {isMobile ? (
            <SwipeableDrawer
              anchor="left"
              open={sideMenuOpen}
              onClose={() => setSideMenuOpen(false)}
              onOpen={() => setSideMenuOpen(true)}
            >
              <SideMenu
                onClose={() => setSideMenuOpen(false)}
                onLogin={() => setLoginModalOpen(true)}
                withFixedSidebar={withFixedSidebar}
              />
            </SwipeableDrawer>
          ) : (
            <SideMenu onLogin={() => setLoginModalOpen(true)} withFixedSidebar={withFixedSidebar} />
          )}

          {isMobile && <UserAvatarButton onClick={() => setSideMenuOpen(true)} />}

          <Box display="flex" flex={1} bgcolor={Colors.Snow} flexDirection="column">
            {isFetchingUser && <LinearProgress />}
            {header}
            {user && children}
            {isNil(user) && <SignInToAccess onSignIn={() => setLoginModalOpen(true)} />}
          </Box>
        </Box>

        <Snackbar
          type={SnackbarType.error}
          message={errorMessage}
          open={errorMessage !== null}
          onClose={() => setErrorMessage(null)}
        />

        <Snackbar
          type={SnackbarType.success}
          open={successMessage !== null}
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
      </Box>
      {isNil(user) && (
        <LoginModal
          open={loginModalOpen}
          onClose={() => setLoginModalOpen(false)}
          onLoggedIn={() => {
            fetchMe();
            setLoginModalOpen(false);
            onLoggedIn && onLoggedIn();
          }}
          onForgotPassword={(email) => {
            setForgotPasswordPrefillEmail(email);
            setLoginModalOpen(false);
            setForgotPasswordModalOpen(true);
          }}
          onCreateAccount={() => {
            setLoginModalOpen(false);
            setSignUpModalOpen(true);
          }}
        />
      )}

      {isNil(user) && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
          onBackToLogin={() => {
            setForgotPasswordModalOpen(false);
            setLoginModalOpen(true);
          }}
          onSentInstructions={(email) => {
            setForgotPasswordModalOpen(false);
            setSuccessMessage(`Password reset instructions have been sent to ${email}`);
          }}
          prefillEmail={forgotPasswordPrefillEmail}
        />
      )}

      {isNil(user) && (
        <SignUpModal
          open={signUpModalOpen}
          onLogin={() => {
            setSignUpModalOpen(false);
            setLoginModalOpen(true);
          }}
          onCreatedAccount={() => {
            fetchMe();
            setSignUpModalOpen(false);
            onLoggedIn && onLoggedIn();
          }}
          onClose={() => setSignUpModalOpen(false)}
        />
      )}
    </>
  );
}
