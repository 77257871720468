import { styled } from "@mui/system";
import { Colors } from "../../../../constants/colors";
import { FontFamily, FontSize, FontWeight, LetterSpacing, LineHeight } from "../enum";

interface Props {
  size?: FontSize;
  color?: Colors;
  weight?: FontWeight;
  font?: FontFamily;
  lineHeight?: LineHeight;
  letterSpacing?: LetterSpacing;
}

const styles = ({ size, color, weight, font, lineHeight, letterSpacing }: Props) => ({
  color: color,
  fontSize: size,
  fontWeight: weight,
  fontFamily: font,
  lineHeight: lineHeight,
  letterSpacing,
});

const Text = styled("span", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "style", // Prevent using custom style from props
})<Props>(styles);

Text.defaultProps = {
  weight: FontWeight.Regular,
  size: FontSize.F16,
  font: FontFamily.OpenSans,
  color: Colors.Dusk,
};

export default Text;
