import { useQuery } from "@tanstack/react-query";
import * as httpClient from "../api/client";
import { getValue } from "../utils/object";

export const useGetGenderPreference = () => {
  const { data } = useQuery(["gender-preference"], async () =>
    httpClient.get("api/v2/genderPreferences/options"),
  );

  return getValue(data, "data") || [];
};
