import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";

import AddressAutocomplete from "../../../components/AddressAutocomplete";
import Dialog from "../../../components/Dialog";
import Button, { ButtonType } from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { Colors } from "../../../constants/colors";
import { formatServicesData, formatTreatmentsData } from "../../../helpers/services";
import { useMobile } from "../../../hooks/mobile";
import { useAlertStore } from "../../../stores/alert";
import { useServiceRates } from "../../../hooks/services/rates.hooks";
import { useUserStore } from "../../../stores/user";
import { DEFAULT_COUNTRY, setCountryCode as storeCountryCode } from "../../../utils/country";
import SelectLocationMap from "../../../components/Addresses/SelectLocationMap";
import { ServiceRate } from "../../../models";
import { useHistory, useLocation } from "react-router-dom";
import { useGetAllLanguageSpokenOptions } from "../../../hooks/languageSpoken.hooks";
import { isNullString } from "../../../helpers/validation";

interface Props {
  onBrowse: Function;
  notFound: Boolean;
  isLoading: Boolean;
}

interface LocationCord {
  long?: number;
  lat?: number;
}

export const extractDataFromQuery = (queryString: string) => {
  const params = new URLSearchParams(queryString);

  const service = params.get("service");
  const lat = params.get("lat");
  const long = params.get("long");
  const treatment = params.get("treatment");
  const address = params.get("address");
  const serviceName = params.get("serviceName");
  const language = params.get("language");

  return {
    service: isNullString(service) ? null : service,
    lat: isNullString(lat) ? null : lat,
    long: isNullString(long) ? null : long,
    treatment: isNullString(treatment) ? null : treatment,
    address: isNullString(address) ? null : address,
    serviceName: isNullString(serviceName) ? null : serviceName,
    language: isNullString(language) ? null : language,
  };
};

function BrowseTherapistForm({ onBrowse, notFound, isLoading = false }: Props): JSX.Element {
  const isMobile = useMobile();
  const { user } = useUserStore();

  const history = useHistory();

  const { setErrorMessage } = useAlertStore();

  const [services, setServices] = useState<any>([]);
  const [serviceId, setServiceId] = useState<any>(null);
  const [serviceName, setServiceName] = useState("");

  const [treatments, setTreatments] = useState<any>([]);
  const [treatmentId, setTreatmentId] = useState<any>(null);

  const [location, setLocation] = useState("");
  const [selectedCords, setSelectedCords] = useState<LocationCord>({});
  const [countryCode, setCountryCode] = useState(user?.country || DEFAULT_COUNTRY);
  const [isMapSelected, setIsMapSelected] = useState(false);

  const [languageSpoken, setLanguageSpoken] = useState("");

  const fetchGlobal = false;
  const { services: servicesList } = useServiceRates(countryCode, fetchGlobal);
  const { data: languageOptions } = useGetAllLanguageSpokenOptions();

  const [profession, setProfession] = useState("therapist");

  const getSlectedServiceTreatments = (serviceId: number) => {
    const treatmentList =
      servicesList?.find((data: ServiceRate) => data.id === serviceId)?.prices || [];
    const treatments = formatTreatmentsData(treatmentList);
    return treatments;
  };

  useEffect(() => {
    storeCountryCode(user?.country || DEFAULT_COUNTRY);
  }, [user]);

  useEffect(() => {
    const services = formatServicesData(servicesList);
    setServices(services);
  }, [servicesList]);

  useEffect(() => {
    if (services && services.length) {
      let selectedService = services[0];
      if (serviceName) {
        const equivalentService = services.find(
          ({ title }: { title: string }) => title === serviceName,
        );
        if (equivalentService) selectedService = equivalentService;
      }
      setServiceName(selectedService?.title);
      setServiceId(selectedService?.value);
      const treatmentList = getSlectedServiceTreatments(selectedService?.value);
      setTreatments(treatmentList);
    }
  }, [services]);

  useEffect(() => {
    const treatmentList = getSlectedServiceTreatments(serviceId);
    setTreatments(treatmentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  useEffect(() => {
    const { service, treatment, lat, long, address, serviceName, language } = extractDataFromQuery(
      window.location.search,
    );

    service && setServiceId(parseInt(service));
    serviceName && setServiceName(serviceName);
    treatment && setTreatmentId(parseInt(treatment));
    address && setLocation(address);
    language && setLanguageSpoken(language);

    if (lat && long) {
      setSelectedCords({ lat: parseFloat(lat), long: parseFloat(long) });
    }
  }, []);

  const appendSearchDataInUrl = ({
    serviceId,
    lat,
    long,
    treatmentId,
    address,
    serviceName,
    languageSpoken,
  }: {
    serviceId?: any;
    lat?: any;
    long?: any;
    treatmentId?: any;
    address?: any;
    serviceName?: any;
    languageSpoken?: any;
  }) => {
    const {
      service,
      treatment,
      lat: latitude,
      long: longitude,
      address: addressName,
      serviceName: selectedServiceName,
      language,
    } = extractDataFromQuery(window.location.search);

    const queryParams = new URLSearchParams();
    queryParams.set("service", serviceId || service);
    queryParams.set("serviceName", serviceName || selectedServiceName);
    queryParams.set("lat", lat || latitude);
    queryParams.set("long", long || longitude);
    queryParams.set("treatment", treatmentId || treatment);
    queryParams.set("address", address || addressName);
    queryParams.set("language", languageSpoken || language);
    history.replace({ search: queryParams.toString() });
  };

  const getServiceById = () => {
    return services.find((option: any) => option.value === serviceId);
  };

  const getTreatmentById = () => {
    return treatments.find((option: any) => option.value === treatmentId);
  };

  const handleBrowse = () => {
    try {
      validateForm();
      const chosenService = getServiceById();
      setProfession(chosenService.profession);
      onBrowse(selectedCords, serviceId, chosenService.title, treatmentId, languageSpoken);
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  };

  const validateForm = () => {
    if (serviceId == null) {
      throw Error("Please select a service");
    }
    if (location === "" || location == null) {
      throw Error("Please select a location");
    }
    if (!selectedCords.lat || !selectedCords.long) {
      throw Error("Please select a valid location");
    }
  };

  const handleAddressSelected = ({ address }: any) => {
    if (!address) return;

    const {
      countryCode: addressCountry,
      longitude,
      latitude,
      address: addressName,
      fullAddress,
    } = address;

    const cords = {
      long: address.long || longitude,
      lat: address.lat || latitude,
    };
    setSelectedCords(cords);
    setLocation(fullAddress || addressName);

    const selectedCountry = addressCountry || DEFAULT_COUNTRY;
    setCountryCode(selectedCountry);
    storeCountryCode(selectedCountry); // for axios interceptor

    appendSearchDataInUrl({
      lat: cords.lat,
      long: cords.long,
      address: fullAddress || addressName,
    });
  };

  const onMapSelected = () => {
    setIsMapSelected(true);
  };

  const handleServiceSelected = (selectedService: any) => {
    setServiceName(selectedService.title);
    setServiceId(selectedService.value);
    appendSearchDataInUrl({
      serviceId: selectedService.value,
      serviceName: selectedService.title,
    });
  };

  const handleTreatmentSelected = (selectedTreatment: any) => {
    setTreatmentId(selectedTreatment.value);
    appendSearchDataInUrl({
      treatmentId: selectedTreatment.value,
    });
  };

  const handleLanguageSelected = (selectedLanguage: any) => {
    setLanguageSpoken(selectedLanguage.value);
    appendSearchDataInUrl({
      languageSpoken: selectedLanguage.value,
    });
  };

  return (
    <Box>
      {/* header */}
      <Dialog
        open={isMapSelected}
        maxWidth={false}
        fullScreen={isMobile}
        onClose={() => setIsMapSelected(false)}
      >
        <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
          <Box paddingLeft="40px" paddingRight="40px" paddingBottom="40px" paddingTop="32px">
            <Box
              fontFamily="Museo"
              fontSize="22px"
              fontWeight="600"
              color={Colors.Indigo}
              textAlign="center"
              mb={2}
            >
              Set your location
            </Box>
            <SelectLocationMap
              setIsMapSelected={setIsMapSelected}
              onSelectedAddress={(address) => handleAddressSelected({ address })}
            />
          </Box>
        </Box>
      </Dialog>

      <Box mb="20px">
        <Box fontFamily="Museo" fontSize="24px" fontWeight={600} color={Colors.NightBlue}>
          {notFound ? "No " + profession + "s found" : "Browse providers"}
        </Box>
        <Box fontFamily="Museo" fontSize="16px" fontWeight={400} color={Colors.Dusk} mt="24px">
          {notFound
            ? "Sorry, there are no active " + profession + "s in your area right now."
            : "Find providers in your area and request a booking directly."}
        </Box>
      </Box>

      {/* Browse form */}

      <Box mb={"12px"}>
        <AddressAutocomplete
          title="Location"
          value={location}
          showSavedAddresses={true}
          placeholder="Enter location"
          onChange={(text) => setLocation(text)}
          onSelectedAddress={(address) => handleAddressSelected({ address })}
          onMapSelected={onMapSelected}
        />
        <Dropdown
          title="Service"
          options={services}
          selectedOption={getServiceById()}
          onSelectedOption={(selectedService) => {
            handleServiceSelected(selectedService);
          }}
        />
        <Dropdown
          title="Treatment type"
          options={treatments}
          selectedOption={getTreatmentById()}
          onSelectedOption={(selectedTreatment) => {
            handleTreatmentSelected(selectedTreatment);
          }}
        />
        <Dropdown
          title="Language spoken"
          options={languageOptions}
          selectedOption={languageOptions?.find((data: any) => data.value === languageSpoken)}
          onSelectedOption={(selectedLanguage) => {
            handleLanguageSelected(selectedLanguage);
          }}
        />
      </Box>

      <Button
        width={isMobile ? "100%" : "173px"}
        type={ButtonType.primary}
        title="Find providers"
        onClick={handleBrowse}
        loading={isLoading ? true : false}
      />
    </Box>
  );
}

export default BrowseTherapistForm;
