import React, { Children } from "react";

interface Props {
  of: any[];
  render: (item: any, index: number) => unknown;
}
const Each = ({ of, render }: Props): JSX.Element => (
  <React.Fragment>
    {Children.toArray((of || []).map((item, index) => render(item, index)))}
  </React.Fragment>
);

export default Each;
