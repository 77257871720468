import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../../../../constants/colors";
import { ServiceRate, ServiceRatePrice } from "../../../../../models";
import "./dropdown.css";
import DropdownItem from "./DropdownItem";
import DropdownValueHolder from "./DropdownValueHolder";
import TreatmentDescription from "../../../../Treatment/TreatmentDescription/TreatmentDescription";
import { getSelectedAddOnName } from "../../../../../helpers/booking";
import SelectNone from "./SelectNone";
import SelectOther from "./SelectOther";
import OtherAddOnModal from "./OtherAddOnModal";
import { useAlertStore } from "../../../../../stores/alert";
import { useSaveAddOn } from "../../../../../hooks/addon/addon.hooks";
import { checkIfEmpty } from "../../../../../utils/object";
import { useMobile } from "../../../../../hooks/mobile";

type Direction = "up" | "down";

interface Props {
  label: string;
  placeholder?: string;
  dropDirection?: Direction;
  value?: string | number | null;
  treatments: Array<ServiceRatePrice>;
  onSelect: ({
    treatment,
    treatmentIndex,
    isMultipleAddonsAllowed,
  }: {
    treatment: ServiceRatePrice;
    treatmentIndex: number;
    isMultipleAddonsAllowed: boolean;
  }) => unknown;
  treatmentIndex: number;
  selectedAddOn?: Array<ServiceRatePrice>;
  isMultipleAddonsAllowed: boolean;
  showOtherAddOnOption?: boolean;
  onNoneSelect: (val: number) => unknown;
  disabled?: boolean;
}

const AddOnDropdownMenu = ({
  label,
  onSelect,
  treatments,
  placeholder,
  dropDirection,
  treatmentIndex,
  selectedAddOn,
  isMultipleAddonsAllowed,
  onNoneSelect,
  showOtherAddOnOption = false,
  disabled = false,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onSuccess = () => {
    setSuccessMessage("Add on added successfully.");
  };
  const onError = () => {
    setErrorMessage("Something went wrong.");
  };
  const { mutateAsync: saveAddOn, isLoading } = useSaveAddOn({ onSuccess, onError });

  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const isMobile = useMobile();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [descriptionVisible, _setDescriptionVisible] = useState<boolean>(false);
  const [dropBelow] = useState<boolean>(dropDirection ? dropDirection === "down" : true);

  const [selectedService, setSelectedService] = useState<ServiceRate>();

  const [selectedForDetail, setSelectedForDetail] = useState<ServiceRatePrice>();

  const [showOtherAddOnModal, setShowOtherAddOnModal] = useState(false);
  const [otherAddOn, setOtherAddOn] = useState([]) as any;

  // Since we cannot access current state values inside event listeners
  // we use ref to go around this issue.
  const descriptionVisibleRef = useRef(descriptionVisible);
  const setDescriptionVisible = (value: boolean) => {
    descriptionVisibleRef.current = value;
    _setDescriptionVisible(value);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      !descriptionVisibleRef.current
    ) {
      setDropdownVisible(false);
    }
  };

  const handleSelect = (treatment: ServiceRatePrice) => {
    if (!isMultipleAddonsAllowed) {
      setDropdownVisible(false);
    }
    onSelect({
      treatment,
      treatmentIndex,
      isMultipleAddonsAllowed,
    });
  };

  const onCustomAddOnConfirmClick = () => {
    setShowOtherAddOnModal(false);
    if (!isMultipleAddonsAllowed) {
      setDropdownVisible(false);
    }

    otherAddOn.forEach((treatment: any) => {
      onSelect({
        treatment,
        treatmentIndex,
        isMultipleAddonsAllowed,
      });
    });
  };

  const onLearnMore = useCallback((treatment: ServiceRatePrice) => {
    setSelectedForDetail(treatment);
    setDescriptionVisible(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setDescriptionVisible(false);
  }, []);

  const handleNoneSelect = () => {
    onNoneSelect(treatmentIndex);
    setDropdownVisible(false);
  };

  const onCustomAddOnSave = async ({
    name,
    price,
    duration,
  }: {
    name: string;
    price: string;
    duration: string;
  }) => {
    const payload = { name, price, duration };
    const responseData = await saveAddOn(payload);
    if (responseData?.addon) {
      const { name, id, price, duration } = responseData.addon;
      setOtherAddOn((prev: any) => [...prev, { name, price, duration, id, custom: true }]);
    }
  };

  const removeOtherAddOn = ({ name }: { name: string }) => {
    const remainingAddOn = otherAddOn.filter((data: any) => data.name !== name);
    setOtherAddOn(remainingAddOn);
  };

  const selectedAddOnName = getSelectedAddOnName({ addOn: selectedAddOn });

  if (checkIfEmpty(treatments) && !showOtherAddOnOption) return <></>;

  return (
    <>
      <div ref={containerRef} style={{ width: "100%" }}>
        <Box
          position={"relative"}
          width="100%"
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
        >
          <Box paddingY="21px">
            <Box fontWeight={700}>{label}</Box>
            <DropdownValueHolder
              disabled={disabled}
              placeholder={placeholder || "Select"}
              value={selectedAddOnName}
              onClick={() => setDropdownVisible(!dropdownVisible)}
            />
            <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
          </Box>

          <Box
            className="treatment-dropdown"
            style={{
              display: dropdownVisible ? "block" : "none",
              // marginTop: dropBelow ? "-8px" : "-560px",
            }}
          >
            {!checkIfEmpty(treatments) && (
              <SelectNone
                onSelect={handleNoneSelect}
                selected={selectedAddOn && selectedAddOn?.length > 0 ? false : true}
              />
            )}

            {treatments.map((treatment: any) => (
              <DropdownItem
                key={treatment.name}
                selected={
                  selectedAddOn?.find(
                    (addOn: ServiceRatePrice) =>
                      treatment?.treatmentAddons?.id === addOn.id || treatment.id === addOn.id
                  )
                    ? true
                    : false
                }
                treatment={treatment}
                onSelect={handleSelect}
                onLearnMoreClick={onLearnMore}
                hasFixedDuration={selectedService?.hasFixedDuration}
              />
            ))}

            {showOtherAddOnOption && <SelectOther onSelect={() => setShowOtherAddOnModal(true)} />}
          </Box>
        </Box>
      </div>

      <TreatmentDescription
        isOpen={descriptionVisible}
        title={selectedForDetail?.label || ""}
        description={selectedForDetail?.fullDescription || ""}
        onClose={handlePopupClose}
      />

      <OtherAddOnModal
        onClose={() => setShowOtherAddOnModal(false)}
        onConfirm={onCustomAddOnConfirmClick}
        open={showOtherAddOnModal}
        onaddAddOnClick={onCustomAddOnSave}
        otherAddOn={otherAddOn}
        removeOtherAddOn={removeOtherAddOn}
        isSaving={isLoading}
      />
    </>
  );
};

export default AddOnDropdownMenu;
