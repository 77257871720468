import { Box, BoxProps, Checkbox } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React from "react";
import useSWR from "swr";
import Button, { ButtonType } from "../../../components/Button";
import DateField from "../../../components/DateField";
import Dropdown, { DropdownOption } from "../../../components/Dropdown";
import UploadedFileRow from "../../../components/ProDashboard/UploadedFileRow";
import TextField from "../../../components/TextField";
import TextLink from "../../../components/TextLink";
import { Colors } from "../../../constants/colors";
import { parseApiError } from "../../../helpers/error";
import { useAccessToken } from "../../../hooks/common";
import { HealthFund, UploadedFile, UserFund } from "../../../models";
import { useAlertStore } from "../../../stores/alert";
import { fetchMyTherapistProfile, useTherapistStore } from "../../../stores/therapist";
import { useUserStore } from "../../../stores/user";
import StripeConnect from "./StripeConnect";
import { useStateOptions } from "../../../hooks/utility/states.hooks";
import { containsSpecialCharacterOrSpace } from "../../../helpers/validation";
import { ABN_REQUIRED_COUNTRIES, ABN_VALIDATION_MESSAGE } from "../../../constants/countries";

const Header = (props: BoxProps) => {
  return (
    <Box fontFamily="Museo" fontSize="24px" fontWeight={600} color={Colors.NightBlue} {...props} />
  );
};

const Title = (props: BoxProps) => {
  return <Box fontFamily="Museo" fontSize="14px" fontWeight={600} color={Colors.Dusk} {...props} />;
};

const Subtitle = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="14px"
      fontWeight={400}
      lineHeight="21px"
      color={Colors.Dusk}
      {...props}
    />
  );
};

interface MyHealthFundRowProps {
  fund: UserFund;
  onRemove: () => unknown;
}

const MyHealthFundRow = ({ fund, onRemove }: MyHealthFundRowProps) => {
  return (
    <Box display="flex" flexDirection="row" maxWidth="400px" mt="9px" mb="9px" alignItems="center">
      <Box fontFamily="Open Sans" fontWeight={400} fontSize="14px" lineHeight="21px" flex={1}>
        {fund.healthfund?.name} / {fund.providerId}
      </Box>

      <TextLink title="Remove" destructive onClick={onRemove} />
    </Box>
  );
};

export default function Information({ informationConfig }: any) {
  const { therapistUser } = useTherapistStore();
  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const { states } = useStateOptions({ countryCode: therapistUser?.country });
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const [insuranceDate, setInsuranceDate] = React.useState<Date | null>(null);
  const [abn, setAbn] = React.useState("");
  const [stateOptions, setStateOptions] = React.useState<any>([]);
  const [businessName, setBusinessName] = React.useState("");
  const [businessAddress, setBusinessAddress] = React.useState("");
  const [gst, setGst] = React.useState(false);
  const [bsb, setBsb] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [selectedState, setSelectedState] = React.useState("");
  const [saving, setSaving] = React.useState(false);

  const insuranceFileUploadRef = React.useRef<HTMLInputElement>(null);

  const { data: uploadedFilesData, mutate: uploadedFilesMutate } = useSWR(
    `/therapists/me/files?accessToken=${accessToken}`
  );
  const uploadedFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter((file) => file.type === "insurance")
    : [];

  const { data: healthFundsData } = useSWR(`/healthFunds?accessToken=${accessToken}`);

  const healthFunds = healthFundsData ? (healthFundsData as HealthFund[]) : [];

  const healthFundsOptions = healthFunds
    .map((fund) => {
      return {
        value: fund.id,
        title: fund.name,
      } as DropdownOption;
    })
    .concat([
      {
        value: "other",
        title: "Other",
      },
    ]);

  const [selectedHealthFundId, setSelectedHealthFundId] = React.useState<number | null | "other">(
    null
  );

  const [customHealthFundName, setCustomHealthFundName] = React.useState("");
  const [providerNumber, setProviderNumber] = React.useState("");

  const { data: myHealthFundsData, mutate: myHealthFundsMutate } = useSWR(
    `/therapists/me/funds?accessToken=${accessToken}`
  );
  const myHealthFunds = myHealthFundsData ? (myHealthFundsData as UserFund[]) : [];

  React.useEffect(() => {
    fetchMyTherapistProfile();
  }, []);

  React.useEffect(() => {
    if (states?.length) {
      setStateOptions(states || []);
    }
  }, [states, therapistUser]);

  React.useEffect(() => {
    if (therapistUser?.therapistprofile) {
      const {
        abn,
        businessName,
        businessAddress,
        gst,
        bsb,
        accountNumber,
        insuranceDate,
        licenseValidLocation,
      } = therapistUser.therapistprofile;

      setAbn(abn);
      setBusinessName(businessName);
      setBusinessAddress(businessAddress);
      setGst(gst);
      setBsb(bsb);
      setAccountNumber(accountNumber);
      setInsuranceDate(insuranceDate ? moment(insuranceDate, "YYYY-MM-DD").toDate() : null);
      setSelectedState(licenseValidLocation);
    }
  }, [therapistUser]);

  const {
    businessNumber,
    stateSelection,
    showTaxCheckbox,
    taxTitle,
    bankingInformation,
    healthFund,
  } = informationConfig;

  React.useEffect(() => {
    if (healthFund?.addHealthFund?.input === "text") {
      setSelectedHealthFundId("other");
    }
  }, [healthFund]);

  return (
    <Box maxWidth="632px" pb={20}>
      <Header mt="56px" mb="24px">
        Business information
      </Header>
      {businessNumber && (
        <TextField
          title={businessNumber.title}
          subtitle={businessNumber.description}
          placeholder="123456789"
          value={abn}
          onChange={(text) => setAbn(text)}
        />
      )}

      {stateSelection && (
        <Dropdown
          title={stateSelection.title}
          options={stateOptions}
          onSelectedOption={(option) => setSelectedState(option.value)}
          selectedOption={stateOptions.find((option: any) => option.value === selectedState)}
        />
      )}

      <TextField
        title="Business name"
        subtitle="Entity legal name as it appears on all official documents or legal
      papers. This name will be included in your invoices."
        placeholder="Sally's Super Massage"
        value={businessName}
        onChange={(text) => setBusinessName(text)}
      />
      <TextField
        title="Business address"
        subtitle="Registered business address as shown in your business registration.
        Your address will not be publicly visible. It will only be included in your invoices for taxation purposes."
        placeholder="10 Smith Street, Sydney NSW 2000"
        value={businessAddress}
        onChange={(text) => setBusinessAddress(text)}
      />

      {showTaxCheckbox && (
        <>
          <Title mt="24px">Registered for {taxTitle}</Title>
          <Subtitle mt="8px">
            You don't have to be, but we just need this information for reporting and payment
            purposes.
          </Subtitle>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox
              style={{
                color: Colors.TurquoiseBlue,
              }}
              checked={gst}
              onChange={(event) => {
                axios
                  .put(`therapists/me/switch?accessToken=${accessToken}`, {
                    field: "gst",
                  })
                  .then((response) => {
                    fetchMyTherapistProfile();

                    setSuccessMessage(`Your ${taxTitle} information has been updated`);
                  })
                  .catch((error) => {
                    setErrorMessage(parseApiError(error));
                  });
              }}
            />
            <Box
              fontFamily="Museo"
              fontWeight={400}
              fontSize="16px"
              lineHeight="24px"
              color={Colors.Dusk}
            >
              I am registered for {taxTitle}
            </Box>
          </Box>
        </>
      )}

      <Box mt="64px" mb="24px">
        <Header>{bankingInformation.title}</Header>
        {bankingInformation.description && (
          <Box
            fontFamily="Museo"
            fontWeight={400}
            fontSize="16px"
            lineHeight="24px"
            marginTop="24px"
            color={Colors.Dusk}
          >
            {bankingInformation.description}
          </Box>
        )}
      </Box>

      {bankingInformation.paymentType === "STRIPE" ? (
        <StripeConnect therapistUser={therapistUser} />
      ) : (
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Title>BSB number</Title>
            <Subtitle mt="8px">No special characters or spaces.</Subtitle>
            <TextField placeholder="000000" value={bsb} onChange={(text) => setBsb(text)} />
          </Box>
          <Box width="32px" />
          <Box flex={1}>
            <Title>Account number</Title>
            <Subtitle mt="8px">No special characters or spaces.</Subtitle>
            <TextField
              placeholder="00000000"
              value={accountNumber}
              onChange={(text) => setAccountNumber(text)}
            />
          </Box>
        </Box>
      )}

      <Box mt="64px" mb="24px">
        <Header>Insurance</Header>
        <Box
          fontFamily="Museo"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          marginTop="24px"
          color={Colors.Dusk}
        >
          Please upload all current insurance documentation, scanned or photos of documents are
          accepted.
        </Box>
      </Box>

      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="18px"
        lineHeight="27px"
        color={Colors.Dusk}
        mt="32px"
        mb="32px"
      >
        Upload documentation
      </Box>

      {/* List of files */}

      {uploadedFiles.map((uploadedFile) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {
            user &&
              axios
                .delete(
                  `/therapists/${uploadedFile.id}/file?accessToken=${accessToken}&therapistId=${user.id}`
                )
                .then((response) => {
                  uploadedFilesMutate();

                  setSuccessMessage("This file has been deleted");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }}
        />
      ))}

      <Box mt="37px" mb="32px">
        <Button
          title="Upload file"
          type={ButtonType.outlined}
          width="132px"
          onClick={() => {
            if (insuranceFileUploadRef.current) {
              insuranceFileUploadRef.current.click();
            }
          }}
        />
      </Box>
      <DateField
        title="Expiry date"
        selectedDate={insuranceDate}
        onSelectedDate={(date) => setInsuranceDate(date)}
        width="100%"
        country={user?.country}
      />
      <Header mt="64px">Health funds</Header>
      {healthFund.addHealthFund.input === "select" ? (
        <>
          <Dropdown
            title={healthFund.addHealthFund.title}
            options={healthFundsOptions}
            onSelectedOption={(option) => setSelectedHealthFundId(option.value)}
            selectedOption={healthFundsOptions.find(
              (option) => option.value === selectedHealthFundId
            )}
          />

          {selectedHealthFundId === "other" && (
            <TextField
              title="Health fund name"
              value={customHealthFundName}
              onChange={(text) => setCustomHealthFundName(text)}
            />
          )}
        </>
      ) : (
        <TextField
          title={healthFund.addHealthFund.title}
          value={customHealthFundName}
          onChange={(text) => setCustomHealthFundName(text)}
        />
      )}

      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          title="Provider number"
          value={providerNumber}
          onChange={(text) => setProviderNumber(text)}
        />
        <Box mt={2} ml="24px">
          <Button
            title="Add"
            type={ButtonType.outlined}
            width="79px"
            onClick={() => {
              const data = {
                healthFundId: selectedHealthFundId !== "other" ? selectedHealthFundId : null,
                customFund: selectedHealthFundId === "other" ? customHealthFundName : null,
                providerId: providerNumber,
              };

              console.debug("data: ", data);

              axios
                .put(`/therapists/me/funds?accessToken=${accessToken}`, data)
                .then((response) => {
                  myHealthFundsMutate();

                  setSuccessMessage("Added new health fund");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
            }}
          />
        </Box>
      </Box>

      {/* Health funds list */}
      {myHealthFunds.map((fund) => (
        <MyHealthFundRow
          fund={fund}
          onRemove={() => {
            axios
              .delete(`/therapists/me/funds/${fund.id}?accessToken=${accessToken}`)
              .then((response) => {
                myHealthFundsMutate();

                setSuccessMessage("Health fund deleted");
              })
              .catch((error) => {
                setErrorMessage(parseApiError(error));
              });
          }}
        />
      ))}

      <Box mt="64px">
        <Button
          loading={saving}
          title="Save changes"
          width="152px"
          type={ButtonType.primary}
          onClick={() => {
            if (ABN_REQUIRED_COUNTRIES.includes(user!.country) && !abn) {
              setErrorMessage(`Please enter the ${ABN_VALIDATION_MESSAGE[user!.country]}`);
              return;
            }
            const insuranceExpiryDate = moment(insuranceDate).format("YYYY-MM-DD");
            if (insuranceExpiryDate === "Invalid date") {
              setErrorMessage("Please enter the valid expiry date for insurance");
              return;
            }

            const isValidBSB = containsSpecialCharacterOrSpace(bsb);
            if (!isValidBSB) {
              setErrorMessage("Please enter the valid BSB number");
              return;
            }

            const isValidAccountNumber = containsSpecialCharacterOrSpace(accountNumber);
            if (!isValidAccountNumber) {
              setErrorMessage("Please enter the valid account number");
              return;
            }

            setSaving(true);
            const data = {
              insuranceDate: insuranceExpiryDate,
              abn,
              bsb,
              businessName,
              businessAddress,
              accountNumber,
              licenseValidLocation: selectedState,
            };

            axios
              .put(`/therapists/me?accessToken=${accessToken}`, data)
              .then((response) => {
                setSaving(false);

                setSuccessMessage("Your business profile has been updated");
              })
              .catch((error) => {
                setSaving(false);

                setErrorMessage(parseApiError(error));
              });
          }}
        />
      </Box>

      <input
        type="file"
        id="insurance-upload-file"
        ref={insuranceFileUploadRef}
        style={{ display: "none" }}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();

          const file = event?.target?.files && event.target.files[0];

          console.debug("file: ", file);

          if (file) {
            const form = new FormData();
            form.append("image", file);

            user &&
              axios
                .post(
                  `/therapists/${user.id}/file?accessToken=${accessToken}&fileOwnership=insurance`,
                  form,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((response) => {
                  uploadedFilesMutate();

                  setSuccessMessage("This file has been uploaded");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }
        }}
      />
    </Box>
  );
}
