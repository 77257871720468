import { ButtonBase } from "@material-ui/core";
import React from "react";
import { Colors } from "../constants/colors";

interface Props {
  selected?: boolean;
  onSelected?: () => unknown;
  style?: React.CSSProperties;
  radioBaseStyle?: React.CSSProperties;
  disabled?: boolean;
}

export default function ({
  selected,
  onSelected,
  disabled = false,
  style = {},
  radioBaseStyle = {},
}: Props) {
  const radioStyle = selected
    ? {
      width: "16px",
      height: "16px",
      border: `solid 5px ${Colors.TurquoiseBlue}`,
      borderRadius: "9px",
      ...radioBaseStyle,
    }
    : {
      width: "16px",
      height: "16px",
      backgroundColor: "white",
      border: `solid 2px ${Colors.LightPeriwinkle}`,
      borderRadius: "9px",
      ...radioBaseStyle,
    };

  return (
    <ButtonBase
      style={{
        width: "35px",
        height: "35px",
        ...style,
      }}
      onClick={() => {
        onSelected && onSelected();
      }}
      disabled={disabled}
    >
      <ButtonBase style={radioStyle} />
    </ButtonBase>
  );
}
