import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Paths } from "../../constants/paths";
import { useUserStore } from "../../stores/user";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import { TherapistPublicProfile } from "../../models";
import ActionModal from "../../components/ActionModal";
import { STEP_PROGRESS } from "../../constants/booking";
import { getAccessToken } from "../../helpers/accessToken";
import Button, { ButtonType } from "../../components/Button";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import { generateHandleNext, updateBooking, useBookingStore } from "../../stores/booking";
import { checkIfEmpty, checkIfEqual, getValue } from "../../utils/object";
import { getPublicProfile } from "../../services/therapist/therapist.service";
import { publishBookingToAllPros } from "../../services/bookings/bookings.service";
import ProviderOptionItem from "../../components/Bookings/PreferredProviders/ProviderOptionItem";
import {
  DEFAULT_PROVIDER_OPTION,
  PROVIDER_OPTION_TYPE,
  PROVIDER_OPTIONS,
  PROVIDER_OPTIONS_VALUE,
} from "../../components/Bookings/PreferredProviders/enum";

const PreferredProviders = (): JSX.Element => {
  const history = useHistory();
  const { state } = useLocation();

  const { setErrorMessage } = useAlertStore();
  const [saving, setSaving] = useState(false);
  const [openProRemoveModal, setOpenProRemoveModal] = useState<boolean>(false);
  const [selectedPros, setSelectedPros] = useState<Array<number | string> | null>([]);

  const {
    address,
    serviceId,
    updatingForBooking,
    preferredTherapists,
    setPreferredTherapists,
    preferredProviderOption,
    setPreferredProviderOption,
  } = useBookingStore();

  const { user, fetchMe } = useUserStore();
  const isNewUser = !(
    getValue(user, "totalCompletedBooking") ?? getValue(user, "totalMassagesBooked", 0)
  );

  // --- Following will be populated if pending booking --- //
  const isRecentProvider = getValue(updatingForBooking, "isPreferredTherapistRecent", false);
  const prefTherapists = getValue(updatingForBooking, "preferredTherapists", null);
  const therapistData = getValue(updatingForBooking, "preferredTherapistProfile");
  // --- * --- //

  useEffect(() => {
    if (!prefTherapists) return;
    const option =
      PROVIDER_OPTIONS.find(({ value }) =>
        isRecentProvider
          ? value === PROVIDER_OPTIONS_VALUE.RECENT_PROVIDERS
          : value === PROVIDER_OPTIONS_VALUE.RECOMMENDED_PROVIDERS
      ) || DEFAULT_PROVIDER_OPTION;

    setSelectedPros(prefTherapists.split(",").map(Number));
    setPreferredProviderOption(option);
  }, [isRecentProvider, prefTherapists]);

  useEffect(() => {
    if (!user) {
      fetchMe();
    }

    if (!prefTherapists) {
      setSelectedPros((preferredTherapists || []).map(({ therapistData }) => therapistData.id));
      if (!preferredProviderOption) {
        setPreferredProviderOption(DEFAULT_PROVIDER_OPTION);
      }
    }
  }, []);

  const proSearchOptions = {
    longitude: getValue(address, "longitude"),
    latitude: getValue(address, "latitude"),
    serviceId,
  };

  const next = getValue(state, "next");

  const handleBack = () => {
    history.replace(Paths.BookingFrequency);
  };

  const verifyFields = () => {
    if (
      preferredProviderOption?.value !== PROVIDER_OPTIONS_VALUE.NO_PROVIDERS &&
      checkIfEmpty(selectedPros)
    ) {
      setErrorMessage("Please select a provider you prefer.");
      return false;
    }

    return true;
  };

  const publishBooking = () => {
    setOpenProRemoveModal(false);
    const bookingId = getValue(updatingForBooking, "id", 0);
    const accessToken = getAccessToken();
    setSaving(true);
    publishBookingToAllPros(bookingId, accessToken)
      .then(() => {
        history.push(`/bookings/${updatingForBooking?.id}`);
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleNext = () => {
    if (!verifyFields()) {
      return;
    }

    if (updatingForBooking) {
      if (
        (prefTherapists && checkIfEmpty(selectedPros)) ||
        (prefTherapists && preferredProviderOption?.value === PROVIDER_OPTIONS_VALUE.NO_PROVIDERS)
      ) {
        setOpenProRemoveModal(true);
        return;
      }

      setSaving(true);
      updateBooking()
        .then(() => {
          history.goBack();
          // history.push(`/bookings/${updatingForBooking?.id}`);
        })
        .catch((error) => {
          setErrorMessage(parseApiError(error));
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      const nextPath = next || Paths.ReviewAndBook;
      history.replace(nextPath, { preFetchPaymentMethod: false });
    }
  };

  const setTherapistAndHandleNext = async () => {
    try {
      // To minimize API calls, fetching pro public profiles upon continue/next rather than in the initial pro selection
      const accessToken = getAccessToken();
      let providers = [];
      setSaving(true);

      const preferredPros = (preferredTherapists || []).map(({ therapistData }) =>
        getValue(therapistData, "id")
      );

      // only fetch public profile if selected pros does not already exist in state.
      if (!checkIfEqual(preferredPros, selectedPros)) {
        providers = await Promise.all(
          (selectedPros || []).map(async (id) => {
            const proId = Number(id);
            return await getPublicProfile(proId, accessToken).then(({ data }) => data);
          })
        );
        setPreferredTherapists(providers as Array<TherapistPublicProfile>);
      }

      handleNext();
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  const handleProSelect = (proId: number | string | null) => {
    setSelectedPros(proId ? [proId] : null);
  };

  const handleOptionSelect = (option: PROVIDER_OPTION_TYPE) => {
    setSelectedPros(null);
    setPreferredProviderOption(option);
  };

  const handleNextObject = generateHandleNext(setTherapistAndHandleNext);

  return (
    <NewBookingWrapper
      overline={"Step 6 of 7"}
      title={"Preferred provider"}
      subtitle={"Select a specific provider from whom you would like to receive the service from."}
      progress={STEP_PROGRESS[6]}
      footerLeftButton={
        <Button
          type={ButtonType.outlined}
          title={updatingForBooking ? "Back" : "Previous"}
          onClick={() => handleBack()}
          style={{ padding: "12px 24px" }}
        />
      }
      nextGeneratorObject={handleNextObject}
      footerRightButton={
        <Button
          loading={saving}
          type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
          title={updatingForBooking ? "Save & apply" : "Continue"}
          onClick={() => handleNextObject.next()}
          style={{ padding: "12px 24px" }}
        />
      }
    >
      <Box mt={"40px"} display={"flex"} flexDirection={"column"} gridGap={"40px"}>
        {PROVIDER_OPTIONS.map((option: PROVIDER_OPTION_TYPE) => (
          <ProviderOptionItem
            key={option.value}
            option={option}
            isNewUser={isNewUser}
            defaultSelect={
              !preferredProviderOption ? DEFAULT_PROVIDER_OPTION.value === option.value : false
            }
            onSelect={handleOptionSelect}
            selected={preferredProviderOption?.value === option.value}
            selectedProIds={selectedPros}
            onSelectPreferredPro={handleProSelect}
            proSearchOptions={proSearchOptions}
          />
        ))}
      </Box>
      <ActionModal
        open={openProRemoveModal}
        title="Confirm provider removal"
        description="Your booking request will be open to all available providers in your area - and will be more likely to get confirmed."
        onCancel={() => setOpenProRemoveModal(false)}
        onConfirm={publishBooking}
      />
    </NewBookingWrapper>
  );
};

export default PreferredProviders;
