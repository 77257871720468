import { Box, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import { useAccessToken } from "../../hooks/common";
import { getBalance } from "../../services/therapist/account.service";

type BalanceProps = {
  loading: boolean,
  balance: string,
  fontColor: string
}

type AccountBalanceProps = {
  currency: string
}

const BalanceComponent = ({loading, fontColor, balance}: BalanceProps) => {
  if (loading) {
    return <CircularProgress color="inherit" size={25} />;
  }
  return (
    <Box
      fontWeight={600}
      marginTop="8px"
      color={fontColor}
      fontSize="24px"
    >
      {balance}
    </Box>
  );
};

const AccountBalance = ({ currency}: AccountBalanceProps) => {
  const accessToken = useAccessToken();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(`${currency}0.00`);
  const [balanceFontColor, setBalanceFontColor] = useState(Colors.Dusk);

  useEffect(() => {
    setLoading(true);
    getBalance(accessToken || "").then(({ data, error }) => {
      setLoading(false);
      if (!error) {
        let balancePrefix;
        if (data.balance < 0) {
          balancePrefix = "-";
          setBalanceFontColor(Colors.Crimson);
        } else {
          setBalanceFontColor(Colors.Dusk);
          balancePrefix = "";
        }
        setBalance(`${balancePrefix}${Math.abs(data.balance).toPriceString(currency)}`);
      }
    });
  }, [accessToken]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      padding="24px"
      bgcolor={Colors.White}
      border={`1px solid ${Colors.LightPeriwinkle}`}
      boxSizing="border-box"
      borderRadius="8px"
      mt="24px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        fontFamily="Museo"
      >
        <Box color={Colors.Dusk}>
          Account Balance
        </Box>
        <BalanceComponent
          loading={loading}
          balance={balance}
          fontColor={balanceFontColor}
        />
      </Box>
    </Box>
  );
};

export default AccountBalance;