import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Button from "../../Button";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import { StripeConnectAccountStatus } from "../../../constants/stripe";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import {
  createExpressAccountLink,
  getExpressAccountStatus,
} from "../../../services/proDashboard/stripe.service";

const StripeConnectExpress = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(StripeConnectAccountStatus.NOT_CONNECTED);
  const [expressDashboard, setExpressDashboard] = useState({ url: "" });
  const { setErrorMessage } = useAlertStore();
  const isMobile = useMobile();

  useEffect(() => {
    getExpressAccountStatus()
      .then(({ status, expressDashboard = { url: "" } }) => {
        setStatus(status);
        setExpressDashboard(expressDashboard);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSetupPayouts = async () => {
    try {
      setLoading(true);
      const { data } = await createExpressAccountLink();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(parseApiError(error));
      sendErrorLog({
        domain: `WEB ${isMobile ? "MOBILE" : ""}`,
        channel: LOG_CHANNEL.SLACK,
        log: {
          title: "WEB: Stripe connect issue",
          data: JSON.stringify(error),
        },
      });
    }
  };

  let buttonText;
  switch (status) {
  case StripeConnectAccountStatus.CONNECTED:
    buttonText = "Banking details";
    break;
  case StripeConnectAccountStatus.INCOMPLETE:
    buttonText = "Complete setup";
    break;
  default:
    buttonText = "Setup payouts";
    break;
  }
  return (
    <Box width="200px">
      {status === StripeConnectAccountStatus.CONNECTED && expressDashboard.url && (
        <Box marginBottom={"24px"}>
          <a
            style={{
              fontFamily: "Museo",
              color: Colors.Dusk,
            }}
            href={expressDashboard.url}
          >
            See payouts
          </a>
        </Box>
      )}
      <Button loading={loading} title={buttonText} onClick={handleSetupPayouts} />
    </Box>
  );
};

export default StripeConnectExpress;
