import { Box, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import {
  INVOICE_DELIVERY_FORMAT,
  INVOICE_FORMAT,
  INVOICE_FORMAT_ENUM,
  INVOICE_ISSUE,
  PAYMENT_RECEIPT_PREFERENCE_OPTIONS,
  SHOULD_ITEMIZE_SURCHARGES,
} from "../../constants/payment";
import { INPUT_TYPE } from "../../data/enums";
import useAuth from "../../hooks/auth.hooks";
import { useInvoiceLinks } from "../../hooks/booking/invoice.hooks";
import {
  useCreateOrUpdateUserConfig,
  useUserConfig,
} from "../../hooks/userConfig/billingDetails.hooks";
import { useAlertStore } from "../../stores/alert";
import { useUserStore } from "../../stores/user";
import { getValue } from "../../utils/object";
import IndividualDetail from "./IndividualDetail";
import { isBulkInvoiceFormat } from "../../services/additionalBillingIdentifier/additionalBillingIdentifier.service";
import { isEmpty } from "lodash";

const BillingDetails = () => {
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [invoiceIssue, setInvoiceIssue] = useState("");
  const [invoiceFormat, setInvoiceFormat] = useState("");
  const [invoiceDeliveryFrequency, setInvoiceDeliveryFrequency] = useState([]);
  const [shouldSendReceipts, setShouldSendReceipts] = useState<string>("");
  const [shouldItemizeSurcharge, setShouldItemizeSurcharge] = useState<string>("");
  const [showInvoiceDeliveryFreq, setShowInvoiceDeliveryFreq] = useState<boolean>(false);
  const [showInvoiceIssueOn, setShowInvoiceIssueOn] = useState<boolean>(false);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { user } = useUserStore();
  const { isAdminLoggedInAsClient } = useAuth();

  const isB2BUser = getValue(user, "role.isB2BUser", false);

  const { isLoading: isUserConfigLoading, data: userConfigData } = useUserConfig({
    userId: user?.id,
  });
  const { clearInvoiceLinks } = useInvoiceLinks();

  const { mutate: createUserConfig } = useCreateOrUpdateUserConfig({
    userId: user?.id,
    onSuccess: (response: any) => {
      setSuccessMessage(response.msg);
      clearInvoiceLinks();
    },
    onError: () => setErrorMessage("Something went wrong"),
  });

  const initBillingDetails = (billingDetails: any) => {
    setCompanyName(billingDetails?.companyName || "");
    setBusinessRegistrationNumber(billingDetails?.businessRegistrationNumber || "");
    setContactName(billingDetails?.contactName || "");
    setBillingAddress(billingDetails?.billingAddress || "");
    setPaymentTerm(billingDetails?.paymentTerm || "");
    setInvoiceIssue(billingDetails?.invoiceIssue || INVOICE_ISSUE[0].value);
    setInvoiceFormat(billingDetails?.invoiceFormat || INVOICE_FORMAT[0].value);
    setInvoiceDeliveryFrequency(
      billingDetails?.bulkInvoiceDeliveryFreq || [INVOICE_DELIVERY_FORMAT[0].value]
    );
    setShouldSendReceipts(
      billingDetails?.shouldSendReceipts || PAYMENT_RECEIPT_PREFERENCE_OPTIONS[1].value
    );
    setShouldItemizeSurcharge(
      billingDetails?.shouldItemizeSurcharge || SHOULD_ITEMIZE_SURCHARGES[0].value
    );
    if (isBulkInvoiceFormat(billingDetails?.invoiceFormat)) {
      setShowInvoiceDeliveryFreq(true);
    } else {
      setShowInvoiceIssueOn(true);
    }
  };

  useEffect(() => {
    if (userConfigData) {
      const { receiptTo, receiptToCC, billingDetails } = userConfigData;
      setPrimaryEmail(receiptTo || user?.email);
      setSecondaryEmail(receiptToCC);
      initBillingDetails(billingDetails);
    }
  }, [userConfigData]);

  const handleSaveDetails = (slug: string, value: string) => {
    let data: any = {};
    if (slug === "primaryBillingEmail") {
      data.receiptTo = value;
    } else if (slug === "secondaryBillingEmail") {
      data.receiptToCC = value;
    } else {
      data.billingDetails = {
        [slug]: value,
      };
    }
    createUserConfig(data);
  };

  return (
    <Box mt={"36px"}>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        lineHeight="24px"
        fontWeight={600}
        color={Colors.Dusk}
        mb={"16px"}
      >
        Billing details
      </Box>
      {isUserConfigLoading ? (
        <Box>
          <CircularProgress
            variant="indeterminate"
            style={{ color: Colors.Dusk }}
            size="25px"
            thickness={4}
          />
        </Box>
      ) : (
        <Box>
          <IndividualDetail
            title={"Primary billing email"}
            slug={"primaryBillingEmail"}
            value={primaryEmail}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
          />
          <IndividualDetail
            title={"Secondary billing email"}
            slug={"secondaryBillingEmail"}
            value={secondaryEmail}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
          />
          <IndividualDetail
            title={"Company Name"}
            slug={"companyName"}
            value={companyName}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
          />
          <IndividualDetail
            title={"Business registration number"}
            slug={"businessRegistrationNumber"}
            value={businessRegistrationNumber}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
          />
          <IndividualDetail
            title={"Contact name"}
            slug={"contactName"}
            value={contactName}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
          />
          <IndividualDetail
            title={"Billing address"}
            slug={"billingAddress"}
            value={billingAddress}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
          />
          <IndividualDetail
            title={"Payment term"}
            slug={"paymentTerm"}
            value={paymentTerm}
            allowEdit={isAdminLoggedInAsClient}
            onSave={(slug, value) => handleSaveDetails(slug, value)}
            inputType={INPUT_TYPE.SELECT}
          />
          {(isB2BUser || isAdminLoggedInAsClient) && (
            <IndividualDetail
              title={"Invoice format"}
              slug={"invoiceFormat"}
              value={invoiceFormat}
              onSave={(slug, value) => handleSaveDetails(slug, value)}
              inputType={INPUT_TYPE.SELECT}
              additionalAction={(value: any) => {
                setShowInvoiceDeliveryFreq(value as boolean);
                setShowInvoiceIssueOn(!value as boolean);
              }}
              isB2BUser={isB2BUser}
            />
          )}

          {isAdminLoggedInAsClient && showInvoiceDeliveryFreq && isB2BUser && (
            <IndividualDetail
              title={"Invoice delivery frequency"}
              slug={"bulkInvoiceDeliveryFreq"}
              value={invoiceDeliveryFrequency}
              allowEdit={isAdminLoggedInAsClient}
              onSave={(slug, value) => handleSaveDetails(slug, value)}
              inputType={INPUT_TYPE.SELECT}
            />
          )}
          {isAdminLoggedInAsClient && showInvoiceIssueOn && (
            <IndividualDetail
              title={"Invoice issue on"}
              slug={"invoiceIssue"}
              value={invoiceIssue}
              allowEdit={isAdminLoggedInAsClient}
              onSave={(slug, value) => handleSaveDetails(slug, value)}
              inputType={INPUT_TYPE.SELECT}
            />
          )}
          {(isB2BUser || isAdminLoggedInAsClient) && (
            <IndividualDetail
              title={"Send payment receipts / remittance advice"}
              slug={"shouldSendReceipts"}
              value={shouldSendReceipts}
              onSave={(slug, value) => handleSaveDetails(slug, value)}
              inputType={INPUT_TYPE.SELECT}
            />
          )}
          {isAdminLoggedInAsClient && (
            <IndividualDetail
              title={"Itemize all surcharges in invoice (public holiday surcharge, late night surcharge, etc.)"}
              slug={"shouldItemizeSurcharge"}
              value={shouldItemizeSurcharge}
              onSave={(slug, value) => handleSaveDetails(slug, value)}
              inputType={INPUT_TYPE.SELECT}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default BillingDetails;
