import queryClient from '../../config/queryClient';
import { removeAdminAccessToken } from '../../helpers/accessToken';
import { reset as resetAddressStore } from '../../stores/address';
import { reset as resetBookingStore } from '../../stores/booking';
import { resetUserStore } from '../../stores/user';

const invalidateUserCache = () => {
  queryClient.clear();
};

export const resetUserData = () => {
  resetBookingStore();
  resetAddressStore();
  resetUserStore();

  invalidateUserCache();

  localStorage.removeItem("token");
  removeAdminAccessToken();
};
