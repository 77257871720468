import React, { useState } from "react";
import { getValue } from "../../utils/object";
import { Box, Link, Text } from "../v2/Styled";
import { FontFamily } from "../v2/Styled/enum";
import InfoModal from "../Modals/InfoModal/InfoModal";

interface Props {
  title: string;
  value: string;
  modalInfo?: {
    title: string;
    description: string;
    data?: [];
  };
  link?: string;
}
const ProScoreRow = ({ title, value, modalInfo, link }: Props) => {
  const [modalContent, setModalContent] = useState({
    visible: false,
    title: "",
    description: "",
    data: [],
  });

  const handleTitleClick = () => {
    if (link) {
      window.open(link, "_blank");
      return;
    }
    const title = getValue(modalInfo, "title", "");
    const description = getValue(modalInfo, "description", "");
    const data = getValue(modalInfo, "data", []);

    setModalContent({
      visible: true,
      title,
      description,
      data,
    });
  };

  const buildTitle = () =>
    link || modalInfo ? (
      <Link onClick={handleTitleClick} font={FontFamily.Museo} underlined>
        {title}
      </Link>
    ) : (
      <Text font={FontFamily.Museo}>{title}</Text>
    );

  const transformedTitle = buildTitle();
  return (
    <Box>
      <Box flex={1}>{transformedTitle}</Box>
      <Text font={FontFamily.Museo}>{value}</Text>
      <InfoModal
        title={modalContent.title}
        visible={modalContent.visible}
        description={modalContent.description}
        data={modalContent.data}
        handleClose={() => setModalContent({ ...modalContent, visible: false })}
        divider={false}
        formatHtml
      />
    </Box>
  );
};

export default ProScoreRow;
