import copy from "copy-to-clipboard";
import { isString } from "lodash";

const checkIsString = (data: any) => isString(data);

const copyStringToClipboard = (value: string) => copy(value);

const toJSON = (string: any, defaultValue = null) => {
  if (!string) return defaultValue;
  try {
    return isString(string) ? JSON.parse(string) : string;
  } catch (e) {
    return defaultValue;
  }
};

const checkStringEndsWith = (str: string, check: string) => str.endsWith(check);

export { checkIsString, copyStringToClipboard, toJSON, checkStringEndsWith };
