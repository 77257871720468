import { useMutation } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import { addReview, updateReview } from "../../services/review/review.service";

export const useTherapistReview = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => unknown;
  onError: (error: any) => unknown;
}) => {
  return useMutation((data: any) => addReview(data), {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useUpdateTherapistReview = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => unknown;
  onError: (error: any) => unknown;
}) => {
  return useMutation((data: any) => updateReview(data), {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};
