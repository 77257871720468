import React from "react";
import { isEmpty } from "lodash";
import { Box, ButtonBase } from "@material-ui/core";

import { Colors } from "../../constants/colors";
import { RecentlyBookedPro } from "../../models";
import { BASE_UPLOADS_URL } from "../../constants/common";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";
import FavTherapistStar from "../../images/fav-therapist-star.svg";
import { ButtonSize } from "../Button";
import MoreIcon from "../../images/therapists-more.svg";
import BookMassageButton, { BookMassageButtonSource } from "../BookMassageButton";
import VaccinatedIcon from "../../images/vaccinated_logo.png";
import { getValue } from "../../utils/object";
import { useUserRole } from "../../hooks/user.hooks";
import TermsAndPoliciesDocuments from "../TermsAndPoliciesDocuments/TermsAndPoliciesDocuments";
import Divider from "../Divider";

interface Props {
  therapist: RecentlyBookedPro;
  isFavourite: Boolean;
  onMoreClicked: (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => unknown;
  onClick: () => unknown;
}

export default function RecentTherapistsItem({
  therapist,
  onMoreClicked,
  onClick,
  isFavourite,
}: Props): JSX.Element {
  const therapistProfileImage = getValue(therapist, "therapistprofile.profileImage", "");
  const therapistFirstName = getValue(therapist, "firstName", "");

  const isCovidVaccinated = getValue(therapist, "isCovidVaccinated");
  const { isHomeCareClient, isSupportRecipient } = useUserRole();
  const showTermsAndPoliciesDocuments = (isHomeCareClient || isSupportRecipient);
  const getProDocuments = (therapist: RecentlyBookedPro) => {
    const documents = getValue(therapist, "useruploadedfiles") || [];
    return documents;
  };
  
  return (
    <Box
      width="275px"
      minHeight="300px"
      borderRadius="6px"
      border={`solid 1px ${Colors.PaleLilac}`}
      bgcolor={Colors.White}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box top={8} right={8} position="absolute" zIndex={999}>
        <ButtonBase
          onClick={(event) => {
            onMoreClicked(event);
          }}
          disableTouchRipple={true}
        >
          <Box
            minWidth="50px"
            minHeight="50px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={MoreIcon} />
          </Box>
        </ButtonBase>
      </Box>

      <ButtonBase onClick={onClick}>
        <Box display="flex" flexDirection="column" alignItems="center" marginTop={"40px"}>
          <Box width="80px" height="80px" position="relative">
            {therapistProfileImage && (
              <img
                src={`${BASE_UPLOADS_URL}/${therapistProfileImage}`}
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "40px",
                  objectFit: "cover",
                }}
              />
            )}
            {!therapistProfileImage && (
              <img src={ProfilePicturePlaceholder} style={{ width: "80px", height: "80px" }} />
            )}
            {isFavourite && (
              <img
                src={FavTherapistStar}
                style={{ position: "absolute", zIndex: 999, top: -3, right: -3 }}
              />
            )}
            {!!isCovidVaccinated && (
              <img
                src={VaccinatedIcon}
                style={{
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                  zIndex: 999,
                  bottom: -3,
                  right: -3,
                  border: "3px solid white",
                  borderRadius: "50%",
                }}
              />
            )}
          </Box>
          <Box fontFamily="Museo" fontWeight={700} color={Colors.NightBlue} mt="13px" mb="24px">
            {!isEmpty(therapistFirstName.trim()) ? therapistFirstName : "Favourite Therapist"}
          </Box>
        </Box>
      </ButtonBase>

      <BookMassageButton
        width="235px"
        title={`Rebook ${therapistFirstName}`}
        size={ButtonSize.small}
        // preferredTherapist={therapistPublicProfile}
        preferredTherapistId={therapist.id}
        source={BookMassageButtonSource.FavouriteTherapistProfile}
      />
      {showTermsAndPoliciesDocuments ? (
        <Box>
          <Divider mt="24px" width="235px" />
          <Box marginTop={"20px"} marginBottom={"20px"}>
            <TermsAndPoliciesDocuments
              showAddAdditionalDocuments={true}
              showAgreements={false}
              hasProsDocuments={true}
              agreements={[]}
              proDocuments={getProDocuments(therapist)}
              therapistId={therapist.id}
            />
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
}
