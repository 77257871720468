import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import {
  checkServiceIsHairAndMakeup,
  getCorporateDetails,
  getFormattedQuestionAnswer,
  getTreatmentDetailsIcon,
  isCorporateTreatmentType,
  isMassageType,
  useBookingStore,
  useServicesStore,
} from "../../../stores/booking";
import TextLink from "../../TextLink";
import CorporateTreatmentDetailItem from "./CorporateTreatmentDetailItem";
import TreatmentDetailItems from "./TreatmentDetailItems";

interface TreatmentDetailItemProps {
  isServiceSelected: boolean;
  onChangeClicked: () => unknown;
  onTreatmentClicked?: (td: any) => unknown;
  onAddOnClicked?: (td: any) => unknown;
}

const ReviewAndBookTreatmentDetail = ({
  isServiceSelected = true,
  onChangeClicked,
  onTreatmentClicked = () => {},
  onAddOnClicked = () => {},
}: TreatmentDetailItemProps) => {
  const { rates } = useServicesStore();

  const {
    serviceId,
    sessionType,
    massageLength,
    massageLength2,
    treatmentDetails,
    massageTreatmentDetails,
    genderPreference1,
    genderPreference2,
    genderPreference, // for HNM bookings
    treatmentId,
    numberOfPros,
    addOn,
    updatingForBooking,
  } = useBookingStore();

  const { answers, questions } = useServicesStore();

  const isCorporateType = isCorporateTreatmentType();
  const isMassage = isMassageType();
  const isHairAndMakeup = checkServiceIsHairAndMakeup(serviceId);
  const details = isMassage ? massageTreatmentDetails : treatmentDetails;

  const selectedGenderPref1 =
    isHairAndMakeup && !updatingForBooking ? genderPreference : genderPreference1;

  const formattedAnswers = details.map(
    (detail, index) => getFormattedQuestionAnswer(answers[index], questions, serviceId) || []
  );

  const getRowDetailIcon = () => {
    let therapistCount = 1;
    if (isCorporateType) {
      therapistCount = numberOfPros || 1;
    } else if (isMassage) {
      const selectedService = rates.find(({ id }) => id === serviceId);
      const treatment = (selectedService?.sessionTypes || []).find(
        ({ value }) => value === sessionType
      );
      therapistCount = treatment?.isCouples ? 2 : 1;
    }
    return getTreatmentDetailsIcon(therapistCount);
  };

  return (
    <Box display="flex" flexDirection="row" alignItems={"flex-start"}>
      <Box
        width="24px"
        height="24px"
        justifyContent="center"
        alignItems="center"
        display="flex"
        style={{ transform: "translateY(-20%)" }}
      >
        <img src={getRowDetailIcon()} alt="icon" />
      </Box>
      <Box display="flex" flex={1} ml={2} mr={2}>
        {isServiceSelected ? (
          <Box>
            {isCorporateType ? (
              <CorporateTreatmentDetailItem
                serviceId={serviceId}
                treatmentId={treatmentId}
                corporateDetails={getCorporateDetails()}
              />
            ) : (
              <Box display="flex" flexDirection="column">
                <TreatmentDetailItems
                  serviceId={serviceId}
                  sessionType={sessionType}
                  treatmentDetails={details}
                  massageLength={massageLength}
                  massageLength2={massageLength2}
                  genderPreference1={selectedGenderPref1}
                  genderPreference2={genderPreference2}
                  onTreatmentClicked={onTreatmentClicked}
                  onAddOnClicked={onAddOnClicked}
                  answers={formattedAnswers}
                  addOn={addOn}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box
            fontFamily="Museo"
            fontSize="16px"
            color={Colors.Dusk}
            style={{ overflowWrap: "break-word" }}
          >
            Select service
          </Box>
        )}
      </Box>

      <TextLink
        title={isServiceSelected ? "Change" : "Add"}
        onClick={onChangeClicked}
        style={{ marginTop: "2px", cursor: "pointer" }}
      />
    </Box>
  );
};

export default ReviewAndBookTreatmentDetail;
