import { Box } from "@material-ui/core";
import { getValue } from "../../../utils/object";
import UnpaidBookingInfo from "./UnpaidBookingInfo";
import AddressSection from "../Address/AddressSection";
import DateTimeIcon from "../../../images/review-calendar-time.svg";
import { BOOKING_STATUS, DELIVERY_METHOD } from "../../../constants/booking";
import { cardWrapperStyles } from "../../../components/BookingDetails/BookingDetailCard";
import DayDateTimeSection from "../../../components/DayDateTime";
import { getRangedBookingFormattedDateTime } from "../../../services/bookings/bookingTime.service";
import { Colors } from "../../../constants/colors";

const BookingDetails = ({ job, booking, address, hideSensativeData, openMapModal, isPaidBooking }: any) => {
  const bookingStatus = getValue(booking, "status");
  const isHairAndMakeup = getValue(booking, "isHairAndMakeup");

  const showLocationDetails =
    (getValue(job, "bookingdetail.deliveryMethod") || DELIVERY_METHOD.inperson) ===
      DELIVERY_METHOD.inperson || hideSensativeData;

  const pendingBookingDeadline = getValue(
    job,
    "bookingdetail.booking.bookingPayment.declinedPaymentExpiryTime",
  );
  const hasBackupDate = !!booking?.backup;

  const getFormattedDateTime = ({ booking }: any) => {
    if (!booking) return;

    const { earliestTime, latestTime, timezone } = booking;
    const { datetime } = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });
    return datetime;
  };

  const getFormattedBackupDate = ({ booking }: any) => {
    if (!booking || !booking?.backup) return "";
    const { timezone } = booking;
    const { earliestTime, latestTime } = booking?.backup;

    const { datetime } = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });
    return datetime;
  };


  return (
    <Box style={{ ...cardWrapperStyles.card, padding: "24px", flexDirection: "column" }}>
      {
        [BOOKING_STATUS.ARRANGED, BOOKING_STATUS.COMPLETED].includes(bookingStatus) &&
         <DayDateTimeSection
           time={getValue(booking,"timeOfArrival")}
           timezone={getValue(booking,"timezone")}
           variant={bookingStatus === BOOKING_STATUS.COMPLETED ? "offline": "active" }
         />
      }
   
      {
        bookingStatus===BOOKING_STATUS.NEW &&
        <Box style={{ ...styles.flex, flexDirection: "row" }}>
          <Box width={"24px"} height={"24px"} alignItems={"center"} justifyContent={"center"} display={"flex"}>
            <img src={DateTimeIcon} alt="Treatment icon" />
          </Box>
          <Box style={{ display: "flex", flexDirection: "column", gap: "8px", justifyContent: "center"}}>
            <Box>
              <Box style={styles.label}>Preferred date and time</Box>
              <Box style={styles.treatmentLabel}>{getFormattedDateTime({ booking })}</Box>
            </Box>
            {hasBackupDate ? (
              <Box>
                <Box style={styles.label}>Backup date and time</Box>
                <Box style={styles.treatmentLabel}>{getFormattedBackupDate({ booking })}</Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
       
        </Box>
      }

      {
        (pendingBookingDeadline && !isPaidBooking) &&
        <UnpaidBookingInfo deadline={pendingBookingDeadline} />
      }
      <AddressSection
        address={address}
        status={bookingStatus}
        hideSensativeData={hideSensativeData}
        showLocationDetails={showLocationDetails}
        openMapModal={openMapModal}
        isHairAndMakeup={isHairAndMakeup}
      />
    </Box>
  );
};

const styles = {
  flex: {
    display: "flex",
    gap: "16px",
  },
  person: {
    lineHeight: "24px",
    color: Colors.Indigo,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 600,
  },
  treatmentLabel: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
    marginTop:8,
  },
  label: {
    fontFamily: "Museo",
    fontWeight: 400,
    fontSize: "16px",
    color: Colors.Grey,
  },
};

export default BookingDetails;
