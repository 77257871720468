import React from "react";
import { StripeConnectInstance } from "@stripe/connect-js";
import { ConnectComponentsProvider, ConnectNotificationBanner } from "@stripe/react-connect-js";

interface Props {
  stripeConnectInstance: StripeConnectInstance | undefined;
  isLoading: boolean;
}

const StripeNotificationBanner = ({ stripeConnectInstance, isLoading }: Props) => {
  if (isLoading || !stripeConnectInstance) {
    return <React.Fragment />;
  }

  const handleNotificationsChange = (response: any) => {
    // reserved for future use
  };

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectNotificationBanner onNotificationsChange={handleNotificationsChange} />
    </ConnectComponentsProvider>
  );
};

export default StripeNotificationBanner;
