import { Box, BoxProps, ButtonBase, SwipeableDrawer } from "@material-ui/core";
import { get, isEmpty, isNil } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import NewBookingProgressBar from "../components/NewBookingProgressBar";
import SideMenu from "../components/SideMenu";
import Snackbar, { SnackbarType } from "../components/Snackbar";
import UserAvatarButton from "../components/UserAvatarButton";
import { BOOKING_STATUS, MassageType, STEP_PROGRESS, TOTAL_DUE_INFO } from "../constants/booking";
import { Colors } from "../constants/colors";
import { MARKETING_SITE_URL } from "../constants/common";
import { COUNTRIES_CURRENCY, COUNTRIES_CURRENCY_SYMBOL } from "../constants/countries";
import { Paths } from "../constants/paths";
import { formatToPrice } from "../helpers/number";
import { getTimeOptions } from "../helpers/time";
import { usePeakHourFixed } from "../hooks/booking/peakHour.hooks";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import BlysLogoImage from "../images/blys-logo.svg";
import CloseIcon from "../images/close.svg";
import FooterDecoImage from "../images/footer-deco.svg";
import HeaderDecoImage from "../images/header-deco.svg";
import ConfirmBookingUpdateModal from "../pages/Bookings/Components/ConfirmBookingUpdateModal";
import { getLastBookings } from "../services/bookings/bookings.service";
import { getUpdatedDateTimePayload } from "../services/bookings/prefillDateTime.service";
import {
  getPublicProfile,
  getPublicProfileForGuest,
} from "../services/therapist/therapist.service";
import { useAlertStore } from "../stores/alert";
import {
  getBookingPrice,
  getBookingQuestions,
  getDurationForMassageService,
  getDurationForNonMasasgeService,
  getHairAndBeautyTreatmentLabel,
  getPriceByQuestion,
  getTimeRanges,
  getTreatmentName,
  getTreatmentPrice,
  isBackToBackMassageType,
  isCorporateTreatmentType,
  isCoupleMassageType,
  isHairAndMakeupBooking,
  isMassageType,
  loadTreatmentDetails,
  useBookingStore,
  useDateTimeStore,
  useLocationDetailsStore,
  usePaymentStore,
  useServicesStore,
} from "../stores/booking";
import { getCountryCode } from "../utils/country";
import { getCurrentDayInTimezone } from "../utils/date.util";
import { checkIfEmpty, convertParamsToObject, getValue } from "../utils/object";
import { checkIsString } from "../utils/string";
import { DropdownOption } from "./Dropdown";
import ErrorBoundary from "./ErrorBoundary";
import InfoModal from "./Modals/InfoModal/InfoModal";
import NewBookingFooter from "./NewBookingFooter";
import PriceBreakDownModal from "./PriceBreakDownModal";

interface Props extends BoxProps {
  overline?: string;
  title?: string;
  subtitle?: string;
  footerLeftButton?: React.ReactElement | null;
  footerRightButton?: React.ReactElement | null;
  progress: number;
  fullWidth?: boolean;
  showFooterDescription?: boolean;
  nextGeneratorObject?: any;
  onClosed?: () => unknown;
}

interface CloseButtonProps {
  onClick?: () => unknown;
}

function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <ButtonBase
      style={{
        width: "40px",
        height: "40px",
        marginBottom: "20px",
      }}
      onClick={onClick}
    >
      <img src={CloseIcon} alt="close" />
    </ButtonBase>
  );
}

export default function NewBookingWrapper({
  children,
  overline,
  title,
  subtitle,
  footerLeftButton,
  footerRightButton,
  progress,
  fullWidth,
  showFooterDescription = false,
  nextGeneratorObject,
  onClosed,
}: Props): JSX.Element {
  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } = useAlertStore();
  const [showPriceBreakdownModal, setShowPriceBreakdownModal] = React.useState<boolean>(false);
  const [showTotalDueUpToModal, setShowTotalDueUpToModal] = React.useState<boolean>(false);

  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const footerHeight = isMobile ? 140.0 : 78.0;
  const footerMaxHeight = isMobile ? 190 : 102;

  const editing = get(location.state, "editing");

  const {
    massageLength,
    massageLength2,
    massageType1: massageType,
    massageType2,
    sessionType,
    preferredTherapists,
    serviceId,
    exitBookingFlowPath,
    setPreferredTherapists,
    address,
    lastBooking,
    setLastBooking,
    treatmentDetails,
    setCurrency,
    currencySymbol: currency,
    totalEventDuration,
    setUpdateFutureRecurrings,
    showUpdateRecurringModal,
    setShowUpdateRecurringModal,
    massageTreatmentDetails,
    updatingForBooking,
  } = useBookingStore();
  const addressTimezone = address?.timezone;

  const { type: typeOfLocation, countryCode } = useLocationDetailsStore();

  const { answers, timeRange } = useServicesStore();

  const timeOptions = getTimeOptions(timeRange) as DropdownOption[];

  const isMassage = isMassageType();
  const isCorporate = isCorporateTreatmentType();

  const { bookingPrice } = usePaymentStore();
  const {
    selectedDay,
    latestStartInMinutes,
    earliestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    timeRangeFilterFromInMinutes,
    updateDateTime,
    setSelectedDay,
    setBackupSelectedDay,
    selectedDayRange,
    backupSelectedDayRange,
  } = useDateTimeStore();
  const accessToken = useAccessToken();

  const { upToText } = usePeakHourFixed({
    lateNightSurcharge: bookingPrice?.lateNightSurcharge,
    lateNightSurchargeApplied: bookingPrice?.lateNightSurchargeApplied,
  });

  const preferredTherapist = !isEmpty(preferredTherapists) ? preferredTherapists[0] : null;

  const queryParams = convertParamsToObject(new URLSearchParams(location?.search || ""));
  // get preferredtherapist id from url params
  const preferredProsFromURL = queryParams?.preferredTherapist;

  const fetchTherapistProfiles = async (ids = []) => {
    const promises = ids.map((proId) => {
      return accessToken
        ? getPublicProfile(parseInt(proId), accessToken)
        : getPublicProfileForGuest(proId);
    });
    const profileResponses = await Promise.all(promises);
    if (!profileResponses || !profileResponses.length) return [];

    return profileResponses.map(({ data }: any) => data);
  };

  const handlePreferredTherapistFromURL = async () => {
    if (preferredProsFromURL) {
      const preferredTherapistId = (preferredTherapist?.therapistData?.id || "").toString();

      // handled cases for rebook all pros
      const firstTherapist = queryParams.preferredTherapist.split(",")[0];
      const therapistId = firstTherapist.split("-").pop();
      if (!therapistId) return;

      // get therapist public profile
      if (!preferredTherapist || !preferredTherapistId || preferredTherapistId !== therapistId) {
        const selectedTherapists = queryParams.preferredTherapist.split(",");
        if (!selectedTherapists || !selectedTherapists.length) return;

        const preferredIds = selectedTherapists
          .map((therapistWithPrefix: string) => {
            const proId = therapistWithPrefix.split("-").pop();
            return proId;
          })
          .filter((promise: any) => !!promise);

        const profiles = await fetchTherapistProfiles(preferredIds);
        if (!profiles || !profiles.length) return;
        setPreferredTherapists(profiles);
      }
    }
  };

  // get public profile
  React.useEffect(() => {
    handlePreferredTherapistFromURL();
  }, [preferredProsFromURL]);

  // set last booking if not already available && params exists
  React.useEffect(() => {
    if (!lastBooking && accessToken && queryParams?.preferredTherapist) {
      getLastBookings(accessToken).then(({ data }) => {
        const resetTherapists = checkIfEmpty(preferredProsFromURL);
        setLastBooking(data || {}, resetTherapists);
      });
    }
  }, [lastBooking]);

  const answersString = JSON.stringify(answers);
  React.useEffect(() => {
    getBookingPrice();
  }, [
    answersString,
    massageLength,
    massageLength2,
    massageType,
    massageType2,
    sessionType,
    typeOfLocation,
    selectedDay,
    earliestStartInMinutes,
    latestStartInMinutes,
    serviceId,
    treatmentDetails,
    massageTreatmentDetails,
  ]);

  // set treatmentId
  React.useEffect(() => {
    // returning if serviceId is null
    if (!serviceId) return;
    getBookingQuestions();
    loadTreatmentDetails(serviceId);
  }, [serviceId]);

  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCoupleMassage = isCoupleMassageType(sessionType);

  const getCurrencySymbol = async (code?: string | null) => {
    let countrycode = "";
    if (code) {
      countrycode = code;
    } else {
      countrycode = await getCountryCode();
    }
    const currencySymbol = COUNTRIES_CURRENCY_SYMBOL[countrycode] || "A$";
    const currency = COUNTRIES_CURRENCY[countrycode] || "AUD";
    setCurrency(currency, currencySymbol);
  };

  const checkShouldAdjustDateTime = () => {
    let shouldAdjustDateTime = true;

    if (updatingForBooking) {
      const { status: bookingStatus } = updatingForBooking;
      const inactiveStatus = [BOOKING_STATUS.COMPLETED, BOOKING_STATUS.CANCELLED];
      if (inactiveStatus.includes(bookingStatus as BOOKING_STATUS)) {
        shouldAdjustDateTime = false;
      }
    }
    return shouldAdjustDateTime;
  };

  React.useEffect(() => {
    getTimeRanges().then(() => {
      initializeSelectedDay();
      if (checkShouldAdjustDateTime()) updateDateTimeStore();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getCurrencySymbol(countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const initializeSelectedDay = () => {
    let selectedDayObject = selectedDay;
    let selectedBackupDayObject = backupSelectedDay;

    // Date Object stored in local storage as string - persist issue
    if (checkIsString(backupSelectedDay) && backupSelectedDay) {
      selectedBackupDayObject = getCurrentDayInTimezone(addressTimezone);
      setBackupSelectedDay(selectedBackupDayObject);
    }

    if (checkIsString(selectedDay) && selectedDay) {
      selectedDayObject = getCurrentDayInTimezone(addressTimezone);
      setSelectedDay(selectedDayObject);
    }
  };

  const updateDateTimeStore = () => {
    let selectedDayObject = selectedDay;
    let selectedBackupDayObject = backupSelectedDay;

    const updatedPayload = getUpdatedDateTimePayload({
      timezone: addressTimezone,
      timeOptions,
      sessionType,
      selectedDay: selectedDayObject,
      earliestStartInMinutes,
      latestStartInMinutes,
      backupSelectedDay: selectedBackupDayObject,
      backupEarliestStartInMinutes,
      backupLatestStartInMinutes,
      timeRangeFilterFromInMinutes,
      selectedDayRange,
      backupSelectedDayRange,
    });
    if (updatedPayload.updated) {
      updateDateTime(updatedPayload);
    }
  };

  const treatmentPrice = getTreatmentPrice();
  const answerDetailPrice = getPriceByQuestion();
  const treatmentName = getTreatmentName();

  React.useEffect(() => {
    console.debug("scroll to top");
    window.scrollTo(0, 0);
  }, [location]);

  const [sideMenuOpen, setSideMenuOpen] = React.useState(false);

  const onLogo = () => {
    window.location.assign(MARKETING_SITE_URL);
  };

  const onClose = () => {
    if (editing) {
      if (onClosed) onClosed();

      let path = Paths.ReviewAndBook as string;

      const bookingId = get(location.state, "bookingId");
      if (bookingId) path = `${Paths.Bookings}/${bookingId}`;

      history.replace(path, { preFetchPaymentMethod: false });
      return;
    }

    const token = localStorage.getItem("token");

    if (exitBookingFlowPath) {
      history.replace(exitBookingFlowPath);
    } else if (token) {
      history.replace(`${Paths.Bookings}`);
    } else {
      history.replace("/");
    }

    if (onClosed) onClosed();
  };

  const getTotalTreatmentDuration = (totalSessionDuration: number) => {
    return totalSessionDuration > 9
      ? `${totalSessionDuration} mins`
      : `${totalSessionDuration} min`;
  };

  const getTotalBookingPrice = () => {
    const totalPrice = getValue(bookingPrice, "price", 0);
    return formatToPrice(totalPrice, currency);
  };

  const totalPrice = getValue(bookingPrice, "price", 0);

  const onTotalPriceClick = () => setShowPriceBreakdownModal(true);

  const sessionDuration = () => {
    if (isMassage && !isCorporate) {
      return getDurationForMassageService();
    }
    if (isCorporate) {
      return `${totalEventDuration?.massageDuration || ""}mins`;
    }

    const totalSessionDuration = getDurationForNonMasasgeService([], true);
    return getTotalTreatmentDuration(totalSessionDuration || 0);
  };

  const handleShowTotalDueUpToModalClose = () => {
    setShowTotalDueUpToModal(false);
  };

  const isCheckOutScreen = progress === STEP_PROGRESS["LAST"];

  let widthForPriceAndProgress = isCheckOutScreen ? 620 : 758;
  widthForPriceAndProgress -= isCorporate && isCheckOutScreen ? 180 : 0;

  const buttonMinWidth = isMobile
    ? !!footerLeftButton && !!footerRightButton
      ? "50%"
      : "100%"
    : "120px";

  return (
    <>
      <Box height="100vh" position={"relative"}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            margin="52px auto 52px"
            style={{
              marginBottom: isMobile ? footerMaxHeight : undefined,
            }}
            maxWidth={"100%"}
          >
            <img
              src={HeaderDecoImage}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "110px",
              }}
              alt="header deco"
            />
            {!isMobile && (
              <img
                src={FooterDecoImage}
                style={{
                  position: "fixed",
                  left: 0,
                  bottom: 0,
                  height: "110px",
                  marginBottom: footerHeight,
                  zIndex: -1,
                }}
                alt="footer deco"
              />
            )}

            <Box
              display="flex"
              flexDirection="column"
              width={fullWidth ? "100%" : isMobile ? "85vw" : "1024px"}
              ml={isMobile ? "25px" : undefined}
              mr={isMobile ? "25px" : undefined}
            >
              {/* Top Header */}

              {isMobile && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                  <Box mt={1}>
                    <UserAvatarButton onClick={() => setSideMenuOpen(true)} buttonStyle={{}} />
                  </Box>

                  <ButtonBase onClick={onLogo}>
                    <img src={BlysLogoImage} alt="logo" />
                  </ButtonBase>

                  <Box mt={1}>
                    <CloseButton onClick={onClose} />
                  </Box>
                </Box>
              )}

              {!isMobile && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ButtonBase onClick={onLogo}>
                    <img src={BlysLogoImage} alt="logo" />
                  </ButtonBase>
                  <CloseButton onClick={onClose} />
                </Box>
              )}

              {/* Titles */}
              <Box display="flex">
                <Box flex={1}>
                  {overline && (
                    <Box
                      fontFamily="SF UI Text"
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="46px"
                      marginTop={isMobile ? undefined : "28px"}
                      color={Colors.BlueyGrey}
                    >
                      {overline}
                    </Box>
                  )}

                  {title && (
                    <Box
                      fontFamily="Museo"
                      fontWeight={700}
                      fontSize="36px"
                      letterSpacing="-0.56px"
                      color={Colors.NightBlue}
                    >
                      {title}
                    </Box>
                  )}

                  {subtitle && (
                    <Box
                      fontFamily="Museo"
                      fontWeight={500}
                      fontSize="16px"
                      marginTop="8px"
                      color={Colors.Dusk}
                    >
                      {subtitle}
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Content */}
              <ErrorBoundary domain="CLIENT_BOOKING">{children}</ErrorBoundary>
            </Box>
          </Box>

          {/* Footer */}
          <NewBookingFooter height={isMobile ? "auto" : footerHeight}>
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              alignSelf="center"
              width={"100%"}
              flexDirection={isMobile ? "column" : "row"}
              style={{ borderTop: `1px solid ${Colors.LightPeriwinkle}` }}
              justifyContent={"center"}
            >
              <Box
                display={"flex"}
                width={isMobile ? "100%" : widthForPriceAndProgress}
                justifyContent={isMobile ? "center" : "flex-start"}
                gridGap={"24px"}
                alignItems={"center"}
                mr={isMobile ? undefined : "24px"}
              >
                <Box
                  flexDirection="column"
                  mt={isMobile ? 2 : undefined}
                  // maxWidth={"210px"}
                  minWidth={"150px"}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={isMobile ? "center" : "flex-start"}
                  >
                    <Box display={"flex"} alignItems={"center"} marginRight={"7px"}>
                      <Box
                        fontFamily="Museo"
                        fontWeight={700}
                        fontSize={
                          (isBackToBackMassage || isCoupleMassage) && massageLength2
                            ? "14px"
                            : "18px"
                        }
                        color={Colors.BlueyGrey}
                      >
                        {sessionDuration()}
                      </Box>
                      <Box
                        style={{
                          width: "6px",
                          height: "6px",
                          backgroundColor: Colors.Dusk,
                          borderRadius: "50%",
                          marginLeft: "7px",
                        }}
                      />
                    </Box>
                    <Box
                      fontFamily="Museo"
                      fontWeight={700}
                      fontSize="18px"
                      color={Colors.Dusk}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={onTotalPriceClick}
                    >
                      {totalPrice ? upToText : ""}
                      {getTotalBookingPrice()}
                    </Box>
                  </Box>
                  {massageType && isMassage && (
                    <Box
                      fontFamily="Open sans"
                      fontWeight={600}
                      fontSize="14px"
                      textAlign={isMobile ? "center" : "left"}
                      color={Colors.BlueyGrey}
                      lineHeight={"21px"}
                    >
                      {MassageType.toString(massageType)}
                    </Box>
                  )}
                  {isHairAndMakeupBooking() && (
                    <Box
                      fontFamily="Open sans"
                      fontWeight={600}
                      fontSize="14px"
                      textAlign={isMobile ? "center" : "left"}
                      color={Colors.BlueyGrey}
                      lineHeight={"21px"}
                    >
                      {getHairAndBeautyTreatmentLabel()}
                    </Box>
                  )}
                  {!isHairAndMakeupBooking() && !isNil(treatmentName) && !isMassage && (
                    <Box
                      fontFamily="Open sans"
                      fontWeight={600}
                      fontSize="14px"
                      color={Colors.BlueyGrey}
                      lineHeight={"21px"}
                      textAlign={isMobile ? "center" : "left"}
                    >
                      {treatmentName}
                    </Box>
                  )}
                </Box>
                {!isMobile && (
                  <Box flex={1}>
                    <NewBookingProgressBar progress={progress} />
                  </Box>
                )}
              </Box>

              {/* Buttons */}
              <Box
                width={isMobile ? "100%" : undefined}
                display={"flex"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box
                  marginTop={isMobile ? "16px" : "0px"}
                  marginBottom={isMobile ? (showFooterDescription ? "16px" : "24px") : "0px"}
                  height="100%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gridGap={"16px"}
                  justifyContent={"center"}
                  paddingX={isMobile ? "24px" : undefined}
                >
                  {!!footerLeftButton ? (
                    <Box style={{ minWidth: buttonMinWidth }}>{footerLeftButton}</Box>
                  ) : (
                    <React.Fragment />
                  )}
                  {!!footerRightButton ? (
                    <Box style={{ minWidth: buttonMinWidth }}>{footerRightButton}</Box>
                  ) : (
                    <React.Fragment />
                  )}
                </Box>
                {showFooterDescription ? (
                  <Box
                    color={Colors.Grey}
                    fontFamily={"Open sans"}
                    fontSize={"14px"}
                    lineHeight={"21px"}
                    fontWeight={"600"}
                    mb={isMobile ? "24px" : 0}
                    width={isMobile ? "100%" : "169px"}
                    textAlign={isMobile ? "center" : "left"}
                    ml={isMobile ? undefined : "16px"}
                  >
                    No charge until booking request accepted by pro.
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </NewBookingFooter>
        </Box>

        <Snackbar
          type={SnackbarType.error}
          message={errorMessage}
          open={errorMessage !== null}
          onClose={() => setErrorMessage(null)}
        />

        <Snackbar
          type={SnackbarType.success}
          open={successMessage !== null}
          message={successMessage}
          onClose={() => setSuccessMessage(null)}
        />
      </Box>
      {isMobile && (
        <SwipeableDrawer
          anchor="left"
          open={sideMenuOpen}
          onClose={() => setSideMenuOpen(false)}
          onOpen={() => setSideMenuOpen(true)}
        >
          <SideMenu onClose={() => setSideMenuOpen(false)} />
        </SwipeableDrawer>
      )}

      <PriceBreakDownModal
        open={showPriceBreakdownModal}
        onToggle={setShowPriceBreakdownModal}
        setShowTotalDueUpToModal={setShowTotalDueUpToModal}
      />
      <ConfirmBookingUpdateModal
        open={showUpdateRecurringModal}
        onClose={() => {
          setUpdateFutureRecurrings(false);
          setShowUpdateRecurringModal(false);
        }}
        handleUpdate={(value?: boolean) => {
          nextGeneratorObject?.next();
          setUpdateFutureRecurrings(!!value);
          setShowUpdateRecurringModal(false);
          nextGeneratorObject?.next();
        }}
      />
      <InfoModal
        visible={showTotalDueUpToModal}
        title={TOTAL_DUE_INFO.title}
        description={TOTAL_DUE_INFO.description}
        handleClose={handleShowTotalDueUpToModalClose}
        divider={false}
      />
    </>
  );
}
