import { Box, Link } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";
import useSWR from "swr";
import PurchaseDetailsModal from "../components/Purchases/PurchaseDetailsModal";
import SectionPageHeader from "../components/SectionPageHeader";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { formatToPrice } from "../helpers/number";
import { useMobile } from "../hooks/mobile";
import { Purchase } from "../models";
import { getValue } from "../utils/object";
import VoucherPaymentDeclined from "../components/Voucher/VoucherPaymentDeclined";
import { VOUCHER_DELIVERY_STATUS, VOUCHER_DELIVERY_DISPLAY_STATUS } from "../constants/voucher";
import { DEFAULT_DATE_FORMAT } from "../constants/date";

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
  header: {
    fontFamily: "SF UI Text",
    fontSize: "14px",
    fontWeight: 500,
    color: Colors.Grey,
    lineHeight: "18px",
    padding: "16px 8px",
    "&:first-child": {
      paddingLeft: "16px"
    },
    "&:last-child": {
      paddingRight: "16px"
    }
  },
  cell: {
    fontFamily: "Museo",
    fontSize: "16px",
    color: Colors.Dusk,
    fontWeight: 500,
    padding: "16px 8px",
    "&:first-child": {
      paddingLeft: "16px"
    },
    "&:last-child": {
      paddingRight: "16px"
    }
  },
});

interface RowProps {
  title: string;
  value: React.ReactNode | null;
  containerStyle?: object;
}

function Row({ title, value, containerStyle = {} }: RowProps) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" mt={0.5} mb={0.5}>
      <Box fontFamily="SF Pro Text" fontSize="14px" fontWeight={500} color={Colors.Grey}>
        {title}
      </Box>
      <Box
        fontFamily="SF Pro Text"
        fontSize="14px"
        color={Colors.Grey}
        style={{ fontWeight: 500, ...containerStyle }}
      >
        {value}
      </Box>
    </Box>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("token"));

  const isMobile = useMobile();

  const { data: purchasesData, mutate: purchasesMutate } = useSWR(
    `/giftVouchers?accessToken=${accessToken}`,
  );

  const purchases = purchasesData ? (purchasesData.purchases as [Purchase]) : [];

  const platformFeeData = purchasesData
    ? purchasesData.platformFeeData
    : { title: "", remarks: "" };

  const [selectedPurchase, setSelectedPurchase] = React.useState<Purchase | null>(null);
  const [showPurchaseModal, setShowPurchaseModal] = React.useState(false);

  const classes = useStyles();

  // Scroll to top on render
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDeliveryStatusBackground = (status: string) => {
    switch (status) {
    case VOUCHER_DELIVERY_STATUS.DELIVERED:
      return Colors.PowderBlue;

    case VOUCHER_DELIVERY_STATUS.SCHEDULE_FOR_DELIVERY:
      return Colors.Peppermint;

    case VOUCHER_DELIVERY_STATUS.DECLINED:
      return Colors.SecondaryPink;

    case VOUCHER_DELIVERY_STATUS.CANCELLED:
      return Colors.SecondaryPink;

    case VOUCHER_DELIVERY_STATUS.FAILED:
      return Colors.SecondaryPink;

    default:
      return Colors.PowderBlue;
    }
  };

  const getDliveryStatusStyle = (status: string) => {
    let textStyle = {
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "SF UI Text",
      color: Colors.NightBlue,
      backgroundColor: Colors.Red,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px 12px",
      borderRadius: "24px",
      letterSpacing: "0.5px",
      width: "fit-content",
      lineHeight: "15.6px"
    };

    textStyle.backgroundColor = getDeliveryStatusBackground(status);

    return textStyle;
  };

  return (
    <>
      <Wrapper
        header={<SectionPageHeader title="Purchases" subtitle="View your purchases here" />}
        onLoggedIn={() => {
          setAccessToken(localStorage.getItem("token"));
        }}
      >
        {isMobile && (
          <Box>
            {purchases.map((purchase: Purchase) => {
              const currency = getValue(purchase, "coupon.selectedCountry.currencySymbol", "A$");
              const deliveryStatusTextStyle = {
                ...getDliveryStatusStyle(purchase.deliveryStatus),
                fontFamily: "Open Sans",
                fontSize: "12px",
              };

              return (
                <Box key={`voucher-mobile-${purchase.id}`} mx={2} my={4} py={2} bgcolor="white">
                  <Row title="Order&nbsp;#" value={`#${purchase.id}`} />
                  <Row
                    title="Item"
                    value={
                      purchase.coupon && (
                        <Link
                          onClick={() => {
                            setSelectedPurchase(purchase);
                            setShowPurchaseModal(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {`${formatToPrice(purchase.coupon.value, currency)} Gift Voucher`}
                        </Link>
                      )
                    }
                  />
                  <Row title="Voucher code" value={purchase.coupon?.code} />
                  <Row title="Usage left" value={purchase.coupon?.usageCounter} />
                  <Row title="Total" value={`${formatToPrice(purchase.price, currency)}`} />
                  <Row title="Deliver to" value={purchase.toName} />
                  <Row
                    title="Delivery date"
                    value={moment(purchase.sendOnDate).format("D MMMM YYYY")}
                  />
                  <Row
                    title="Delivery status"
                    value={purchase.deliveryStatus}
                    containerStyle={deliveryStatusTextStyle}
                  />

                  {purchase.deliveryStatus === VOUCHER_DELIVERY_STATUS.DECLINED && (
                    <Box>
                      <VoucherPaymentDeclined
                        voucher={purchase}
                        containerWrapper={{
                          backgroundColor: Colors.CornflowerBlue,
                          padding: "24px",
                          borderRadius: "4px",
                          gap: "24px",
                          marginBottom: "0px",
                          marginTop: "20px",
                        }}
                        buttonWrapper={{
                          marginTop: "20px",
                        }}
                        purchasesMutate={purchasesMutate}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        {!isMobile && (
          <TableContainer
            component={Paper}
            style={{
              marginLeft: "18px",
              marginTop: "24px",
              width: "auto",
              marginBottom: "12px",
              marginRight: "18px",
            }}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header}>Order #</TableCell>
                  <TableCell className={classes.header}>Item</TableCell>
                  <TableCell className={classes.header}>Voucher code</TableCell>
                  <TableCell className={classes.header}>Usage left</TableCell>
                  <TableCell className={classes.header}>Total</TableCell>
                  <TableCell className={classes.header}>Deliver to</TableCell>
                  <TableCell className={classes.header}>Delivery date</TableCell>
                  <TableCell className={classes.header}>Delivery status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchases.map((purchase: Purchase) => {
                  const currency = getValue(
                    purchase,
                    "coupon.selectedCountry.currencySymbol",
                    "A$",
                  );

                  const cellStyle =
                    purchase.deliveryStatus === VOUCHER_DELIVERY_STATUS.DECLINED
                      ? { borderBottom: 0 }
                      : {};

                  const deliveryStatusStyle = getDliveryStatusStyle(purchase.deliveryStatus);

                  return (
                    <>
                      <TableRow key={`voucher-lg-${purchase.id}`}>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          #{purchase.id}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          {purchase.coupon && (
                            <Link
                              onClick={() => {
                                setSelectedPurchase(purchase);
                                setShowPurchaseModal(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {`${formatToPrice(purchase.coupon?.value, currency)} Gift Voucher`}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          {purchase.coupon?.code}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          {purchase.coupon?.usageCounter}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          {formatToPrice(purchase.price, currency)}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          {purchase.toName}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          {moment(purchase.sendOnDate).format(DEFAULT_DATE_FORMAT)}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          component="th"
                          scope="row"
                          style={cellStyle}
                        >
                          <Box style={{ ...deliveryStatusStyle }}>{VOUCHER_DELIVERY_DISPLAY_STATUS[purchase.deliveryStatus]}</Box>
                        </TableCell>
                      </TableRow>

                      {purchase.deliveryStatus === VOUCHER_DELIVERY_STATUS.DECLINED && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <VoucherPaymentDeclined
                              voucher={purchase}
                              containerWrapper={{
                                display: "flex",
                                backgroundColor: Colors.CornflowerBlue,
                                padding: "24px",
                                gap: "40px",
                                marginBottom: "20px",
                                justifyContent: "space-between",
                              }}
                              titleFontSize="16px"
                              buttonWrapper={{
                                alignItems: "flex-end",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                              purchasesMutate={purchasesMutate}
                              buttonStyle={{ width: "200px" }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Wrapper>
      <PurchaseDetailsModal
        open={showPurchaseModal}
        purchase={selectedPurchase}
        platformFeeData={platformFeeData}
        onClose={() => setShowPurchaseModal(false)}
      />
    </>
  );
}
