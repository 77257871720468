import AddNewCardModal from "../components/Payment/AddNewCardModal";
import { BRAINTREE_COUNTRIES } from "../data/enums";
import AddNewCardModelStripe from "./Payment/AddNewCardModelStripe";

export default function MobileAppNewPaymentMethod(): JSX.Element {
  const accessToken = new URLSearchParams(window.location.search).get("accessToken");
  const countryCode = new URLSearchParams(window.location.search).get("countryCode");

  localStorage.setItem("token", accessToken as string);

  const postWebviewMessage = () => {
    window.location.replace("/new-payment-successful");
  };

  if (countryCode && !BRAINTREE_COUNTRIES.includes(countryCode)) {
    return (
      <AddNewCardModelStripe
        open={true}
        onClose={() => {}}
        onAddedNewCard={() => postWebviewMessage()}
        country={countryCode}
      />
    );
  } else {
    return (
      <AddNewCardModal
        open={true}
        onClose={() => {}}
        onAddedNewCard={() => postWebviewMessage()}
        canAddPaypal={BRAINTREE_COUNTRIES.includes(countryCode || "")}
      />
    );
  }
}
