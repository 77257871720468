import { Box } from "@material-ui/core";
import React from "react";
import ContentSectionControl from "../../components/ContentSectionControl";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import Wrapper from "../../components/Wrapper";
import { useMobile } from "../../hooks/mobile";
import BillingSummary from "./Reports/BillingSummary";
import Overview from "./Reports/Overview";
import PaymentSummary from "./Reports/PaymentSummary";

const sections = ["Overview", "Payment\nsummary", "Billing"];

export default function () {
  const isMobile = useMobile();

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Reports" />

        <Box
          mt="40px"
          ml={isMobile ? "16px" : "80px"}
          mr={isMobile ? "16px" : "80px"}
        >
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={(index) => setSelectedSectionIndex(index)}
          />

          {selectedSectionIndex === 0 && <Overview />}

          {selectedSectionIndex === 1 && <PaymentSummary />}

          {selectedSectionIndex === 2 && <BillingSummary />}
        </Box>
      </Wrapper>
    </>
  );
}
