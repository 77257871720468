import React from "react";
import { Box } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";
import VaccinatedIcon from "../images/vaccinated_logo.png";
import ProfilePicturePlaceholder from "../images/profile-pic-placeholder.png";

interface Props {
    isCovidVaccinated: boolean,
    imageSrc: string | null,
    size: ImageSize,
    whiteBorderImage?: boolean
}

export enum ImageSize {
    large,
    mediumLarge,
    medium,
    small,
  }


export default function ({ isCovidVaccinated = false, imageSrc=ProfilePicturePlaceholder, size=ImageSize.small, whiteBorderImage=false }: Props) {
  const isMobile = useMobile();
  const imageDims = () => {
    switch (size) {
    case ImageSize.large:
      return isMobile ? "96px" : "125px";
    case ImageSize.mediumLarge:
      return isMobile ? "80px" : "80px";
    case ImageSize.medium:
      return isMobile ? "64px" : "64px";
    case ImageSize.small:
      return isMobile ? "32x" : "32px";
            
    }
  };
  const covidStatusDims = () => {
    switch (size) {
    case ImageSize.large:
      return isMobile ? "28px" : "36px";
    case ImageSize.medium:
      return isMobile? "20px" : "20px";
    case ImageSize.small:
      return isMobile ? "14px" : "14px";
    }
  };

  const covidStatusPosition = () => {
    switch (size) {
    case ImageSize.large:
      return isMobile ? -1 : -3;
    case ImageSize.medium:
      return isMobile? -3 : -3;
    case ImageSize.small:
      return isMobile ? -2 : -5;
    }
  };
    
  const covidStatusBorder = () => {
    switch (size) {
    case ImageSize.large:
      return isMobile ? "3px solid white" : "3px solid white";
    case ImageSize.medium:
      return isMobile ? "3px solid white" : "3px solid white";
    case ImageSize.small:
      return isMobile ? "2px solid white" : "2px solid white";
    }
  };

  return (
    <Box position="relative" width={imageDims()} height={imageDims()}>
      <img
        src={imageSrc || ProfilePicturePlaceholder}
        width={imageDims()}
        height={imageDims()}
        style={{ borderRadius: "50%", objectFit: "cover", border: whiteBorderImage ? "3px solid white" : "" }}/>
      {isCovidVaccinated && <img
        src={VaccinatedIcon}
        style={{ width: covidStatusDims(), height: covidStatusDims(), position: "absolute", zIndex: 999, bottom: covidStatusPosition(), right: covidStatusPosition(), border: covidStatusBorder(), borderRadius: "50%" }}
      />}
    </Box>
  );
}
