import React from "react";
import useSWR from "swr";
import axios from "axios";
import { Box } from "@material-ui/core";
import { Booking, DeclinedJob, TherapistPublicProfile } from "../../models";
import { useBookingStore } from "../../stores/booking";
import { useHistory } from "react-router-dom";
import { Paths } from "../../constants/paths";
import { useAccessToken } from "../../hooks/common";
import { useAlertStore } from "../../stores/alert";
import Divider from "../Divider";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";
import { BOOKING_STATUS, RebookingStatus } from "../../constants/booking";
import { getPreferredTherapistId } from "../../helpers/booking";
import { UpdateRequestStatus } from "../../constants/booking";
import { UpdateRequestedBy } from "../../constants/booking";
import CancelBooking from "../CancelBooking";

interface Props {
  booking: Booking;
  buttonDirection?: "column" | "row";
  showHeader?: boolean;
  bookingMutate: () => unknown;
}

export default function RebookingAction({
  booking,
  buttonDirection = "column",
  showHeader = false,
  bookingMutate,
}: Props): JSX.Element {
  const history = useHistory();
  const { setUpdatingForBooking } = useBookingStore();
  const accessToken = useAccessToken();
  const { setErrorMessage } = useAlertStore();

  const [showCancelBooking, setShowCancelBooking] = React.useState(false);
  const [publishButtonLoading, setPublishButtonLoading] = React.useState<boolean>(false);
  const therapistId = getPreferredTherapistId(booking);
  const { data } = useSWR(
    therapistId ? `/therapists/${therapistId}/publicProfile?accessToken=${accessToken}` : null
  );

  const therapistPublicProfile = data as TherapistPublicProfile;
  const therapistFirstname = therapistPublicProfile
    ? therapistPublicProfile.therapistData.firstName
    : "";

  const reasonForDecline = () => {
    if (!therapistId || !booking.declinedjobs) return "";

    if (booking.rebookingStatus === RebookingStatus.notResponded)
      return `No response from ${therapistFirstname}`;

    if (booking.rebookingStatus === RebookingStatus.abandoned) {
      return `${therapistFirstname} has abandoned the booking`;
    }

    const correctJobs = booking.declinedjobs.filter(
      (job: DeclinedJob) => job.therapistId == therapistId
    );
    if (correctJobs && correctJobs.length) {
      return correctJobs[correctJobs.length - 1].reasonForDeclining;
    }

    return "";
  };

  const navigateToDateTime = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setUpdatingForBooking(booking);

    history.push(Paths.DateAndTime);
  };

  const requestCancel = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowCancelBooking(true);
  };

  const publishRebooking = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (publishButtonLoading) return;

    setPublishButtonLoading(true);
    const path = `/api/v2/bookings/${booking.id}/publish?accessToken=${accessToken}`;

    axios
      .post(path)
      .then(() => {
        bookingMutate();
      })
      .catch((error) => {
        setErrorMessage("Unable to make the rebooking public");
      })
      .finally(() => {
        setPublishButtonLoading(false);
      });
  };

  const buttonSpaceStyle = () => {
    if (buttonDirection === "column") {
      return {
        marginBottom: 8,
      };
    }
    return {
      marginRight: 8,
    };
  };

  const bookingUpdates = booking.bookingUpdates && booking.bookingUpdates[0];

  if (!booking.rebookingStatus || booking.status !== BOOKING_STATUS.NEW) return <></>;

  if (bookingUpdates?.status === UpdateRequestStatus.pending && bookingUpdates?.requestedBy === UpdateRequestedBy.pro && booking.rebookingStatus !== UpdateRequestStatus.declined) return <></>;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Divider mt="16px" />
      {showHeader && (
        <>
          <Box
            fontFamily="Museo"
            fontWeight={700}
            color={Colors.Dusk}
            fontSize="18px"
            textAlign="left"
            mt="16px"
          >
            {therapistFirstname} is unavailable
          </Box>
          <Box fontFamily="Open Sans" fontSize="14px" color={Colors.Dusk} textAlign="left" mt="8px">
            {`Reason: "${reasonForDecline()}"`}
          </Box>
        </>
      )}

      <Box display="flex" flexDirection={buttonDirection} flex={1} mt="16px">
        <Button
          title={booking.rebookingStatus === RebookingStatus.abandoned ? "Open booking to all providers" : "Try another practitioner"}
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={publishRebooking}
          disabled={publishButtonLoading}
          loading={publishButtonLoading}
          style={buttonSpaceStyle()}
        />
        <Button
          title="Reschedule to another time"
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={navigateToDateTime}
          style={buttonSpaceStyle()}
        />
        <Button
          title="Cancel booking"
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={requestCancel}
        />
      </Box>

      <CancelBooking
        booking={booking}
        open={showCancelBooking}
        onConfirm={() => {
          bookingMutate();
          history.push("/bookings");
        }}
        onClose={() => setShowCancelBooking(false)}
      />

    </Box>
  );
}
