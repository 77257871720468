import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputBase from "@material-ui/core/InputBase";
import { Colors } from "../../../constants/colors";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: "40px",
    position: "relative",
    backgroundColor: "transparent",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontWeight: 500,
    padding: 0,

    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: ["Museo", "sans-serif"].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      backgroundColor: "transparent",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  formControl: {
    borderRadius: "40px",
    padding: "3px 9px 3px 11px",
    minWidth: "128px",
  },
  formControlOn: {
    color: "white",

    backgroundColor: Colors.TurquoiseBlue,
  },
  formControlOff: {
    border: "1px solid #8B96AD",
  },
  formControlDisabled: {
    border: "1px solid #DCDFE6",
  },
  selectOn: {
    color: "white",
  },
  selectOff: {
    color: "black",
  },
  menuItem: {
    backgroundColor: Colors.TurquoiseBlue,
  },
  textWhite: {
    color: "white",
  },
  textBlack: {
    color: "#8B96AD",
  },
  textIcon: {
    color: "#41506F",
  },
}));

type ValueType = boolean | string | number;

type Props = {
  defaultValue?: ValueType;
  showNone?: boolean;
  value: ValueType;
  disabled?: boolean;
  options: {
    label: string;
    value: ValueType;
  }[];

  onChange: (value: ValueType) => void;
};

export default function DropDown({
  options,
  onChange,
  value,
  defaultValue,
  disabled,
  showNone,
}: Props) {
  const classes = useStyles();
  const isOn = value;
  const handleSelectChange = (event: any) => {
    onChange(event.target.value);
  };
  return (
    <div>
      <FormControl
        className={` ${classes.formControl} ${
          isOn ? classes.formControlOn : classes.formControlOff
        } ${disabled && classes.formControlDisabled}`}
      >
        <Select
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          className={`  ${isOn ? classes.textWhite : classes.textBlack} `}
          IconComponent={(props) => (
            <KeyboardArrowDownIcon
              {...props}
              className={`${props.className} ${isOn ? classes.textWhite : classes.textIcon} `}
            />
          )}
          onChange={handleSelectChange}
          input={<BootstrapInput />}
        >
          {options.map((item) => (
            // @ts-ignore
            <MenuItem value={item.value}> {item.label} </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
