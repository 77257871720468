import { Colors } from "../../constants/colors";
import TableSeparator from "../Reports/TableSeparator";
import { Box } from "../v2/Styled";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../v2/Styled/enum";

interface Props {
  totalPendingCount: number;
  totalPendingAmount: number;
  totalOverdueCount: number;
  totalOverdueAmount: number;
  totalPartiallyPaidCount: number;
  totalPartiallyPaidAmount: number;
  totalDueAmount: number;
  currency: string;
}

const EachDueItem = ({ title, value, color }: { title: string; value: string; color?: string }) => {
  return (
    <Box justifyContent={JustifyContent.spaceBetween}>
      <Box
        fontSize={FontSize.F16}
        fontFamily={FontFamily.Museo}
        fontWeight={FontWeight.Medium}
        color={Colors.Dusk}
      >
        {title}
      </Box>
      <Box
        fontSize={FontSize.F16}
        fontFamily={FontFamily.Museo}
        fontWeight={FontWeight.Bold}
        color={color ? color : Colors.Dusk}
      >
        {value}
      </Box>
    </Box>
  );
};

const TotalDueInvoice: React.FC<Props> = (props) => {
  return (
    <Box
      display={Display.Flex}
      direction={FlexDirection.Column}
      gap={Spacing.S3}
      border={`solid 1px ${Colors.PaleLilac}`}
      backgroundColor={Colors.White}
      paddingX={Spacing.S6}
      paddingY={Spacing.S4}
      borderRadius={Spacing.S2}
      marginY={Spacing.S10}
    >
      <EachDueItem
        title={`${props.totalPendingCount} Pending payment`}
        value={`${props.currency} ${props.totalPendingAmount}`}
      />
      <EachDueItem
        title={`${props.totalOverdueCount} Overdue`}
        value={`${props.currency} ${props.totalOverdueAmount}`}
      />
      <EachDueItem
        title={`${props.totalPartiallyPaidCount} Partially paid`}
        value={`${props.currency} ${props.totalPartiallyPaidAmount}`}
      />

      <TableSeparator />

      <EachDueItem
        title="Total due"
        value={`${props.currency} ${props.totalDueAmount}`}
        color={Colors.Danger}
      />
    </Box>
  );
};

export default TotalDueInvoice;
