import { Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Colors } from "../constants/colors";
import { Paths } from "../constants/paths";
import { setAdminAccessToken } from "../helpers/accessToken";
import { parseApiError } from "../helpers/error";
import { resetUserData } from "../services/auth/auth.service";
import { setCountryCode } from "../utils/country";
import { getValue } from "../utils/object";

export default function LoginAs() {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const accessToken = query.get("accessToken"); // Admin access token
  const _targetEmail = query.get("targetEmail");
  const targetEmail = _targetEmail?.replaceAll(" ", "+"); // REMINDER: + signs are not passed properly

  React.useEffect(() => {
    axios
      .post(`/users/loginAsUser?accessToken=${accessToken}`, {
        targetEmail,
      })
      .then((response) => {
        resetUserData();
        const { accessToken: userAccessToken, country } = response.data;
        setCountryCode(country);
        if (userAccessToken) {
          localStorage.setItem("token", userAccessToken);
          setAdminAccessToken(accessToken);
          const isPro = !!getValue(response, "data.therapistprofile", null);
          if (isPro) {
            history.push(Paths.ProDashboardProfile);
          } else {
            history.push(Paths.Bookings);
          }
        }
      })
      .catch((error) => {
        alert(parseApiError(error));
      });
  }, []);

  return (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress style={{ color: Colors.NightBlue }} />
    </Box>
  );
}
