import React from "react";
import { isEmpty } from "lodash";
import { Box, ButtonBase } from "@material-ui/core";

import Divider from "../Divider";
import { ButtonSize } from "../Button";
import { getValue } from "../../utils/object";
import { Colors } from "../../constants/colors";
import { FavoriteTherapist } from "../../models";
import { getOrdinal } from "../../helpers/ordinal";
import { useUserRole } from "../../hooks/user.hooks";
import MoreIcon from "../../images/therapists-more.svg";
import { BASE_UPLOADS_URL } from "../../constants/common";
import VaccinatedIcon from "../../images/vaccinated_logo.png";
import FavTherapistStar from "../../images/fav-therapist-star.svg";
import FavTherapistRankBadge from "../../images/fav-therapist-rank-badge.svg";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";
import BookMassageButton, { BookMassageButtonSource } from "../BookMassageButton";
import TermsAndPoliciesDocuments from "../TermsAndPoliciesDocuments/TermsAndPoliciesDocuments";

interface Props {
  therapist: FavoriteTherapist;
  onMoreClicked: (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => unknown;
  onClick: () => unknown;
}

export default function FavTherapistItem({
  therapist,
  onMoreClicked,
  onClick,
}: Props): JSX.Element {
  const therapistProfileImage = therapist.therapist?.therapistprofile?.profileImage;
  const therapistFirstName = therapist.therapist?.firstName || "";
  const providerDocuments = therapist.therapist?.useruploadedfiles || [];
  const { isHomeCareClient, isSupportRecipient } = useUserRole();
  const showTermsAndPoliciesDocuments = (isHomeCareClient || isSupportRecipient);

  const rank = therapist.position;

  const isCovidVaccinated = getValue(therapist, "therapist.isCovidVaccinated", false);

  return (
    <Box
      width="275px"
      minHeight="300px"
      borderRadius="6px"
      border={`solid 1px ${Colors.PaleLilac}`}
      bgcolor={Colors.White}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box top={24} left={24} position="absolute">
        <img src={FavTherapistRankBadge} style={{ zIndex: -999 }} />
        <Box
          zIndex={999}
          top={7}
          left={0}
          position="absolute"
          color={Colors.White}
          fontFamily="Open Sans"
          fontWeight={700}
          fontSize="10px"
          width="100%"
          textAlign="center"
        >
          {getOrdinal(rank)}
        </Box>
      </Box>

      <Box top={8} right={8} position="absolute" zIndex={999}>
        <ButtonBase
          onClick={(event) => {
            onMoreClicked(event);
          }}
          disableTouchRipple={true}
        >
          <Box
            minWidth="50px"
            minHeight="50px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={MoreIcon} />
          </Box>
        </ButtonBase>
      </Box>

      <ButtonBase onClick={onClick}>
        <Box display="flex" flexDirection="column" marginTop={"40px"}>
          <Box width="80px" height="80px" position="relative">
            {therapistProfileImage && (
              <img
                src={`${BASE_UPLOADS_URL}/${therapistProfileImage}`}
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "40px",
                  objectFit: "cover",
                }}
              />
            )}
            {!therapistProfileImage && (
              <img src={ProfilePicturePlaceholder} style={{ width: "80px", height: "80px" }} />
            )}
            <img
              src={FavTherapistStar}
              style={{ position: "absolute", zIndex: 999, top: -3, right: -3 }}
            />
            {isCovidVaccinated && (
              <img
                src={VaccinatedIcon}
                style={{
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                  zIndex: 999,
                  bottom: -3,
                  right: -3,
                  border: "3px solid white",
                  borderRadius: "50%",
                }}
              />
            )}
          </Box>
          <Box fontFamily="Museo" fontWeight={700} color={Colors.NightBlue} mt="13px" mb="24px">
            {!isEmpty(therapistFirstName.trim()) ? therapistFirstName : "Favourite Therapist"}
          </Box>
        </Box>
      </ButtonBase>

      <Box alignSelf={"center"}>
        <BookMassageButton
          width="235px"
          title={`Rebook ${therapistFirstName}`}
          size={ButtonSize.small}
          // preferredTherapist={therapistPublicProfile}
          preferredTherapistId={therapist.therapistId}
          source={BookMassageButtonSource.FavouriteTherapistProfile}
        />
      </Box>
      {showTermsAndPoliciesDocuments ? (
        <Box>
          <Divider mt="24px" width="235px" />
          <Box marginTop={"20px"} marginBottom={"20px"}>
            <TermsAndPoliciesDocuments
              showAddAdditionalDocuments={true}
              showAgreements={false}
              hasProsDocuments={true}
              agreements={[]}
              proDocuments={providerDocuments}
              therapistId={therapist.therapistId}
            />
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
}
