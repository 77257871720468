import React, { useState } from "react";
import Button from "../../Button";
import { Dialog } from "../../v2";
import { Box, Link } from "../../v2/Styled";
import StripePayouts from "./StripePayouts";
import { getValue } from "../../../utils/object";
import StripeOnboarding from "./StripeOnboarding";
import { useMobile } from "../../../hooks/mobile";
import { useUserStore } from "../../../stores/user";
import { useAlertStore } from "../../../stores/alert";
import queryClient from "../../../config/queryClient";
import { parseApiError } from "../../../helpers/error";
import StripeAccountManagement from "./StripeAccountManagement";
import StripeNotificationBanner from "./StripeNotificationBanner";
import { StripeConnectAccountStatus } from "../../../constants/stripe";
import { FlexDirection, FontFamily, Spacing } from "../../v2/Styled/enum";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import { STRIPE_KEYS, useStripeConnect } from "../../../hooks/stripe/connect/stripeConnect.hooks";
import {
  getStripeAccount,
  initiateOnboarding as initiateStripeOnboarding,
} from "../../../services/proDashboard/stripe.service";

interface Props {
  accountStatus?: {
    status: boolean;
    accountType: string;
  };
}

const StripeConnectCustom = ({ accountStatus }: Props) => {
  const [account, setAccount] = useState({});
  const [showPayouts, setShowPayouts] = useState<boolean>(false);
  const [isInitiateLoading, setIsInitiateLoading] = useState<boolean>(false);
  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
  const [showAccountManagement, setShowAccountManagement] = useState<boolean>(false);

  const isMobile = useMobile();
  const { user } = useUserStore();
  const { setErrorMessage } = useAlertStore();

  const stripeConnectInstance = useStripeConnect({ account, user });

  const status = getValue(accountStatus, "status");

  const handleOnboardingExit = () => {
    setShowOnboarding(false);
    queryClient.invalidateQueries({ queryKey: [STRIPE_KEYS.ACCOUNT_STATUS] });
  };

  const handleSetupPayouts = async () => {
    try {
      setIsInitiateLoading(true);

      const account = await getStripeAccount();
      setAccount(account);

      const onboarding = await initiateStripeOnboarding();

      if (getValue(onboarding, "success")) {
        const isAccountConnected = status === StripeConnectAccountStatus.CONNECTED;
        setShowAccountManagement(isAccountConnected);
        setShowOnboarding(!isAccountConnected);
      }
    } catch (error) {
      setErrorMessage(parseApiError(error));
      sendErrorLog({
        domain: `WEB ${isMobile ? "MOBILE" : ""}`,
        channel: LOG_CHANNEL.SLACK,
        log: {
          title: "WEB: Stripe connect issue",
          data: error.message,
        },
      });
    } finally {
      setIsInitiateLoading(false);
    }
  };

  let buttonText;
  switch (status) {
  case StripeConnectAccountStatus.CONNECTED:
    buttonText = "Banking details";
    break;
  case StripeConnectAccountStatus.INCOMPLETE:
    buttonText = "Complete setup";
    break;
  default:
    buttonText = "Setup payouts";
    break;
  }

  const showOnboardingFlow = showOnboarding && status !== StripeConnectAccountStatus.CONNECTED;

  const handlePayoutClicked = () => setShowPayouts(true);

  return (
    <Box direction={FlexDirection.Column} gap={Spacing.S4}>
      <Box width="200px" direction={FlexDirection.Column} gap={Spacing.S3}>
        {status === StripeConnectAccountStatus.CONNECTED ? (
          <Box marginBottom={"24px"}>
            <Link onClick={handlePayoutClicked} font={FontFamily.Museo} underlined>
              See payouts
            </Link>
          </Box>
        ) : (
          <></>
        )}

        <Button loading={isInitiateLoading} title={buttonText} onClick={handleSetupPayouts} />
      </Box>

      <Dialog
        maxWidth={"xl"}
        width={"400px"}
        fullWidth={isMobile}
        fullScreen={isMobile}
        title={"Setup payouts"}
        open={showOnboardingFlow}
        onClose={() => setShowOnboarding(false)}
      >
        <Box>
          <StripeOnboarding
            stripeConnectInstance={stripeConnectInstance}
            onExit={handleOnboardingExit}
            isLoading={isInitiateLoading}
          />
        </Box>
      </Dialog>

      <StripeNotificationBanner
        stripeConnectInstance={stripeConnectInstance}
        isLoading={isInitiateLoading}
      />

      <Dialog
        maxWidth={"xl"}
        title={"Banking details"}
        open={showAccountManagement}
        fullWidth={isMobile}
        fullScreen={isMobile}
        onClose={() => setShowAccountManagement(false)}
      >
        <Box>
          <StripeAccountManagement
            stripeConnectInstance={stripeConnectInstance}
            isLoading={isInitiateLoading}
          />
        </Box>
      </Dialog>

      <Dialog
        open={showPayouts}
        onClose={() => setShowPayouts(false)}
        fullScreen={isMobile}
        title={"Payouts"}
        maxWidth={"xl"}
        fullWidth={isMobile}
      >
        <Box mb={Spacing.S4}>
          <StripePayouts
            stripeConnectInstance={stripeConnectInstance}
            isLoading={isInitiateLoading}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default StripeConnectCustom;
