import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { User } from "../../../models";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { STRIPE_PUBLISABLE_KEY } from "../../../constants/common";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import { checkAndGetUsersStripeCountry } from "../../../services/countries/countries.services";
import {
  createAccountLink,
  createAccountSession,
  getAccountStatus,
} from "../../../services/proDashboard/stripe.service";

export const STRIPE_KEYS = {
  ACCOUNT_SESSION: "ACCOUNT_SESSION",
  ACCOUNT_LINK: "ACCOUNT_LINK",
  ACCOUNT_STATUS: "ACCOUNT_STATUS",
} as const;

interface StripeConnectProps {
  account?: {
    accountId?: string;
  };
  user: User | null;
}

const useStripeAccountLink = (userId: number | undefined) =>
  useQuery([STRIPE_KEYS.ACCOUNT_LINK, { userId }], () =>
    createAccountLink().then(({ data }) => data)
  );

const useAccountStatus = () => useQuery([STRIPE_KEYS.ACCOUNT_STATUS], getAccountStatus);

const getAccountSessionSecret = async () => {
  try {
    const session = await createAccountSession();
    return getValue(session, "client_secret", "");
  } catch (err) {
    sendErrorLog({
      domain: "WEB",
      channel: LOG_CHANNEL.SLACK,
      log: {
        title: "WEB: Stripe connect custom account session issue",
        data: { message: err.message },
      },
    });
    return "";
  }
};

const useStripeConnect = ({
  account,
  user,
}: StripeConnectProps): StripeConnectInstance | undefined => {
  const userCountry = getValue(user, "country");
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance>();
  const stripeCountry = checkAndGetUsersStripeCountry(user, userCountry);
  const publishableKey = STRIPE_PUBLISABLE_KEY(stripeCountry);

  useEffect(() => {
    if (account?.accountId) {
      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey,
          fetchClientSecret: getAccountSessionSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: Colors.NightBlue,
              colorText: Colors.Dusk,
              colorDanger: Colors.Danger,
              buttonPrimaryColorBackground: Colors.NightBlue,
            },
          },
        })
      );
    }
  }, [account?.accountId]);

  return stripeConnectInstance;
};

export { useStripeConnect, useStripeAccountLink, useAccountStatus };
