import { useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Button, { ButtonSize, ButtonType } from "../../../../Button";
import { Colors } from "../../../../../constants/colors";
import { useMobile } from "../../../../../hooks/mobile";
import Dialog from "../../../../Dialog";
import TextField from "../../../../TextField";
import Divider from "../../../../Divider";

interface Props {
  open: boolean;
  onClose: (e: MouseEvent) => unknown;
  onConfirm: () => unknown;
  otherAddOn: any;
  removeOtherAddOn: ({ name }: { name: string }) => unknown;
  onaddAddOnClick: ({
    name,
    price,
    duration,
  }: {
    name: string;
    price: string;
    duration: string;
  }) => unknown;
  isSaving: boolean;
}

export default function OtherAddOnModal({
  open,
  onClose,
  onConfirm,
  onaddAddOnClick,
  otherAddOn,
  removeOtherAddOn,
  isSaving,
}: Props): JSX.Element {
  const isMobile = useMobile();

  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} style={{ overflowY: "visible" }}>
      <Box
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        paddingY="42px"
        flexDirection="column"
      >
        <Box display="flex">
          <Box
            width={isMobile ? "100%" : "547px"}
            display="flex"
            flexDirection="column"
            alignItems="start"
            paddingX="48px"
          >
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="21px"
              color={Colors.NightBlue}
              textAlign="center"
              width="100%"
            >
              Add-on
            </Box>

            <Box
              fontFamily="Open Sans"
              fontSize="18px"
              color={Colors.BlueyGrey}
              mt="16px"
              width="100%"
            >
              <TextField
                title="Item name"
                placeholder="Name"
                value={name}
                onChange={(val: string) => setName(val)}
              />
              <TextField
                title="Duration (Optional)"
                placeholder="Duration (In minutes)"
                value={duration}
                onChange={(val: string) => setDuration(val)}
              />
              <TextField
                title="Item Price"
                placeholder="Price"
                value={price}
                onChange={(val: string) => setPrice(val)}
              />

              <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                {isSaving ? (
                  <CircularProgress />
                ) : (
                  <Button
                    height={40}
                    type={ButtonType.outlined}
                    size={ButtonSize.small}
                    title="Add"
                    onClick={() => onaddAddOnClick({ name, price, duration })}
                    style={{ marginRight: "12px" }}
                    disabled={isSaving}
                  />
                )}
              </Box>

              {otherAddOn?.length > 0 && (
                <Box mt={5}>
                  <Box
                    mb={1}
                    fontFamily="Museo"
                    fontSize="14px"
                    fontWeight={700}
                    color={Colors.Dusk}
                  >
                    Items
                  </Box>
                  {otherAddOn.map((data: any) => (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Box
                        fontFamily="Museo"
                        fontSize="14px"
                        fontWeight={700}
                        color={Colors.TurquoiseBlue}
                      >
                        {data.name} ({`$${data.price}`}) | {data.duration}min
                      </Box>
                      <Box
                        onClick={() => removeOtherAddOn({ name: data.itemName })}
                        fontFamily="Museo"
                        fontSize="14px"
                        fontWeight={700}
                        color={Colors.Tomato}
                        style={{ cursor: "pointer" }}
                      >
                        Remove
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Divider mt={6} />

        <Box style={styles.actionWrapper} flexDirection="row">
          <Button
            height={48}
            type={ButtonType.outlined}
            size={ButtonSize.small}
            title="Cancel"
            onClick={onClose}
            style={{ marginRight: "12px" }}
            width={164}
          />
          <Button
            height={48}
            type={ButtonType.primary}
            size={ButtonSize.small}
            title="Confirm"
            onClick={onConfirm}
            width={164}
            style={{ marginRight: "24px" }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "24px",
    justifyContent: "end",
  },
};
