import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import navigatorIcon from "../../images/navigator.svg";
import plusIcon from "../../images/plus.svg";
import minusIcon from "../../images/minus.svg";

interface Props {
  map: google.maps.Map | null;
  panToLatLng?: { lat: number; lng: number };
}

enum ZoomType {
  increment,
  decreament,
}

const MapActions = ({ map, panToLatLng }: Props) => {
  const handleZoomChange = (type: ZoomType) => {
    const currentZoom = map?.getZoom() || 10;
    let nextZoomLevel = 0;
    switch (type) {
    case ZoomType.increment:
      nextZoomLevel = currentZoom + 1;
      break;
    case ZoomType.decreament:
      nextZoomLevel = currentZoom - 1;
      break;
    }
    map?.setZoom(nextZoomLevel);
  };

  const navigate = () => {
    if (panToLatLng) {
      map?.panTo({ lat: panToLatLng?.lat, lng: panToLatLng?.lng });
    } else {
      return;
    }
  };
  return (
    <Box
      position="absolute"
      bgcolor={Colors.White}
      width="28px"
      height="auto"
      bottom="30px"
      right="20px"
      borderRadius="2px"
      padding="8px 0px 8px 0px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      boxShadow="0 4px 10px 0 rgba(10,3,41,0.2)"
      style={{ userSelect: "none" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: "pointer" }}
        justifyContent="center"
        alignItems="center"
        flex={1}
        width="100%"
      >
        {panToLatLng && (
          <>
            <Box onClick={navigate} style={styles.iconContainer} textAlign={"center"}>
              <img src={navigatorIcon} style={styles.actionIcon} />
            </Box>
            <Box width="80%" height="1px" bgcolor={Colors.LightPeriwinkle} mt="5px" mb="5px" />
          </>
        )}

        <Box
          onClick={() => handleZoomChange(ZoomType.increment)}
          style={styles.iconContainer}
          textAlign={"center"}
        >
          <img src={plusIcon} style={styles.actionIcon} />
        </Box>

        <Box width="80%" height="1px" bgcolor={Colors.LightPeriwinkle} mt="5px" mb="5px" />

        <Box
          onClick={() => handleZoomChange(ZoomType.decreament)}
          style={styles.iconContainer}
          textAlign={"center"}
        >
          <img src={minusIcon} style={styles.actionIcon} />
        </Box>
      </Box>
    </Box>
  );
};
export default MapActions;

const styles = {
  actionIcon: {
    width: "35%",
    height: "100%",
  },

  iconContainer: {
    width: "100%",
    height: "100%",
  },
};
