import React from "react";
import Wrapper from "../../../components/Wrapper";
import ProDashboardHeader from "../../../components/ProDashboard/ProDashboardHeader";
import CommunicationPreferences from "../../../components/CommunicationPreferences/CommunicationPreferences";

const Settings = () => {
  return (
    <Wrapper>
      <ProDashboardHeader section="Settings" />

      <CommunicationPreferences proDash />
    </Wrapper>
  );
};
export default Settings;
