import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import { RecipientDataInterface } from "../../pages/BlysTeam/Settings";
import queryClient from "../../config/queryClient";

const KEYS = {
  RECIPIENT: "RECIPIENT",
  MANAGER: "MANAGER",
  HOME_CARE_PROVIDER: "HOME_CARE_PROVIDER",
};

interface UpdateRecipientInterface {
  id: number;
  data: RecipientDataInterface;
}

export const useRecipients = ({ currentPage, userId }: any) =>
  useQuery([KEYS.RECIPIENT, KEYS.MANAGER, userId, { currentPage }], () =>
    httpClient
      .get("api/v2/recipient/list", {
        currentPage,
      })
      .then((res) => res.data),
  );

export const useCreateRecipient = ({ onSuccess, onError, userId }: any) =>
  useMutation((data: RecipientDataInterface) => httpClient.post("api/v2/recipient", data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.RECIPIENT, KEYS.MANAGER, userId] });
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });

export const useUpdateRecipient = ({ onSuccess, onError }: any) =>
  useMutation(
    ({ id, data }: UpdateRecipientInterface) => httpClient.put(`api/v2/recipient/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.RECIPIENT, KEYS.MANAGER] });
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        onError();
      },
    },
  );

export const useRemoveRecipient = ({ onSuccess, onError }: any) =>
  useMutation((id: number) => httpClient.deleteApi(`api/v2/recipient/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.RECIPIENT, KEYS.MANAGER] });
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });

export const useManager = () =>
  useQuery([KEYS.RECIPIENT, KEYS.MANAGER], () =>
    httpClient.get("api/v2/recipient/manager").then((res) => res.data),
  );

export const useCreateManager = ({ onSuccess, onError }: any) =>
  useMutation(
    (data: RecipientDataInterface) => httpClient.post("api/v2/users/account/manager", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.RECIPIENT, KEYS.MANAGER] });
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        onError();
      },
    },
  );

export const useUpdateManager = ({ onSuccess, onError }: any) =>
  useMutation(
    ({ id, data }: UpdateRecipientInterface) =>
      httpClient.put(`api/v2/users/account/manager/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.RECIPIENT, KEYS.MANAGER] });
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        onError();
      },
    },
  );

export const useSendInvitaion = ({ onSuccess, onError }: any) =>
  useMutation((data: RecipientDataInterface) => httpClient.post("api/v2/invite", data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.HOME_CARE_PROVIDER] });
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });

export const useGetAllContactPerson = ({ userId }: any) =>
  useQuery(
    [KEYS.RECIPIENT, KEYS.MANAGER, userId],
    () => httpClient.get("api/v2/recipient/list").then((res) => res.data),
    {
      refetchOnWindowFocus: true,
      staleTime: 1000,
    },
  );
