const TRICKY_NUMBER_PATTERN_AU = ["zerofour", "04", "zero4", "0four"];
const TRICKY_NUMBER_PATTERN_US = ["nineseven", "9seven", "nine7", "97"];
const noOfPreviousMessagesForPhoneNumber = 8;
// constant to pick previous message within 30 minutes
const previousMessageDiffInMinutes = 30;

export {
  TRICKY_NUMBER_PATTERN_AU,
  TRICKY_NUMBER_PATTERN_US,
  noOfPreviousMessagesForPhoneNumber,
  previousMessageDiffInMinutes,
};
