import { Box, BoxProps } from "@material-ui/core";
import { MouseEvent } from "react";
import { get } from "lodash";
import moment from "moment";
import { BOOKING_STATUS, JobStatus } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import {
  bookingIsCancelled,
  bookingIsComplete,
  getFormattedJobDateTime,
  getMassageDescriptionForBooking,
  parseServiceDescriptionForJob,
} from "../../helpers/booking";
import { useMobile, useTab } from "../../hooks/mobile";
import { AbandonedJob, Job, TherapistBooking } from "../../models";
import { useUserStore } from "../../stores/user";
import { getValue } from "../../utils/object";
import BookingStatusPill from "../Bookings/BookingStatusPill";
import CovidStatus from "./CovidStatus";
import DownloadInvoice from "./DownloadInvoice";
import Divider from "../Divider";
import Button, { ButtonSize, ButtonType } from "../Button";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import { DEFAULT_COUNTRY } from "../../utils/country";
import { getFormatRespectToCountry } from "../../utils/date.util";
import crossIcon from "../../images/cross.svg";
import { useAlertStore } from "../../stores/alert";
import { useUnblockClient } from "../../hooks/client/blockClient.hooks";
import { DEFAULT_BOOKING_DATETIME_FORMAT } from "../../constants/date";

const Title = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="14px"
      lineHeight="21px"
      fontWeight={600}
      color={Colors.BlueyGrey}
      {...props}
    />
  );
};

const Value = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Museo"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      mt="4px"
      {...props}
    />
  );
};

interface Props {
  job?: Job;
  onClick?: ({ jobId }: { jobId: number }) => unknown;
  booking?: TherapistBooking;
  abandonedJob?: AbandonedJob;
  showAbandonBookingBtn?: boolean;
  openAbandonBookingModal?: (booking: any) => unknown;
  openBlockClientModal?: (jobId: number) => unknown;
  isModal?: boolean;
  onClose?: (event: MouseEvent<HTMLElement>) => void;
  refetchUpdatedData?: any;
  fromProDash?:boolean
}

const BookingItem = (props: Props) => {
  const {
    booking,
    abandonedJob,
    showAbandonBookingBtn,
    openAbandonBookingModal,
    openBlockClientModal,
    isModal = false,
    onClose,
    refetchUpdatedData,
    job:jobFromProDash,
    fromProDash = false
  } = props;

  const isMobile = useMobile();
  const isTab = useTab();
  const { user } = useUserStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { services } = useServiceRates(user?.country || DEFAULT_COUNTRY);

  const { mutate: unblockClient, isLoading } = useUnblockClient();
  const _job = () => {
    if (booking) {
      return booking.bookingdetail.booking.bookingdetails.find(
        (detail) => detail.job.therapistId === user?.id
      )?.job;
    }

    if (abandonedJob) {
      return abandonedJob.job;
    }

    if (props.job) return props.job;
    return null;
  };

  const job = _job();

  const _earning = () => {
    return job?.serviceFee || booking?.serviceFee || 0;
  };

  const earning = _earning();

  const _recipient = () => {
    const bookingData = booking
      ? get(booking, "bookingdetail.booking")
      : get(abandonedJob, "job.bookingdetail.booking");

    if (bookingData) {
      return bookingData.recipient || bookingData.user;
    }

    return null;
  };

  const recipient = _recipient();

  const _address = () => {
    const innerBooking = job?.bookingdetail.booking || booking?.bookingdetail.booking;
    const innerAddress = innerBooking?.address;

    if (innerBooking && innerAddress) {
      if (innerBooking?.status === BOOKING_STATUS.NEW) {
        const { suburb, state } = innerAddress;
        return `${suburb}, ${state}`;
      }
      if (bookingIsCancelled(innerBooking) || bookingIsComplete(innerBooking) || abandonedJob) {
        return innerAddress.suburb;
      }
      return `${innerAddress.state ?? ""}, ${innerAddress.suburb} ,${innerAddress.postcode}`;
    }

    return null;
  };

  const address = _address();

  var bookingStatus = "";
  const _status = () => {
    if (booking) {
      const isDeclinedBooking =
        getValue(booking, "bookingdetail.booking.status") === BOOKING_STATUS.DECLINED;
      if (isDeclinedBooking) bookingStatus = BOOKING_STATUS.DECLINED;
      return booking.status as JobStatus;
    }

    if (abandonedJob) {
      return JobStatus.cancelled;
    }

    if (job) {
      return getValue(job, "status") || null;
    }

    return null;
  };

  const status = _status();
  const isNewJob =
    job || abandonedJob
      ? [JobStatus.pending, JobStatus.rebooking].includes(status)
      : BOOKING_STATUS.NEW === status;

  const _dateTime = () => {
    if (booking) {
      return booking?.bookingdetail.booking.timeOfArrival;
    }

    if (abandonedJob) {
      return abandonedJob?.job?.bookingdetail?.booking?.earliestTime;
    }

    if (job) {
      if (isNewJob) return job?.bookingdetail?.booking.earliestTime;
      return job?.bookingdetail?.booking.timeOfArrival;
    }

    return null;
  };

  const _date = () => {
    return _dateTime() ? moment(_dateTime()).format(DEFAULT_BOOKING_DATETIME_FORMAT) : "N/A";
  };

  const date = _date();
  const _time = () => {
    return _dateTime() ? moment(_dateTime()).format("h:mma") : "N/A";
  };

  const time = _time();

  function JobID() {
    return (
      <Box display="flex" flexDirection="column" flex={1}>
        <Title>Job ID</Title>
        <Value>{job?.id}</Value>
      </Box>
    );
  }

  const bookingCurrency =
    getValue(booking || job, "bookingdetail.booking.service.selectedCountry.currencySymbol") ||
    getValue(abandonedJob, "job.bookingdetail.booking.service.selectedCountry.currencySymbol") ||
    "$";

  function Earning() {
    return (
      <Box display="flex" flexDirection="column" flex={1}>
        <Title>Payout</Title>
        <Value>
          {bookingCurrency} {earning.toFixed(2)}
        </Value>
      </Box>
    );
  }

  function AbandonmentFee() {
    return abandonedJob ? (
      <Box display="flex" flexDirection="column" flex={1}>
        <Title>Abandonment Fee</Title>
        <Value>
          {bookingCurrency}
          {abandonedJob.cancellationFee.toFixed(2)}
        </Value>
      </Box>
    ) : (
      <></>
    );
  }

  const formatPreferredDateTime = () => {
    const booking = getValue(job, "bookingdetail.booking");
    if (!booking) return "";

    const { earliestTime, latestTime, timeOfArrival, timezone } = booking;
    return getFormattedJobDateTime({ earliestTime, latestTime, timeOfArrival, timezone });
  };

  const formatBackupDateTime = () => {
    const booking = getValue(job, "bookingdetail.booking");
    if (!booking || !booking.backup) return "";

    const { timezone } = booking;
    const { earliestTime, latestTime } = booking.backup;
    return getFormattedJobDateTime({ earliestTime, latestTime, timezone });
  };

  const getServiceLabel = () => {
    let label;

    if (booking) {
      label = getMassageDescriptionForBooking(booking?.bookingdetail.booking);
    } else if (abandonedJob) {
      label = getMassageDescriptionForBooking(abandonedJob.job.bookingdetail.booking);
    }

    if (job) {
      const isMassage = getValue(job, "bookingdetail.booking.isMassage");
      label = parseServiceDescriptionForJob({ job, services, includeDuration: isMassage });
    }

    return label;
  };

  const isBookingCompleted =
    booking && (booking.status === JobStatus.finished || booking.status === JobStatus.reviewLeft);
  const isCancelledBooking = booking && bookingIsCancelled(booking.bookingdetail.booking);

  const shouldShowBackupDate = isNewJob && (getValue(job, "bookingdetail.booking.backup") || false);

  const getCardWidth = () => {
    if (isModal && (isMobile || isTab)) return "100%";
    if (isMobile) return "100%";
    if (abandonedJob) return "378px";
    if (isNewJob) return "250px";

    return "180px";
  };

  const handleClick = () => {
    const { onClick } = props;
    if (!onClick) return;

    if (!!job) {
      onClick({ jobId: job.id });
    }
  };

  const onAbandonBookingButtonClick = (e: any) => {
    e.stopPropagation();
    if (openAbandonBookingModal) {
      if(fromProDash){
        openAbandonBookingModal(jobFromProDash);
        return;
      }
      openAbandonBookingModal(booking);
    }
  };

  const onBlockButtonClicked = (e: any, jobId: number) => {
    e.stopPropagation();
    handleClientBlockOrUnblock(jobId);
  };

  const isClientBlockedByPro = () => {
    const userBlocks =
      getValue(booking, "bookingdetail.booking.user.userblocks") ||
      getValue(abandonedJob, "job.bookingdetail.booking.user.userblocks");
    if (userBlocks.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleClientBlockOrUnblock = (jobId: number) => {
    const isClientAlreadyBlocked = isClientBlockedByPro();

    if (isClientAlreadyBlocked) {
      unblockClient(
        { jobId },
        {
          onSuccess: () => {
            refetchUpdatedData();
            setSuccessMessage("Client unblocked.");
          },
          onError: () => {
            setErrorMessage("Something went wrong! Please try again later.");
          },
        }
      );
    } else {
      if (openBlockClientModal) {
        openBlockClientModal(jobId);
      }
    }
  };

  const getBookingUserInfo = () => {
    let bookingUserDetails = getValue(job, "bookingdetail.booking.user");
    let recipientDetails = getValue(job, "bookingdetail.booking.recipient");

    if (booking) {
      bookingUserDetails = getValue(booking, "bookingdetail.booking.user");
      recipientDetails = getValue(booking, "bookingdetail.booking.recipient");
    }

    const userFirstName = getValue(bookingUserDetails, "firstName", "");

    const recipientFirstName = getValue(recipientDetails, "firstName", "");

    const bookedFor = recipientFirstName ? recipientFirstName : userFirstName;

    return { bookedBy: userFirstName, bookedFor };
  };

  const { bookedBy, bookedFor } = getBookingUserInfo();

  const clientName = `${bookedFor}`;

  return (
    <Box
      bgcolor="white"
      borderRadius="8px"
      border="1px solid #DCDFE6"
      padding="24px"
      mt="8px"
      mb="8px"
      onClick={handleClick}
      position={isModal ? "absolute" : null}
      bottom={isModal ? (isMobile ? 0 : 50) : null}
      style={{
        cursor: !!props.onClick ? "pointer" : undefined,
        ...(isModal ? { ...modalStyles.modal } : null),
      }}
    >
      {isModal && onClose && (
        <Box position="absolute" p="12px" top={0} right={0}>
          <Box
            width="24px"
            height="24px"
            onClick={onClose}
            style={{ cursor: "pointer" }}
            zIndex={999}
          >
            <img src={crossIcon} alt="close" width="100%" height="100%" />
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection={
          isModal ? (isMobile || isTab ? "column" : "row") : isMobile ? "column" : "row"
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          minWidth={getCardWidth()}
          maxWidth={getCardWidth()}
        >
          <Box mb="16px">
            {status && (
              <BookingStatusPill
                bookingStatus={bookingStatus}
                status={status}
                textOverride={abandonedJob ? "Abandoned" : undefined}
              />
            )}
          </Box>

          {!isNewJob ? (
            !isMobile ? (
              <Box display="flex" flexDirection="row" flex={1} mt="8px" mb="8px">
                <JobID />
                <Earning />
                {abandonedJob && <AbandonmentFee />}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                mt="8px"
                mb="8px"
                style={{ gap: "2px" }}
              >
                <JobID />

                <Box display="flex" flexDirection="row" flex={1} mt="16px">
                  <Earning />
                  {abandonedJob && <AbandonmentFee />}
                </Box>
              </Box>
            )
          ) : null}

          {isNewJob ? (
            <Box display="flex" flexDirection="column" mt="8px" mb="8px">
              <Box display="flex" flexDirection="column" flex={1}>
                <Title>Preferred date and time</Title>
                <Value>{formatPreferredDateTime()}</Value>
              </Box>

              {shouldShowBackupDate && (
                <Box display="flex" flexDirection="column" flex={1} marginTop={"15px"}>
                  <Title>Backup date and time</Title>
                  <Value>{formatBackupDateTime()}</Value>
                </Box>
              )}

              {!isMobile && abandonedJob && <Box flex={1} />}
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" flex={1} mt="8px" mb="8px">
              <Title>Booking date and time</Title>
              <Value>{date}</Value>
            </Box>
          )}
        </Box>

        {/* Divider */}
        {!isMobile && (
          <Box width="1px" height="100v" bgcolor={Colors.LightPeriwinkle} ml="24px" mr="24px" />
        )}

        {isMobile && (
          <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} mt="24px" mb="24px" />
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignSelf={!isMobile ? "center" : undefined}
          flexGrow={1}
          width={isModal && (isMobile || isTab) ? "100%" : null}
        >
          <Box
            display="flex"
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            flexDirection={isMobile ? "column" : "row"}
            gridGap={5}
          >
            <Box display="flex" flexDirection="column">
              <Title>Booked by</Title>
              <Value>{bookedBy}</Value>
            </Box>
            <Box display="flex" flexDirection="column">
              <Title>Booked for</Title>
              <Value>{bookedFor}</Value>
            </Box>

            <Box>
              <CovidStatus mb="8px" flex={1} isVaccinated={recipient?.isCovidVaccinated || false} />
            </Box>

            <Box>
              {(isBookingCompleted || isCancelledBooking || abandonedJob) && job && (
                <Button
                  height={40}
                  width={160}
                  type={ButtonType.outlined}
                  size={ButtonSize.small}
                  title={isClientBlockedByPro() ? `Unblock ${clientName}` : `Block ${clientName}`}
                  loading={isLoading}
                  onClick={(e) => {
                    onBlockButtonClicked(e, job.id);
                  }}
                />
              )}
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" mt="8px" mb="8px">
            <Title>Service</Title>
            <Value>{getServiceLabel()}</Value>
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="space-between" mt="8px" mb="8px">
            <Box display="flex" flexDirection="column">
              <Title>Location</Title>
              <Value>{address}</Value>
            </Box>
            {(isBookingCompleted || isCancelledBooking) && (
              <Box alignSelf="end">
                <DownloadInvoice bookingId={booking?.bookingdetail?.bookingId} jobId={job?.id} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {showAbandonBookingBtn && (
        <>
          <Divider mt="20px" />
          <Box display="flex" justifyContent="center" marginTop={"16px"} alignItems="center">
            <Box width={isMobile ? "100%" : "296px"}>
              <Button
                type={ButtonType.outlined}
                size={ButtonSize.small}
                height={48}
                colorTitle={Colors.Dusk}
                title="Abandon booking"
                fontStyle={{
                  fontFamily: "Museo",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                style={{ borderRadius: 4, flexGrow: 1 }}
                onClick={onAbandonBookingButtonClick}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const modalStyles = {
  modal: {
    width: "80%",
    minHeight: "20%",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: 0,
  },
};

export default BookingItem;
