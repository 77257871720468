import React from "react";
import { StripeConnectInstance } from "@stripe/connect-js";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";

interface Props {
  onExit: () => unknown;
  stripeConnectInstance: StripeConnectInstance | undefined;
  isLoading: boolean;
}

const StripeOnboarding = ({ onExit, stripeConnectInstance, isLoading }: Props) => {
  if (isLoading || !stripeConnectInstance) {
    return <React.Fragment />;
  }

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountOnboarding onExit={onExit} />
    </ConnectComponentsProvider>
  );
};

export default StripeOnboarding;
