import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { Box, ButtonBase } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { BlockedTherapist } from "../../models";
import { BASE_UPLOADS_URL } from "../../constants/common";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";
import BlockedTherapistX from "../../images/blocked-therapist-x.svg";
import Button, { ButtonType, ButtonSize } from "../Button";
import MoreIcon from "../../images/therapists-more.svg";

interface Props {
  therapist: BlockedTherapist;
  onMoreClicked: (
    event: React.MouseEvent<HTMLButtonElement | MouseEvent>
  ) => unknown;
  onClick: () => unknown;
}

export default function BlockedTherapistItem({
  therapist,
  onMoreClicked,
  onClick,
}: Props): JSX.Element {
  const therapistProfileImage =
    therapist.therapist?.therapistprofile?.profileImage;
  const therapistFirstName = therapist.therapist?.firstName || "";

  const reason = therapist.blockReason;
  const blockedDateMoment = therapist.createdAt && moment(therapist.createdAt);
  const hasReason = !!reason;
  const blockedDate =
    blockedDateMoment && blockedDateMoment.format("d MMM yyyy");
  const blockedTime = blockedDateMoment && blockedDateMoment.format("h:mma");

  return (
    <Box
      width="275px"
      height={hasReason ? "300px" : "240px" }
      borderRadius="6px"
      border={`solid 1px ${Colors.PaleLilac}`}
      bgcolor={Colors.White}
      alignItems="center"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box top={0} right={8} position="absolute" zIndex={999}>
        <ButtonBase
          onClick={(event) => {
            onMoreClicked(event);
          }}
          disableTouchRipple={true}
        >
          <Box
            minWidth="50px"
            minHeight="50px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={MoreIcon} />
          </Box>
        </ButtonBase>
      </Box>

      <ButtonBase onClick={onClick}>
        <Box
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          display="flex"
          height={hasReason ? "250px" : "180px" }
          mt={hasReason ? "16px" : "30px" }
        >
          <Box width="80px" height="80px" position="relative">
            {therapistProfileImage && (
              <img
                src={`${BASE_UPLOADS_URL}/${therapistProfileImage}`}
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "40px",
                  objectFit: "cover",
                }}
              />
            )}
            {!therapistProfileImage && (
              <img
                src={ProfilePicturePlaceholder}
                style={{ width: "80px", height: "80px" }}
              />
            )}
            <img
              src={BlockedTherapistX}
              style={{ position: "absolute", zIndex: 999, top: -3, right: -3 }}
            />
          </Box>
          <Box
            fontFamily="Museo"
            fontWeight={700}
            color={Colors.NightBlue}
            mt="13px"
            mb="24px"
          >
            {!isEmpty(therapistFirstName.trim())
              ? therapistFirstName
              : "Blocked Therapist"}
          </Box>
          {
            hasReason ? (
              <Box
                margin="0 0 6px"
                padding="11px 15px 13px"
                borderRadius="10px"
                bgcolor="#faf1eb"
                maxWidth="230px"
                fontFamily="Open Sans"
                fontSize="14px"
                lineHeight="20px"
                color={Colors.NightBlue}
              >
                {reason}
              </Box>
            ) : (
              <Box />
            )
          }

        </Box>
      </ButtonBase>

      <Box display="flex" alignItems="flex-end" marginBottom="16px">
        <Box
          fontFamily="Open Sans"
          fontSize="12px"
          lineHeight="20px"
          color={Colors.Dusk}
        >
          Blocked {blockedDate} at {blockedTime}
        </Box>
      </Box>
    </Box>
  );
}
