import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";

export const PayoutKeys = {
  PAYOUTS_FILTER: "PAYOUT_FILTER",
  PAYOUTS: "PAYOUTS",
  BALANCE: "BALANCE",
  EARNINGS_BREAKDOWN: "EARNINGS_BREAKDOWN",
};

export const usePayoutFilters = () =>
  useQuery([PayoutKeys.PAYOUTS_FILTER], () =>
    httpClient.get("api/v2/therapists/earnings/filters").then(({ data }) => data)
  );

export const useProPayouts = ({ params = {} }: { params: object }) =>
  useQuery([PayoutKeys.PAYOUTS, params], () =>
    httpClient.get("api/v2/therapists/earnings/payouts/web-version", params).then(({ data }) => data)
  );

export const useAccountBalance = () =>
  useQuery([PayoutKeys.BALANCE], () =>
    httpClient.get("api/v2/therapists/me/balance").then((data) => data)
  );

export const useEarningsBreakdown = () =>
  useQuery([PayoutKeys.EARNINGS_BREAKDOWN], () =>
    httpClient.get("api/v2/therapists/earnings/breakdown").then(({ data }) => data)
  );
