import { useMutation } from "@tanstack/react-query";
import { BRAINTREE_COUNTRIES } from "../data/enums";
import { post } from "../api/client";

export const useAllowedPayments = (countryCode: string) => {
  const code = (countryCode || "").toUpperCase();

  return {
    isPaypalAllowed: BRAINTREE_COUNTRIES.includes(code),
    isStripeAllowed: !BRAINTREE_COUNTRIES.includes(code),
    isAfterpayAllowed: BRAINTREE_COUNTRIES.includes(code),
    isBraintreeApplePayAllowed: BRAINTREE_COUNTRIES.includes(code),
  };
};

export const useBulkPaymentWithInvoice = () => {
  return useMutation(async (body: { invoices: number[] }) =>
    post("api/v2/payments/bulk-pay-with-invoice", body)
  );
};

export const useBulkPaymentWithCard = () => {
  return useMutation(async (body: { invoices: number[]; paymentMethodId: number }) =>
    post("api/v2/payments/bulk-pay-with-card", body)
  );
};
