import React from "react";
import styles from "./styles";
import { Box } from "@material-ui/core";

interface Props {
  title: string;
  wrapperStyle?: any,
}

const SectionHeader = ({ title, wrapperStyle = {}}: Props) => {
  return (
    <Box style={{ ...wrapperStyle }}>
      {title && <Box style={{ ...styles.header }}>{title}</Box>}
    </Box>
  );
};

export default SectionHeader;
