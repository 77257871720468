import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { capitalizeFirstLetter } from "../../helpers/string";
import { Recipient } from "../../models";
import TextLink from "../TextLink";
import RadioButton from "../RadioButton";
import { isNil } from "lodash";
import { useUserStore } from "../../stores/user";
import { useMobile } from "../../hooks/mobile";
import { getValue } from "../../utils/object";
import { isArray } from "../../utils/array";
import { Roles } from "../../constants/blysTeam";

interface Props {
  recipient?: Recipient;
  selected?: boolean;
  onEditClicked?: () => unknown;
  onDeleteClicked?: () => unknown;
  onSelected?: () => unknown;
  myself?: boolean;
  showMyself?: boolean;
}

export default function AddressItem({
  recipient,
  selected,
  onEditClicked,
  onDeleteClicked,
  onSelected,
  myself = false,
  showMyself = false,
}: Props): JSX.Element {
  const { user } = useUserStore();
  const isMobile = useMobile();

  const relationShip =
    recipient?.relationship === "guest" ? "client" : recipient?.relationship || "";
  return (
    <Box display="flex" flexDirection="row" mt={2} mb={2} alignItems="center">
      {!isNil(selected) && (
        <Box ml={isMobile ? undefined : "20px"} mr="9px">
          <RadioButton selected={selected} onSelected={onSelected} />
        </Box>
      )}

      <Box display="flex" flexDirection="column" flex={1}>
        <Box fontFamily="Museo" fontSize="14px" fontWeight={700} color={Colors.Dusk} mt={2}>
          {showMyself && "Myself"}
          {recipient && capitalizeFirstLetter(relationShip || "")}
        </Box>
        <Box display="flex" flexDirection="row" paddingRight={4} mt={0.5}>
          <Box
            fontFamily="Museo"
            fontSize="18px"
            fontWeight={500}
            color={Colors.NightBlue}
            mr={4}
            flex={1}
          >
            {myself
              ? `${user?.firstName} ${user?.lastName}`
              : `${recipient?.firstName} ${recipient?.lastName}`}
          </Box>
          {onEditClicked && (
            <Box>
              <TextLink title="Edit" onClick={onEditClicked} />
            </Box>
          )}

          {onDeleteClicked && (
            <>
              <Box width="16px" />
              <Box>
                <TextLink title="Delete" destructive onClick={onDeleteClicked} />
              </Box>
            </>
          )}
        </Box>
        <Box
          fontFamily="SF UI Text"
          fontSize="13px"
          fontWeight={400}
          color={Colors.BlueyGrey}
          mt={0.5}
          mb={1}
        >
          {myself ? user?.email : recipient?.email}
        </Box>

        <Box height="1px" bgcolor={Colors.LightPeriwinkle} borderRadius="1px" width="100%" mt={1} />
      </Box>
    </Box>
  );
}
