import TherapistBlock from "./TherapistBlock";
import { Box, Grid } from "@material-ui/core";
import { useMobile } from "../../../hooks/mobile";
import {BASE_UPLOADS_URL} from "../../../constants/common";
import PaginationControl from "../../../components/PaginationControl";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";

interface Props {
  providers? : any,
  pageDetails : { pageCount:number, currentPage:number },
  resetSearch : Function,
  onPageChange : Function
}

const BrowseResults = ({ providers, resetSearch, pageDetails, onPageChange } : Props): JSX.Element => {

  const isMobile = useMobile();
  
  const renderHeader = () => {
    return <Box mb={"24px"} width={"115px"}>
      <Button 
        title="New search" 
        colorTitle = "#8B96AD"
        type={ButtonType.white} 
        size={ButtonSize.small} 
        onClick={()=>resetSearch()}
        style={{border:"1px solid #DCDFE6",borderRadius:"24px",padding:"8px 16px"}}
        fontStyle={{fontFamily:"Open Sans",fontWeight:600}}
      />
    </Box>
  }

  const renderProviders = (providers:any) => {
    const summaries =  providers.map((provider:any)=>{
      const therapistProfile = provider.therapistprofile;
      const imageUrl = (therapistProfile && therapistProfile.profileImage) ? `${BASE_UPLOADS_URL}/${therapistProfile.profileImage}`: null;
      return <TherapistBlock 
        key={provider.id} 
        image={imageUrl}
        userId={provider.id} 
        isVaccinated={provider.isCovidVaccinated} 
        firstName={provider.firstName} 
        totalReviews={provider.totalReviews} 
        rating={therapistProfile.rating ?? 0} 
        isMobile={isMobile}
      />
    })
    return <Box display="flex" flexWrap="wrap" flexDirection="row" width="100%" >
      {summaries}
    </Box>
  }

  const renderPaginationControl = () => {
    return <Box
      display="flex"
      justifyContent="flex-start"
      width={isMobile ? "100%" : "885px"}
      mb="55px"
    >
      <PaginationControl
        currentPage={pageDetails.currentPage - 1 } 
        pages={pageDetails.pageCount}
        onPrevious={() => onPageChange(pageDetails.currentPage-1)}
        onNext={() => onPageChange(pageDetails.currentPage+1)}
      />
    </Box>
  }

  return <Box>
    {renderHeader()}
    {renderProviders(providers)}
    {renderPaginationControl()}
  </Box>

}

export default BrowseResults;