import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { get } from "../../api/client";
import { DropdownOption } from "../../components/Dropdown";

export interface StateOption extends DropdownOption {
  code?: string;
}

export const useStateOptions = ({ countryCode }: any) => {
  const [states, setStates] = useState<StateOption[]>([]);

  const { isLoading, data } = useQuery(
    ["country-states-options"],
    () => {
      return get(`api/v2/utility/country-states?countryCode=${countryCode}`);
    },
    { enabled: countryCode === "US" }
  );

  useEffect(() => {
    setStates(
      (data || []).map(({ name, code }: any) => ({
        title: name,
        value: code,
      }))
    );
  }, [data]);

  return { states, isLoading };
};
