import React from "react";
import { isEmpty, isNil } from "lodash";

import { Box, Typography, ButtonBase } from "@material-ui/core";
import { Address } from "../../models";
import { stringForAddress } from "../../helpers/address";
import { capitalizeFirstLetter } from "../../helpers/string";
import { Colors } from "../../constants/colors";
import TextLink from "../TextLink";
import { labelForLocationType, useTypesStore } from "../../stores/types";
import RadioButton from "../RadioButton";
import { useMobile } from "../../hooks/mobile";

interface Props {
  address: Address;
  selected?: boolean;
  onEditClicked: () => unknown;
  onDeleteClicked?: (address: Address) => unknown;
  onSelected?: () => unknown;
}

export default function AddressItem({
  address,
  selected,
  onEditClicked,
  onDeleteClicked,
  onSelected,
}: Props): JSX.Element {
  const { locationTypes } = useTypesStore();
  const isMobile = useMobile();

  const [typeLabel, setTypeLabel] = React.useState("");

  React.useEffect(() => {
    setTypeLabel(labelForLocationType(address.type) || address.type);
  }, [address, locationTypes]);

  return (
    <Box display="flex" flexDirection="row" mt={2} mb={2} alignItems="center">
      {!isNil(selected) && (
        <Box ml={isMobile ? undefined : "20px"} mr="9px">
          <RadioButton selected={selected} onSelected={onSelected} />
        </Box>
      )}

      <Box display="flex" flex={1} flexDirection="column">
        <Box
          fontFamily="Museo"
          fontSize="14px"
          fontWeight={700}
          color={Colors.Dusk}
          mt={1}
        >
          {typeLabel}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          paddingRight={isMobile ? 0 : 4}
          alignItems="center"
        >
          <Box
            fontFamily="Museo"
            fontSize="18px"
            fontWeight={500}
            color={Colors.NightBlue}
            mt={1}
            mr={4}
            flex={1}
          >
            {stringForAddress(address)}
          </Box>
          <TextLink title="Edit" onClick={onEditClicked} />
          {onDeleteClicked && (
            <>
              <Box width="16px" />
              <TextLink
                title="Delete"
                onClick={() => onDeleteClicked(address)}
                destructive
              />
            </>
          )}
        </Box>

        {!isEmpty(address.instructions) && (
          <Box
            fontFamily="Open Sans"
            fontSize="14px"
            fontWeight={500}
            color={Colors.BlueyGrey}
            mt={0.35}
          >
            Notes: {address.instructions}
          </Box>
        )}

        <Box
          height="1px"
          bgcolor={Colors.LightPeriwinkle}
          borderRadius="1px"
          width="100%"
          mt={2}
        />
      </Box>
    </Box>
  );
}
