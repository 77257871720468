import { Colors } from "../../../constants/colors";
import RadioButton from "../../RadioButton";
import TextMuted from "../../Text/TextMuted";
import { Box } from "../../v2/Styled";
import { FlexDirection, FontFamily, Spacing } from "../../v2/Styled/enum";

type Props = {
  shouldCreateFutureBookings?: boolean;
  onChange?: (value: boolean) => unknown;
  disabled?:boolean
};

const FutureBookingOptions = ({ shouldCreateFutureBookings, onChange,disabled }: Props) => {
  return (
    <Box fontFamily={FontFamily.Museo} direction={FlexDirection.Column} gap={Spacing.S4}>
      <Box direction={FlexDirection.Column} gap={Spacing.S2}>
        <Box fontSize="16px" fontWeight={600} color={Colors.Dusk}>
          Would you like to place all future bookings now?
        </Box>

        <TextMuted text="Your next 10 bookings will be placed at once." />
      </Box>

      <Box direction={FlexDirection.Column} gap={Spacing.S4}>
        <Box alignItems="center" gap={Spacing.S4}>
          <RadioButton
            disabled={disabled}
            selected={shouldCreateFutureBookings}
            onSelected={() => onChange?.(true)}
            style={{ height: "auto", width: "auto" }}
          />
          <Box
            color={Colors.Dusk}
            fontSize="14px"
            className= {!disabled?"cursor-pointer":""} 
            onClick={() =>!disabled && onChange?.(true)}
          >
            Yes
          </Box>
        </Box>

        <Box alignItems="center" gap={Spacing.S4}>
          <RadioButton
            disabled={disabled}
            selected={!shouldCreateFutureBookings}
            onSelected={() => onChange?.(false)}
            style={{ height: "auto", width: "auto" }}
          />
          <Box
            color={Colors.Dusk}
            fontSize="14px"
            className= {!disabled?"cursor-pointer":""} 
            onClick={() =>!disabled && onChange?.(false)}
          >
            No, create them one after the other
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FutureBookingOptions;
