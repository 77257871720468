import React, { ReactNode } from "react";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "./v2/Styled/enum";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  children: ReactNode;
  headerText: string;
  wrapperStyle?: object;
}
export const FilterBlockWrapper = ({ children, headerText, wrapperStyle = {} }: Props) => {
  return (
    <Box>
      <Box
        fontWeight={FontWeight.Bold}
        fontSize={FontSize.F16}
        lineHeight={"24px"}
        fontFamily={FontFamily.Museo}
        color={Colors.Dusk}
        style={{ ...wrapperStyle }}
      >
        {headerText}
      </Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        mt={Spacing.S2}
        style={{
          gap: Spacing.S4,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
