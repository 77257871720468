import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { HOME_PAGE } from "../../constants/links";
import { useMobile } from "../../hooks/mobile";
import { useUserStore } from "../../stores/user";
import Button, { ButtonType } from "../Button";
import Dialog from "../Dialog";
import { Display, FlexDirection, Spacing } from "../v2/Styled/enum";

type Props = {
  open: boolean;
  onClose: () => void;
  hasCashKeyword?: boolean;
};

const PersonalInfoDisclaimerModal = ({ open, onClose, hasCashKeyword }: Props) => {
  const isMobile = useMobile();
  const { user } = useUserStore();

  const userCountry = user?.country || "";
  const websiteHomePage = HOME_PAGE[userCountry.toUpperCase()] || HOME_PAGE.DEFAULT;

  return (
    <Dialog open={open}>
      <Box
        width={isMobile ? "auto" : "330px"}
        padding={`${Spacing.S8} ${Spacing.S4} ${Spacing.S4}`}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        style={{ rowGap: Spacing.S6 }}
      >
        <Typography
          variant="h3"
          style={{
            fontWeight: 700,
            color: Colors.Indigo,
            fontSize: "22px",
            textAlign: "center",
            lineHeight: "24.2px",
            padding: `0 ${Spacing.S2}`,
          }}
        >
          Action not allowed
          {!hasCashKeyword && ": you cannot share external links or private contact info"}
        </Typography>

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{ rowGap: Spacing.S4 }}
          color={Colors.BlueyGrey}
          fontWeight={500}
          textAlign="center"
          lineHeight="24px"
        >
          <Typography>
            As per our{" "}
            <a
              href={`${websiteHomePage}/terms/software-licence-agreement/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: Colors.Indigo }}
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href={`${websiteHomePage}/privacy/`}
              target="_blank"
              rel="noreferrer"
              style={{ color: Colors.Indigo }}
            >
              Privacy Policy
            </a>
            ,{" "}
            {hasCashKeyword
              ? "all payments must be made online through the Blys platform. Cash payments are not allowed."
              : `all correspondence must remain within the Blys Platform. These terms are in place to
            ensure the safety and privacy of all users.`}
          </Typography>

          {!hasCashKeyword && (
            <Typography>
              Please use the in-app messaging and calling function to contact each other.
            </Typography>
          )}

          <Typography>
            Failure to adhere to this policy can result in account suspension.
          </Typography>
        </Box>

        <Button title="Edit message" type={ButtonType.outlined} onClick={onClose} />
      </Box>
    </Dialog>
  );
};

export default PersonalInfoDisclaimerModal;
