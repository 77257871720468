import React from "react";
import {
  ConnectComponentsProvider,
  ConnectPayouts,
  ConnectPayoutsList,
} from "@stripe/react-connect-js";
import { StripeConnectInstance } from "@stripe/connect-js";

interface Props {
  stripeConnectInstance: StripeConnectInstance | undefined;
  isLoading: boolean;
  onlyList?: boolean;
}

const StripePayouts = ({ stripeConnectInstance, isLoading, onlyList = true }: Props) => {
  if (isLoading || !stripeConnectInstance) {
    return <React.Fragment />;
  }

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      {onlyList ? <ConnectPayoutsList /> : <ConnectPayouts />}
    </ConnectComponentsProvider>
  );
};

export default StripePayouts;
