import { Box, CircularProgress } from "@material-ui/core";
import { ceil } from "lodash";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import PaginationControl from "../../../components/PaginationControl";
import PaymentSummaryItem from "../../../components/Reports/PaymentSummaryItem";
import TableSeparator from "../../../components/Reports/TableSeparator";
import { Colors } from "../../../constants/colors";
import { useAccessToken } from "../../../hooks/common";
import { useMobile } from "../../../hooks/mobile";
import { PaginatedResponse3, PaymentSummary } from "../../../models";
import { useUserStore } from "../../../stores/user";
import { isNil, isEmpty } from "lodash";
import NoItemsPlaceholder from "../../../components/ProDashboard/NoItemsPlaceholder";

interface PaymentSummariesResponse extends PaginatedResponse3 {
  data: PaymentSummary[];
}

const perPage = 15;

export default function () {
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const { user, currency } = useUserStore();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { data } = useSWR(
    `/api/v2/summary/payment?accessToken=${accessToken}&limit=${perPage}&page=${currentPage + 1
    }`
  );

  const paymentSummaries = data ? (data as PaymentSummariesResponse) : null;

  const pages = paymentSummaries ? ceil(paymentSummaries.total / perPage) : 0;

  const noItems = !isNil(data) && paymentSummaries?.total === 0;

  useEffect(()=>{
    if(data !== undefined){
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
  }, [data]);

  return (
    <Box maxWidth={isMobile ? "100%" : "960px"}>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="24px"
        lineHeight="26.4px"
        mt="55px"
        mb="24px"
      >
        Weekly payment summary
      </Box>

      <Box borderRadius="8px" border="1px solid #C5CBD6" bgcolor="white">
        {!isMobile && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              fontFamily="Museo"
              fontSize="16px"
              lineHeight="24px"
              fontWeight={400}
              color={Colors.BlueyGrey}
              height="55px"
              pl="24px"
              pr="24px"
              style={{ gap: "24px" }}
            >
              <Box width="30%">Period</Box>
              <Box width="10%">Bookings</Box>

              <Box width="10%">Transferred</Box>
              <Box width="30%">Status</Box>

              <Box></Box>
            </Box>

            {!noItems && <TableSeparator />}
          </>
        )}

        {!noItems &&
          paymentSummaries?.data.map((summary, index) => (
            <>
              {index > 0 && <TableSeparator />}
              <PaymentSummaryItem summary={summary} currency={currency} country={user?.country} />
            </>
          ))}
      </Box>

      {isLoading && (
        <Box mt={"12%"} ml={"auto"} mr={"auto"} textAlign={"center"}>
          <CircularProgress variant="indeterminate" size={30} thickness={5} />
        </Box>
      )}

      {noItems && !isLoading && (
        <Box width="100%" height="50vh" alignItems="center" justifyContent="center" display="flex">
          <NoItemsPlaceholder>No summaries to display</NoItemsPlaceholder>
        </Box>
      )}

      {pages > 0 && (
        <Box display="flex" justifyContent="flex-start" width={"100%"} mb="55px" mt="25px">
          <PaginationControl
            currentPage={currentPage}
            pages={pages}
            onPrevious={() => {
              if (currentPage > 0) {
                setCurrentPage(currentPage - 1);
              }
            }}
            onNext={() => {
              if (currentPage < pages - 1) {
                setCurrentPage(currentPage + 1);
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
}
