"use client";
import { pick } from "lodash";
import queryString from "query-string";

import { useLocation } from "react-router-dom";

interface UseBookingFilterProps {
  forUpcoming?: boolean;
}
// safe query are those queries that are supported in backend
interface SafeBookingQueries {
  sortBy: string;
  status?: string[];
  paymentStatus?: string;
  recipientId?: number;
  providerId?: number;
  section?:string;
  page?:number;
  filterDate?:{
    startDate:string,
    endDate:string
  },
  bookingId: string,
};

interface AllBookingQueries extends SafeBookingQueries{
  providerName?: string;

}

const UseBookingQueryFilter = ({ forUpcoming }: UseBookingFilterProps) => {
  const location = useLocation();
  const query = queryString.parse(location.search) as Record<
    string,
    string | number | undefined | string[]
  >;
  const defaultSortBy = forUpcoming ? "asc" : "desc";
  query.sortBy = query.sortBy || defaultSortBy;

  if (query.recipientId) {
    query.recipientId = parseInt(query.recipientId as string);
  }
  if (query.providerId) {
    query.providerId = parseInt(query.providerId as string);
  }
  if(query.filterDate){
    query.filterDate = JSON.parse(query.filterDate as string);
  }
  const safeQueries = [
    "sortBy",
    "status",
    "paymentStatus",
    "recipientId",
    "providerId",
    "filterDate",
    "bookingId",
  ] as const;

  const allBookingQueries = pick(query,[...safeQueries,"providerName","section","page"] ) as AllBookingQueries;

  const safeBookingQueries = pick(query,safeQueries) as SafeBookingQueries;

  const parseQueryString = (query:any)=>{

    if(query.filterDate){
      query.filterDate = JSON.stringify(query.filterDate);
    }

    return queryString.stringify(query);
      
  };
  return {allBookingQueries,safeBookingQueries,parseQueryString};
};

export default UseBookingQueryFilter;
