import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import { DropdownOption } from "../../components/Dropdown";
import { Recipient } from "../../models";

const key = {
  RECIPIENT_OPTIONS: "RECIPIENT_OPTIONS",
  PROVIDER_OPTIONS: "PROVIDER_OPTIONS",
};
export const useRecipientUpdate = () => {
  const { isLoading, mutate } = useMutation((recipientData: any) => {
    const { id: recipientId, updatedRecipientData: body } = recipientData;
    return httpClient.put(`users/me/recipients/${recipientId}`, body);
  });
  return { isLoading, updateRecipient: mutate };
};

export const useGetRecipientOptions = ({ name }: { name: string }) =>
  useQuery([key.RECIPIENT_OPTIONS, name], () => {
    return httpClient
      .get("users/me/recipients", { search: { name } })
      .then((res: any) => {
        const { data } : any  = res;
        const recipientOptions: Array<DropdownOption> = (data || []).map((recipient: Recipient) => ({
          value: recipient.id,
          title: `${recipient.firstName} ${recipient.lastName}`,
        }));

        return recipientOptions;
      });
  });
