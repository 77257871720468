import { Box, ButtonBase, Typography, withStyles } from "@material-ui/core";
import { isNil } from "lodash";
import React, { Fragment } from "react";
import { Colors } from "../constants/colors";
import { getPageHeight, getTopOffset } from "../helpers/screen";
import DropdownArrow from "../images/dropdown-arrow.svg";
import InfoIcon from "../images/info.svg";
import "./Dropdown.css";
import { getValue } from "../utils/object";

export interface DropdownOption {
  value: any;
  title: string;
  helperText?: string;
  type?: string;
  locationTypeId?: number;
  id?: number;
}

export interface DropdownStyle {
  width?: string;
  height?: string;
  titleFontSize?: string;
  optionFontSize?: string;
  optionFontWeight?: string;
  titleTextAlign?: string;
  titleStyle?: object;
  selectedOptionAlignment?: "left" | "right" | "center";
  optionMarginRight?: string;
  optionMarginLeft?: string;
}

interface Props {
  title?: string;
  description?: string;
  options: Array<DropdownOption>;
  onSelectedOption: (option: DropdownOption) => unknown;
  selectedOption?: DropdownOption | null;
  onInfoClicked?: () => unknown;
  lineHidden?: boolean;
  paddingTop?: string | number;
  paddingBottom?: string | number;
  dropDownMarginButton?: string | number;
  placeholder?: string;
  checkDirection?: boolean;
  isDropdownDirectionUp?: boolean;
  isDisabled?: boolean;
  onOpen?: () => unknown;
  onClose?: () => unknown;
  setIsDropdown?: (val: boolean) => unknown;
  validationOnClick?: () => boolean;
  styles?: DropdownStyle;
}

export const dropdownContainerStyle = {
  zIndex: 999,
  marginTop: "-8px",
  width: "100%",
};

export const dropdownStyle = {
  width: "100%",
  borderRadius: "11px",
  boxShadow: "0 4px 10px 0 rgba(10,3,41,0.2)",
  backgroundColor: "#ffffff",
};

const secondaryTextStyle = {
  fontFamily: "Open Sans",
  fontSize: 14,
  color: "#41506F",
  marginTop: 4,
};

export const dropdownStylesCombined = Object.assign({}, dropdownContainerStyle, dropdownStyle);

export interface StyledButtonBaseProps {
  selected?: boolean;
}

const selectedColor = "rgba(185, 229, 236, 0.14)";

export const StyledButtonBase = withStyles({
  root: ({ selected = false }: StyledButtonBaseProps) => ({
    backgroundColor: selected ? selectedColor : undefined,
    width: "100%",
    minHeight: "60px",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: selectedColor,
    },
    paddingTop: "15px",
    paddingBottom: "15px",
  }),
})(ButtonBase);

export default function Dropdown({
  title,
  description,
  options,
  onSelectedOption,
  selectedOption,
  onInfoClicked,
  lineHidden = false,
  paddingTop = "21px",
  paddingBottom = "21px",
  dropDownMarginButton = 1,
  placeholder = "Select",
  isDisabled = false,
  checkDirection = false, // check dropdown direction, implementation not complete (dyanmic dropdown options height check)
  isDropdownDirectionUp = false,
  setIsDropdown,
  onOpen = () => {},
  onClose = () => {},
  styles,
  validationOnClick = () => {
    return true;
  },
}: Props): JSX.Element {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // dropdown direction
  const [dropsDown, setDropsDown] = React.useState(true);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
      onClose?.();
      setIsDropdown && setIsDropdown(false);
    }
  };

  React.useEffect(() => {
    if (checkDirection) {
      checkDropDirection(containerRef, 250);
    }
    if (isDropdownDirectionUp) {
      setDropsDown(!isDropdownDirectionUp);
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const checkDropDirection = (itemRef: React.RefObject<HTMLDivElement>, minHeight: number) => {
    let pageHeight = getPageHeight();
    let offsetTop = getTopOffset(itemRef);
    if (offsetTop) {
      let shouldDropDown = pageHeight - offsetTop;
      if (shouldDropDown < 0) {
        shouldDropDown *= -1;
      }
      if (shouldDropDown < minHeight) {
        setDropsDown(false);
      }
    }
  };

  const customStyle = !dropsDown ? { bottom: 0 } : {};

  const selectedOptionAlignment = getValue(styles, "selectedOptionAlignment", "left");

  return (
    <div
      ref={containerRef}
      style={{ width: styles?.width ? styles.width : "100%", height: styles?.height || "auto" }}
    >
      <Box position="relative" width="100%">
        <Box paddingTop={paddingTop} paddingBottom={paddingBottom} width="100%">
          {title && (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                fontFamily="Museo"
                fontSize={styles?.titleFontSize ? styles.titleFontSize : "14px"}
                color={Colors.Dusk}
                fontWeight={700}
                flex={1}
                textAlign={styles?.titleTextAlign ? styles.titleTextAlign : "left"}
              >
                {title}
              </Box>
              {onInfoClicked && (
                <ButtonBase
                  onClick={(event) => {
                    event.stopPropagation();
                    onInfoClicked();
                  }}
                >
                  <img src={InfoIcon} width="20px" height="20px" />
                </ButtonBase>
              )}
            </Box>
          )}

          {description ? (
            <Box>
              <Typography
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 400,
                  color: Colors.Dusk,
                  fontSize: "14px",
                  marginTop: "8px",
                }}
              >
                {description || ""}
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          <ButtonBase
            style={{
              width: "100%",
              cursor: isDisabled ? "default" : "pointer",
            }}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              if (!validationOnClick()) {
                return;
              } else if (showDropdown) {
                setShowDropdown(false);
                onClose?.();
                setIsDropdown && setIsDropdown(false);
              } else {
                setShowDropdown(true);
                onOpen?.();
                setIsDropdown && setIsDropdown(true);
              }
            }}
          >
            <Box display="flex" flexDirection="row" mt={1} mb={dropDownMarginButton} flex={1}>
              {" "}
              {!isNil(selectedOption) && (
                <Typography
                  style={{
                    height: "32px",
                    lineHeight: "32px",
                    textAlign: selectedOptionAlignment,
                    marginBottom: "0px",
                    color: isDisabled ? Colors.LightBlueGrey : Colors.TurquoiseBlue,
                    width: "100%",
                    overflow: "hidden",
                    fontSize: styles?.optionFontSize ? styles.optionFontSize : "1rem",
                    fontWeight: styles?.optionFontWeight ? styles.optionFontWeight : "500",
                    ...(styles?.titleStyle || {}),
                  }}
                >
                  {selectedOption?.title}
                </Typography>
              )}
              {isNil(selectedOption) && (
                <Typography
                  style={{
                    height: "32px",
                    lineHeight: "32px",
                    textAlign: "left",
                    marginBottom: "0px",
                    color: Colors.LightBlueGrey,
                    width: "100%",
                  }}
                >
                  {placeholder}
                </Typography>
              )}
              <img src={DropdownArrow} alt="Dropdown" />
            </Box>
          </ButtonBase>

          {!lineHidden && <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />}
        </Box>

        {showDropdown && (
          <Box
            position="absolute"
            style={{
              ...dropdownContainerStyle,
              ...{ marginTop: dropsDown ? "-8px" : "-430px" },
              ...customStyle,
            }}
          >
            <Box style={dropdownStyle} className="dropdown">
              {options.map((option, index) => (
                <Fragment key={option.title}>
                  <StyledButtonBase
                    selected={selectedOption === option}
                    onClick={() => {
                      setShowDropdown(false);
                      onClose?.();
                      onSelectedOption(option);
                      setIsDropdown && setIsDropdown(false);
                    }}
                    key={index}
                  >
                    <Box
                      fontFamily="Open Sans"
                      fontSize="16px"
                      lineHeight="18px"
                      color={Colors.Dusk}
                      marginLeft={styles?.optionMarginRight || "21px"}
                      marginRight={styles?.optionMarginLeft || "21px"}
                      textAlign="left"
                      fontWeight={selectedOption === option ? 700 : 600}
                    >
                      {option.title}
                      {option.helperText && (
                        <Typography style={secondaryTextStyle}>{option.helperText}</Typography>
                      )}
                    </Box>
                  </StyledButtonBase>
                  {index !== options.length && (
                    <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} />
                  )}
                </Fragment>
              ))}
            </Box>
            {/* Extra padding at the bottom for better UX */}
            <Box height="80px" />
          </Box>
        )}
      </Box>
    </div>
  );
}
