import { Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import AddressItem from "../components/Addresses/AddressItem";
import AddressModal from "../components/Addresses/AddressModal";
import SectionPageHeader from "../components/SectionPageHeader";
import TextLink from "../components/TextLink";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { TrackingEvents } from "../constants/tracking";
import { withVersion } from "../helpers/tracking";
import { useMobile } from "../hooks/mobile";
import { Address } from "../models";
import { trackEvent } from "../services/segment/track.service";
import { fetchAddresses, useAddressStore } from "../stores/address";
import { useAlertStore } from "../stores/alert";
import { fetchLocationTypes } from "../stores/types";
import PaginationControl from "../components/PaginationControl";
import { PaginatedResponse3 } from "../models";
import { removeUserAddress, updateUserAddress } from "../services/users/users.service";
import { ADDRESS_QUERY_KEYS, useIsAddressRecurring } from "../hooks/address/address.hooks";
import RecurringAddressUpdateModal from "./Bookings/Components/RecurringAddressUpdateModal";
import { checkIfEmpty } from "../utils/object";
import queryClient from "../config/queryClient";

export default function Addresses(): JSX.Element {
  const isMobile = useMobile();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("token"));

  const [recurringModalOpen, setRecurringModalOpen] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState<Address | null>(null);
  const [page, setPage] = React.useState(1);
  const [selectedRecurringAddress, setSelectedRecurringAddress] = useState<any>(null);
  const [paginateResponse, setpaginateResponse] = useState<PaginatedResponse3>();

  const { addresses } = useAddressStore();

  const selectedAddressId = selectedAddress ? selectedAddress.id : null;
  const { isFetching: isRecurringCheckLoading, data: hasRecurringBooking } =
    useIsAddressRecurring(selectedAddressId);

  useEffect(() => {
    fetchLocationTypes();
  }, []);

  useEffect(() => {
    const getAddresses = async () => {
      const res = await fetchAddresses(page);

      setpaginateResponse({
        page: res?.page,
        total: res?.totalPage,
        perPage: res?.perPage,
      });
    };
    getAddresses();
  }, [page]);

  useEffect(() => {
    queryClient.invalidateQueries([ADDRESS_QUERY_KEYS.hasRecurring]);
  }, [selectedAddress]);

  const handleSelectedForEdit = (address: Address) => {
    setSelectedAddress(address);
    setAddressModalOpen(true);
  };

  const onDeleteTapped = (address: Address) => {
    removeUserAddress({ addressId: address.id })
      .then(() => {
        fetchAddresses();
        setSuccessMessage("Address successfully deleted.");
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const onAddressSaved = () => {
    setAddressModalOpen(false);
    fetchAddresses();
  };

  const onEditRecurringAddress = (data: any) => {
    if (checkIfEmpty(data)) return;

    setAddressModalOpen(false);
    setSelectedRecurringAddress(data);
    setTimeout(() => setRecurringModalOpen(true), 200);
  };

  const handleRecurringOptionSelected = (updateAll: boolean = false) => {
    if (!selectedRecurringAddress || !selectedAddress) return;

    const updateData = { ...selectedRecurringAddress, updateRecurringBookingAddress: updateAll };
    updateUserAddress({ addressId: selectedAddress.id, data: updateData })
      .then(() => {
        fetchAddresses();
        setRecurringModalOpen(false);
        setSelectedRecurringAddress(null);
        setSuccessMessage("Address successfully updated.");
      })
      .catch((error) => setErrorMessage("Unable to update address"));
  };

  const handleCloseRecurring = () => {
    setRecurringModalOpen(false);
    setAddressModalOpen(false);
    setSelectedAddress(null);
    setSelectedRecurringAddress(null);
  };

  return (
    <>
      <Wrapper
        header={<SectionPageHeader title="Saved addresses" subtitle="Manage your addresses here" />}
        onLoggedIn={() => {
          setAccessToken(localStorage.getItem("token"));
          fetchAddresses();
        }}
      >
        {!isMobile && (
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="24px"
            color={Colors.NightBlue}
            ml="80px"
            mt="43px"
            mb="24px"
          >
            Manage your addresses
          </Box>
        )}

        <Box
          style={{
            marginLeft: isMobile ? undefined : "80px",
            marginRight: isMobile ? undefined : "80px",
            backgroundColor: Colors.White,
            // padding: isMobile ? undefined : "0px 0 26px 23px",
            borderRadius: "6px",
            border: `solid 1px ${Colors.VeryLightPink}`,
          }}
          maxWidth={isMobile ? "100%" : "609px"}
          mt={isMobile ? 2 : undefined}
        >
          <Box ml="24px" mr="24px">
            {addresses?.map((address: Address) => (
              <AddressItem
                address={address}
                onEditClicked={() => handleSelectedForEdit(address)}
                onDeleteClicked={() => onDeleteTapped(address)}
                key={address?.id}
              />
            ))}
            <Box mt={4} mb={4}>
              <TextLink
                title="Add address"
                onClick={() => {
                  setSelectedAddress(null);
                  setAddressModalOpen(true);
                  setPage(1);
                  trackEvent(TrackingEvents.AddAddressClicked, withVersion());
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          style={{
            marginLeft: isMobile ? undefined : "80px",
            marginRight: isMobile ? undefined : "80px",
          }}
          maxWidth={isMobile ? "100%" : "609px"}
          display="flex"
          justifyContent={isMobile ? "center" : "flex-end"}
          marginTop="23px"
          marginBottom="25px"
        >
          <PaginationControl
            currentPage={paginateResponse?.page!}
            pages={paginateResponse?.total!}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            startFromOne={true}
          />
        </Box>
      </Wrapper>
      <AddressModal
        additionalLoading={isRecurringCheckLoading}
        open={addressModalOpen}
        existingAddress={selectedAddress}
        onClose={() => setAddressModalOpen(false)}
        onSaved={() => onAddressSaved()}
        isRecurringAddress={hasRecurringBooking}
        onEditRecurringAddress={onEditRecurringAddress}
      />
      <RecurringAddressUpdateModal
        onClose={handleCloseRecurring}
        title="You have a recurring booking scheduled. Would you like to update the address?"
        open={recurringModalOpen}
        handleUpdate={handleRecurringOptionSelected}
      />
    </>
  );
}
