import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../constants/colors";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import { PayoutInfoCard } from "./PayoutInfoCard/PayoutInfoCard";
import { FilterButton } from "../../FilterButton/FilterButton";
import {
  useAccountBalance,
  useEarningsBreakdown,
  usePayoutFilters,
  useProPayouts,
} from "../../../hooks/payout/payout";
import { PredefineFilterEnum, PredefinedFilter } from "./PredefinedFilter";
import { getValue } from "../../../utils/object";
import { useHistory } from "react-router-dom";
import { LoadingSpinner } from "../../loadingSpinner";
import {
  FilterName,
  ProDashPastBookingFilter,
  SearchFilter,
} from "../../Modals/FilterModal/ProDashPastBookingFilter";
import { getFilterCount } from "../../../helpers/filter";
import { AccountBalance } from "./AccountBalance";
import PaginationControl from "../../PaginationControl";
import { Jobs } from "./Jobs";

interface Params {
  [key: string]: any;
}
export const PastBookings = () => {
  const history = useHistory();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<object>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPreDefinedFilter, setSelectedPreDefinedFilter] = useState(PredefineFilterEnum.All);

  const { isLoading: isFilterLoading, data: filters } = usePayoutFilters();
  const { isLoading: isPayoutsLoading, data: proPayouts } = useProPayouts({
    params: {
      currentPage,
      ...selectedFilters,
      completed: getValue(selectedFilters, "completed") ?? true,
      cancelled: getValue(selectedFilters, "cancelled") ?? true,
      preDefinedFilter: selectedPreDefinedFilter,
    },
  });
  const { isLoading: isBalanceLoading, data: accountBalanceData } = useAccountBalance();
  const { isLoading: isEarningBreakDownLoading, data: earningBreakdownData } =
    useEarningsBreakdown();

  const initializeData = () => {
    const params = new URLSearchParams(window.location.search);
    const searchParams: Params = {};

    const allowedKeys: Set<FilterName> = new Set([
      FilterName.Completed,
      FilterName.Cancelled,
      FilterName.PayoutStatus,
      FilterName.Overdue,
      FilterName.StartingDate,
      FilterName.EndingDate,
    ]);

    params.forEach((value, key) => {
      if (allowedKeys.has(key as FilterName)) {
        searchParams[key] = key === FilterName.PayoutStatus ? value.split(",") : value;
      }
    });

    const page = params.get("page");
    setCurrentPage(page ? +page : 1);

    const preDefinedFilter = params.get("preDefinedFilter");
    setSelectedPreDefinedFilter(
      (preDefinedFilter as PredefineFilterEnum) || PredefineFilterEnum.All
    );
    setSelectedFilters({
      completed: params.get("completed"),
      cancelled: params.get("cancelled"),
      startingDate: params.get("startingDate"),
      endingDate: params.get("endingDate"),
      overdue: params.get("overdue"),
      payoutStatus: params.get("payoutStatus")?.split(","),
    });
  };

  useEffect(() => {
    if (!isPayoutsLoading && proPayouts) {
      const totalPage = getValue(proPayouts, "pageCount");
      setTotalPage(totalPage);
      console.log(proPayouts, "proPayouts");
    }
  }, [isPayoutsLoading]);

  useEffect(() => {
    initializeData();
  }, []);

  if (isBalanceLoading || isFilterLoading) return <LoadingSpinner />;

  const preDefinedFilter = [
    { label: PredefineFilterEnum.All, value: PredefineFilterEnum.All },
    ...getValue(filters, "preDefinedFilter", []),
  ];

  const paymentStatusFilter = getValue(filters, "paymentStatus", []);
  const bookingStatusFilter = getValue(filters, "bookingStatus", []);

  const filterCount = () => {
    const count = getFilterCount({
      filteredData: selectedFilters,
      considerSingleCount: [["startingDate", "endingDate"]],
    });
    return count;
  };

  const balance = getValue(accountBalanceData, "balance", 0);
  const currencySymbol = getValue(accountBalanceData, "currencySymbol", "");
  const breakDown = getValue(accountBalanceData, "breakDown", []);
  
  const handlePaginate = (page: number) => {
    const params = new URLSearchParams(history.location.search);
    params.set("page", `${page}`);
    const searchParams = params.toString();
    history.push({ search: searchParams });
  };
  return (
    <Box mb={Spacing.S10}>
      <Box mt={Spacing.S10} mb={Spacing.S10}>
        <AccountBalance
          accountBalance={+balance}
          currencySymbol={currencySymbol}
          breakDown={breakDown}
        />
        <Box mt={Spacing.S6}>
          <PayoutInfoCard infoData={earningBreakdownData} />
        </Box>

        <Box mt={Spacing.S10} mb={Spacing.S4}>
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.spaceBetween}
          >
            <Typography
              style={{
                fontFamily: FontFamily.Museo,
                fontWeight: FontWeight.Bold,
                lineHeight: LineHeight.L27,
                fontSize: FontSize.F24,
                color: Colors.Dusk,
              }}
            >
              Your payouts
            </Typography>
            <Box>
              <FilterButton
                title="Filter"
                onClick={() => {
                  setOpenFilterModal(true);
                }}
                filterCount={filterCount()}
              />
            </Box>
          </Box>
        </Box>

        {/* Pre defined filter */}
        <PredefinedFilter
          filters={preDefinedFilter}
          onPredefineFilterApplied={(selectedFilter: PredefineFilterEnum) => {
            setCurrentPage(1);
            setSelectedPreDefinedFilter(selectedFilter);
          }}
        />
        <Box
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Jobs proPayouts={proPayouts} isPayoutsLoading={isPayoutsLoading} />
        </Box>
      </Box>
      <ProDashPastBookingFilter
        open={openFilterModal}
        onClose={() => {
          setOpenFilterModal(false);
        }}
        onFilterApplied={(selectedFilter: SearchFilter) => {
          setSelectedFilters(selectedFilter);
          setCurrentPage(1);
        }}
        paymentStatusFilter={paymentStatusFilter}
        bookingStatusFilter={bookingStatusFilter}
        appliedFilter={selectedFilters}
      />
      <Box>
        <PaginationControl
          containerStyle={{
            justifyContent: "right",
          }}
          onPrevious={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
              handlePaginate(currentPage - 1);
            }
          }}
          onNext={() => {
            if (currentPage < totalPage) {
              setCurrentPage(currentPage + 1);
              handlePaginate(currentPage + 1);
            }
          }}
          currentPage={currentPage - 1}
          pages={totalPage}
        />
      </Box>
    </Box>
  );
};
