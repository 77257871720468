// api / v2 / therapists / providers;
import { useQuery } from "@tanstack/react-query";
import * as httpClient from "../api/client";
import { User } from "../models";
import { DropdownOption } from "../components/Dropdown";

const KEY = {
  PROVIDER_OPTIONS: "PROVIDER_OPTIONS",
};

export const useSearchProviderByName = ({ name }: { name: string }) =>
  useQuery([KEY.PROVIDER_OPTIONS, name], async (): Promise<any> => {
    return httpClient
      .get("api/v2/therapists/providers/search", { search: { name }, myProviders: true })
      .then((providers: Array<User>) => {
        const providerOptions: Array<DropdownOption> = providers.map((provider) => ({
          value: provider.id,
          title: `${provider.firstName} ${provider.lastName}`,
        }));

        return providerOptions;
      });
  });
