import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import BackgroundImage from "../../images/giftVoucher/back-blank.png";
import BlysLogo from "../../images/giftVoucher/blys-logo.png";
import { useVoucherStore } from "../../stores/voucher";
import { getFormatRespectToCountry } from "../../utils/date.util";

const VOUCHER_EXPIRY_YEAR = 3;

interface VoucherInterface {
  country?: string;
}

export default function GiftVoucherPreview({ country = "AU" }: VoucherInterface) {
  const isMobile = useMobile();
  const { voucher, recipientName = "", yourName = "", notes = "" } = useVoucherStore();

  // @todo: static voucher expiry date
  // on api using voucher created + 3 years for expiry date
  const expiryDate = moment(new Date())
    .add("years", VOUCHER_EXPIRY_YEAR)
    .format(getFormatRespectToCountry(country));

  return (
    <Box style={isMobile ? styles.containerMobile : styles.container}>
      {/* header */}
      <Box display="flex" alignItems="center" marginBottom={isMobile ? "8px" : "16px"}>
        <img
          src={BlysLogo}
          alt="Blys Logo"
          height={isMobile ? "20px" : "32px"}
          width={isMobile ? "34px" : "55px"}
        />
        <Typography
          variant="h1"
          style={{
            fontFamily: "Museo",
            fontSize: isMobile ? "10px" : "18px",
            display: "inline",
            fontWeight: isMobile ? 600 : 400,
            lineHeight: "22px",
            letterSpacing: "3px",
            textAlign: "center",
            padding: isMobile ? "10px" : "12px",
            color: Colors.Indigo
          }}
        >
          YOU DESERVE SOME ‘ME-TIME’.
        </Typography>
      </Box>

      {/* main body */}
      <Grid container>
        <Grid item xs={isMobile ? 5 : 6}>
          <BorderBox style={isMobile ? styles.messageBoxMobile : styles.messageBox}>
            <Box>MESSAGE: </Box>
            <Box paddingTop={isMobile ? "8px" : "16px"} letterSpacing={1}>{notes}</Box>
          </BorderBox>
        </Grid>
        <Grid item xs={isMobile ? 7 : 6}>
          <BorderBox style={isMobile ? styles.inputBoxMobile : styles.inputBox}>
            <Box display='flex' flexDirection='row'>
              <Box>TO: </Box>
              <Box paddingLeft={"4px"}>{recipientName}</Box>
            </Box>
          </BorderBox>
          <BorderBox style={isMobile ? styles.inputBoxMobile : styles.inputBox}>
            <Box display='flex' flexDirection='row'>
              <Box>FROM: </Box>
              <Box paddingLeft={"4px"}>{yourName}</Box>
            </Box>
          </BorderBox>
          <BorderBox style={isMobile ? styles.inputBoxMobile : styles.inputBox}>
            <Box display='flex' flexDirection='row'>
              <Box>VALUE: </Box>
              <Box paddingLeft={"4px"}>{voucher?.type === "custom" ? voucher?.currency + voucher?.voucherValue: voucher?.giftText}</Box>
            </Box>
          </BorderBox>
          <BorderBox style={isMobile ? { ...styles.inputBoxMobile, marginBottom: "0px"} : { ...styles.inputBox, marginBottom: "0px"}}>
            <Box display='flex' flexDirection='row'>
              <Box>EXPIRY: </Box>
              <Box paddingLeft={"4px"}> {voucher?.giftText ? expiryDate : ""}</Box>
            </Box>
          </BorderBox>
        </Grid>
      </Grid>

      {/* footer */}
      <Box fontSize={isMobile ? "8.5px" : "9.5px"} textAlign={"right"} paddingTop={isMobile ? "8px" : "18px"} letterSpacing={"0.92"}>
        Redeem online at getblys.com or on our app
      </Box>
    </Box >
  );
}

const BorderBox = (props: any) => (
  <Box boxSizing={"border-box"} border={`1.92px solid ${Colors.Indigo}`} style={{ background: "white", ...props.style }}>
    {props.children}
  </Box>
);


const styles = {
  container: {
    backgroundImage: `url(${BackgroundImage})`,
    minHeight: "265px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    fontFamily: "Museo, 'Open Sans', serif",
    padding: "41px 32px",
    color: Colors.Indigo
  },
  containerMobile: {
    backgroundImage: `url(${BackgroundImage})`,
    width: "327px",
    minHeight: "215px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    fontFamily: "Museo, 'Open Sans', serif",
    padding: "8px 11px",
    color: Colors.Indigo
  },
  messageBox: {
    width: "235px",
    height: "100%",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    alignItems: "center",
    letterSpacing: "1.5px",
    marginRight: "16px",
    padding: "16px",
  },
  messageBoxMobile: {
    width: "132px",
    height: "100%",
    fontWeight: 400,
    fontSize: "8px",
    lineHeight: "10px",
    alignItems: "center",
    letterSpacing: "1px",
    marginRight: "8px",
    padding: "6px",
  },
  inputBox: {
    width: "248px",
    fontWeight: 400,
    fontSize: "10px",
    alignItems: "center",
    letterSpacing: "1.5px",
    padding: "13px 8px",
    marginBottom: "8px"
  },
  inputBoxMobile: {
    width: "100%",
    fontWeight: 400,
    fontSize: "8px",
    alignItems: "center",
    letterSpacing: "1.2px",
    lineHeight: "12px",
    padding: "10px 2px 10px 5px",
    marginBottom: "7px"
  }
};