import { Box, BoxProps, CircularProgress } from "@material-ui/core";
import { isEmpty, isNil, last, reverse, slice, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Bar, Chart } from "react-chartjs-2";
import useSWR from "swr";
import Button, { ButtonType } from "../../../components/Button";
import Dropdown, { DropdownOption } from "../../../components/Dropdown";
import AccountBalance from "../../../components/ProDashboard/AccountBalance";
import SegmentedControl from "../../../components/SegmentedControl";
import Divider from "../../../components/Divider";
import { Colors } from "../../../constants/colors";
import { BASE_URL } from "../../../constants/common";
import { useAccessToken } from "../../../hooks/common";
import config from "../../../config/proDashboard/dashboardConfig.json";
import { useMobile } from "../../../hooks/mobile";
import {
  EarningsChartData,
  EarningsSummaryData,
  ReportingData,
  ReportingDataValue,
} from "../../../models";
import { useUserStore } from "../../../stores/user";
import { getValue } from "../../../utils/object";

const chartSegments = ["Weekly", "Monthly", "Yearly"];

Chart.defaults.font.size = 14;
Chart.defaults.font.family = "Open Sans";

interface SummaryRowProps {
  title: string;
  value: string;
  marginTop?: number | string;
  marginBottom?: number | string;
}

const SummaryRow = ({
  title,
  value,
  marginTop = "16px",
  marginBottom = "16px",
}: SummaryRowProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flex={1}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Box
        fontFamily="Museo"
        fontWeight={400}
        fontSize="16px"
        lineHeight="24px"
        color={Colors.Dusk}
        flex={1}
      >
        {title}
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="16px"
        lineHeight="24px"
        color={Colors.Dusk}
        flex={1}
        textAlign="right"
      >
        {value}
      </Box>
    </Box>
  );
};

const SummaryTitle = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Museo"
      fontWeight={600}
      fontSize="18px"
      lineHeight="27px"
      color={Colors.Dusk}
      {...props}
    />
  );
};

const TotalSummaryRow = ({ title, value }: SummaryRowProps) => {
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box
        fontFamily="Museo"
        fontWeight={400}
        fontSize="16px"
        lineHeight="24px"
        color={Colors.Dusk}
        flex={1}
      >
        {title}
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="24px"
        lineHeight="26.4px"
        color={Colors.Dusk}
        flex={1}
        mt="8px"
      >
        {value}
      </Box>
    </Box>
  );
};

const Container = (props: BoxProps) => {
  return (
    <Box
      bgcolor="white"
      p="24px"
      border="1px solid #DCDFE6"
      borderRadius="8px"
      mt="24px"
      {...props}
    />
  );
};

const Report = () => {
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const { user, currency, currencyCode } = useUserStore();
  const countryCode = user?.country || "AU";

  const [shouldShowTAXInfo, setShouldShowTAXInfo] = useState(true);
  const [taxTitle, setTaxTitle] = useState("GST");

  const {data} = useSWR(`/api/v2/therapists/me/earning?accessToken=${accessToken}`);
  const [isLoading, setIsLoading] = useState(true);

  // const { data } = useSWR(null);
  const earnings = data ? (data as any[]) : null;
  const _summaryData = earnings ? (earnings[0] as EarningsSummaryData[]) : null;

  const _chartData = earnings ? (earnings[earnings.length - 1] as EarningsChartData[]) : null;

  const _reportingData = earnings
    ? (slice(earnings, 1, earnings.length - 1) as ReportingData[])
    : [];

  const _reportingDropdownOptions = () => {
    if (_reportingData) {
      const options = _reportingData.map((data: ReportingData) => {
        const thisDataYear = parseInt(data.id);

        return {
          value: data.id,
          title: `${thisDataYear} Financial Year (1 July ${
            thisDataYear - 1
          } - 30 June ${thisDataYear})`,
        } as DropdownOption;
      });

      return reverse(options);
    }

    return [];
  };

  const reportingDropdownOptions = _reportingDropdownOptions();

  const chartData = () => {
    if (_chartData) {
      const keyOptions = ["week", "month", "year"];
      const key = keyOptions[selectedSegmentIndex] || "week";
      const thisChartData = _chartData.find((data) => data.key === key); // TODO: Switch between weekly and monthly

      return (
        thisChartData && {
          labels: thisChartData.data.label,
          datasets: [
            {
              label: thisChartData.name,
              data: thisChartData.data.values,
            },
          ],
        }
      );
    }

    return null;
  };

  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(0);

  const summaryDataForKey = (key: string) => {
    if (_summaryData) {
      return _summaryData.find((data) => data.key === key)?.data?.amount.toPriceString(currency);
    }

    return (0).toPriceString(currency);
  };

  const [selectedReportingYear, setSelectedReportingYear] = React.useState("");

  const reportingDataForKey = (key: string) => {
    const data = _reportingData.find((data: ReportingData) => data.id === selectedReportingYear);

    return data?.values.find((value: ReportingDataValue) => value.key === key)?.data.amount || 0;
  };

  const reportingPriceDataForKey = (key: string) => {
    return reportingDataForKey(key)?.toPriceString(currency) || (0).toPriceString(currency);
  };

  const _totalBookingsToDate = () => {
    return sumBy(_reportingData, (data: ReportingData) => {
      return data.values.find((value) => value.key === "total-bookings")?.data.amount || 0;
    });
  };

  const getProBusinessConfig = (config: any, country = "AU") => {
    // defaults to AU if country code is not found
    let configCountry = "AU";
    if (["AU", "US", "GB", "NZ"].includes(country)) {
      configCountry = country;
    }
    const countryConfig = config[configCountry];
    return getValue(countryConfig, "proDashboard.business.information");
  };

  const getBusinessConfigBasedOnCountry = (countryCode: string) => {
    const businessConfig = getProBusinessConfig(config, countryCode);
    const taxEnabled = businessConfig.showTAXInfoSection;
    setShouldShowTAXInfo(taxEnabled);
    setTaxTitle(businessConfig.taxTitle);
  };

  const totalBookingsToDate = _totalBookingsToDate();

  React.useEffect(() => {
    if (!isEmpty(_reportingData) && isEmpty(selectedReportingYear)) {
      const mostRecentReportingData = last(_reportingData);
      mostRecentReportingData && setSelectedReportingYear(mostRecentReportingData.id);
    }
  }, [_reportingData]);

  useEffect(() => {
    getBusinessConfigBasedOnCountry(countryCode);
  }, []);

  useEffect(()=>{
    if(data !== undefined){
      setIsLoading(false);
    }
    else {
      setIsLoading(true);
    }
  }, [data]);

  return (
    <Box>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="24px"
        lineHeight="26.4px"
        mt="55px"
        mb="24px"
      >
        Earnings
      </Box>

      {/* REMINDER: Container needs to be fixed width to help with Chart responsiveness https://www.chartjs.org/docs/master/configuration/responsive.html#important-note */}
      <Box width={!isMobile ? "65vw" : undefined}>
        <Container position="relative">
          <SegmentedControl
            segments={chartSegments}
            selectedSegmentIndex={selectedSegmentIndex}
            onSelectedIndex={(index) => setSelectedSegmentIndex(index)}
          />

          <Box height="400px" mt="24px">
            {!isLoading ? (
              <Bar
                data={chartData()}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  backgroundColor: Colors.TurquoiseBlue,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        // Format labels to show as price
                        label: function (context: any) {
                          var label = context.dataset.label || "";

                          if (label) {
                            label += ": ";
                          }
                          if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: currencyCode,
                            }).format(context.parsed.y);
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <>
                <Box
                  ml={"auto"}
                  mr={"auto"}
                  height={"inherit"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress variant="indeterminate" size={30} thickness={5} color="primary" />
                </Box>
              </>
            )}
          </Box>

          {isEmpty(chartData()) && chartData() !== null && (
            <Box
              position="absolute"
              top={"50%"}
              ml="auto"
              mr="auto"
              left={0}
              right={0}
              textAlign="center"
              color={Colors.Dusk}
              fontWeight={400}
              fontSize="16px"
              lineHeight="24px"
              fontFamily="Museo"
            >
              No bookings have been completed
            </Box>
          )}
        </Container>

        <Box display="flex" flexDirection={isMobile ? "column" : "row"} flex={1} mb="40px">
          <Box display="flex" flexDirection="column">
            <AccountBalance currency={currency} />
            <Container flex={1}>
              <TotalSummaryRow
                title="Total earnings to date"
                value={`${summaryDataForKey("to-date")}`}
              />

              <Box height="22px" />
              <TotalSummaryRow title="Total bookings to date" value={`${totalBookingsToDate}`} />
            </Container>
          </Box>
          <Box width="24px" />
          <Container flex={1}>
            <SummaryRow
              title="This week"
              value={`${summaryDataForKey("this-week")}`}
              marginTop={0}
            />
            <Divider mt="16px" mb="16px" />
            <SummaryRow title="This month" value={`${summaryDataForKey("this-month")}`} />
            <Divider mt="16px" mb="16px" />
            <SummaryRow
              title="This year"
              value={`${summaryDataForKey("this-year")}`}
              marginBottom={0}
            />
          </Container>
          <Box width="24px" />
          <Container flex={1}>
            <SummaryRow
              title="Last week"
              value={`${summaryDataForKey("last-week")}`}
              marginTop={0}
            />
            <Divider mt="16px" mb="16px" />
            <SummaryRow
              title="Last month"
              value={`${summaryDataForKey("last-month")}`}
              marginTop={0}
            />
            <Divider mt="16px" mb="16px" />
            <SummaryRow
              title="Last year"
              value={`${summaryDataForKey("last-year")}`}
              marginBottom={0}
            />
          </Container>
        </Box>

        <Box fontFamily="Museo" fontWeight={600} fontSize="24px" lineHeight="26.4px" mb="24px">
          Reporting
        </Box>

        <Container flex={1} mb="80px">
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? undefined : "flex-end"}
          >
            <Container
              padding="0px"
              pl="24px"
              pr="24px"
              flex={1}
              mt={0}
              mr={isMobile ? undefined : "24px"}
            >
              <Dropdown
                options={reportingDropdownOptions}
                selectedOption={reportingDropdownOptions.find(
                  (option) => option.value === selectedReportingYear
                )}
                onSelectedOption={(option) => setSelectedReportingYear(option.value)}
                lineHidden
                paddingTop={0.5}
                paddingBottom={0.5}
              />
            </Container>

            <Box height="24px" />

            <Button
              title="Download report"
              type={ButtonType.secondary}
              width={isMobile ? "100%" : "178px"}
              onClick={() => {
                if (!isEmpty(selectedReportingYear) && user) {
                  window.location.assign(
                    `${BASE_URL}/reports/fyReportDownload/${user.id}?accessToken=${accessToken}&year=${selectedReportingYear}`
                  );
                }
              }}
            />
          </Box>

          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Container flex={1}>
              <SummaryTitle>Service fee</SummaryTitle>
              <SummaryRow
                title="Subtotal"
                value={`${reportingPriceDataForKey("massage-fee-excl-gst")}`}
              />
              {shouldShowTAXInfo && (
                <>
                  <SummaryRow
                    title={taxTitle}
                    value={`${reportingPriceDataForKey("massage-fee-gst")}`}
                  />
                  <Divider mt="16px" mb="16px" />
                  <SummaryRow
                    title={`Total inc. ${taxTitle}`}
                    value={`${reportingPriceDataForKey("massage-fee")}`}
                  />
                </>
              )}
            </Container>
            <Box width="24px" />

            <Container flex={1}>
              <SummaryTitle>License fee</SummaryTitle>
              <SummaryRow
                title="Subtotal"
                value={`${reportingPriceDataForKey("licence-fee-excl-gst")}`}
              />
              {shouldShowTAXInfo && (
                <>
                  <SummaryRow
                    title={taxTitle}
                    value={`${reportingPriceDataForKey("licence-fee-gst")}`}
                  />
                  <Divider mt="16px" mb="16px" />
                  <SummaryRow
                    title={`Total inc. ${taxTitle}`}
                    value={`${reportingPriceDataForKey("licence-fee")}`}
                  />
                </>
              )}
            </Container>
            <Box width="24px" />

            <Container flex={1}>
              <SummaryTitle>Earnings</SummaryTitle>
              {/* 
            <SummaryRow
              title="Service fee"
              value={`${reportingPriceDataForKey("total-service-fee")}`}
            />

            <Divider mt="16px" mb="16px" /> */}

              <SummaryRow
                title="Total earnings"
                value={`${reportingPriceDataForKey("total-received")}`}
              />
              <SummaryRow
                title="Total bookings"
                value={`${reportingDataForKey("total-bookings")}`}
              />
            </Container>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Report;
