import React from 'react';
import { ButtonBase } from '@material-ui/core';

import CloseIcon from '../../images/close.svg';

interface Props {
  onClick: any;
}

const CloseButton = ({ onClick }: Props) => {
  return (
    <ButtonBase
      onClick={onClick}
      style={{
        width: '40px',
        height: '40px',
        marginBottom: '20px',
      }}
    >
      <img src={CloseIcon} alt='close' />
    </ButtonBase>
  );
};

export default CloseButton;
