import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import { useState } from "react";
import { Colors } from "../../constants/colors";
import { RECURRING_ENDS } from "../../constants/time";
import { useOccurrenceOptions } from "../../hooks/booking/recurring/recurringOption.hooks";
import CalendarIcon from "../../images/calendar-muted.png";
import { formatTime } from "../../utils/date.util";
import Dropdown, { DropdownOption, StyledButtonBase } from "../Dropdown";
import RadioButton from "../RadioButton";
import { AlignItems, Display, FlexDirection, Spacing } from "../v2/Styled/enum";
import RecurringDatePicker from "./RecurringDatePicker";

interface RecurringEndsOnProps {
  endType: string;
  setEndType: (data: string) => unknown;
  recurringEndData: { occurrence: number; endDate: Date | string };
  setRecurringEndData: (data: any) => unknown;
  saveRecurringEndTypeData: (data: any) => unknown;
  isMobile: boolean;
  getMinValidDate: () => Date;
  oneYearFromNow: Date;
  countryCode: string;
}

const RecurringEndsOn = (props: RecurringEndsOnProps) => {
  const {
    endType,
    setEndType,
    recurringEndData,
    setRecurringEndData,
    saveRecurringEndTypeData,
    isMobile,
    getMinValidDate,
    oneYearFromNow,
    countryCode,
  } = props;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const occurrenceOptions = useOccurrenceOptions({});

  const handleRecurringEndDateSelect = (date: Date) => {
    setRecurringEndData({ ...recurringEndData, endDate: date || new Date() });
    saveRecurringEndTypeData({
      endsOn: date,
      type: RECURRING_ENDS.ON,
      occurrence: recurringEndData.occurrence,
    });
    setShowDatePicker(false);
  };

  const setNeverEnding = () => {
    setEndType(RECURRING_ENDS.NEVER);
    saveRecurringEndTypeData({
      endsOn: null,
      type: RECURRING_ENDS.NEVER,
      occurrence: recurringEndData.occurrence,
    });
  };

  const setEndDate = () => {
    setEndType(RECURRING_ENDS.ON);
    saveRecurringEndTypeData({
      endsOn: recurringEndData.endDate,
      type: RECURRING_ENDS.ON,
      occurrence: recurringEndData.occurrence,
    });
  };

  const setOccurrence = () => {
    setEndType(RECURRING_ENDS.AFTER);
    saveRecurringEndTypeData({
      endsOn: recurringEndData.endDate,
      type: RECURRING_ENDS.AFTER,
      occurrence: recurringEndData.occurrence || 1,
    });
  };

  const updateNumberOfOccurrence = (option: DropdownOption) => {
    setRecurringEndData({
      ...recurringEndData,
      occurrence: option.value,
    });
    saveRecurringEndTypeData({
      endsOn: recurringEndData.endDate,
      type: RECURRING_ENDS.AFTER,
      occurrence: option.value,
    });
  };

  return (
    <Box
      fontFamily="Museo"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      gridRowGap={Spacing.S4}
    >
      <Box fontSize="16px" fontWeight={600} color={Colors.Dusk}>
        Ends on
      </Box>

      {/* Never Ends */}
      <Box
        fontSize={"14px"}
        fontWeight={400}
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gridColumnGap={Spacing.S4} width="172px">
          <RadioButton
            selected={RECURRING_ENDS.NEVER === endType}
            onSelected={setNeverEnding}
            style={{ height: "auto", width: "auto" }}
          />
          <Box color={Colors.Dusk} className="cursor-pointer" onClick={setNeverEnding}>
            {RECURRING_ENDS.NEVER}
          </Box>
        </Box>
      </Box>

      {/* Ends On */}
      <Box
        display={Display.Flex}
        alignSelf={isMobile ? undefined : "flex-start"}
        flexDirection={FlexDirection.Column}
        position="relative"
      >
        <Box
          fontSize={"14px"}
          fontWeight={400}
          display="flex"
          justifyContent="start"
          alignItems={isMobile ? "start" : "center"}
          gridColumnGap={Spacing.S16}
          flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
          gridRowGap={isMobile ? "8px" : 0}
        >
          <Box
            display="flex"
            alignItems="center"
            gridGap={4}
            gridColumnGap={Spacing.S4}
            width="172px"
          >
            <RadioButton
              selected={RECURRING_ENDS.ON === endType}
              onSelected={setEndDate}
              style={{ width: "auto", height: "auto" }}
            />
            <Box color={Colors.Dusk} className="cursor-pointer" onClick={setEndDate}>
              {RECURRING_ENDS.ON}
            </Box>
          </Box>

          <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
            <Box
              width={isMobile ? "90%" : "276px"}
              marginLeft={isMobile ? "34px" : 0}
              position="relative"
            >
              <StyledButtonBase
                placeholder="dd/mm/yy"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  minHeight: "30px",
                  cursor: RECURRING_ENDS.ON === endType ? "pointer" : "default",
                }}
                disabled={RECURRING_ENDS.ON !== endType}
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                <Typography
                  style={{
                    height: "32px",
                    lineHeight: "32px",
                    marginBottom: "0px",
                    color:
                      RECURRING_ENDS.ON === endType ? Colors.TurquoiseBlue : Colors.LightBlueGrey,
                    width: "100%",
                    overflow: "hidden",
                    textAlign: "left",
                  }}
                >
                  {formatTime(String(recurringEndData.endDate), "ddd, D MMM YYYY")}
                </Typography>
                <Box>
                  <img src={CalendarIcon} alt="Calendar icon" height={24} width={24} />
                </Box>
              </StyledButtonBase>
              <RecurringDatePicker
                showDatePicker={showDatePicker}
                handleRecurringEndDateSelect={handleRecurringEndDateSelect}
                selectedDate={recurringEndData.endDate}
                getMinValidDate={getMinValidDate}
                oneYearFromNow={oneYearFromNow}
              />
              <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>

      {/* Ends After */}
      <Box
        fontSize={"14px"}
        fontWeight={400}
        display="flex"
        justifyContent="start"
        alignItems="start"
        gridColumnGap={Spacing.S16}
        flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
        gridRowGap={isMobile ? "8px" : 0}
      >
        <Box display="flex" alignItems="center" width="172px" gridColumnGap={Spacing.S4}>
          <RadioButton
            selected={RECURRING_ENDS.AFTER === endType}
            onSelected={setOccurrence}
            style={{ width: "auto", height: "auto" }}
          />
          <Box color={Colors.Dusk} className="cursor-pointer" onClick={setOccurrence}>
            {RECURRING_ENDS.AFTER}
          </Box>
        </Box>

        <Box
          width={isMobile ? "90%" : "276px"}
          marginLeft={isMobile ? "34px" : 0}
          position="relative"
          marginTop={isMobile ? "0" : "-10px"}
        >
          <Box
            color={RECURRING_ENDS.AFTER !== endType ? Colors.LightBlueGrey : Colors.TurquoiseBlue}
            display={Display.Flex}
            alignItems={AlignItems.center}
            position="relative"
          >
            <Box style={{ flex: 1 }}>
              <Dropdown
                options={occurrenceOptions}
                onSelectedOption={updateNumberOfOccurrence}
                selectedOption={occurrenceOptions.find(
                  (option) => option.value === recurringEndData.occurrence
                )}
                paddingTop={"0px"}
                paddingBottom={"8px"}
                dropDownMarginButton={0}
                isDisabled={RECURRING_ENDS.AFTER !== endType}
              />
            </Box>
            <Box
              fontSize={18}
              fontWeight={500}
              flex={1}
              position="absolute"
              right="30px"
              zIndex={-5}
            >
              {recurringEndData.occurrence < 2 ? "occurrence" : "occurrences"}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecurringEndsOn;
