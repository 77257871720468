import { Box } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React, { useState, useEffect } from "react";
import { LOCATION_NOTES_LIMIT, environment } from "../../constants/common";
import { LOCATION_NOTES_PLACEHOLDER } from "../../constants/placeholder";
import { TrackingEvents } from "../../constants/tracking";
import { withVersion } from "../../helpers/tracking";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { Address, AddressOption, AddressOptions, LocationType } from "../../models";
import { trackEvent } from "../../services/segment/track.service";
import {
  createUserAddress,
  createUserAddressOnBehalfOfClientByPro,
  updateUserAddress,
} from "../../services/users/users.service";
import { getValue } from "../../utils/object";
import { getPostalPlaceholder, getPostalTitle } from "../../services/address/address.service";
import { useAlertStore } from "../../stores/alert";
import { DEFAULT_COUNTRY } from "../../utils/country";
import AddressAutocomplete, { SelectedAddress } from "../AddressAutocomplete";
import Button, { ButtonSize, ButtonType } from "../Button";
import Dialog from "../Dialog";
import Dropdown, { DropdownOption } from "../Dropdown";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import TextField from "../TextField";
import { SERVICE_COUNTRIES } from "../../constants/countries";
import { useUserStore } from "../../stores/user";
import { useAddressOptions } from "../../hooks/address/addressOptions.hooks";
import { Colors } from "../../constants/colors";
import SelectLocationMap from "./SelectLocationMap";

interface Props {
  existingAddress?: Address | null;
  open: boolean;
  addedByPro?: boolean;
  userId?: string | null;
  onClose: () => unknown;
  onSaved: (address: Address) => unknown;
  showAddressIdentifiers?: boolean;
  additionalLoading?: boolean;
  onEditRecurringAddress?: (data?: any) => unknown;
  isRecurringAddress?: boolean;
}

export default function AddressModal({
  existingAddress,
  open,
  onClose,
  onSaved,
  userId,
  addedByPro = false,
  showAddressIdentifiers = false,
  additionalLoading = false,
  isRecurringAddress = false,
  onEditRecurringAddress,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const { setSuccessMessage } = useAlertStore();

  const [isMapSelected, setIsMapSelected] = useState(false);
  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState<string | null | undefined>("");
  const [suburb, setSuburb] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [state, setState] = useState<string | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [instructions, setInstructions] = useState("");
  const [parkingOption, setParkingOption] = useState<DropdownOption | null>(null);
  const [stairOption, setStairOption] = useState<DropdownOption | null>(null);
  const [petOption, setPetOption] = useState<DropdownOption | null>(null);
  const [tableOption, setTableOption] = useState<DropdownOption | null>(null);
  const [postalTitle, setPostalTitle] = useState("Postcode");
  const [postalPlaceholder, setPostalPlaceholder] = useState("0000");

  const { addressOptions } = useAddressOptions(countryCode || "AU");
  const [saving, setSaving] = useState(false);

  const [typeOptions, setTypeOptions] = useState<Array<DropdownOption>>([]);
  const [selectedTypeOption, setSelectedTypeOption] = useState<DropdownOption | null>(null);

  const { setErrorMessage } = useAlertStore();
  const [disableOtherFields, setDisableOtherFields] = useState(false);

  useEffect(() => {
    if (existingAddress) {
      const existingCountryCode = getValue(existingAddress, "selectedCountry.code", "AU");
      setAddress(existingAddress.address);
      setFullAddress(existingAddress.fullAddress || existingAddress.address);
      setSuburb(existingAddress.suburb);
      setPostcode(existingAddress.postcode);
      setCountry(existingAddress.country);
      setInstructions(existingAddress.instructions);
      setState(existingAddress.state || null);
      setLatitude(existingAddress.latitude);
      setLongitude(existingAddress.longitude);
      setCountryCode(existingCountryCode);
      setPostalTitle(getPostalTitle(existingCountryCode));
      setPostalPlaceholder(getPostalPlaceholder(existingCountryCode));
    } else {
      resetFields();

      const existingCountryCode = getValue(user, "country", DEFAULT_COUNTRY);
      setPostalTitle(getPostalTitle(existingCountryCode));
      setPostalPlaceholder(getPostalPlaceholder(existingCountryCode));
    }
  }, [existingAddress]);

  useEffect(() => {
    if (existingAddress) initSelectedAddressOptions({ existingAddress });
  }, [addressOptions, existingAddress]);

  useEffect(() => {
    setPostalCodeDetails();
    axios
      .get("/locationTypes?global=yes")
      .then((response) => {
        const types = response.data as LocationType[];

        setTypeOptions(
          types.map((type) => {
            return {
              value: type.slug,
              title: type.label,
              id: type.id,
              countryCode: type.countryCode,
              helperText: type.helperText,
            };
          }),
        );
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (existingAddress && typeOptions) {
      setSelectedTypeOption(
        typeOptions.find((option) => option.value === existingAddress.type) || null,
      );
    }
  }, [existingAddress, typeOptions]);

  const findAddressOption = (options: AddressOption[], id: number | null) => {
    if (!options || options.length <= 0) return null;
    return options.find(({ id: optionId }) => id === optionId) || null;
  };

  const initSelectedAddressOptions = ({ existingAddress }: { existingAddress: Address | null }) => {
    if (!addressOptions || !existingAddress) return;

    const { parkingOptionId, stairOptionId, petOptionId, tableOptionId } = existingAddress;
    const selectedParking = findAddressOption(addressOptions.parkingOptions, parkingOptionId);
    const selectedStairs = findAddressOption(addressOptions.stairOptions, stairOptionId);
    const selectedPetOption = findAddressOption(addressOptions.petOptions, petOptionId);
    const selectedTableOption = findAddressOption(addressOptions.tableOptions, tableOptionId);

    setParkingOption(selectedParking);
    setStairOption(selectedStairs);
    setPetOption(selectedPetOption);
    setTableOption(selectedTableOption);
  };

  const filterOptionCode = (options: any, id: any) => {
    return options.filter((option: DropdownOption) => {
      if (option.locationTypeId === null || option.locationTypeId === id) {
        return option;
      }
    });
  };

  const getFilteredOptions = (addressOptions: AddressOptions, id: any) => {
    const filteredPetOption = filterOptionCode(addressOptions.petOptions, id);
    const filteredParkingOption = filterOptionCode(addressOptions.parkingOptions, id);
    const filteredStairOption = filterOptionCode(addressOptions.stairOptions, id);
    const filteredTableOption = filterOptionCode(addressOptions.tableOptions, id);

    return {
      filteredPetOption: filteredPetOption,
      filteredParkingOption: filteredParkingOption,
      filteredStairOption: filteredStairOption,
      filteredTableOption: filteredTableOption,
    };
  };

  const setPostalCodeDetails = async () => {
    const countryCode = existingAddress
      ? getValue(existingAddress, "selectedCountry.code", DEFAULT_COUNTRY)
      : getValue(user, "country", DEFAULT_COUNTRY);

    const postalTitle = getPostalTitle(countryCode);
    const postalPlaceholder = getPostalPlaceholder(countryCode);

    setPostalTitle(postalTitle);
    setPostalPlaceholder(postalPlaceholder);
  };

  const resetFields = () => {
    setAddress("");
    setFullAddress("");
    setSelectedTypeOption(null);
    setSuburb("");
    setPostcode("");
    setCountry("");
    setCountryCode("");
    setInstructions("");
    setState(null);
    setLatitude(null);
    setLongitude(null);
    setParkingOption(null);
    setStairOption(null);
    setPetOption(null);
    setTableOption(null);
  };

  const handleAddressSelected = ({ address }: { address: SelectedAddress }) => {
    const countryChanged = hasCountryChanged(address.countryCode, countryCode);
    setAddress(address.address);
    setSuburb(address.suburb);
    setPostcode(address.postcode);
    setState(address.state || null);
    setLatitude(address.lat || null);
    setLongitude(address.long || null);
    setCountry(address.country);
    setCountryCode(address.countryCode || "AU");
    setFullAddress(address.fullAddress);
    setDisableOtherFields(true);
    if (countryChanged) {
      setSelectedTypeOption(null);
      setPostalTitle(getPostalTitle(address.countryCode || countryCode));
      setPostalPlaceholder(getPostalPlaceholder(address.countryCode || countryCode));
    }
  };

  const getFilteredLocationTypes = (code: string) => {
    const countryCode = SERVICE_COUNTRIES.includes(code) ? code : "AU";
    return typeOptions.filter((l: any) => l.countryCode === countryCode);
  };

  const hasCountryChanged = (currentCountryCode: any, previousCountryCode: any) =>
    currentCountryCode !== previousCountryCode;

  const locationTypes = getFilteredLocationTypes(countryCode || "AU");

  const verifyFields = () => {
    if (isNil(selectedTypeOption)) {
      return { isValid: false, error: "Please select the type of address" };
    }

    if (isEmpty(address)) {
      return { isValid: false, error: "Address cannot be empty" };
    }

    if (showAddressIdentifiers) {
      if (isEmpty(suburb)) {
        return { isValid: false, error: "Suburb cannot be empty" };
      }

      if (isEmpty(postcode)) {
        return { isValid: false, error: `${postalTitle} cannot be empty` };
      }

      if (isEmpty(country)) {
        return { isValid: false, error: "Country cannot be empty" };
      }
    }

    if (isNil(parkingOption)) {
      return { isValid: false, error: "Please let us know if there is parking" };
    }

    if (isNil(stairOption)) {
      return { isValid: false, error: "Please let us know if you have stairs" };
    }

    if (isNil(petOption)) {
      return { isValid: false, error: "Please let us know if you have pets" };
    }

    // if (isNil(tableOption)) {
    //   return { isValid: false, error: "Please let us know if you have massage table" };
    // }

    return { isValid: true, error: null };
  };

  const handleSaveSuccess = (addressData: any, isNewAddress = true) => {
    const formatted = { ...addressData, countryCode };
    setSaving(false);
    onSaved(formatted);
    trackEvent(TrackingEvents.AddressSaved, withVersion());
    resetFields();
    setSuccessMessage(`Address successfully ${isNewAddress ? "added" : "updated"}.`);
  };

  const handleSaveFailed = () => {
    setSaving(false);
  };

  const handleSaveAddress = () => {
    const { isValid, error } = verifyFields();
    if (!isValid) {
      setErrorMessage(error || "Failed to verify address");
      return;
    }

    if (!selectedTypeOption || !parkingOption || !stairOption || !petOption) return;

    const data = {
      accessToken,
      type: selectedTypeOption.value,
      address,
      fullAddress,
      suburb,
      postcode,
      country,
      countryCode,
      parkingOptionId: parkingOption.id,
      stairOptionId: stairOption.id,
      petOptionId: petOption.id,
      // tableOptionId: tableOption?.id,
      instructions,
      state,
      latitude,
      longitude,
    };

    if (existingAddress) {
      // handle edit from recurring modal
      if (isRecurringAddress && !!onEditRecurringAddress) {
        onEditRecurringAddress(data);
        return;
      }

      setSaving(true);
      if (!addedByPro) {
        updateUserAddress({ addressId: existingAddress.id, data })
          .then((addressData) => handleSaveSuccess(addressData, false))
          .catch((error) => handleSaveFailed());
      }
    }
    // New address
    else {
      setSaving(true);
      if (!addedByPro) {
        createUserAddress({ data })
          .then((addressData) => handleSaveSuccess(addressData))
          .catch((error) => handleSaveFailed());
        return;
      }

      createUserAddressOnBehalfOfClientByPro({ data: { ...data, userId } })
        .then(({ address }) => handleSaveSuccess(address))
        .catch((error) => handleSaveFailed());
    }
  };

  const filteredAddressOptions = getFilteredOptions(addressOptions, selectedTypeOption?.id);

  const onMapSelected = () => {
    setIsMapSelected(true);
  };

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      {isMapSelected ? (
        <>
          <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
            <Box paddingLeft="40px" paddingRight="40px" paddingBottom="40px" paddingTop="32px">
              <Box
                fontFamily="Museo"
                fontSize="22px"
                fontWeight="600"
                color={Colors.Indigo}
                textAlign="center"
                mb={2}
              >
                Set your location
              </Box>
              <SelectLocationMap
                setIsMapSelected={setIsMapSelected}
                onSelectedAddress={(address) => handleAddressSelected({ address })}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
            <ModalHeader
              title={existingAddress ? "Edit address" : "Add new address"}
              onClose={onClose}
            />
            <Box paddingLeft="40px" paddingRight="40px" paddingBottom="40px">
              <AddressAutocomplete
                locationType={selectedTypeOption?.value}
                value={fullAddress || ""}
                onChange={(text) => setFullAddress(text)}
                onSelectedAddress={(address) => handleAddressSelected({ address })}
                onMapSelected={onMapSelected}
              />
              {showAddressIdentifiers && (
                <>
                  <TextField
                    title="Suburb"
                    placeholder="Forest Lodge"
                    value={suburb}
                    disabled={disableOtherFields}
                    onChange={(text) => setSuburb(text)}
                    useInputTextColor={true}
                  />
                  <TextField
                    title={postalTitle}
                    placeholder={postalPlaceholder}
                    value={postcode}
                    onChange={(text) => setPostcode(text)}
                    useInputTextColor={true}
                    disabled={disableOtherFields}
                  />
                  <TextField
                    title="Country"
                    placeholder="Australia"
                    value={country}
                    disabled={disableOtherFields}
                    useInputTextColor={true}
                    onChange={(text) => setCountry(text)}
                  />
                </>
              )}

              <Dropdown
                title="Location type"
                options={locationTypes}
                selectedOption={selectedTypeOption}
                onSelectedOption={(option) => {
                  setSelectedTypeOption(option);
                }}
              />

              <Dropdown
                title="Parking"
                options={filteredAddressOptions.filteredParkingOption}
                selectedOption={parkingOption}
                onSelectedOption={(option: DropdownOption) => {
                  setParkingOption(option);
                }}
              />

              <Dropdown
                title="Do you have stairs in this location?"
                options={filteredAddressOptions.filteredStairOption}
                selectedOption={stairOption}
                onSelectedOption={(option: DropdownOption) => {
                  setStairOption(option);
                }}
              />

              <Dropdown
                title="Do you have pets in this location?"
                options={filteredAddressOptions.filteredPetOption}
                selectedOption={petOption}
                onSelectedOption={(option: DropdownOption) => {
                  setPetOption(option);
                }}
              />

              {/* <Dropdown
                title="Do you have your own professional massage table in this location?"
                options={filteredAddressOptions.filteredTableOption}
                selectedOption={tableOption}
                onSelectedOption={(option: DropdownOption) => {
                  setTableOption(option);
                }}
              /> */}

              <TextField
                title="Location notes"
                placeholder={LOCATION_NOTES_PLACEHOLDER}
                value={instructions}
                onChange={(text) => setInstructions(text)}
                multiline
                maxLength={LOCATION_NOTES_LIMIT}
              />
            </Box>
          </Box>

          <ModalFooter>
            <Button
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              width="130px"
              onClick={onClose}
            />
            <Box width="14.5px" />
            <Button
              size={ButtonSize.small}
              title="Save"
              width="130px"
              loading={saving || additionalLoading}
              onClick={handleSaveAddress}
            />
          </ModalFooter>
        </>
      )}
    </Dialog>
  );
}
