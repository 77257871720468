import { Box } from "@material-ui/core";
import axios from "axios";
import braintree from "braintree-web";
import { isEmpty, isNil } from "lodash";
import momentTZ from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { post } from "../../api/client";
import EmailVerificationModal from "../../components/Account/EmailVerificationModal";
import ActionModal from "../../components/ActionModal";
import SummarySection from "../../components/Bookings/SummarySection";
import { SendToSomeoneInterface } from "../../components/Bookings/V2/Corporate/CorporateFlow";
import TotalDueAccordion from "../../components/Bookings/V2/TotalDueAccordion/TotalDueAccordion";
import Button, { ButtonType } from "../../components/Button";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import PaymentFrame from "../../components/PaymentFrame/PaymentFrame";
import ReviewAndBookNoteForTherapistItem from "../../components/ReviewAndBook/BookingNoteForTherapistItem";
import ConfirmCheckoutModal from "../../components/ReviewAndBook/ConfirmBookingModal";
import { ReviewAndBookPrefTherapist } from "../../components/ReviewAndBook/PreferredTherapistView";
import ReviewAndBookDateAndTimeModal from "../../components/ReviewAndBook/ReviewAndBookDateAndTimeModal";
import ReviewAndBookDetailsItem, {
  ReviewAndBookDetailsItemType,
} from "../../components/ReviewAndBook/ReviewAndBookDetailsItem";
import ReviewAndBookTimeDetail from "../../components/ReviewAndBook/ReviewAndBookTimeDetail";
import ReviewAndBookTreatmentDetail from "../../components/ReviewAndBook/ReviewAndBookTreatmentDetail/ReviewAndBookTreatmentDetail";
import SendQuotationToModal from "../../components/ReviewAndBook/SendQuotationToModal";
import TextField from "../../components/TextField";
import TreatmentDescription from "../../components/Treatment/TreatmentDescription/TreatmentDescriptionV2";
import Wrapper from "../../components/Wrapper";
import { Display, FlexDirection, Spacing } from "../../components/v2/Styled/enum";
import {
  CORPORATE_CLIENTS_NUM,
  GenderPreference,
  MassageFor,
  STEP_PROGRESS,
  TOTAL_DUE_INFO,
} from "../../constants/booking";
import { CLIENT_EVENT_TYPE } from "../../constants/clientEvent";
import { Colors } from "../../constants/colors";
import { environment } from "../../constants/common";
import { Paths } from "../../constants/paths";
import { PaymentType } from "../../constants/payment";
import { UTM_SOURCES } from "../../constants/tracking";
import { formatTreatmentAddOnData } from "../../helpers/booking";
import { parseApiError } from "../../helpers/error";
import { minutesToTime, setMomentTime } from "../../helpers/time";
import isValidEmail from "../../helpers/validation";
import useAuth from "../../hooks/auth.hooks";
import { useUpcomingBookings } from "../../hooks/booking/booking.hooks";
import { useBookingQuotation, useGetQuoteAsEmail } from "../../hooks/booking/corporate.hooks";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import { useUserRole } from "../../hooks/user.hooks";
import { Coupon, PaymentMethod, ServiceRatePrice } from "../../models";
import { makeCorporateBooking } from "../../services/bookings/bookings.service";
import clientEventService from "../../services/clientEvent";
import dataLayerService from "../../services/gtm/dataLayer.service";
import { trackEvent } from "../../services/segment/track.service";
import { fetchAddresses } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import {
  getAddOnDetailById,
  getAnswersApiValue,
  getBookingPrice,
  getProductsForSegmentTracking,
  getServicesRates,
  getSubtotal,
  getTotal,
  getTreatmentDeliveryMethod,
  getTreatmentDetailByTreatmentId,
  getTreatmentDetails,
  getTreatmentId,
  isBackToBackMassageType,
  isCorporateTreatmentType,
  isCoupleMassageType,
  isHairAndMakeupBooking,
  isHairColouringBooking,
  isMassageType,
  preselectLastPaymentMethod,
  setCorporateBookingWithQuotation,
  useBookingStore,
  useDateTimeStore,
  usePaymentStore,
  useRecipientDetailsStore,
  useServicesStore,
} from "../../stores/booking";
import { useBraintreeStore } from "../../stores/braintree";
import { useUserStore } from "../../stores/user";
import { convertDatetoTimezoneMoment } from "../../utils/date.util";
import { checkIfEmpty, getValue } from "../../utils/object";

interface PaymentSectionProps {
  paymentMethod: PaymentMethod | null;
  onPaymentMethodClicked: () => unknown;
  couponCode: string;
  isValidatingCoupon?: boolean;
  onCouponCodeChange: (code: string) => unknown;
  onCouponCodeActionClicked: () => unknown;
  appliedCouponCode: string | null;
  showPaymentSplit?: boolean;
}

function PaymentSection({
  paymentMethod,
  onPaymentMethodClicked,
  couponCode,
  isValidatingCoupon = false,
  onCouponCodeChange,
  onCouponCodeActionClicked,
  appliedCouponCode,
  showPaymentSplit = false,
}: PaymentSectionProps) {
  const { paymentType, setPaymentType, bookingPrice } = usePaymentStore();

  useEffect(() => {
    getBookingPrice();
  }, [paymentType]);

  const applied = !isEmpty(appliedCouponCode);

  const checkIsCouponApplied = () => {
    // is validating coupon
    // validate complete + has applied coupon + bookingPrice has no coupon discount = fetching price with coupon
    const isApplying =
      isValidatingCoupon || (!isValidatingCoupon && appliedCouponCode && !bookingPrice?.discount);
    return isApplying || false;
  };

  const isCouponLoading = checkIsCouponApplied();

  return (
    <>
      <PaymentFrame
        title="Payment"
        selectedType={paymentType}
        onSelectedType={(type) => setPaymentType(type)}
        allowSkipPayment
        showPaymentSplit={showPaymentSplit}
      />
      <Box height="16px" />
      <Box padding={"0px 2px"} style={{ background: Colors.White }}>
        <TextField
          title="Have a coupon?"
          placeholder="Enter coupon code"
          isActionLoading={isCouponLoading}
          value={couponCode}
          onChange={onCouponCodeChange}
          actionTitle={applied ? "Remove" : "Apply"}
          actionDestructive={applied}
          onActionClicked={onCouponCodeActionClicked}
          disabled={applied}
        />
      </Box>
    </>
  );
}

function BookingDetailsSection() {
  const history = useHistory();

  const {
    setRecipient,
    sessionType,
    massageLength,
    address,
    preferredTherapists,
    setPreferredTherapists,
    serviceId,
    totalEventDuration,
    frequency,
  } = useBookingStore();

  const { massageFor } = useRecipientDetailsStore();
  const isMassage = isMassageType();
  const isHairAndMakeup = isHairAndMakeupBooking();
  const isCorporateType = isCorporateTreatmentType();
  const { selectedDay, earliestStartInMinutes, latestStartInMinutes } = useDateTimeStore();
  const [showRemoveProModal, setShowRemoveProModal] = React.useState(false);
  const [treatmentForDetail, setTreatmentForDetail] = useState<ServiceRatePrice>();
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const preferredTherapist = !checkIfEmpty(preferredTherapists) ? preferredTherapists : null;

  const handleRemoveTherapist = () => {
    setPreferredTherapists([]);
    setShowRemoveProModal(false);
    history.replace(Paths.ReviewAndBook);
  };

  const checkServiceNotSelected = () => {
    const isNotSelected =
      (isMassage && isNil(massageLength) && !isCorporateType) ||
      (isNil(sessionType) && isMassage) ||
      (!!isCorporateType && !totalEventDuration?.massageDuration);
    return isNotSelected;
  };

  const handleTreatmentAction = () => {
    history.push(Paths.ServiceDetails, {
      next: Paths.ReviewAndBook,
      editing: true,
    });
  };

  const isServiceSelected = !checkServiceNotSelected();

  const handleTreatmentClicked = (td: any) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const treatment = getTreatmentDetailByTreatmentId(treatmentId);
    setTreatmentForDetail(treatment);
    setIsDescriptionVisible(true);
  };

  const handleAddOnClick = ({ td, addOnId }: { td: any; addOnId: any }) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const addOn = getAddOnDetailById({ treatmentId, id: addOnId });
    setTreatmentForDetail(addOn);
    setIsDescriptionVisible(true);
  };

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
      <ReviewAndBookDetailsItem
        type={ReviewAndBookDetailsItemType.location}
        onChangeClicked={() =>
          history.push(Paths.SavedLocations, {
            next: Paths.ReviewAndBook,
            editing: true,
          })
        }
        isNewAction={isNil(address)}
      />

      <ReviewAndBookTreatmentDetail
        isServiceSelected={isServiceSelected}
        onChangeClicked={handleTreatmentAction}
        onTreatmentClicked={handleTreatmentClicked}
        onAddOnClicked={handleAddOnClick} // for future, if addons opens separate modal
      />

      <ReviewAndBookDetailsItem
        type={ReviewAndBookDetailsItemType.recipient}
        onChangeClicked={() =>
          history.push(Paths.SavedRecipients, {
            next: Paths.ReviewAndBook,
            editing: true,
          })
        }
        isNewAction={isNil(massageFor)}
      />

      <ReviewAndBookTimeDetail
        onChangeClicked={() =>
          history.push(Paths.DateAndTime, {
            next: Paths.ReviewAndBook,
            editing: true,
          })
        }
        isNewAction={isNil(latestStartInMinutes)}
        showLabel={!isCorporateType}
      />

      <ReviewAndBookPrefTherapist
        profile={preferredTherapist}
        onChange={() =>
          history.push(Paths.PreferredProviders, {
            next: Paths.ReviewAndBook,
            isEditing: true,
            editing: true, //  handles routing when tab closed
          })
        }
        serviceId={serviceId}
      />

      <ReviewAndBookDetailsItem
        type={ReviewAndBookDetailsItemType.frequency}
        onChangeClicked={() =>
          history.push(Paths.BookingFrequency, {
            next: Paths.ReviewAndBook,
            editing: true,
          })
        }
        isNewAction={isNil(frequency)}
      />

      <ReviewAndBookNoteForTherapistItem
        onRecipientUpdated={(recipient) => {
          setRecipient(recipient);
        }}
      />
      <ActionModal
        open={showRemoveProModal}
        title="Confirm provider removal"
        description="Your booking request will be open to all available providers in your area - and will be more likely to get confirmed."
        onCancel={() => setShowRemoveProModal(false)}
        onConfirm={() => handleRemoveTherapist()}
      />
      <TreatmentDescription
        isOpen={isDescriptionVisible}
        onClose={() => {
          setIsDescriptionVisible(false);
        }}
        title={treatmentForDetail?.label || ""}
        description={treatmentForDetail?.fullDescription || ""}
      />
    </Box>
  );
}

export default function ReviewAndBook(): JSX.Element {
  const history = useHistory();
  const { search, state } = useLocation();
  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const { isAdminLoggedInAsClient } = useAuth();
  const quotationReference = new URLSearchParams(search).get("quotationReference");

  const preFetchPaymentMethod = getValue(state, "preFetchPaymentMethod", true);

  const needToLogin = quotationReference && !accessToken; // user need to login if quotation is present

  const { isLoading: isQuotationLoading, data: bookingQuotation } =
    useBookingQuotation(quotationReference);

  const {
    massageLength,
    massageLength2,
    massageType1,
    massageType2,
    sessionType,
    address,
    genderPreference1,
    genderPreference2,
    genderFallback,
    couplesFallback,
    couplesFallbackGenderPreference,
    recipient,
    preferredTherapists,
    serviceId,
    specialInstructions,
    contraindications,
    preferences,
    treatmentDetails,
    numOfRecipients,
    massageTreatmentDetails,
    currencySymbol,
    setCurrency,
    sourceDetails,
    resetSourceDetails,
    genderPreference,

    // for corporate booking
    treatmentId,
    numberOfPerson,
    durationPerPerson,
    numberOfPros,
    eventType,
    noteForRecipients,
    eventName,
    totalEventDuration,
    paymentSplit,

    // frequency
    frequency,
    recurring,
    recuringEndOn,
    isSameProvider,

    resetCorprateFields,
  } = useBookingStore();
  const isMassage = isMassageType();
  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCoupleMassage = isCoupleMassageType(sessionType);
  const isCorporateType = isCorporateTreatmentType();
  const {
    selectedDay,
    earliestStartInMinutes,
    latestStartInMinutes,
    setLatestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    toBeReadyByInMinutes,
    setToBeReadyByInMinutes,
    selectedDayRange,
    backupSelectedDayRange,
    setSelectedDayRange,
    setBackupSelectedDayRange,
  } = useDateTimeStore();

  const {
    couponCode: appliedCouponCode,
    setCouponCode: setAppliedCouponCode,
    bookingPrice,
    paymentMethod,
    setPaymentMethod,
    paymentType,
    firstTimeUserCardRecaptchaSuccess,
    skipPayment,
  } = usePaymentStore();

  const { invalidateUpcomingBookings } = useUpcomingBookings({});

  const [couponCode, setCouponCode] = React.useState("");
  const [openVerificationModal, setOpenVerificationModal] = React.useState(false);

  const { massageFor } = useRecipientDetailsStore();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { rates } = useServicesStore();

  const { user, fetchMe } = useUserStore();
  const { isAdmin, isHomeCareClient, isB2BUser } = useUserRole();
  const [checkoutModalOpen, setCheckoutModalOpen] = React.useState(false);

  const [saving, setSaving] = React.useState(false);

  const [isValidatingCoupon, setValidatingCoupon] = useState(false);
  const [canMakeApplePayment, setCanMakeApplePayment] = React.useState(false);
  const [applePayInstance, setApplePayInstance] = React.useState<any>(null);
  const [initingApplePay, setInitingApplePay] = React.useState(false);
  const [showQuotationToModal, setShowQuotationToModal] = useState(false);
  const [showTotalDueUpToModal, setShowTotalDueUpToModal] = useState(false);
  const [showDateAndTimeModal, setShowDateAndTimeModal] = useState(false);

  const { isLoading: isSendingQuote, mutate: getQuote } = useGetQuoteAsEmail({
    onSuccess: () => {
      setSuccessMessage("Quote sent. Please check your inbox and spam folder.");
      setShowQuotationToModal(false);
    },
    onError: () => setErrorMessage("Something went wrong"),
  });

  // update selected date range to single date if session type becomes couple or corporate

  useEffect(() => {
    if (isCorporateType || isCoupleMassage) {
      setSelectedDayRange(selectedDayRange?.from, selectedDayRange?.from);
      if (earliestStartInMinutes) {
        setLatestStartInMinutes(earliestStartInMinutes);
      }
      if (backupSelectedDay) {
        setBackupSelectedDayRange(backupSelectedDayRange.from, backupSelectedDayRange.from);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType]);

  // const [selectedPaymentSection, setSelectedPaymentSection] =
  //   React.useState<PaymentFrameSection>(PaymentFrameSection.Cards);

  const { hostedFieldsInstance } = useBraintreeStore();

  const { data: upcomingBookingsData } = useSWR(
    `/api/v2/bookings/upcoming?accessToken=${accessToken}`
  );

  const handleSendEmailAsQuoteClicked = () => {
    setShowQuotationToModal(true);
  };

  const handleShowTotalDueUpToModalClose = () => {
    setShowTotalDueUpToModal(false);
  };

  const handleConfirmClicked = (data: SendToSomeoneInterface) => {
    const payload = makeCorporatePayload(true, data);
    const validPayload = validateCorporateFields(payload, true);
    if (!validPayload) return;

    getQuote({ data: validPayload });
  };

  const CheckIfBookingAlreadyExists = () => {
    const shouldShowCheckout =
      upcomingBookingsData && upcomingBookingsData.data && upcomingBookingsData.data.length > 0;
    if (shouldShowCheckout) {
      setCheckoutModalOpen(true);
    } else {
      makeBooking();
    }
  };

  React.useEffect(() => {
    if (!isQuotationLoading && bookingQuotation) {
      setCorporateBookingWithQuotation(bookingQuotation);
    }
  }, [bookingQuotation]);

  React.useEffect(() => {
    initApplePay();
  }, []);

  const analyticsValues = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return {
      subtotal: getSubtotal(),
      total: getTotal(),
      currency: "AUD",
      affiliation: "web",
      product_id: getTreatmentId(),
      session_type: isMassage ? sessionType : undefined,
      recipient_role: recipient?.relationship,
      recipient_gender: recipient?.gender,
      peak_hour: bookingPrice?.lateNightSurcharge,
      location_type: address?.type,
      location_parking: address?.parking,
      location_stairs: address?.stairs,
      version: 2,
      products: getProductsForSegmentTracking(),
      // @ts-ignore
      email: user?.email,
      // @ts-ignore
      phone_number: user?.mobile,
      firstName: user?.firstName,
      lastName: user?.lastName,
      country: address?.country,
      region: address?.state,
      street: address?.suburb,
      city: address?.suburb,
      postalCode: address?.postcode,
    };
  };

  React.useEffect(() => {
    setTimeout(() => {
      const values = analyticsValues();

      const { phone_number, ...valuesWithoutPhoneNumber } = values;

      trackEvent("Checkout Started", values);

      clientEventService.fireEvent({ type: CLIENT_EVENT_TYPE.REVIEW_AND_BOOK_VIEWED });
    }, 2000);
  }, []);

  const initApplePay = () => {
    if (!window.ApplePaySession) {
      return;
    }

    setInitingApplePay(true);

    let path = "/paymentmethods/clientToken";

    axios
      .get(path, {
        params: {
          accessToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.clientToken) {
          let token = response.data.clientToken;

          braintree.client.create(
            {
              authorization: token,
            },
            (clientErr, clientInstance) => {
              braintree.applePay
                .create({
                  client: clientInstance,
                })
                .then((applePayInstance) => {
                  setApplePayInstance(applePayInstance);

                  console.debug("merchant id: ", applePayInstance.merchantIdentifier);

                  window.ApplePaySession.canMakePaymentsWithActiveCard(
                    applePayInstance.merchantIdentifier
                  )
                    .then((canMakePaymentsWithActiveCard: boolean) => {
                      console.debug(
                        "canMakePaymentsWithActiveCard: ",
                        canMakePaymentsWithActiveCard
                      );

                      setCanMakeApplePayment(canMakePaymentsWithActiveCard);

                      setInitingApplePay(false);
                    })
                    .catch((error: any) => {
                      setInitingApplePay(false);

                      console.debug("canMakePaymentsWithActiveCard error", error);
                    });
                });
            }
          );
        }
      });
  };

  const checkIfTotalBookingPriceZero = () => {
    const totalBookingPrice: number | undefined = getTotal();

    return totalBookingPrice === 0;
  };

  const validateCorporateFields = (payload: any, sendEmailAsQuote = false) => {
    if (!payload.treatmentId) {
      setErrorMessage("Please select a treatment");
      return false;
    }

    const personNum = payload.numberOfPerson;
    if (!personNum) {
      setErrorMessage("Please enter number of people");
      return false;
    }
    if (personNum < CORPORATE_CLIENTS_NUM.min || personNum > CORPORATE_CLIENTS_NUM.max) {
      setErrorMessage(
        `Number of clients must be in range ${CORPORATE_CLIENTS_NUM.min} to ${CORPORATE_CLIENTS_NUM.max}`
      );
      return false;
    }

    if (!payload.durationPerPerson) {
      setErrorMessage("Please select duration per person");
      return false;
    }
    if (!payload.numberOfPros) {
      setErrorMessage("Please select number of provider");
      return false;
    }
    if (!payload.eventType) {
      setErrorMessage("Please enter event type");
      return false;
    }
    if (sendEmailAsQuote) {
      if (!payload.sendToMe) {
        if (!payload.recipientEmail) {
          setErrorMessage("Please enter email to send quotation");
          return false;
        }
        if (!isValidEmail(payload.recipientEmail)) {
          setErrorMessage("Please enter a valid email");
          return false;
        }
        if (!payload.recipientFirstName) {
          setErrorMessage("Please enter first name");
          return false;
        }
        if (!payload.recipientLastName) {
          setErrorMessage("Please enter the last name");
          return false;
        }
      }
    }
    return payload;
  };

  const verifyEmail = () => {
    setErrorMessage(null);
    setOpenVerificationModal(true);
  };

  const onEmailVerified = () => {
    fetchMe();
    setOpenVerificationModal(false);
  };

  const makeBooking = () => {
    // if (!isAdminLoggedInAsClient && !user?.emailVerifiedAt) {
    //   setErrorMessage(<VerifyEmailAlert onClick={verifyEmail} />);
    //   return;
    // }

    // Credit card - first time user
    const isTotalBookingPriceZero = checkIfTotalBookingPriceZero();

    // if total booking price is zero no payment is required
    // or if admin is making booking on behalf of client with skip payment selected
    if (isTotalBookingPriceZero || (isAdminLoggedInAsClient && skipPayment)) {
      actuallyMakeBooking();
      return;
    }

    if (paymentType === PaymentType.card) {
      if (hostedFieldsInstance && !paymentMethod && !isTotalBookingPriceZero) {
        if (!firstTimeUserCardRecaptchaSuccess) {
          setErrorMessage("Please complete reCAPTCHA");
          return;
        }

        setSaving(true);

        // console.debug("instance: ", hostedFieldsInstance);
        hostedFieldsInstance
          .tokenize()
          .then((payload) => {
            // setPayload(payload);

            // console.debug("payload: ", payload);

            const { nonce, details } = payload;
            const { lastFour, expirationMonth, expirationYear, cardType } = details;

            axios
              .post("/paymentmethods", {
                accessToken,
                nonce,
                last4: lastFour,
                expiryMonth: expirationMonth,
                expiryYear: expirationYear,
                cardType,
              })
              .then((response) => {
                const paymentMethod = response.data as PaymentMethod;

                actuallyMakeBooking(paymentMethod.id);

                // trackEvent(
                //   TrackingEvents.CardAdded,
                //   withVersion()
                // );
              })
              .catch((error) => {
                setSaving(false);

                console.debug("add payment method error: ", error);

                setErrorMessage(parseApiError(error));
              });
          })
          .catch((error) => {
            setSaving(false);

            console.debug("tokenize error: ", error);
            if (error.code === "HOSTED_FIELDS_FIELDS_EMPTY") {
              setErrorMessage("Add your card");
              return;
            }
            setErrorMessage("Error with your card");
          });
      } else {
        actuallyMakeBooking();
      }

      return;
    }

    // Apple pay
    if (paymentType === PaymentType.applepay) {
      if (!canMakeApplePayment) {
        setErrorMessage("You don't have any cards linked with Apple Pay");
        return;
      }

      let label = "Get Blys Pty Ltd";

      let paymentRequest = applePayInstance.createPaymentRequest({
        total: {
          label: label,
          amount: getTotal(),
        },

        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with
        // all Apple Pay transactions as a best practice.
        //requiredBillingContactFields: ['postalAddress']
      });
      console.log(paymentRequest.countryCode);
      console.log(paymentRequest.currencyCode);
      console.log(paymentRequest.merchantCapabilities);
      console.log(paymentRequest.supportedNetworks);

      let session = new window.ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = (event: any) => {
        applePayInstance
          .performValidation({
            validationURL: event.validationURL,
            displayName: label,
          })
          .then((merchantSession: any) => {
            console.debug("merchantSession:", merchantSession);

            session.completeMerchantValidation(merchantSession);
          })
          .catch((validationErr: any) => {
            // You should show an error to the user, e.g. 'Apple Pay failed to load.'
            console.error("Error validating merchant:", validationErr);
            session.abort();
          });
      };

      session.onpaymentauthorized = (event: any) => {
        console.log("Your shipping address is:", event.payment.shippingContact);

        applePayInstance
          .tokenize({
            token: event.payment.token,
          })
          .then((payload: any) => {
            // Send payload.nonce to your server.
            console.log("nonce:", payload.nonce);

            // If requested, address information is accessible in event.payment
            // and may also be sent to your server.
            // console.log('billingPostalCode:', event.payment.billingContact.postalCode);

            // After you have transacted with the payload.nonce,
            // call `completePayment` to dismiss the Apple Pay sheet.
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);

            // window.location.href = `${APPLE_PAY_SUCCESS_APP_REDIRECT_URL}?nonce=${payload.nonce}`;

            // alert("nonce: " + payload.nonce);

            let path = "/paymentmethods";

            axios
              .post(path, {
                nonce: payload.nonce,
                accessToken: accessToken,
                type: "apple-pay",
              })
              .then((response) => {
                let paymentMethod = response.data as PaymentMethod;

                setPaymentMethod(paymentMethod);

                actuallyMakeBooking(paymentMethod.id);
              });
          })
          .catch((tokenizeErr: any) => {
            console.error("Error tokenizing Apple Pay:", tokenizeErr);
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          });
      };

      session.begin();

      return;
    }

    // Others - just proceed
    actuallyMakeBooking();
  };

  const actuallyMakeBooking = (overridePaymentMethodId?: number) => {
    const isMassage = isMassageType();

    if (massageFor !== MassageFor.myself && isNil(recipient)) {
      setErrorMessage("Please select a recipient");
      return;
    }

    if (isNil(address)) {
      setErrorMessage("Please select an address");
      return;
    }

    if (isNil(selectedDay)) {
      setErrorMessage("Please select the date and time for your treatment");
      return;
    }

    if (isNil(earliestStartInMinutes)) {
      setErrorMessage("Please select the earliest start time for your treatment");
      return;
    }

    if (isNil(latestStartInMinutes)) {
      setErrorMessage("Please select the latest start time for your treatment");
      return;
    }

    if (isMassage && isNil(bookingPrice)) {
      setErrorMessage("Please select a treatment");
      return;
    }

    if (isMassage && isCoupleMassage) {
      if (couplesFallback && !couplesFallbackGenderPreference) {
        setErrorMessage("Please select your preferred therapist gender");
        return false;
      }
    }

    if (isNil(serviceId)) {
      setErrorMessage("Please select a treatment");
      return;
    }

    if (isNil(frequency)) {
      setErrorMessage("Please select a booking frequency");
      return;
    }

    const isPayWithInvoice = (isB2BUser || isAdmin) && paymentType === PaymentType.payWithInvoice;
    const isPayWithNdis =
      (isHomeCareClient || isAdmin) && paymentType === PaymentType.payWithNDISFunding;

    if (
      isNil(overridePaymentMethodId) &&
      paymentType === PaymentType.card &&
      isNil(paymentMethod) &&
      !checkIfTotalBookingPriceZero() &&
      !skipPayment &&
      !isAdminLoggedInAsClient &&
      !user?.isHomeCareProvider
    ) {
      setErrorMessage("Add your card");
      return;
    }

    if (isNil(earliestStartInMinutes) || isNil(latestStartInMinutes) || isNil(address)) {
      return;
    }

    const paymentmethodId = overridePaymentMethodId
      ? overridePaymentMethodId
      : paymentType === PaymentType.card ||
        paymentType === PaymentType.applepay ||
        paymentType === PaymentType.paypal
        ? paymentMethod?.id
        : null;

    // skip for this step
    // - 1. price is zero
    // - 2. paymentType: after pay
    // - 3. Admin placing booking on behalf of client with skip payment selected
    if (
      !checkIfTotalBookingPriceZero() &&
      paymentType !== PaymentType.afterpay &&
      !paymentmethodId &&
      !isAdminLoggedInAsClient &&
      !skipPayment &&
      !user?.isHomeCareProvider &&
      !isPayWithInvoice &&
      !isPayWithNdis
    ) {
      let errorPaymentMethodMessage = "Select your payment method";

      if (paymentType === PaymentType.card) {
        errorPaymentMethodMessage = "Add your card";
      } else if (paymentType === PaymentType.applepay) {
        errorPaymentMethodMessage = "Add your ApplePay account";
      } else if (paymentType === PaymentType.paypal) {
        errorPaymentMethodMessage = "Add your PayPal account";
      }

      setErrorMessage(errorPaymentMethodMessage);
      setSaving(false);
      return;
    }

    const timezone = address?.timezone || momentTZ.tz.guess();

    const selectedDayMoment = convertDatetoTimezoneMoment(selectedDay, timezone);
    const selectedStartDayMoment = convertDatetoTimezoneMoment(selectedDayRange.from, timezone);
    const selectedEndDayMoment = convertDatetoTimezoneMoment(selectedDayRange.to, timezone);
    const earliestTime = minutesToTime(earliestStartInMinutes);
    const latestTime = minutesToTime(latestStartInMinutes);

    const earliestTimeString = setMomentTime(selectedStartDayMoment, earliestTime, timezone);

    const latestTimeString = setMomentTime(selectedEndDayMoment, latestTime, timezone);

    let toBeReadyByString = null;
    if (toBeReadyByInMinutes) {
      const toBeReadyByTime = minutesToTime(toBeReadyByInMinutes);
      toBeReadyByString = setMomentTime(selectedDayMoment, toBeReadyByTime, timezone);
    }
    const instructions = {
      note: specialInstructions ? specialInstructions.trim() : "",
      preference: preferences,
      contraindications,
    };

    if (isCorporateType) {
      const payload = makeCorporatePayload(false);

      payload.paymentMethodId = paymentmethodId;
      payload.isAfterpayPayment = paymentType === PaymentType.afterpay;
      payload.paymentSplit = paymentSplit;
      payload.instructions = instructions;
      payload.paymentType = paymentType;

      const validPayload = validateCorporateFields(payload);
      if (!validPayload) {
        return;
      }
      setSaving(true);

      makeCorporateBooking(validPayload)
        .then((data) => {
          setSaving(false);
          invalidateUpcomingBookings();
          // Note :- redirectedAfterBookingPlaced has been added so that when clicking back from the booking detail page
          // after placing a booking it gets redirected to the upcommings page.
          history.push({
            pathname: `/bookings/${data.id}`,
            state: {
              redirectedAfterBookingPlaced: true,
            },
          });
          resetCorprateFields();
        })
        .catch((error) => {
          setSaving(false);
          setErrorMessage(parseApiError(error));
        });
      return;
    }

    setSaving(true);
    // Booking details
    let bookingDetails: any[] = [];
    let selectedTreatmentDetails = getTreatmentDetails();

    if (isMassage) {
      bookingDetails.push({
        massageType: massageType1,
        deliveryMethod: "inperson",
        genderPreference: genderPreference1,
        genderFallback: genderFallback,
        serviceDuration: massageLength,
        treatmentTypeId: massageTreatmentDetails[0].treatmentTypeId,
        addons: massageTreatmentDetails[0]?.addons,
      });

      if (isCoupleMassage) {
        bookingDetails.push({
          massageType: massageType2,
          deliveryMethod: "inperson",
          genderPreference: genderPreference2,
          serviceDuration: massageLength2,
          treatmentTypeId: massageTreatmentDetails[1].treatmentTypeId,
          addons: massageTreatmentDetails[1]?.addons,
        });
      }

      if (isBackToBackMassage) {
        bookingDetails[0].treatmentDetails = [
          {
            massageType: massageType1,
            serviceDuration: massageLength,
            treatmentTypeId: massageTreatmentDetails[0]?.treatmentTypeId,
            addons: massageTreatmentDetails[0]?.addons,
          },
          {
            massageType: massageType2,
            serviceDuration: massageLength2,
            treatmentTypeId: massageTreatmentDetails[1]?.treatmentTypeId,
            addons: massageTreatmentDetails[1]?.addons,
          },
        ];

        // @todo: include in bookingDetails [] for back to back
        bookingDetails.push({
          massageType: massageType2,
          deliveryMethod: "inperson",
          serviceDuration: massageLength2,
          treatmentTypeId: massageTreatmentDetails[1].treatmentTypeId,
          addons: massageTreatmentDetails[1]?.addons,
        });
      }
    } else {
      // Other services
      bookingDetails.push({
        massageType: selectedTreatmentDetails?.name,
        deliveryMethod: getTreatmentDeliveryMethod(),
        genderPreference: genderPreference || GenderPreference.dontCare,
        genderFallback: genderFallback,
      });
    }

    let fallback: any = null;
    if (isMassage && isCoupleMassage) {
      if (couplesFallback && couplesFallbackGenderPreference) {
        fallback = {
          couples: {
            autoConvert: couplesFallback,
            fallbackGenderPreference: couplesFallbackGenderPreference,
          },
        };
      } else {
        fallback = {
          couples: {
            autoConvert: false,
            fallbackGenderPreference: null,
          },
        };
      }
    }

    // Params
    let data = {
      accessToken,
      instructions,
      earliestTime: earliestTimeString,
      latestTime: latestTimeString,
      toBeReadyBy: toBeReadyByString,
      addressId: address.id,
      sessionType: !isMassage ? selectedTreatmentDetails?.name : sessionType,
      massageFor,
      timezone: momentTZ.tz.guess(),
      bookingDetails,
      paymentmethodId,
      frequency,
      recuringEndOn,
      recurring,
      isSameProvider,
      currency: "AUD", // TODO
      country: "Australia", // TODO
      // hasTravelHistory: hasTravelHistory,
      // hasFluLikeSymptoms: hasFluLikeSymptoms,
      recipientId: massageFor === MassageFor.someoneElse ? recipient?.id : undefined,
      preferredTherapists: !isEmpty(preferredTherapists)
        ? preferredTherapists
          .map(
            (therapist) =>
              therapist.therapistData.id || therapist.therapistData?.userservices[0]?.userId
          )
          .join(",")
        : undefined,
      couponCode: appliedCouponCode,
      channel: "web",
      clientPrice: getTotal(),
      fallback: fallback,
      numberOfClients: isHairAndMakeupBooking() ? numOfRecipients : null,
      serviceId,
      skipPayment,
      payWithInvoice: isPayWithInvoice,
      paymentType,
    } as any;

    if (sourceDetails && sourceDetails.source === UTM_SOURCES.google) {
      data = {
        ...data,
        sourceDetails,
      };
    }

    if (backupSelectedDay && backupEarliestStartInMinutes) {
      const selectedDayMoment = convertDatetoTimezoneMoment(backupSelectedDay, timezone);
      const backupSelectedStartDayMoment = convertDatetoTimezoneMoment(
        backupSelectedDayRange.from,
        timezone
      );
      const backupSelectedEndDayMoment = convertDatetoTimezoneMoment(
        backupSelectedDayRange.to,
        timezone
      );

      const earliestTime = minutesToTime(backupEarliestStartInMinutes);
      const latestTime = minutesToTime(backupLatestStartInMinutes as number);
      data.backup = {
        earliestTime: setMomentTime(backupSelectedStartDayMoment, earliestTime, timezone),
        latestTime: setMomentTime(backupSelectedEndDayMoment, latestTime, timezone),
      };
    }

    const bookingAnswers = getAnswersApiValue();

    // add massageDuration only if type is not 'massage'
    if (!isMassage) {
      data.massageDuration = massageLength;
      data.treatmentDetails = (treatmentDetails || []).map(
        ({ treatmentId, addons, ...others }) => ({
          ...others,
          treatmentTypeId: treatmentId,
          addons: formatTreatmentAddOnData(addons),
        })
      );
    }

    if (isHairAndMakeupBooking()) {
      data.treatmentDetails = [];
      const sessionTypes: string[] = [];
      treatmentDetails.forEach((td, index) => {
        const treatmentData: any = {
          treatmentTypeId: td.treatmentId,
          noteForProvider: td.noteForProvider,
          treatmentFor: td.treatmentFor,
          referenceImages: td.referenceImages || [],
          clientImages: td.clientImages || [],
          currentColour: isHairColouringBooking() ? td.currentColour : null,
          desiredColour: isHairColouringBooking() ? td.desiredColour : null,
          addons: formatTreatmentAddOnData(td?.addons),
        };

        if (bookingAnswers && bookingAnswers[index]) {
          treatmentData.bookingAnswers = bookingAnswers[index];
        }

        data.treatmentDetails.push(treatmentData);
        sessionTypes.push(getTreatmentDetailByTreatmentId(td.treatmentId).name);
      });

      data.sessionType = sessionTypes.join(",");
    } else if (isMassage) {
      data.treatmentDetails = [];

      if (isBackToBackMassage) {
        bookingDetails[0].treatmentDetails.forEach(
          ({ treatmentTypeId, addons }: any, index: number) => {
            data.treatmentDetails.push({
              treatmentTypeId,
              bookingAnswers: (bookingAnswers && bookingAnswers[index]) || [],
              addons: formatTreatmentAddOnData(addons),
            });
          }
        );
      } else {
        bookingDetails.forEach(({ treatmentTypeId, addons }, index) => {
          data.treatmentDetails.push({
            treatmentTypeId,
            bookingAnswers: (bookingAnswers && bookingAnswers[index]) || [],
            addons: formatTreatmentAddOnData(addons),
          });
        });
      }
    } else {
      data.bookingAnswers = bookingAnswers[0] || [];
    }

    if (
      !selectedDayMoment.isValid() ||
      earliestTimeString === "Invalid date" ||
      latestTimeString === "Invalid date"
    ) {
      post("api/v2/notifications/dev", {
        domain: "Web",
        log: {
          selectedDayMoment,
          earliestStartInMinutes,
          latestStartInMinutes,
          selectedDay,
          timezone,
          browser: navigator.userAgent,
        },
      });
    }

    axios
      .post(paymentType === PaymentType.afterpay ? "/afterpay" : "/bookings", data)
      .then((response) => {
        invalidateUpcomingBookings();

        setSaving(false);

        // segement
        trackEvent("Order Completed", {
          coupon_name: appliedCouponCode,
          discount: bookingPrice?.discount,
          booking_id: response.data.id,
          ...analyticsValues(),
        });

        if (!environment.isProduction) {
          trackEvent("Purchase", {
            coupon_name: appliedCouponCode,
            discount: bookingPrice?.discount,
            booking_id: response.data.id,
            ...analyticsValues(),
          });
        }

        // gtag
        dataLayerService.trackOrderCompleted(response.data, analyticsValues());

        // refetches price without treatment details
        // unable to fetch booking Price after booking created
        // resetTreatmentDetails();
        setToBeReadyByInMinutes(null);

        resetSourceDetails();

        // Note :- redirectedAfterBookingPlaced has been added so that when clicking back from the booking detail page
        // after placing a booking it gets redirected to the upcommings page.
        history.push({
          pathname: `/bookings/${response.data.id}`,
          state: {
            redirectedAfterBookingPlaced: true,
          },
        });
      })
      .catch((error) => {
        console.debug("error: ", error);

        setSaving(false);

        setErrorMessage(parseApiError(error));
      });
  };

  const makeCorporatePayload = (sendEmailAsQuote = false, data?: SendToSomeoneInterface) => {
    if (isNil(address)) {
      setErrorMessage("Please select an address");
      return;
    }

    if (isNil(selectedDay) || isNil(earliestStartInMinutes) || isNil(latestStartInMinutes)) {
      setErrorMessage("Please select the date and time for your treatment");
      return;
    }

    if (isNil(serviceId)) {
      setErrorMessage("Please select a treatment");
      return;
    }

    const timezone = address?.timezone || momentTZ.tz.guess();

    const selectedDayMoment = convertDatetoTimezoneMoment(selectedDay, timezone);
    const selectedStartDayMoment = convertDatetoTimezoneMoment(selectedDayRange.from, timezone);
    const selectedEndDayMoment = convertDatetoTimezoneMoment(selectedDayRange.to, timezone);
    const earliestTime = minutesToTime(earliestStartInMinutes);
    const latestTime = minutesToTime(latestStartInMinutes);

    const earliestTimeString = setMomentTime(selectedStartDayMoment, earliestTime, timezone);

    const latestTimeString = setMomentTime(selectedEndDayMoment, latestTime, timezone);

    const preferredPro = !isEmpty(preferredTherapists)
      ? preferredTherapists
        .map(
          (therapist) =>
            therapist.therapistData.id || therapist.therapistData?.userservices[0]?.userId
        )
        .join(",")
      : null;

    const payload: any = {
      addressId: address.id,
      timezone: timezone,
      massageType: massageType1,
      couponCode: appliedCouponCode,
      durationPerPerson,
      earliestTime: earliestTimeString,
      eventType,
      latestTime: latestTimeString,
      numberOfPerson,
      totalEventDuration,
      numberOfPros,
      recipientId: massageFor === MassageFor.someoneElse ? recipient?.id : null,
      serviceId,
      specialInstructions: specialInstructions ? specialInstructions.trim() : "",
      treatmentId,
      preferredTherapists: preferredPro,
      frequency: frequency,
      recuringEndOn,
      isSameProvider: isSameProvider,
      genderPreference: genderPreference1,
      genderFallback,
    };

    if (eventName && eventName.length > 0) {
      payload.eventName = eventName;
    }

    if (noteForRecipients && noteForRecipients.length > 0) {
      payload.noteForRecipients = noteForRecipients;
    }

    if (sendEmailAsQuote && data) {
      payload.sendToMe = data.isSendToMe;
      payload.recipientFirstName = data.firstName;
      payload.recipientLastName = data.lastName;
      payload.recipientEmail = data.email;
      payload.recipientNote = data.note;
    }

    return payload;
  };

  React.useEffect(() => {
    if (preFetchPaymentMethod) {
      preselectLastPaymentMethod();
    }
  }, []);

  const onCouponCodeActionClicked = () => {
    if (!isEmpty(appliedCouponCode)) {
      setAppliedCouponCode("");
    } else {
      if (!couponCode) {
        return setErrorMessage("Please enter coupon code.");
      }
      setValidatingCoupon(true);
      const accessToken = localStorage.getItem("token");

      trackEvent("Coupon Entered", {
        coupon_name: couponCode,
        version: 2,
      });

      axios
        .get(`/coupons/code/${couponCode}?accessToken=${accessToken}`)
        .then((response) => {
          if (response?.data?.error) {
            trackEvent("Coupon Denied", {
              coupon_name: couponCode,
              version: 2,
            });

            setErrorMessage(response?.data?.error);
          }

          const coupon = response.data as Coupon;

          if (!isNil(coupon?.id) || !isNil(coupon?.value)) {
            console.debug("coupon:", coupon);

            setAppliedCouponCode(couponCode);

            trackEvent("Coupon Applied", {
              coupon_name: couponCode,
              version: 2,
            });
          }
          setValidatingCoupon(false);
        })
        .catch((error) => {
          setErrorMessage(parseApiError(error));
          setValidatingCoupon(false);
        });
    }
  };

  const handleClick = () => {
    if (!selectedDay || !earliestStartInMinutes || !latestStartInMinutes) {
      setShowDateAndTimeModal(true);
    } else if (backupSelectedDay && (!backupEarliestStartInMinutes || !backupLatestStartInMinutes))
      setShowDateAndTimeModal(true);
    else {
      CheckIfBookingAlreadyExists();
    }
  };

  React.useEffect(() => {
    setCouponCode(appliedCouponCode || "");
    getBookingPrice();
  }, [appliedCouponCode]);

  React.useEffect(() => {
    if (paymentMethod?.type !== paymentType) {
      setPaymentMethod(null);
    }
  }, [paymentType]);

  React.useEffect(() => {
    if (rates && rates[0]) {
      const { selectedCountry } = rates[0];
      const currency = getValue(selectedCountry, "currency", "AUD");
      const currencySymbol = getValue(selectedCountry, "currencySymbol", "A$");
      setCurrency(currency, currencySymbol);
    }
  }, [rates]);

  const isDisabled = !!(paymentType === PaymentType.applepay && initingApplePay);

  const hasBackupDate = !!backupSelectedDay;

  if (needToLogin) {
    return (
      <Wrapper
        onLoggedIn={() => {
          fetchAddresses();
          getServicesRates();
        }}
      />
    );
  }

  return (
    <>
      <NewBookingWrapper
        fullWidth
        overline="Step 7 of 7"
        title="Review and book"
        // subtitle="No charge until booking is confirmed"
        footerLeftButton={
          isCorporateType ? (
            <Button
              loading={isSendingQuote}
              width={isMobile ? "100%" : "173px"}
              type={ButtonType.indigo}
              title={"Email as a quote"}
              onClick={handleSendEmailAsQuoteClicked}
            />
          ) : null
        }
        footerRightButton={
          <Button
            width={isMobile ? "100%" : "173px"}
            type={ButtonType.secondary}
            style={{
              paddingTop: "12px",
              paddingBottom: "12px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
            title="Request to book"
            onClick={handleClick}
            loading={saving}
            disabled={isDisabled}
          />
        }
        progress={STEP_PROGRESS[7]}
        showFooterDescription={true}
      >
        <Box display="flex" flexDirection={isMobile ? "column-reverse" : "row"}>
          <Box mt={4} width={isMobile ? "100%" : "500px"}>
            <PaymentSection
              paymentMethod={paymentMethod}
              onPaymentMethodClicked={() => {}}
              couponCode={couponCode}
              isValidatingCoupon={isValidatingCoupon}
              onCouponCodeChange={(code = "") => setCouponCode(code.replaceAll(" ", ""))}
              onCouponCodeActionClicked={onCouponCodeActionClicked}
              appliedCouponCode={appliedCouponCode}
              showPaymentSplit={false}
            />
          </Box>
          <Box ml={isMobile ? "0px" : "24px"} maxWidth={isMobile ? "100%" : "500px"}>
            <Box
              fontFamily="Museo"
              fontWeight={600}
              fontSize="16px"
              lineHeight="24px"
              color={Colors.Dusk}
              mb="16px"
              mt="32px"
            >
              Booking summary
            </Box>
            <Box
              display="flex"
              bgcolor="white"
              overflow="auto"
              maxWidth={"468px"}
              borderRadius="6px"
              border={`solid 1px ${Colors.PaleLilac}`}
            >
              <Box
                mt={"32px"}
                mb={"12px"}
                display={Display.Flex}
                flexDirection={FlexDirection.Column}
                gridRowGap={Spacing.S4}
              >
                <Box
                  paddingX="32px"
                  display={Display.Flex}
                  flexDirection={FlexDirection.Column}
                  gridRowGap={Spacing.S4}
                >
                  <BookingDetailsSection />
                  <SummarySection />
                </Box>
                <Box paddingX="16px">
                  <TotalDueAccordion
                    currency={currencySymbol}
                    totalPrice={getTotal()}
                    bookingStatus="new"
                    hasBackup={hasBackupDate}
                    paymentType={paymentType}
                    bookingPrice={bookingPrice}
                    setShowTotalDueUpToModal={setShowTotalDueUpToModal}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </NewBookingWrapper>

      <InfoModal
        visible={showTotalDueUpToModal}
        title={TOTAL_DUE_INFO.title}
        description={TOTAL_DUE_INFO.description}
        handleClose={handleShowTotalDueUpToModalClose}
        divider={false}
      />

      <ConfirmCheckoutModal
        open={checkoutModalOpen}
        onClose={() => setCheckoutModalOpen(false)}
        makeBooking={() => makeBooking()}
      />

      <SendQuotationToModal
        open={showQuotationToModal}
        onClose={() => setShowQuotationToModal(false)}
        onConfirm={(data: SendToSomeoneInterface) => handleConfirmClicked(data)}
      />

      <EmailVerificationModal
        onVerified={onEmailVerified}
        open={openVerificationModal}
        inputPlaceholder="&nbsp;"
        onClose={() => setOpenVerificationModal(false)}
        verifiedMessage="Your email is now verified, please proceed with the booking."
      />
      <ReviewAndBookDateAndTimeModal
        showChangeDateModal={showDateAndTimeModal}
        onClose={() => {
          setShowDateAndTimeModal(false);
        }}
      />
    </>
  );
}
