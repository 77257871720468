import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Moment } from "moment";

import AddressModal from "../../components/Addresses/AddressModal";
import CardWrapper from "../../components/BookingDetails/BookingDetailCard";
import BookingDetailRow from "../../components/BookingSummary/BookingDetailRow/BookingDetailRow";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import AbandonBookingModal from "../../components/ProDashboard/AbandonBookingModal";
import JobHeaderSubtitle from "../../components/ProDashboard/Job/JobHeaderSubtitle";
import SectionPageHeader from "../../components/SectionPageHeader";
import CustomTimePicker from "../../components/TimePicker/TimePicker";
import Wrapper from "../../components/Wrapper";
import { Spacing } from "../../components/v2/Styled/enum";
import {
  BOOKING_FALLBACK,
  BOOKING_STATUS,
  BOOKING_TIME,
  JobStatus,
  UpdateRequestStatus,
  UpdateRequestedBy,
} from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { BASE_UPLOADS_URL } from "../../constants/common";
import {
  ALTERNATIVE_OFFER_MESSAGE,
  ALTERNATIVE_OFFER_UPDATE_MESSAGE,
  DECLINED_REASONS_VALUE,
} from "../../constants/job";
import { Paths } from "../../constants/paths";
import { BOOKINGS_SECTION_VALUES } from "../../constants/prodashboard";
import { PRO_ACCOUNT_STATUS } from "../../constants/profile";
import {
  BOOKING_SESSION_TYPE,
  getMassageDescriptionForBooking,
  parseServiceDescriptionForJob,
} from "../../helpers/booking";
import { parseApiError, parseApiV2Error } from "../../helpers/error";
import { checkIfBackToBackUpdateRequired } from "../../helpers/job";
import { getCurrentMoment } from "../../helpers/time";
import { makeOffer, updateAlternateOffer } from "../../hooks/job/alternateDateAndTime.hooks";
import { useJobDetail } from "../../hooks/job/job.hooks";
import { useMobile, useTab } from "../../hooks/mobile";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import TherapistIcon from "../../images/pro-icon.png";
import ProfilePlaceHolder from "../../images/profile-pic-placeholder.png";
import AddressIcon from "../../images/review-map-icon.png";
import {
  Address,
  Booking,
  ConfirmAbandonJobParameters,
  TherapistBooking,
  User,
} from "../../models";
import { hasRangedDates } from "../../services/bookings/bookingTime.service";
import { sendUpdateRequest } from "../../services/bookings/bookings.service";
import {
  acceptJob,
  acceptOrDeclineBackToBackConversionUpdateRequest,
  acceptUpdateRequest,
  canCompleteJob,
  completeBooking,
  declineJob,
  declineUpdateRequest,
  getFormattedJob,
  onMyWay,
  removeAlternateOffer,
  shouldHideSensitiveData,
  validateTimeOfArrival,
} from "../../services/proDashboard/job.service";
import { confirmJobAbandon } from "../../services/proDashboard/jobAbandon.service";
import { invalidateQueries } from "../../services/query";
import { RootState } from "../../stores/V2";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import { bookingInitializerUsingJobId } from "../../stores/V2/booking/booking.initializers";
import { transformUpdatedData } from "../../stores/V2/booking/booking.transformer";
import { useAlertStore } from "../../stores/alert";
import { isCoupleMassageType } from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import { DEFAULT_COUNTRY, setCountryCode } from "../../utils/country";
import {
  convertToMoment,
  formatDateWithTimezone,
  getCurrentTimezone,
  getTimeDifferenceInHour,
  isSameDay,
  isSameMoment,
  isSelectedDateBeforeEndDate,
} from "../../utils/date.util";
import { checkIfEmpty, getValue } from "../../utils/object";
import BookingDetails from "./BookingDetails/BookingDetails";
import ClientDetails from "./ClientDetails/ClientDetails";
import ClientPaymentDetails from "./ClientPaymentDetails";
import CompletedJobActions from "./JobActions/CompletedJobActions";
import ConfirmedJobActions from "./JobActions/ConfirmedJobActions";
import JobActions from "./JobActions/JobActions";
import OnMyWayJobActions from "./JobActions/OnMyWayJobActions";
import PendingUpdateReqeuestActions from "./JobActions/PendingUpdateRequestActions";
import AcceptJobModal from "./Modals/AcceptModal/AcceptJobModal";
import BookingCompleteModal from "./Modals/BookingCompleteModal/BookingCompleteModal";
import DateAndTimeModal from "./Modals/DateAndTimeModal/DateAndTimeModal";
import DeclineBackToBackConversionModal from "./Modals/DeclineBackToBackConverionModal/DeclineBackToBackConversionModal";
import DeclineJobModal from "./Modals/DeclineJobModal/DeclineJobModal";
import MapModal from "./Modals/MapModal/MapModal";
import OnMyWayModal from "./Modals/OnMyWayModal/OnMyWayModal";
import PayoutInfoModal from "./Modals/PayoutInfoModal/PayoutInfoModal";
import OfferedAlternative from "./OfferedAlternative/OfferedAlternative";
import PayoutSummary from "./PayoutSummary/PayoutSummary";
import RecurringInfo from "./RecurringInfo";
import TreatmentDetailSection from "./TreatmentDetails/TreatmentDetailsSection";
import BackToBackUpdate from "./UpdateRequest/BackToBackUpdate";
import UpdateRequest from "./UpdateRequest/UpdateRequest";
import { isChatEnableForBooking } from "../../hooks/chat/chat.hooks";
import ChatButton from "../../components/Chat/ChatButton";
import JobDocuments from "./documents/documents";
import { KEYS } from "../../hooks/userTerms.hook";
import OfferAlternativeModal from "./Modals/OfferAlternative/OfferAlternativeModal";

const JobDetails = () => {
  const params: any = useParams();
  const history = useHistory();
  const { id: jobId } = params;
  const dispatch = useDispatch();
  const location = useLocation();

  const bookingData = useSelector(({ booking }: RootState) => booking);

  const isMobile = useMobile();
  const isTab = useTab();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { job, isLoading, refetch: refreshJobDetails } = useJobDetail({ jobId });
  const { services } = useServiceRates();

  const [showTimePicker, setShowTimePicker] = useState(false);
  const [selectedArrivalTime, setSelectedArrivalTime] = useState(getCurrentMoment());
  const [selectedArrivalType, setArrivalType] = useState(BOOKING_TIME.PREFERRED);
  const [data, setData] = useState<any>(null);

  const [showInfo, setShowInfo] = useState(false);
  const [infoDetails, setInfoModalDetails] = useState({ title: "", description: "" });

  const [disableActions, setDisableActions] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showDateAndTime, setShowDateAndTime] = useState(false);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [showAcceptRangeModal, setShowAcceptRangeModal] = useState(false);
  const [showOnMyWayModal, setShowOnMyWayModal] = useState(false);
  const [showBookingCompleteModal, setShowBookingCompleteModal] = useState(false);
  const [alretMessage, setAlertMessage] = useState("");
  const [showAbandonBookingModal, setShowAbandonBookingModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState<TherapistBooking | undefined>();
  const [arrivalTime, setArrivalTime] = useState<string | undefined>("");
  const [abandoning, setAbandoning] = useState(false);
  const [showBackToBackConversionModal, setShowBackToBackConversionModal] = useState(false);

  const [modelInfo, setModelInfo] = useState({
    visible: false,
    title: "",
    description: "",
  });

  const [addressModalOpen, setAddressModalOpen] = useState(false);

  const [updateOfferId, setUpdateOfferId] = useState<null | number>(null);

  const { user } = useUserStore();

  const currency = getValue(
    job,
    "bookingdetail.booking.service.selectedCountry.currencySymbol",
    ""
  );

  const setDefaultArrivalTime = ({ arrivalType }: any) => {
    const booking = getValue(job, "bookingdetail.booking");
    if (!booking) return;

    let { earliestTime } = booking;
    if (arrivalType === BOOKING_TIME.BACKUP && booking?.backup) {
      const backup = booking.backup;
      earliestTime = backup?.earliestTime;
    }

    const selectedMoment = convertToMoment(earliestTime, booking.timezone);
    setSelectedArrivalTime(selectedMoment);
  };

  useEffect(() => {
    initLoadBooking();
  }, [isLoading, job]);

  const initLoadBooking = () => {
    const loadBooking = getValue(location, "state.initialize", true);
    if (loadBooking || !bookingData.id) {
      initialize();
    }
  };

  const initialize = async () => {
    try {
      if (!jobId) return;
      dispatch(bookingActions.toggleFetching({ isFetching: true }));
      bookingInitializerUsingJobId(jobId, dispatch, (message) => {
        setErrorMessage(message);
        history.push(Paths.ProDashboardBookings, { jobId });
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const formattedData = getFormattedJob(job);
    setDefaultArrivalTime({ arrivalType: selectedArrivalType });
    setData(formattedData);
  }, [job]);

  const [canChat, setCanChat] = useState(false);
  useEffect(() => {
    if (job) {
      const { canClientChat } = isChatEnableForBooking(job.bookingdetail.booking, job.status);
      setCanChat(canClientChat);
    }
  }, [job]);

  if (isLoading || checkIfEmpty(job)) {
    return <Wrapper></Wrapper>;
  }

  const getServiceDescription = () => {
    if (!job || checkIfEmpty(job)) return "";
    const label = parseServiceDescriptionForJob({
      job,
      services,
      includeDuration: false,
      includeSessionType: false,
    });

    return label;
  };

  const handleAccept = (date: any, timezone: string) => {
    try {
      setShowTimePicker(false);
      setSelectedArrivalTime(date);

      acceptBooking({ timeOfArrival: date });
    } catch (err: any) {
      parseApiV2Error(err);
    }
  };

  const onAlternateOffer = () => {
    setShowDateAndTime(true);
  };

  const onArrivalTypeChanged = (selected: BOOKING_TIME) => {
    setArrivalType(selected);
    setDefaultArrivalTime({ arrivalType: selected });
  };

  const onDeclineConfirmed = ({
    reason,
    customReason,
  }: {
    reason: string;
    customReason: string;
  }) => {
    const booking = getValue(job, "bookingdetail.booking");
    if (checkIfEmpty(booking)) return;

    declineBooking({ jobId: job.id, reason, customReason });
  };

  const onDeclineClicked = () => {
    setShowDeclineModal(true);
  };

  const onInfoClicked = (details: any) => {
    if (checkIfEmpty(details)) return;

    const { title, description } = details;
    setInfoModalDetails({ title, description });
    setShowInfo(true);
  };

  const declineBooking = async ({ jobId, reason, customReason }: any) => {
    try {
      toggleDisableActions(true);
      const reasonText = reason === DECLINED_REASONS_VALUE.OTHER ? customReason : reason;
      await declineJob({ jobId, reason: reasonText, reasonAlias: reason });
      setSuccessMessage("The job has been declined");
      history.push(Paths.ProDashboardBookings, {
        shouldShowUpdateServicesModal: reason === DECLINED_REASONS_VALUE.DONT_OFFER_SERVICE,
      });
    } catch (err: any) {
      parseApiV2Error(err);
    }
    toggleDisableActions(false);
  };

  const onAcceptConfirmed = () => {
    setShowAcceptModal(false);

    const booking = getValue(job, "bookingdetail.booking");
    if (checkIfEmpty(booking)) return;

    let { earliestTime, latestTime, timezone } = booking;
    const hasDateRange = hasRangedDates({ booking, timezone });

    let datePickerRequired = hasDateRange;
    if (booking.backup && !datePickerRequired) {
      const { earliestTime: backupEarliest } = booking.backup;
      datePickerRequired = !isSameDay(earliestTime, backupEarliest, timezone);
    }

    if (datePickerRequired) {
      setShowAcceptRangeModal(true);
      return;
    }

    const isPickerRequired =
      booking.sessionType !== BOOKING_SESSION_TYPE.COUPLES &&
      !isSameMoment(earliestTime, latestTime);
    if (isPickerRequired) {
      setShowTimePicker(true);
      return;
    }

    acceptBooking({ timeOfArrival: convertToMoment(earliestTime, booking.timezone) });
  };

  const onAcceptClicked = () => {
    setShowAcceptModal(true);
  };

  const dismissTimePicker = () => {
    setShowTimePicker(false);
    setDefaultArrivalTime({ arrivalType: selectedArrivalType });
  };

  const toggleDisableActions = (value: boolean) => {
    setDisableActions(value);
  };

  const acceptBooking = async ({ timeOfArrival }: any) => {
    try {
      toggleDisableActions(true);

      // validate timeOfArrival and arrival type

      const booking = getValue(job, "bookingdetail.booking");
      if (!booking) return;

      const { timezone } = booking;
      const { isValid, arrivalType } = validateTimeOfArrival(
        timeOfArrival,
        timezone,
        booking,
        setErrorMessage
      );
      if (!isValid) {
        toggleDisableActions(false);
        return;
      }

      await acceptJob({
        jobId: job.id,
        status: arrivalType,
        timeOfArrival,
      });
      invalidateQueries([`job-${job.id}`]);
      invalidateQueries([KEYS.JOB_AGREEMENTS]);
      history.push(Paths.ProDashboardBookings, { section: BOOKINGS_SECTION_VALUES.UPCOMING });
      setSuccessMessage("The job has been accepted");
    } catch (err: any) {
      setErrorMessage(err.message || "Sorry something went wrong. Please try again.");
    }
    toggleDisableActions(false);
  };

  const hasAlternativeOffer = ({ booking }: any) => !checkIfEmpty(booking?.alternativeDate);

  const allowAlternateOffer = ({ booking }: any) => {
    return (
      !hasAlternativeOffer({ booking }) &&
      [BOOKING_SESSION_TYPE.SINGLES, BOOKING_SESSION_TYPE.BACK_TO_BACK].includes(
        booking.sessionType
      )
    );
  };

  const booking = getValue(job, "bookingdetail.booking");
  const therapistCount = getValue(booking, "bookingdetails", []).length || 1;
  const { latitude: bookingLat, longitude: bookingLng } = getValue(booking, "address");
  const addressSuburb = getValue(data, "address.suburb");

  const getClientName = (booking: Booking) => {
    if (!booking || checkIfEmpty(booking)) return "";
    const client = booking?.recipient || booking?.user;
    if (!client || checkIfEmpty(client)) return "";
    return `${client.firstName} ${client.lastName.charAt(0)}.`;
  };

  const getClientImage = (booking: Booking) => {
    const userProfile = getValue(booking, "user.profileImage", null);

    if (userProfile) {
      return `${BASE_UPLOADS_URL}/${userProfile}`;
    }

    return ProfilePlaceHolder;
  };

  const showAlternateOffer = hasAlternativeOffer({ booking });

  const onRemoveOffer = async ({ offerId }: { offerId: number }) => {
    if (!offerId) return;
    try {
      toggleDisableActions(true);
      await removeAlternateOffer({ id: offerId });

      setUpdateOfferId(null);
      refreshJobDetails();
      setSuccessMessage("Your alternative date & time has been removed.");
    } catch (err: any) {
      setErrorMessage(err.message || "Sorry something went wrong. Please try again.");
    }
    toggleDisableActions(false);
  };

  // update alternate offer
  const onUpdateOffer = async ({ offerId }: { offerId: number }) => {
    setUpdateOfferId(offerId);
    setShowDateAndTime(true);
  };

  const handleOfferSuccess = () => {
    refreshJobDetails();
    setShowDateAndTime(false);
  };

  const handleOfferModalClose = () => {
    setUpdateOfferId(null);
    setShowDateAndTime(false);
  };

  const shouldRestrictData = (user: User | null) => {
    const accountStatus = getValue(user, "therapistprofile.status") || PRO_ACCOUNT_STATUS.PENDING;
    return shouldHideSensitiveData({ accountStatus });
  };

  const hideSensativeData = shouldRestrictData(user);

  const getDefaultOfferTime = ({ booking }: any) => {
    const selectedOffer = getValue(booking, "alternativeDate[0]") || null;
    const selectedOfferTime = selectedOffer?.time || getValue(booking, "earliestTime");
    return updateOfferId ? selectedOfferTime : getValue(booking, "earliestTime");
  };

  const getOfferedRate = ({ booking }: any) => {
    const selectedOffer = getValue(booking, "alternativeDate[0]") || null;
    return selectedOffer?.preferredPayout;
  };

  const onOfferAlternate = ({
    jobId,
    selectedDateTime,
    preferredPayout,
  }: {
    jobId: string;
    selectedDateTime: Moment;
    preferredPayout: string;
  }) => {
    const body = { jobId, timeOfArrival: selectedDateTime, preferredPayout };
    if (!!updateOfferId) {
      updateExistingOffer(body);
    } else {
      createNewOffer(body);
    }
  };

  const createNewOffer = async (body: any) => {
    return makeOffer(body)
      .then(() => {
        handleOfferSuccess();
        setSuccessMessage(ALTERNATIVE_OFFER_MESSAGE);
      })
      .catch((error) => {
        setErrorMessage("Unable to offer alternative date & time.");
      });
  };

  const updateExistingOffer = (body: any) => {
    if (!updateOfferId) return;
    return updateAlternateOffer(body, updateOfferId)
      .then(() => {
        handleOfferSuccess();
        setSuccessMessage(ALTERNATIVE_OFFER_UPDATE_MESSAGE);
      })
      .catch((err) => {
        const errorMessage = err?.message || "Unable to update the offer. Please try again";
        setErrorMessage(errorMessage);
      });
  };

  const onAcceptRangedBooking = ({
    jobId,
    selectedDateTime,
  }: {
    jobId: string;
    selectedDateTime: Moment;
  }) => {
    acceptBooking({ timeOfArrival: selectedDateTime });
  };

  const getTimeLimits = ({ booking }: any) => {
    const { earliestTime, latestTime, backup: backupDate } = booking;
    if (!backupDate) return { min: earliestTime, max: latestTime };

    const { earliestTime: backupEarliest, latestTime: backupLatest } = backupDate;

    const min = isSelectedDateBeforeEndDate(earliestTime, backupEarliest)
      ? earliestTime
      : backupEarliest;
    const max = isSelectedDateBeforeEndDate(latestTime, backupLatest) ? backupLatest : latestTime;
    return { min, max };
  };

  const { min: minTime, max: maxTime } = getTimeLimits({ booking });

  const sessionType = getValue(booking, "sessionType");
  const bookingDetailId = getValue(job, "bookingdetail.id");
  const isCoupleBooking = isCoupleMassageType(sessionType);

  const bookingdetails =
    getValue(bookingData, "bookingDetails") || getValue(bookingData, "bookingdetails");

  const getTreatmentDetails = () => {
    let bookingDetails = bookingdetails;

    if (isCoupleBooking) {
      bookingDetails = bookingdetails.filter(
        (data: any) => data.bookingDetailId === bookingDetailId
      );
    }

    let treatments = bookingDetails.map((details: any) => {
      let { treatmentDetails } = details;
      if (!checkIfEmpty(treatmentDetails)) {
        const updatedDetails = treatmentDetails.map((detail: any) => ({
          ...detail,
          treatmentId: detail.id || detail.treatmentId,
        }));
        treatmentDetails = updatedDetails;
      }
      return treatmentDetails;
    });
    return treatments.flat();
  };

  const treatmentDetails = getTreatmentDetails();

  const jobStatus = getValue(job, "status");

  const onMyWayClicked = () => {
    const { timeOfArrival, bookingUpdates } = booking;

    let message = "";
    if (!booking.bookingdetails.every(({ job }: any) => !!job.therapistId)) {
      message =
        "'This booking hasn’t been confirmed yet, \nPlease do not proceed until all therapists are confirmed.";
      setShowInfo(true);
      setInfoModalDetails({
        title: "This booking hasn’t been confirmed yet",
        description: "Please do not proceed until all therapists are confirmed.",
      });
      return;
    }

    if (
      booking.bookingdetails.length > 1 &&
      bookingUpdates.length &&
      !bookingUpdates.every((update: any) => update.status === JobStatus.accepted)
    ) {
      setShowInfo(true);
      setInfoModalDetails({
        title: "This booking update hasn’t been confirmed yet",
        description: "Please do not proceed until all therapists have accepted the booking update.",
      });
      return;
    }

    const diffInHours = getTimeDifferenceInHour({ timeOfArrival });

    if (diffInHours >= 3) {
      message =
        "Too soon \nThis option will be available 3 hours prior to confirmed booking start time.";
    } else {
      message = "This will notify the client that the session is about to start.";
    }
    setShowOnMyWayModal(true);
    setAlertMessage(message);
  };

  const onMyWayConfirmed = async () => {
    setShowOnMyWayModal(false);

    try {
      toggleDisableActions(true);

      // validate timeOfArrival and arrival type

      const booking = getValue(job, "bookingdetail.booking");
      if (!booking) return;

      await onMyWay({
        jobId: job.id?.toString(),
      });

      await refreshJobDetails();

      // history.push(Paths.ProDashboardBookings, { section: BOOKINGS_SECTION_VALUES.UPCOMING });
      setSuccessMessage("The client has been notified");
    } catch (err: any) {
      setErrorMessage(err.message || "Sorry something went wrong. Please try again.");
    }
    toggleDisableActions(false);
  };

  const openAbandonBookingModal = () => {
    setSelectedJob(booking);
    setShowAbandonBookingModal(true);
  };

  const closeAbandonBookingModal = () => {
    setShowAbandonBookingModal(false);
  };

  const onAbandonConfirmed = async ({ id, fee, reason }: ConfirmAbandonJobParameters) => {
    if (!reason) {
      setErrorMessage("Abandon reason is required.");
    } else {
      setAbandoning(true);
      try {
        const res = await confirmJobAbandon({ id, fee, reason });
        if (res?.success) {
          await refreshJobDetails();
          setSuccessMessage("Job abandoned successfully");
          setShowAbandonBookingModal(false);
          invalidateQueries([KEYS.JOB_AGREEMENTS]);
        }
      } catch (error: any) {
        setErrorMessage(error);
      } finally {
        setAbandoning(false);
      }
    }
  };

  const onBookingCompleteClicked = () => {
    const timeOfArrival = getValue(booking, "timeOfArrival") || getValue(booking, "earliestTime");
    const { canComplete, waitTime } = canCompleteJob(timeOfArrival);

    if (canComplete) {
      setShowBookingCompleteModal(true);
      setAlertMessage("Please confirm that booking is complete.");
    } else {
      const timezone = getCurrentTimezone();
      const waitUntil = formatDateWithTimezone(waitTime, timezone, "h:mma");

      const message = `Please wait until ${waitUntil} to complete your booking.`;

      setModelInfo({
        visible: true,
        title: "Too soon to complete",
        description: message,
      });
    }
  };

  const onBookingCompleteConfirmed = async () => {
    setShowBookingCompleteModal(false);

    try {
      toggleDisableActions(true);

      // validate timeOfArrival and arrival type

      const booking = getValue(job, "bookingdetail.booking");
      if (!booking) return;

      await completeBooking({
        jobId: job.id?.toString(),
      });

      await refreshJobDetails();

      // history.push(Paths.ProDashboardBookings, { section: BOOKINGS_SECTION_VALUES.UPCOMING });
      setSuccessMessage("The booking has been completed.");
    } catch (err: any) {
      setErrorMessage(err.message || "Sorry something went wrong. Please try again.");
    }
    toggleDisableActions(false);
  };

  const { updated, existingBooking } = bookingData;
  const updatedKeys = Object.keys(updated);
  const { id } = booking;

  const handleUpdate = async () => {
    try {
      if (!id) return;
      const updatedData = transformUpdatedData(updated, existingBooking);

      if (isEmpty(updatedData)) {
        dispatch(bookingActions.clearUpdates({}));
        return;
      }

      const { status } = await sendUpdateRequest(id, updatedData);
      if (status !== "OK") throw Error("Unable to send booking request.");

      // const profession = getValue(selectedService, "profession", "therapist");

      // update the booking frequency for existing booking;

      setSuccessMessage("Your update request has been sent to the client for approval");

      dispatch(bookingActions.clearUpdates({}));
    } catch (err: any) {
      setErrorMessage(parseApiError(err));
    }
  };

  const address =
    getValue(bookingData, "address.fullAddress") || getValue(bookingData, "address.address");

  const bookingStatus = getValue(booking, "status");

  const numberOfTherapist = getValue(booking, "bookingdetails", []);

  const isLocationUpdateAllowed = () => {
    if (
      sessionType === BOOKING_SESSION_TYPE.COUPLES ||
      sessionType === BOOKING_SESSION_TYPE.CORPORATE ||
      numberOfTherapist.length > 1
    ) {
      return false;
    }

    if (bookingStatus === BOOKING_STATUS.NEW && jobStatus === JobStatus.rebooking) {
      return true;
    }

    if (bookingStatus === BOOKING_STATUS.COMPLETED && jobStatus === JobStatus.rebooking) {
      return true;
    }

    if (jobStatus === JobStatus.accepted) {
      return true;
    }

    return false;
  };

  const handleLocationChangeClicked = () => {
    setAddressModalOpen(true);
  };

  const { address: bookingAddress, userId } = bookingData;

  const handleAddressSelected = ({ address }: { address: Address }) => {
    const country = getValue(bookingAddress, "country", DEFAULT_COUNTRY);
    const selectedCountry = getValue(address, "country", DEFAULT_COUNTRY);

    if (country !== selectedCountry) {
      setErrorMessage("Address cannot be updated to a new country");
      return;
    }

    dispatch(bookingActions.updateBookingLocation({ address }));

    setCountryCode(selectedCountry);
  };

  const handleSaveAddress = ({ address }: { address: Address }) => {
    handleAddressSelected({ address });
    setAddressModalOpen(false);
  };

  const hasPendingUpdateRequest = () => {
    const updates = booking.bookingUpdates || [];
    if (!updates || !updates.length || !user) return false;

    const updateRequest = updates.find(
      (update: any) => update.proId === user.id && update.status === UpdateRequestStatus.pending
    );
    if (checkIfEmpty(updateRequest)) return false;

    const isRequestedByClient = updateRequest?.requestedBy === UpdateRequestedBy.client;

    const isChangeRequested = isRequestedByClient && updateRequest.requestDetails;

    return !!isChangeRequested;
  };

  const parseUpdateRequestData = () => {
    const updates = booking.bookingUpdates || [];
    if (!user || checkIfEmpty(updates)) return {};

    const updateRequest = updates.find(
      (update: any) => update.proId === user.id && update.status === UpdateRequestStatus.pending
    );
    if (checkIfEmpty(updateRequest)) return {};

    return updateRequest.requestDetails;
  };

  const handleAcceptBookingUpdateRequest = async () => {
    try {
      toggleDisableActions(true);
      await acceptUpdateRequest({ bookingId: booking.id });
      invalidateQueries([`job-${job.id}`]);
      setSuccessMessage("Update request accepted successfully.");
    } catch (error: any) {
      setErrorMessage(error.message || "Sorry something went wrong. Please try again.");
    } finally {
      toggleDisableActions(false);
    }
  };

  const handleDeclineBookingUpdateRequest = async () => {
    try {
      toggleDisableActions(true);
      await declineUpdateRequest({ bookingId: booking.id });
      invalidateQueries([`job-${job.id}`]);
      setSuccessMessage("Update request declined successfully.");
    } catch (error: any) {
      setErrorMessage(error.message || "Sorry something went wrong. Please try again.");
    } finally {
      toggleDisableActions(false);
    }
  };

  const onDeclineBackToBackUpdateReqeuest = () => {
    setShowBackToBackConversionModal(true);
    return;
  };

  const handleAcceptOrDeclineBackToBackConversionUpdateRequest = async (payload: {
    acceptFallback: boolean;
  }) => {
    const { acceptFallback } = payload;
    setShowBackToBackConversionModal(false);
    try {
      toggleDisableActions(true);
      await acceptOrDeclineBackToBackConversionUpdateRequest({
        jobId: job.id,
        body: {
          acceptFallback,
          type: BOOKING_FALLBACK.COUPLES_TO_BACK_TO_BACK,
        },
      });
      invalidateQueries([`job-${job.id}`]);
      setSuccessMessage(
        `Back to back conversion request  ${acceptFallback ? "accepted" : "declined"} successfully.`
      );
    } catch (error: any) {
      setErrorMessage(error.message || "Sorry something went wrong. Please try again.");
    } finally {
      toggleDisableActions(false);
    }
  };

  const clientRequestedUpdate = hasPendingUpdateRequest();
  const updateRequestData = clientRequestedUpdate ? parseUpdateRequestData() : null;

  const isPaidBooking = !(bookingStatus === BOOKING_STATUS.DECLINED);

  const backToBackUpdateRequired = checkIfBackToBackUpdateRequired(booking, job);

  const CanChatButton = (
    <>
      {canChat && (
        <ChatButton
          bookingId={jobId}
          conversationId={booking.conversationId}
          text="Message client"
          forClient={false}
        />
      )}
    </>
  );

  return (
    <Wrapper>
      <SectionPageHeader
        title={getServiceDescription()}
        onBack={() => history.goBack()}
        subtitleElement={
          <JobHeaderSubtitle jobId={job.id} jobStatus={jobStatus} bookingStatus={bookingStatus} />
        }
      />
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        ml={isMobile ? "24px" : "80px"}
        mr={isMobile ? "24px" : undefined}
        mt={isMobile ? "40px" : "42px"}
        mb={"16px"}
        alignItems={isMobile ? "center" : undefined}
      >
        <Box display="flex" flexDirection="column" width={isMobile ? "100%" : "468px"}>
          {/* <ContentHeader text="Booking details" style={styles.header} /> */}
          <Box>
            <BookingDetails
              job={job}
              address={data?.address}
              hideSensativeData={hideSensativeData}
              booking={getValue(job, "bookingdetail.booking")}
              openMapModal={() => {
                setOpenMapModal(true);
              }}
              isPaidBooking={isPaidBooking}
            />
            <ClientDetails
              job={job}
              hideSensativeData={hideSensativeData}
              isAdditionalDocument={true}
              refreshJobDetails={refreshJobDetails}
            />
            <JobDocuments job={job} />
          </Box>
        </Box>

        <Box ml={isMobile ? 0 : "29px"} mb={"60px"} maxWidth={isMobile ? "100%" : "468px"}>
          {/* <Box id="mobile" mb={isMobile ? "22px" : "60px"} /> */}

          {showAlternateOffer && (
            <CardWrapper>
              <Box style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                <OfferedAlternative
                  timezone={booking?.timezone}
                  offer={getValue(booking, "alternativeDate[0]", null)}
                  onRemoveOffer={onRemoveOffer}
                  onUpdateOffer={onUpdateOffer}
                  currency={currency}
                />
              </Box>
            </CardWrapper>
          )}

          <CardWrapper
            style={{
              rowGap: Spacing.S4,
            }}
          >
            <BookingDetailRow
              icon={AddressIcon}
              text={address || ""}
              iconWrapperStyle={{ width: "24px", height: "24px", marginTop: "5px" }}
              marginBottom={1}
              allowChange={isLocationUpdateAllowed()}
              handleChange={handleLocationChangeClicked}
              verticalAlignment="start"
            />
            <TreatmentDetailSection
              job={job}
              booking={booking}
              sessionType={data?.sessionType}
              corporateInfo={getValue(booking, "corporateInfo")}
              treatmentDetails={treatmentDetails}
            />
            <RecurringInfo booking={booking} />
            <Box display="flex" alignItems="center" gridGap={16}>
              <img src={TherapistIcon} alt="therapist" style={{ width: 24, height: 24 }} />
              <Box style={{ ...styles.detailText }}>
                {therapistCount} {therapistCount > 1 ? "Therapists" : "Therapist"}
              </Box>
            </Box>
          </CardWrapper>

          <ClientPaymentDetails job={job} style={{ marginTop: "16px" }} />

          <Box mt={"16px"}>
            <PayoutSummary
              job={job}
              selectedTime={selectedArrivalType}
              onInfoClicked={onInfoClicked}
              proPayouts={getValue(location, "state.proPayouts", null)}
            />
            {clientRequestedUpdate && (
              <UpdateRequest job={job} booking={booking} updatedData={updateRequestData} />
            )}

            {backToBackUpdateRequired && <BackToBackUpdate />}
          </Box>

          {(jobStatus === JobStatus.pending || jobStatus === JobStatus.rebooking) && (
            <JobActions
              onAccept={onAcceptClicked}
              onDecline={onDeclineClicked}
              onAlternateOffer={onAlternateOffer}
              allowAlternateOffer={allowAlternateOffer({ booking })}
              disabled={disableActions}
              CanChatBtn={CanChatButton}
            />
          )}

          {backToBackUpdateRequired && (
            <PendingUpdateReqeuestActions
              onUpdateRequestAccept={() =>
                handleAcceptOrDeclineBackToBackConversionUpdateRequest({ acceptFallback: true })
              }
              onUpdateRequestDeclined={() => onDeclineBackToBackUpdateReqeuest()}
              disabled={disableActions}
              acceptBtnText="Accept back-to-back update"
            />
          )}

          {jobStatus === JobStatus.accepted &&
            !clientRequestedUpdate &&
            !backToBackUpdateRequired && (
            <ConfirmedJobActions
              onMyWay={onMyWayClicked}
              onAbandon={openAbandonBookingModal}
              onBookingUpdate={handleUpdate}
              allowBookingUpdate={updatedKeys?.length > 0 && isPaidBooking}
              disabled={disableActions}
              isPaidBooking={isPaidBooking}
              CanChatBtn={CanChatButton}
            />
          )}
          {[JobStatus.finished, JobStatus.reviewLeft].includes(jobStatus) &&
            updatedKeys?.length > 0 && (
            <CompletedJobActions onBookingUpdate={handleUpdate} jobStatus={jobStatus} />
          )}

          {clientRequestedUpdate && !backToBackUpdateRequired && (
            <PendingUpdateReqeuestActions
              onUpdateRequestAccept={handleAcceptBookingUpdateRequest}
              onUpdateRequestDeclined={handleDeclineBookingUpdateRequest}
              disabled={disableActions}
            />
          )}

          {jobStatus === JobStatus.onway && !clientRequestedUpdate && !backToBackUpdateRequired && (
            <OnMyWayJobActions
              onAbandon={openAbandonBookingModal}
              onBookingComplete={onBookingCompleteClicked}
              onBookingUpdate={onAlternateOffer}
              allowBookingUpdate={true}
              disabled={disableActions}
              CanChatBtn={CanChatButton}
            />
          )}
        </Box>
      </Box>

      <AcceptJobModal
        visible={showAcceptModal}
        address={addressSuburb}
        onClose={() => setShowAcceptModal(false)}
        onConfirm={onAcceptConfirmed}
      />

      <OnMyWayModal
        visible={showOnMyWayModal}
        address={addressSuburb}
        onClose={() => setShowOnMyWayModal(false)}
        onConfirm={onMyWayConfirmed}
        message={alretMessage}
      />

      <DeclineBackToBackConversionModal
        visible={showBackToBackConversionModal}
        onClose={() => setShowBackToBackConversionModal(false)}
        onConfirm={() =>
          handleAcceptOrDeclineBackToBackConversionUpdateRequest({ acceptFallback: false })
        }
      />

      <BookingCompleteModal
        visible={showBookingCompleteModal}
        address={addressSuburb}
        onClose={() => setShowBookingCompleteModal(false)}
        onConfirm={onBookingCompleteConfirmed}
        message={alretMessage}
      />

      {showAbandonBookingModal && (
        <AbandonBookingModal
          open={showAbandonBookingModal}
          onClose={closeAbandonBookingModal}
          abandoning={abandoning}
          onConfirm={onAbandonConfirmed}
          jobId={jobId}
          arrivalTime={booking?.timeOfArrival}
          selectedJob={job}
        />
      )}

      <MapModal
        openMapModal={openMapModal}
        onCloseModal={() => {
          setOpenMapModal(false);
        }}
        massageDescription={getMassageDescriptionForBooking(booking)}
        booking={booking}
        clientName={getClientName(booking)}
        clientImage={getClientImage(booking)}
        bookingPosition={{ lat: bookingLat, lng: bookingLng }}
      />

      <DeclineJobModal
        visible={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        onConfirm={onDeclineConfirmed}
      />

      <PayoutInfoModal
        renderHTML
        info={infoDetails}
        visible={showInfo}
        onClose={() => setShowInfo(false)}
      />

      <OfferAlternativeModal
        visible={showDateAndTime}
        isMobile={isMobile}
        isTab={isTab}
        onClose={() => handleOfferModalClose()}
        jobId={job.id?.toString()}
        updateOfferId={updateOfferId}
        offeredTime={getDefaultOfferTime({ booking })}
        offeredPrice={getOfferedRate({ booking })}
        timezone={getValue(booking, "timezone")}
        actionName="Offer"
        title="Make an offer"
        onConfirm={onOfferAlternate}
        currency={currency}
      />

      {/* accept range booking */}
      <DateAndTimeModal
        visible={showAcceptRangeModal}
        isMobile={isMobile}
        isTab={isTab}
        onClose={() => setShowAcceptRangeModal(false)}
        jobId={job.id?.toString()}
        updateOfferId={updateOfferId}
        offeredTime={booking?.earliestTime}
        timezone={getValue(booking, "timezone")}
        label="Time of arrival"
        actionName="Accept"
        onConfirm={onAcceptRangedBooking}
      />

      <CustomTimePicker
        onOpen={() => {}}
        onChange={() => {}}
        open={showTimePicker}
        onClose={dismissTimePicker}
        maxTime={maxTime}
        minTime={minTime}
        timezone={getValue(booking, "timezone")}
        onAccept={(date) => handleAccept(date, booking.timezone)}
      />

      {/* Address modal */}
      <AddressModal
        open={addressModalOpen}
        onClose={() => setAddressModalOpen(false)}
        onSaved={(address) => handleSaveAddress({ address })}
        userId={userId}
        addedByPro={true}
      />

      <InfoModal
        title={modelInfo.title}
        visible={modelInfo.visible}
        description={modelInfo.description}
        handleClose={() => setModelInfo({ ...modelInfo, visible: false })}
        divider={false}
      />
    </Wrapper>
  );
};

const styles = {
  header: {
    fontWeight: 600,
    color: Colors.Dusk,
    marginBottom: "16px",
  },
  wrapper: {
    display: "flex",
  },
  payoutHeader: {
    fontSize: "16px",
    fontWeight: 600,
    color: Colors.Dusk,
  },
  detailText: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
};

export default JobDetails;
